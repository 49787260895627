import { Row, Col, Card, Form, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { getMasterData, valueOptions } from "../../helpers/utils";
import { API_URI } from "../../constants";
import { resolve } from "dns";
import { toast } from "react-toastify";
import FormInput from "../../components/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// static data

const EditWorkflowSpecial = () => {
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const [dataDepartment, setDataDepartment] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>([]);
  const [dataID, setDataID] = useState<any>({});
  const [department, setDepartment] = useState<any>();
  const colourOptions = [
    { value: 'Hanies Febrian', label: 'Hanies Febrian'},
    { value: 'Nursuhadah', label: 'Nursuhadah'},
    { value: 'Imran Ridwan', label: 'Imran Ridwan'},
    { value: 'Ranny Widya Kartika', label: 'Ranny Widya Kartika'},
    { value: 'Rosaline Joyo', label: 'Rosaline Joyo'},
    { value: 'Oetomo Raharjo', label: 'Oetomo Raharjo'},
    { value: 'Nur Intan Kinasih', label: 'Nur Intan Kinasih'},
  ]
  const company = [
    { value: 'STM', label: 'STM'},
    { value: 'VEI', label: 'VEI'},
  ]
  const fromContract = [
    { value: '0', label: 'From Master Data Employee'},
  ]
  const getDataDepartment = () => getMasterData('department-list', {
    columnFilters: [{
      id: 'company',
      value: currentCompany?.company_initial
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    if(data && data?.data && data?.data?.data) {
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.name + ' - ' + a.description,
          value: a.id
        })
      }
    }
    setDataDepartment(hasil);
  })
  const getDataUser = (department: any) => 
  new Promise((resolve, reject) => {
    getMasterData('workflows-user-list', {
      columnFilters: [{
        id: 'company',
        value: currentCompany?.company_initial
      }],
      pagination: {
        "pageIndex": 0,
        "pageSize": 10000
      },
      sorting: []
    }).then(data => {
      const hasil = []
      if(data && data?.data && data?.data?.data) {
        for(const a of data.data.data){
          hasil.push({
            key: a.user_id,
            label: a.payroll_name, // + ' - ' + a.description,
            value: a.user_id
          })
        }
      }
      setDataUser(hasil);
      resolve(hasil)
    }).catch(err => {
      reject(err);
    })
  })

  const getData = (department: any, data_user: any) => {
    return new Promise ((resolve, reject) => {
      fetch(API_URI + 'workflows-list', {
        method: "POST",
        body:JSON.stringify({
            "columnFilters": [{
              "id": "company",
              "value": currentCompany?.company_initial
            },{
                "id": "department",
                "value": department
            },{
              "id": "module_id",
              "value": 3
            }],
            "pagination": {
              "pageIndex": 0,
              "pageSize": 10000
            },
            "sorting": []
        })
      })
      .then(response => response.json())
      .then((pilih) => {
        if(pilih && pilih?.data && pilih?.data?.data){
          // setData({
          //   approval1: [],
          //   approval2: [],
          //   approval3: [],
          //   approval4: [],
          //   approval5: [],
          //   approval6: [],
          // })
          const a = [...pilih.data.data];
          const d: any = {
            approval1: [],
            approval2: [],
            approval3: [],
            approval4: [],
            approval5: [],
            approval6: [],
          }
          const dId: any = {}
          for (const b of a) {
            const c = b.user_id.replace('[','').replace(']','').split(',')
            console.log(c);
            if (b.step === '1') {
              d.approval1 = c //b.user_id
              dId.appID1 = b.id
            } else if(b.step === '2'){
              d.approval2 = c //b.user_id
              dId.appID2 = b.id
            } else if(b.step === '3'){
              d.approval3 = c //b.user_id
              dId.appID3 = b.id
            } else if(b.step === '4'){
              d.approval4 = c //b.user_id
              dId.appID4 = b.id
            } else if(b.step === '6'){
              d.approval5 = c //b.user_id
              dId.appID5 = b.id
            } else if(b.step === '5'){
              d.approval6 = c //b.user_id
              dId.appID6 = b.id
            }
            console.log(d);
          }
          setData(d)
          setDataID(dId)
          resolve(d)
        }else{
          setData({
            approval1: [],
            approval2: [],
            approval3: [],
            approval4: [],
            approval5: [],
            approval6: [],
          })
          setDataID({})
          resolve([])
        }
      })
      .catch(err => reject(err))
    })
  }

  const addData = ((dataSend: any) => {
    const approval1 = [];
    const approval2 = [];
    const approval3 = [];
    const approval4 = [];
    const approval5 = [];
    const approval6 = [];
    if(dataSend.approval1 && dataSend.approval1.length > 0){
      for (const a of dataSend.approval1){
        approval1.push(parseInt(a));
      }
    }
    if(dataSend.approval2 && dataSend.approval2.length > 0){
      for (const a of dataSend.approval2){
        approval2.push(parseInt(a));
      }
    }
    if(dataSend.approval3 && dataSend.approval3.length > 0){
      for (const a of dataSend.approval3){
        approval3.push(parseInt(a));
      }
    }
    if(dataSend.approval4 && dataSend.approval4.length > 0){
      for (const a of dataSend.approval4){
        approval4.push(parseInt(a));
      }
    }
    if(dataSend.approval5 && dataSend.approval5.length > 0){
      for (const a of dataSend.approval5){
        approval5.push(parseInt(a));
      }
    }
    // for (const a of dataSend.approval5){
    //   approval5.push(parseInt(a));
    // }
    if(dataSend.approval6 && dataSend.approval6.length > 0){
      for (const a of dataSend.approval6){
        approval6.push(parseInt(a));
      }
    }
    const a = [{
      id: dataID.appID1 ? dataID.appID1 : null,
      user_id: approval1,
      company: currentCompany?.company_initial,
      department_id: department,
      module_id: '3',
      wf_role: 'Direct Manager',
      step: '1',
    },{
      id: dataID.appID2 ? dataID.appID2 : null,
      user_id: approval2,
      company: currentCompany?.company_initial,
      department_id: department,
      module_id: '3',
      wf_role: 'Department Manager',
      step: '2',
    },{
    //   id: dataID.appID3 ? dataID.appID3 : null,
    //   user_id: approval3,
    //   company: currentCompany?.company_initial,
    //   department_id: department,
    //   module_id: '3',
    //   wf_role: 'Finance Manager',
    //   step: '3',
    // },{
      id: dataID.appID4 ? dataID.appID4 : null,
      user_id: approval4,
      company: currentCompany?.company_initial,
      department_id: department,
      module_id: '3',
      wf_role: 'Releaser FV60',
      step: '4',
    },{
    //   id: dataID.appID5 ? dataID.appID5 : null,
    //   user_id: approval5,
    //   company: currentCompany?.company_initial,
    //   department_id: department,
    //   module_id: '3',
    //   wf_role: 'President Director',
    //   step: '6',
    // },{
      id: dataID.appID6 ? dataID.appID6 : null,
      user_id: approval6,
      company: currentCompany?.company_initial,
      department_id: department,
      module_id: '3',
      wf_role: 'Treasury',
      step: '5'
    }]
    return new Promise ((resolve, reject) => {
      fetch(API_URI + 'workflows', {
        method: "POST",
        body:JSON.stringify(a)
      })
      .then(response => response.json())
      .then(async(pilih) => {
        resolve(pilih)
      })
      .catch(err => reject(err))
    })
  })

  const schemaResolver = yupResolver(
    yup.object().shape({
      company: yup.string().required("Please enter Company"),
      // approval2_input: yup.string().required('Please enter Approval 2'),
      // approval3_input: yup.string().required('Please enter Approval 3'),
      // approval4_input: yup.string().required('Please enter Approval 4'),
      // approval5_input: yup.string().required('Please enter Approval 5')
    })
  )
  const methods = useForm<any>({
    // defaultValues: listData2,
    reValidateMode:"onBlur",
    resolver: schemaResolver,
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;
  

  setValue('company', currentCompany?.company_initial)
  setValue('approval1_input', data?.approval1_input)
  setValue('approval2_input', data?.approval2_input)
  setValue('approval3_input', data?.approval3_input)
  setValue('approval4_input', data?.approval4_input)
  setValue('approval5_input', data?.approval5_input)
  setValue('approval6_input', data?.approval6_input)

  useEffect(() => {
    getDataDepartment();
    // getDataUser();
  },[])
  return(
  <>
    <div className="mb-3"></div>
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Card
              style={{ width: "fit-content" }}
              className="employee-claim-subtitle mb-1"
            >
              <Card.Body style={{ width: "fit-content" }} className="">
                <h4
                  className="header-title2 mt-0 mb-0"
                  style={{ color: "#b28924", fontSize: 16 }}
                >
                  Workflow Management Special Payment
                </h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
              <form onSubmit={handleSubmit((d) => console.log(d))}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Company</Form.Label>
                      {/* <Select
                        defaultValue={company[0]}
                        name="company"
                        options={company}
                        isClearable
                        isSearchable
                        isDisabled={true}
                      /> */}
                      <FormInput
                        name="company"
                        key="company"
                        type="text"
                        readOnly={true}
                        defaultValue=""
                        value={currentCompany?.company_initial}
                        register={register}
                        errors={errors}
                        hidden={false}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Department</Form.Label>
                      <Select
                        defaultValue={department ? department : null}
                        name="Department"
                        options={dataDepartment}
                        isClearable
                        isSearchable
                        onChange={(e: any) => {
                          console.log(e);
                          if(e){
                            // setData({
                            //   ...data,
                            //   department: e.value
                            // })
                            setDepartment(e.value);
                            // department = e.value;
                            getDataUser(e.value).then((hasil) => {
                              console.log(hasil);
                              getData(e.value, hasil).then((hasil2) =>{
                                // setData(hasil2);
                                console.log(hasil2);
                              })
                            })
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 1 (Department Manager): </Form.Label>
                      <Select
                        name="approval2select"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        value={data?.approval2?.map((a: any) => {
                          console.log(a);
                          console.log(dataUser[valueOptions(dataUser, a)])
                          return dataUser[valueOptions(dataUser, a)]
                        })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval2: [...hasil],
                            approval2_input: JSON.stringify(hasil)
                          })
                        }}
                        // value=
                      />
                      <FormInput
                        name="approval2_input"
                        key="approval2_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval2_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 2 (Approver FV60): </Form.Label>
                      <Select
                        name="approval1select"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        value={
                          data?.approval1?.map((a: any) => {
                            console.log(a);
                            console.log(dataUser[valueOptions(dataUser, a)])
                            return dataUser[valueOptions(dataUser, a)]
                          })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval1: [...hasil],
                            approval1_input: JSON.stringify(hasil)
                          })
                        }}
                        // value=
                      />
                      <FormInput
                        name="approval1_input"
                        key="approval1_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval1_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 3 (Releaser FV60): </Form.Label>
                      <Select
                        // defaultValue={colourOptions[4]}
                        name="approval4"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        value={data?.approval4?.map((a: any) => {
                          console.log(a);
                          console.log(dataUser[valueOptions(dataUser, a)])
                          return dataUser[valueOptions(dataUser, a)]
                        })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval4: [...hasil],
                            approval4_input: JSON.stringify(hasil)
                          })
                        }}
                      />
                      <FormInput
                        name="approval4_input"
                        key="approval4_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval4_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 4 (Treasury): </Form.Label>
                      <Select
                        // defaultValue={colourOptions[4]}
                        name="approval6"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        value={data?.approval6?.map((a: any) => {
                          console.log(a);
                          console.log(dataUser[valueOptions(dataUser, a)])
                          return dataUser[valueOptions(dataUser, a)]
                        })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval6: [...hasil],
                            approval6_input: JSON.stringify(hasil)
                          })
                        }}
                      />
                      <FormInput
                        name="approval6_input"
                        key="approval6_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval6_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                {/* <Row> */}
                  {/* <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 3 (Finance Manager): </Form.Label>
                      <Select
                        // defaultValue={colourOptions[4]}
                        name="approval3"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        value={data?.approval3?.map((a: any) => {
                          console.log(a);
                          console.log(dataUser[valueOptions(dataUser, a)])
                          return dataUser[valueOptions(dataUser, a)]
                        })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval3: [...hasil],
                            approval3_input: JSON.stringify(hasil)
                          })
                        }}
                      />
                      <FormInput
                        name="approval3_input"
                        key="approval3_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval3_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col> */}
                  
                {/* </Row> */}
                {/* <br></br> */}
                {/* <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Approval 3 (President Director): </Form.Label>
                      <Select
                        // defaultValue={colourOptions[4]}
                        name="approval5"
                        options={dataUser}
                        isClearable
                        isSearchable
                        isMulti
                        // isDisabled={true}
                        value={data?.approval5?.map((a: any) => {
                          console.log(a);
                          console.log(dataUser[valueOptions(dataUser, a)])
                          return dataUser[valueOptions(dataUser, a)]
                        })}
                        onChange={(e: any) => {
                          console.log(e);
                          const hasil = []
                          for(const a of e) {
                            hasil.push(a.value)
                          }
                          setData({
                            ...data,
                            approval5: [...hasil],
                            approval5_input: JSON.stringify(hasil)
                          })
                        }}
                      />
                      <FormInput
                        name="approval5_input"
                        key="approval5_input"
                        type="text"
                        readOnly={false}
                        // defaultValue=""
                        value={data?.approval5_input}
                        register={register}
                        errors={errors}
                        hidden={true}
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  
                </Row> */}
                <br></br>
                <br></br>
                <Row>
                  <div className="d-flex justify-content-end mt-3">
                    <Button 
                      className="vale-button me-2"
                      onClick={
                        handleSubmit(() => {
                          addData(data).then((result: any) => {
                            if(result.success) {
                              toast.success('Success: Saved', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            }else{
                              toast.error('Failed: Save', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            }
                          })
                        })
                      }
                    >Save</Button>
                  </div>
                </Row>
              </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </>
  )
}
export default EditWorkflowSpecial;