import React, { useState, useMemo } from "react";
import { Button, Card } from "react-bootstrap";
import DetailList from "../../../components/MyComponents/Detail/DetailList";
import logoVale from "../../../assets/images/stm-logo.png";
// import logoVale from "../../../assets/images/logo-dashboard.png";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {history} from "../historyData";
import HistoryComponent from "../../../components/MyComponents/History/HistoryComponent";
import { Check, Close } from "@mui/icons-material";
import ConfirmationModal from "../../../components/MyComponents/Modal/ConfirmationModal";

type ExpenditureColumn = {
    date: string;
    description: string;
    justification: string;
    wbs: string;
    costElement: string;
    curr: string;
    amount: string;
    currRate: string;
    amountInIdr: string;
  };

const dataView = {
  // "Advanced / Settlement": "Settlement",
  "Employee Name": "Dwayne a",
  "Employee ID": "10400111",
  "Department": "Corporate IT",
  "Position": "IT Analyst",
  "Have Cash Advance": "Yes",
  "Doc No":"V-00081",
  "Doc Date":new Date().getDate() +"/"+ new Date().getMonth() + "/" + new Date().getFullYear(),
  "Starting Date": "",
  "Ending Date":"",
  "Travel Route": "",
  "Kind Of Transport": "",
  "Cash Advance": "IDR 50.000.000",
  "Total Expense Claim": "IDR 100.000.000",
  "Difference": "IDR 50.000.000",
  "Company to Pay Employee": 'IDR 0',
  "Return To Company": "IDR 50.000.000"
  }
const changeIntoTitleValue = (data:any) => {
    const a = Object.entries(data);
    console.log(a)
    const b = []
    for (const c of a) {
        b.push({
            title: c[0],
            value: [c[1]]
        })
    }
    return b
}

const ViewClaimFormCCHeadDept = () => {
const items = changeIntoTitleValue(dataView);
const [modalType, setModalType] = useState("");
const [openModal, setOpenModal] = useState(false);
const [expenditureData, setExpenditureData] = useState([
    {
      date: "19/07/2022",
      description: "Other expense",
      justification: "Services",
      wbs: "VEI employee",
      costElement: "9090909",
      curr: "IDR",
      amount: "100.000",
      currRate: "",
      amountInIdr: "",
    },
  ]);
  const columnExpenditureMRT = useMemo<MRT_ColumnDef<ExpenditureColumn>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date (dd/mm/yyyy)",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 180,
      },
      {
        accessorKey: "description",
        header: "Description (Type of expense)",
        size: 180,
      },
      {
        accessorKey: "justification",
        header: "Justification (Detail of expenditure)",
        size: 180,
      },
      {
        accessorKey: "wbs",
        header: "WBS (STM) / Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costElement",
        header: "Cost Element",
        size: 180,
      },
      {
        accessorKey: "curr",
        header: "Cur",
        size: 180,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 300,
      },
      {
        accessorKey: "currRate",
        header: "Currency Rate",
        size: 180,
      },
      {
        accessorKey: "amountInIdr",
        header: "Amount In IDR",
        size: 300,
      },
    ],
    []
  );
return (
  <div className="card mb-12">
    <div className="card-header bg-vale">Detail Claim Non CC</div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      <img src={logoVale} height="100em" alt="" />
      <h5>Claim Credit Card</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          <DetailList items={items} />
        </Card.Body>
        <MaterialReactTable
          columns={columnExpenditureMRT}
          data={expenditureData}
          enablePagination={false}
        />
      </Card>
    </div>
    <div
      style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
      className="mx-3 mb-3"
    >
      <Button
        onClick={() => {
          setModalType("reject");
          setOpenModal(true);
        }}
        className="reject-button d-flex align-items-center justify-content-center"
      >
        <p className="m-0">Reject</p>
        <Close />
      </Button>
      <Button
        onClick={() => {
          setModalType("approve");
          setOpenModal(true);
        }}
        className="vale-button d-flex align-items-center justify-content-center"
      >
        <p className="m-0">Approve</p>
        <Check />
      </Button>
    </div>
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">Employee Claim History</p>
      </div>
      <HistoryComponent data={history} />
    </div>
    <ConfirmationModal
      modalType={modalType}
      open={openModal}
      setOpen={setOpenModal}
    />
  </div>
);
    }
export default ViewClaimFormCCHeadDept;