import { Form } from "react-bootstrap";

const ColorPicker = () => {
  return (
    <form action="/action_page.php">
      <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label>
      <Form.Control
        type="color"
        size="sm"
        id="exampleColorInput"
        defaultValue="#ff0000"
        title="Choose your color"
      />
    </form>
  );
};

export default ColorPicker;
