import { useSelector } from "react-redux";

const useGetUserRole = () => {
  const { Auth } = useSelector((state: any) => state);
  // console.log(Auth, 'AUTH')
  const user = Auth?.user;
  const userData = user?.data;
  const userRole = userData?.role?.[0]?.title;
  const userName = userData.name
  const userId = userData.id
  const userCompanyCode = userData.mapping[0]?.company_code

  return { userRole, userName, userId, userData, userCompanyCode }
}

export default useGetUserRole