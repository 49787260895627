const HeaderDetailWithStatus = ({
  status = "Waiting",
  data,
  title,
  status_name,
}: {
  status?: string;
  data?: any;
  title?: any;
  status_name?: any;
}) => {
  return (
    <div className="card-header bg-vale d-flex align-items-center justify-content-between">
      <p className="m-0">{title ? title : "Detail BAST"}</p>
      {status !== "none" && (
        <div className="d-flex align-items-center ms-2">
          <p className="m-0" style={{ color: "white" }}>
            Status:
          </p>
          <div
            className={`status-${
              status.includes("Waiting") || status.includes("Input")
                ? "pending"
                : status.includes("Completed") ||
                  status.includes("Paid") ||
                  status.includes("Approved")
                ? "complete"
                : "reject"
            } ms-2`}
            style={{ backgroundColor: `${data?.status_color}` }}
          >
            <p>{status_name ? status_name : data?.status_name ? data?.status_name : status}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderDetailWithStatus;
