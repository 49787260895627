import { Card } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// types
type HistoryBASTColumn = {
  date: any;
  company: any;
  user: any;
  process: any;
  activity: any;
  comment: string;
};

// dummy data

const HistoryComponent = (props: any) => {
  const columnMRT = useMemo<MRT_ColumnDef<HistoryBASTColumn>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        maxSize: 100,
        Cell: ({ cell }) => <div>{dayjs(cell.getValue() as string).format("DD MMM YYYY hh:mm")}</div>
      },
      {
        accessorKey: "company",
        header: "Vendor Name",
        size: 80,
      },
      {
        accessorKey: "user",
        header: "User",
        size: 80,
      },
      {
        accessorKey: "process",
        header: "Process",
        size: 80,
      },
      {
        accessorKey: "activity",
        header: "Activity",
        size: 80,
      },
      {
        accessorKey: "comment",
        header: "Comment",
        size: 80,
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columnMRT}
      data={props.data}
      enableSorting={false}
      enableFilters={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [5, 10, 25, 50],
      }}
    />
  );
};

export default HistoryComponent;
