import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import EditInvoiceSAP from "../pages/sap/EditInvoiceSAP";
import SpecialPaymentList from "../pages/sap";
import ViewInvoiceSAP from "../pages/sap/ViewInvoiceSAP";
import CreateClaimNonCC from "../pages/valeForm/CreateClaimNonCC";
import ClaimNonCCList from "../pages/valeForm";
import ViewClaimForm from "../pages/valeForm/ViewClaimForm";
import SpecialPaymentListHeadDept from "../pages/sap/head_dept";
import SpecialPaymentListAdminAP from "../pages/sap/admin_ap";
import SpecialPaymentListFinance from "../pages/sap/finance";
import SpecialPaymentListPresDir from "../pages/sap/presdir";
import SpecialPaymentListTreasury from "../pages/sap/treasury";
import ClaimNonCCListHeadDept from "../pages/valeForm/head-dept";
import ClaimNonCCListAdminAp from "../pages/valeForm/admin-ap";
import ClaimNonCCListFinance from "../pages/valeForm/finance";
import ClaimNonCCListPresDir from "../pages/valeForm/presdir";
import ClaimNonCCListTreasury from "../pages/valeForm/treasury";
import AddInvoiceSAPTreasury from "../pages/sap/treasury/AddInvoiceSAP";
import CreateClaimNonCCTreasury from "../pages/valeForm/treasury/CreateClaimNonCC";
import CreateClaimCC from "../pages/valeForm/CreateClaimCC";
import ClaimCCList from "../pages/valeForm/list_cc";
import ViewClaimFormCC from "../pages/valeForm/ViewClaimFormCC";
import ClaimCCListHeadDept from "../pages/valeForm/head-dept/list_cc";
import ClaimCCListAdminAp from "../pages/valeForm/admin-ap/list_cc";
import ClaimCCListFinance from "../pages/valeForm/finance/list_cc";
import ClaimCCListPresDir from "../pages/valeForm/presdir/list_cc";
import ColorPicker from "../pages/layouting/ColorPicker";
import ViewInvoiceSAPTreasury from "../pages/sap/treasury/ViewInvoiceSAP";
import ViewClaimFormTreasury from "../pages/valeForm/treasury/ViewClaimForm";
import MasterContract from "../pages/superAdmin/Contract";
import MasterGL from "../pages/superAdmin/Gl";
import MasterWBS from "../pages/superAdmin/Wbs";
import MasterStockCode from "../pages/superAdmin/StockCode";
import TypeExpense from "../pages/superAdmin/TypeExpense";
import MasterCostCentre from "../pages/superAdmin/CostCentre";
import EditWorkflowBAST from "../pages/workflow/WorkflowBAST";
import EditWorkflowInvoice from "../pages/workflow/WorkflowInvoice";
import EditWorkflowSpecial from "../pages/workflow/WorkflowSpecial";
import EditWorkflowEmployeeClaim from "../pages/workflow/WorkflowEmployeeClaim";
import ViewClaimFormHeadDept from "../pages/valeForm/head-dept/ViewClaimForm";
import ViewClaimFormCCHeadDept from "../pages/valeForm/head-dept/ViewClaimFormCC";
import ViewInvoiceSAPHeadDept from "../pages/sap/head_dept/ViewInvoiceSAP";
import ReportBAST from "../pages/report/ListBAST";
import ReportInvoice from "../pages/report/ListInvoice";
import ReportSpecialPayment from "../pages/report/ListSpecialPayment";
import ReportEmployeeClaim from "../pages/report/ListEmployeeClaim";
import ViewInvoiceSAPAdminAp from "../pages/sap/admin_ap/ViewInvoiceSAP";
import AddInvoiceSAPAdminAP from "../pages/sap/admin_ap/AddInvoiceSAP";
import ViewClaimFormAdminAp from "../pages/valeForm/admin-ap/ViewClaimForm";
import ViewClaimFormCCAdminAp from "../pages/valeForm/admin-ap/ViewClaimFormCC";
import DetailBASTWarehouse from "../pages/bast/DetailBASTWarehouse";
import InvoiceDetailAdminAP from "../pages/invoice/InvoiceDetailAdminAP";
import LayoutInputContainer from "../components/MyComponents/Input/LayoutInputContainer";
import CreateClaimNonCCAdminAp from "../pages/valeForm/admin-ap/CreateClaimNonCC";
import InvoiceDetailTax from "../pages/invoice/InvoiceDetailTax";
import VendorAdminUsers from "../pages/superAdmin/VendorAdminUser";
import Bank from "../pages/superAdmin/Bank";
import Tax from "../pages/superAdmin/Tax";
import CreateInvoice from "../pages/invoice/CreateInvoice";
import IASAdminAPView from "../pages/sap/IASAdminAPView";
import EditWorkflowEmployeeClaimCC from "../pages/workflow/WorkflowEmployeeClaimCC";
import TestTable from "../pages/valeForm/TestTable";
import KpiHome from "../pages/dashboard/KpiHome";
import ChangePasswordForgot from "../pages/user/ChangePasswordForgot";
import MiroLeadHome from "../pages/dashboard/MiroLeadHome";
import BastListClone from "../pages/bast/BastListClone";
import CreateBASTClone from "../pages/bast/CreateBASTClone";
import DetailBASTClone from "../pages/bast/DetailBASTClone";
import MasterUom from "../pages/superAdmin/Uom";
import Rkab from "../pages/rkab/Rkab";
import ExchangeRate from "../pages/superAdmin/ExchangeRate";
import HouseBank from "../pages/superAdmin/HouseBank";
import AccountBank from "../pages/superAdmin/AccountBank";
import PaymentMethod from "../pages/superAdmin/PaymentMethod";
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
// const EcommerceDashboard = React.lazy(
//   () => import("../pages/dashboard/Ecommerce/")
// );
const Dashboard = React.lazy(() => import("../pages/dashboard/"));
const Layout = React.lazy(() => import("../pages/layouting"));
const LayoutInvoiceStatus = React.lazy(
  () => import("../pages/layouting/LayoutInvoiceStatus")
);
// const AnalyticsDashboard = React.lazy(
//   () => import("../pages/dashboard/Analytics/")
// );

// user
const ChangePassword = React.lazy(() => import("../pages/user/ChangePassword"));

// roles
const RolesList = React.lazy(() => import("../pages/roles/"));
const ListRoles = React.lazy(() => import("../pages/roles/listRoles"));

// master data
const MasterData = React.lazy(() => import("../pages/masterData/"));

// bast management
const BASTList = React.lazy(() => import("../pages/bast/BASTList"));
const BASTListVendor = React.lazy(() => import("../pages/bast/BASTListVendor"));
const BASTListContractOwner = React.lazy(
  () => import("../pages/bast/BASTListContractOwner")
);
const BASTListWarehouse = React.lazy(
  () => import("../pages/bast/BASTListWarehouse")
);
const BASTListSAPAdmin = React.lazy(
  () => import("../pages/bast/BASTListSAPAdmin")
);
const CreateBAST = React.lazy(() => import("../pages/bast/CreateBAST"));
const DetailBAST = React.lazy(() => import("../pages/bast/DetailBAST"));
const DetailBASTVendor = React.lazy(
  () => import("../pages/bast/DetailBASTVendor")
);
const EditBAST = React.lazy(() => import("../pages/bast/EditBAST"));
const EditBASTSAPAdmin = React.lazy(
  () => import("../pages/bast/EditBASTSAPAdmin")
);

// invoice
const InvoiceList = React.lazy(() => import("../pages/invoice/InvoiceList"));
const InvoiceListVendor = React.lazy(
  () => import("../pages/invoice/InvoiceListVendor")
);
const InvoiceListAdminAP = React.lazy(
  () => import("../pages/invoice/InvoiceListAdminAP")
);
const InvoiceListTax = React.lazy(
  () => import("../pages/invoice/InvoiceListTax")
);
const InvoiceListFinanceManager = React.lazy(
  () => import("../pages/invoice/InvoiceListFinanceManager")
);
const InvoiceListPresdir = React.lazy(
  () => import("../pages/invoice/InvoiceListPresdir")
);
const InvoiceListTreasury = React.lazy(
  () => import("../pages/invoice/InvoiceListTreasury")
);
// const InvoiceListTreasury2 = React.lazy(
//   () => import("../pages/invoice/InvoiceListTreasury2")
// );
const InvoiceRegistered = React.lazy(
  () => import("../pages/invoice/InvoiceRegistered")
);
const InvoiceAdd = React.lazy(() => import("../pages/invoice/InvoiceAdd"));
const InvoiceDetail = React.lazy(
  () => import("../pages/invoice/InvoiceDetail")
);
const InvoiceDetailFinance = React.lazy(
  () => import("../pages/invoice/InvoiceDetailFinance")
);
const InvoiceDetailTreasury = React.lazy(
  () => import("../pages/invoice/InvoiceDetailTreasury")
);
const InvoiceDetailPresdir = React.lazy(
  () => import("../pages/invoice/InvoiceDetailPresdir")
);
// const IAS = React.lazy(() => import("../pages/invoice/IAS"));
const InvoiceIAS = React.lazy(() => import("../pages/invoice/InvoiceIAS"));
const InvoiceIASAdminAP = React.lazy(
  () => import("../pages/invoice/InvoiceIASAdminAP")
);

// SAP
const SAPDashboard = React.lazy(() => import("../pages/sap/Dashboard"));
const SAPAddInvoice = React.lazy(() => import("../pages/sap/AddInvoiceSAP"));

// Super Admin
const SAUpdateDataVendor = React.lazy(
  () => import("../pages/superAdmin/SAUpdateDataVendor")
);
const AddAdministrator = React.lazy(
  () => import("../pages/superAdmin/AddAdministrator")
);
const SAVendorList = React.lazy(
  () => import("../pages/superAdmin/SAVendorList")
);
const VendorDetail = React.lazy(
  () => import("../pages/superAdmin/VendorDetail")
);
const Users = React.lazy(() => import("../pages/superAdmin/Users"));
const UserDetail = React.lazy(() => import("../pages/superAdmin/UserDetail"));
const Employee = React.lazy(() => import("../pages/superAdmin/Employee"));
const Department = React.lazy(() => import("../pages/superAdmin/Department"));
const POOnTheSpot = React.lazy(() => import("../pages/superAdmin/POOnSpot"));

// Vale Forms
const ClaimForm = React.lazy(() => import("../pages/valeForm/ClaimForm"));
const ClaimFormEmployee = React.lazy(
  () => import("../pages/valeForm/ClaimFormEmployee")
);

// Dashboards
const VendorDashboard = React.lazy(() => import("../pages/dashboard/Vendor"));
const WarehouseDashboards = React.lazy(
  () => import("../pages/dashboard/Warehouse")
);
const ContractOwnerDashboards = React.lazy(
  () => import("../pages/dashboard/ContracOwner")
);
const SAPAdminDashboards = React.lazy(
  () => import("../pages/dashboard/SAPAdmin")
);
const AdminAPDashboards = React.lazy(
  () => import("../pages/dashboard/AdminAP")
);
const TaxDashboards = React.lazy(() => import("../pages/dashboard/Tax"));
const FinanceManagerDashboards = React.lazy(
  () => import("../pages/dashboard/Finance")
);
const TreausryDashboards = React.lazy(
  () => import("../pages/dashboard/Treasury")
);
const PresdirDashboards = React.lazy(
  () => import("../pages/dashboard/Presdir")
);
const DirectManagerDashboards = React.lazy(
  () => import("../pages/dashboard/Manager")
);

const UserManual = React.lazy(
  () => import("../pages/manual/UserManual")
);

// apps
// const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
// const Projects = React.lazy(() => import("../pages/apps/Projects/"));
// const ProjectDetail = React.lazy(
//   () => import("../pages/apps/Projects/Detail/")
// );
// - chat
// const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - email
// const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
// const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
// const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - tasks
// const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
// const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// - file
// const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const ContactSupportMain = React.lazy(() => import("../pages/other/ContactSupportMain"));
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Profile = React.lazy(() => import("../pages/other/Profile"));
// const Activity = React.lazy(() => import("../pages/other/Activity"));
// const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
// const Pricing = React.lazy(() => import("../pages/other/Pricing"));

// uikit
// const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
// const Widgets = React.lazy(() => import("../pages/widgets/"));

// icons
// const Unicons = React.lazy(() => import("../pages/icons/Unicons"));
// const FeatherIcons = React.lazy(() => import("../pages/icons/Feather/"));
// const BootstrapIcon = React.lazy(() => import("../pages/icons/Bootstrap/"));

// forms
// const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
// const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
// const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
// const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
// const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
// const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
// const Charts = React.lazy(() => import("../pages/charts/"));

// tables
// const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
// const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// maps
// const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
// const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  route: PrivateRoute,
  icon: "airplay",
  header: "Navigation",
  element: <Dashboard />,
  roles: ["Admin"],
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard",
      name: "Root",
      element: <Dashboard />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/kpi-payment-invoice",
      name: "Root",
      element: <KpiHome />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/miro-invoice-lead-time",
      name: "Root",
      element: <MiroLeadHome />,
      route: PrivateRoute,
    },
  ],
};

const userProfileRoute: RoutesProps = {
  path: "/user",
  name: "User",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  header: "User",
  children: [
    {
      path: "/user/change-password",
      name: "UserChangePassword",
      roles: ["Admin"],
      element: <ChangePassword />,
    },
    {
      path: "/user/profile",
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
    },
  ],
};

const rolesListRoutes: RoutesProps = {
  path: "/sys-admin/master-data/user-management/roles",
  name: "RolesList",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  element: <RolesList />,
  header: "Roles",
};

const listRolesRoutes: RoutesProps = {
  path: "/sys-admin/master-data/user-management/roles/listRoles",
  name: "listRoles",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  element: <ListRoles />,
  header: "Roles",
};

const rolesRoutes = [rolesListRoutes, listRolesRoutes];

const masterDataRoutes1: RoutesProps = {
  path: "/masterData",
  name: "MasterData",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  element: <MasterData />,
  header: "MasterData",
};

const masterDataRoutes = [masterDataRoutes1];

const bastManagement: RoutesProps = {
  path: "/bast",
  name: "BAST Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/bast/list",
      name: "BAST List Vendor",
      element: <BASTList />,
      route: PrivateRoute,
    },
    {
      path: "/bast/vendor/list",
      name: "BAST List Vendor",
      element: <BASTListVendor />,
      route: PrivateRoute,
    },
    {
      path: "/bast/contract-owner/list",
      name: "BAST List Contract Owner",
      element: <BASTListContractOwner />,
      route: PrivateRoute,
    },
    {
      path: "/bast/warehouse/list",
      name: "BAST List Warehouse",
      element: <BASTListWarehouse />,
      route: PrivateRoute,
    },
    // {
    //   path: "/sap-admin/bast/list",
    //   name: "BAST List SAP Admin",
    //   element: <BASTListSAPAdmin />,
    //   route: PrivateRoute,
    // },
    {
      path: "/bast/add",
      name: "Create BAST",
      element: <CreateBAST />,
      route: PrivateRoute,
    },
    {
      path: "/bast/edit/:id",
      name: "Detail BAST",
      element: <EditBAST />,
      route: PrivateRoute,
    },
    {
      path: "/bast/input-ses-migo/edit/:id",
      name: "Detail BAST",
      element: <EditBASTSAPAdmin />,
      route: PrivateRoute,
    },
    {
      path: "/bast/warehouse/edit/:id",
      name: "Detail BAST",
      element: <EditBASTSAPAdmin />,
      route: PrivateRoute,
    },
    {
      path: "/bast/detail/:id",
      name: "Detail BAST",
      element: <DetailBAST />,
      route: PrivateRoute,
    },
    {
      path: "/bast/vendor/detail/:id",
      name: "Detail BAST",
      element: <DetailBASTVendor />,
      route: PrivateRoute,
    },
  ],
};

const layouting: RoutesProps = {
  path: "/layout",
  name: "Layout",
  route: PrivateRoute,
  roles: ["Admin"],
  children: [
    {
      path: "/layout/layout",
      name: "Invoice Status",
      element: <Layout />,
      route: PrivateRoute,
    },
    {
      path: "/layout/invoice-status",
      name: "Invoice Status",
      element: <LayoutInvoiceStatus />,
      route: PrivateRoute,
    },
    {
      path: "/layout/color-picker",
      name: "Color Picker",
      element: <ColorPicker />,
      route: PrivateRoute,
    },
    {
      path: "/layout/inputs",
      name: "Inputs",
      element: <LayoutInputContainer />,
      route: PrivateRoute,
    },
  ],
};

const invoice: RoutesProps = {
  path: "/invoice",
  name: "Invoice",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/invoice/list",
      name: "Invoice list",
      element: <InvoiceList />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/vendor/list",
      name: "Invoice list Vendor",
      element: <InvoiceListVendor />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/admin-ap/list",
      name: "Invoice list Admin AP",
      element: <InvoiceListAdminAP />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/tax/list",
      name: "Invoice list Tax",
      element: <InvoiceListTax />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/finance-manager/list",
      name: "Invoice list Finance Manager",
      element: <InvoiceListFinanceManager />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/presdir/list",
      name: "Invoice list Presdir",
      element: <InvoiceListPresdir />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/treasury/list",
      name: "Invoice list Treasury",
      element: <InvoiceListTreasury />,
      route: PrivateRoute,
    },
    // {
    //   path: "/invoice/treasury2/list",
    //   name: "Invoice list Treasury",
    //   element: <InvoiceListTreasury2 />,
    //   route: PrivateRoute,
    // },
    {
      path: "/invoice/registered",
      name: "Invoice Registered",
      element: <InvoiceRegistered />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/add",
      name: "Invoice Add",
      element: <InvoiceAdd />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/create",
      name: "Invoice Create",
      element: <CreateInvoice />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/edit/:id",
      name: "Invoice edit",
      element: <InvoiceAdd />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/detail/:id",
      name: "Invoice Detail",
      element: <InvoiceDetail />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/finance-manager/detail/:id",
      name: "Invoice Detail Finance Manager",
      element: <InvoiceDetailFinance />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/treasury/detail",
      name: "Invoice Detail Treasury",
      element: <InvoiceDetailTreasury />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/presdir/detail",
      name: "Invoice Detail Presdir",
      element: <InvoiceDetailPresdir />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/ias/:id",
      name: "Invoice Detail",
      // element: <InvoiceIAS />,
      element: <InvoiceIASAdminAP />,
      route: PrivateRoute,
    },
  ],
};

const sapAdmin: RoutesProps = {
  path: "/sap",
  name: "SAP Admin",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/sap/dashboard",
      name: "SAP Dashboard",
      element: <SAPDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/sap/special-payment/:page/:id",
      name: "SAP Add Invoice",
      element: <SAPAddInvoice />,
      route: PrivateRoute,
    },
    {
      path: "/sap/special-payment-ias/:edit/:id",
      name: "IAS",
      element: <IASAdminAPView />,
      route: PrivateRoute,
    },
    // {
    //   path: "/sap/special-payment-edit",
    //   name: "SAP Edit Invoice",
    //   element: <EditInvoiceSAP />,
    //   route: PrivateRoute,
    // },
    {
      path: "/sap/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentList />,
      route: PrivateRoute,
    },
    {
      path: "/sap/special-payment-view/:id",
      name: "SAP View Invoice",
      element: <ViewInvoiceSAP />,
      route: PrivateRoute,
    },
    {
      path: "/sap/head-dept/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentListHeadDept />,
      route: PrivateRoute,
    },
    {
      path: "/sap/head-dept/special-payment-view",
      name: "SAP View Invoice",
      element: <ViewInvoiceSAPHeadDept />,
      route: PrivateRoute,
    },
    {
      path: "/sap/admin-ap/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentListAdminAP />,
      route: PrivateRoute,
    },
    {
      path: "/sap/admin-ap/special-payment/:page",
      name: "SAP Add Invoice",
      element: <AddInvoiceSAPAdminAP />,
      route: PrivateRoute,
    },
    {
      path: "/sap/admin-ap/special-payment-view",
      name: "SAP View Invoice",
      element: <ViewInvoiceSAPAdminAp />,
      route: PrivateRoute,
    },
    {
      path: "/sap/finance/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentListFinance />,
      route: PrivateRoute,
    },
    {
      path: "/sap/presdir/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentListPresDir />,
      route: PrivateRoute,
    },
    {
      path: "/sap/treasury/special-payment-List",
      name: "SAP List Invoice",
      element: <SpecialPaymentListTreasury />,
      route: PrivateRoute,
    },
    {
      path: "/sap/treasury/special-payment/:page",
      name: "SAP Add Invoice",
      element: <AddInvoiceSAPTreasury />,
      route: PrivateRoute,
    },
    {
      path: "/sap/treasury/special-payment-view",
      name: "SAP View Invoice",
      element: <ViewInvoiceSAPTreasury />,
      route: PrivateRoute,
    },
  ],
};

const report: RoutesProps = {
  path: "/report",
  name: "Report",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/report/bast",
      name: "Report BAST",
      element: <ReportBAST />,
      route: PrivateRoute,
    },
    {
      path: "/report/invoice",
      name: "Report Invoice",
      element: <ReportInvoice />,
      route: PrivateRoute,
    },
    {
      path: "/report/special-payment",
      name: "Report Special Payment",
      element: <ReportSpecialPayment />,
      route: PrivateRoute,
    },
    {
      path: "/report/employee-claim",
      name: "Report Employee Claim",
      element: <ReportEmployeeClaim />,
      route: PrivateRoute,
    },
  ],
};

const superAdmin: RoutesProps = {
  path: "/sys-admin",
  name: "Super Admin",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/sys-admin/master-data",
      name: "SA Master Data",
      route: PrivateRoute,
      // element: <SAPAddInvoice />,
      roles: ["Admin"],
      children: [
        {
          path: "/sys-admin/master-data/user-management",
          name: "User Management",
          route: PrivateRoute,
          roles: ["Admin"],
          children: [
            {
              path: "/sys-admin/master-data/user-management/users",
              name: "Users",
              route: PrivateRoute,
              element: <Users />,
              roles: ["Admin"],
              children: [
                {
                  path: "/sys-admin/master-data/user-management/users/:userId",
                  name: "User Detail",
                  route: PrivateRoute,
                  element: <UserDetail />,
                  roles: ["Admin"],
                },
              ],
            },
            {
              path: "/vendor/dashboards/user-management/vendor-admin-users",
              name: "Vendor Admin Users",
              route: PrivateRoute,
              element: <VendorAdminUsers />,
              roles: ["Admin"],
              children: [
                {
                  path: "/sys-admin/master-data/user-management/users/:userId",
                  name: "User Detail",
                  route: PrivateRoute,
                  element: <UserDetail />,
                  roles: ["Admin"],
                },
              ],
            },
            {
              path: "/sys-admin/master-data/user-management/employee",
              name: "Users",
              route: PrivateRoute,
              element: <Employee />,
              roles: ["Admin"],
              children: [
                {
                  path: "/sys-admin/master-data/user-management/employee/:employeeId",
                  name: "User Detail",
                  route: PrivateRoute,
                  element: <UserDetail />,
                  roles: ["Admin"],
                },
              ],
            },
            {
              path: "/sys-admin/master-data/user-management/currently-logged-users/",
              name: "Currently Logged Users",
              route: PrivateRoute,
              element: <Starter />,
              roles: ["Admin"],
              // children: [
              //   {
              //     path: "/sys-admin/master-data/user-management/employee/:employeeId",
              //     name: "User Detail",
              //     route: PrivateRoute,
              //     element: <UserDetail />,
              //     roles: ["Admin"],
              //   },
              // ],
            },
          ],
        },
        {
          path: "/sys-admin/master-data/vendor-list",
          name: "Vendor List",
          route: PrivateRoute,
          element: <SAVendorList />,
          roles: ["Admin"],
          children: [
            {
              path: "/sys-admin/master-data/vendor-list/:vendorCode",
              name: "Vendor Detail",
              route: PrivateRoute,
              element: <VendorDetail />,
              roles: ["Admin"],
            },
          ],
        },
        {
          path: "/sys-admin/master-data/department",
          name: "Department",
          route: PrivateRoute,
          element: <Department />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/contract",
          name: "Contract",
          route: PrivateRoute,
          element: <MasterContract />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/gl",
          name: "GL",
          route: PrivateRoute,
          element: <MasterGL />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/wbs",
          name: "WBS",
          route: PrivateRoute,
          element: <MasterWBS />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/stock-code",
          name: "Stock Code",
          route: PrivateRoute,
          element: <MasterStockCode />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/type-expense",
          name: "Type Expense",
          route: PrivateRoute,
          element: <TypeExpense />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/cost-center",
          name: "Cost Center",
          route: PrivateRoute,
          element: <MasterCostCentre />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/user-roles",
          name: "User Roles",
          route: PrivateRoute,
          element: <SAPAddInvoice />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/contract-po",
          name: "Contract / PO",
          route: PrivateRoute,
          element: <SAPAddInvoice />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/po",
          name: "PO On The Spot",
          route: PrivateRoute,
          element: <POOnTheSpot />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/uom",
          name: "UOM",
          route: PrivateRoute,
          element: <MasterUom />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/invoice-registered",
          name: "Invoice Registered",
          route: PrivateRoute,
          element: <SAPAddInvoice />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/bank",
          name: "Bank",
          route: PrivateRoute,
          element: <Bank />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/tax",
          name: "Tax",
          route: PrivateRoute,
          element: <Tax />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/exchange-rate",
          name: "Exchange Rate",
          route: PrivateRoute,
          element: <ExchangeRate />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/house-bank",
          name: "House Bank",
          route: PrivateRoute,
          element: <HouseBank />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/account-bank",
          name: "Account Bank",
          route: PrivateRoute,
          element: <AccountBank />,
          roles: ["Admin"],
        },
        {
          path: "/sys-admin/master-data/payment-method",
          name: "Payment Method",
          route: PrivateRoute,
          element: <PaymentMethod />,
          roles: ["Admin"],
        },
      ],
    },
    {
      path: "/sys-admin/update-data-vendor",
      name: "Update Data Vendor",
      route: PrivateRoute,
      roles: ["Admin"],
      element: <SAUpdateDataVendor />,
    },
    {
      path: "/sys-admin/add-administrator",
      name: "Create Administrator",
      route: PrivateRoute,
      roles: ["Admin"],
      element: <AddAdministrator />,
    },
  ],
};

const workflow: RoutesProps = {
  path: "/workflow",
  name: "Workflow",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/workflow/bast",
      name: "Workflow Management BAST",
      route: PrivateRoute,
      element: <EditWorkflowBAST />,
      roles: ["Admin"],
    },
    {
      path: "/workflow/invoice",
      name: "Workflow Management Invoice",
      route: PrivateRoute,
      element: <EditWorkflowInvoice />,
      roles: ["Admin"],
    },
    {
      path: "/workflow/special-payment",
      name: "Workflow Management Special Payment",
      route: PrivateRoute,
      element: <EditWorkflowSpecial />,
      roles: ["Admin"],
    },
    {
      path: "/workflow/employee-claim",
      name: "Workflow Management Employee Claim",
      route: PrivateRoute,
      element: <EditWorkflowEmployeeClaim />,
      roles: ["Admin"],
    },
    {
      path: "/workflow/employee-claim-cc",
      name: "Workflow Management Employee Claim CC",
      route: PrivateRoute,
      element: <EditWorkflowEmployeeClaimCC />,
      roles: ["Admin"],
    },
  ],
};

const employee: RoutesProps = {
  path: "/employee",
  name: "Employee",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/employee/dashboard",
      name: "Dashboard",
      element: <Starter />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};

const valeForm: RoutesProps = {
  path: "/employee-claim",
  name: "Vale Form",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/employee-claim/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCList />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/cc/:page/:id",
      name: "Credit Card Claim",
      element: <CreateClaimCC />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/cc-view/:id",
      name: "Credit Card Claim",
      element: <ViewClaimFormCC />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCList />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/ec/:page/:id",
      name: "Credit Card Claim",
      element: <CreateClaimNonCC />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/ec-view/:id",
      name: "Credit Card Claim",
      element: <ViewClaimForm />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/head-dept/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCListHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/head-dept/ec-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/head-dept/cc-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormCCHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/admin-ap/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCListAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/admin-ap/ec-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/admin-ap/ec/:page",
      name: "Credit Card Claim",
      element: <CreateClaimNonCCAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/admin-ap/cc-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormCCAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/finance/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCListFinance />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/presdir/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCListPresDir />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/treasury/ec-list",
      name: "Credit Card Claim",
      element: <ClaimNonCCListTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/treasury/ec-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/treasury/ec/:page",
      name: "Credit Card Claim",
      element: <CreateClaimNonCCTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    //  CREDIT CARD
    {
      path: "/employee-claim/cc/:page",
      name: "Credit Card Claim",
      element: <CreateClaimCC />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCList />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/cc-view",
      name: "Credit Card Claim",
      element: <ViewClaimFormCC />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/head-dept/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCListHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/admin-ap/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCListAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/finance/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCListFinance />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/pres-dir/cc-list",
      name: "Credit Card Claim",
      element: <ClaimCCListPresDir />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/employee-claim/test-table",
      name: "Credit Card Claim",
      element: <TestTable />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};

// Roles routes
const vendorRoutes: RoutesProps = {
  path: "/vendor",
  name: "Vendor",
  route: PrivateRoute,
  roles: ["Admin"],
  // icon: "calendar",
  header: "Vendor",
  children: [
    {
      path: "/vendor/dashboards",
      name: "Vendor Dashboard",
      element: <VendorDashboard />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/vendor/bast/list",
      name: "Vendor List BAST",
      element: <BASTListVendor />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/vendor/invoice/list",
      name: "Vendor List Invoice",
      element: <InvoiceListVendor />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const warehouseRoutes: RoutesProps = {
  path: "/warehouse",
  name: "Warehouse",
  route: PrivateRoute,
  roles: ["Admin"],
  // icon: "calendar",
  header: "Warehouse",
  children: [
    {
      path: "/warehouse/dashboard",
      name: "Warehouse Dashboard",
      element: <WarehouseDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/warehouse/bast/list",
      name: "Warehouse List BAST",
      element: <BASTListWarehouse />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/warehouse/bast/detail",
      name: "Warehouse BAST Detail",
      element: <DetailBASTWarehouse />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const contractOwnerRoutes: RoutesProps = {
  path: "/contract-owner",
  name: "Contract Owner",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/contract-owner/dashboard",
      name: "Contract Owner Dashboard",
      element: <ContractOwnerDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/contract-owner/bast/list",
      name: "Contract Owner List BAST",
      element: <BASTListContractOwner />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const sapAdminRoutes: RoutesProps = {
  path: "/sap-admin",
  name: "SAP Admins",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/sap-admin/dashboard",
      name: "SAP Admins Dashboard",
      element: <SAPAdminDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/sap-admin/bast/list",
      name: "SAP Admins List BAST",
      element: <BASTListSAPAdmin />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const adminAPRoutes: RoutesProps = {
  path: "/admin-ap",
  name: "Admin AP",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/admin-ap/dashboard",
      name: "Admin AP Dashboard",
      element: <AdminAPDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/invoice/list",
      name: "Admin AP List Invoice",
      element: <InvoiceListAdminAP />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/invoice/detail/:id",
      name: "Admin AP Invoice Detail",
      // element: <InvoiceDetailAdminAP />,
      element: <InvoiceDetail />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/special-payment/list",
      name: "Admin AP List",
      element: <SpecialPaymentListAdminAP />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/employee-claim/list",
      name: "Admin AP List",
      element: <ClaimNonCCListAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/credit-card-claim/list",
      name: "Admin AP List",
      element: <ClaimCCListAdminAp />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/admin-ap/invoice/ias/:id",
      name: "Admin AP IAS",
      element: <InvoiceIASAdminAP />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const taxRoutes: RoutesProps = {
  path: "/tax",
  name: "Tax",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/tax/dashboard",
      name: "Tax Dashboard",
      element: <TaxDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/tax/invoice/list",
      name: "Tax Invoice List",
      element: <InvoiceListTax />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/tax/invoice/detail/:id",
      name: "Tax Invoice List",
      element: <InvoiceDetail />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const managerFinanceRoutes: RoutesProps = {
  path: "/finance-manager",
  name: "Manager Finance",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/finance-manager/dashboard",
      name: "Dashboard",
      element: <FinanceManagerDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/finance-manager/invoice/list",
      name: "Finance Manager Invoice List",
      element: <InvoiceListFinanceManager />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/finance-manager/invoice/detail/:id",
      name: "Finance Manager Invoice List",
      element: <InvoiceDetail />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/finance-manager/special-payment/list",
      name: "Finance Manager List",
      element: <SpecialPaymentListFinance />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/finance-manager/employee-claim/list",
      name: "Finance Manager List",
      element: <ClaimNonCCListFinance />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const presdirRoutes: RoutesProps = {
  path: "/presdir",
  name: "President Director",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/presdir/dashboard",
      name: "Presdir Dashboard",
      element: <PresdirDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/presdir/invoice/list",
      name: "Presdir Invoice List",
      element: <InvoiceListPresdir />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/presdir/invoice/detail/:id",
      name: "President Director Invoice List",
      element: <InvoiceDetail />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/presdir/special-payment/list",
      name: "Presdir List",
      element: <SpecialPaymentListPresDir />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/presdir/employee-claim/list",
      name: "Presdir List",
      element: <ClaimNonCCListPresDir />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const treasuryRoutes: RoutesProps = {
  path: "/treasury",
  name: "Treasury",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/treasury/dashboard",
      name: "Treasury Dashboard",
      element: <TreausryDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/treasury/invoice/list",
      name: "Treausury Invoice List",
      element: <InvoiceListTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/treasury/invoice/detail/:id",
      name: "Treausury Invoice List",
      element: <InvoiceDetail />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/treasury/special-payment/list",
      name: "Treausury List",
      element: <SpecialPaymentListTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/treasury/employee-claim/list",
      name: "Treausury List",
      element: <ClaimNonCCListTreasury />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};
const directManagerRoutes: RoutesProps = {
  path: "/direct-manager",
  name: "Direct Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/direct-manager/dashboard",
      name: "Direct Manager Dashboard",
      element: <DirectManagerDashboards />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/direct-manager/special-payment/list",
      name: "Direct Manager Special Payment List",
      element: <SpecialPaymentListHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/direct-manager/employee-claim/list",
      name: "Direct Manager Employee Claim List",
      element: <ClaimNonCCListHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: "/direct-manager/credit-card-claim/list",
      name: "Direct Manager Credit Card Claim List",
      element: <ClaimCCListHeadDept />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
  ],
};

// const calendarAppRoutes: RoutesProps = {
//   path: "/apps/calendar",
//   name: "Calendar",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "calendar",
//   element: <CalendarApp />,
//   header: "Apps",
// };

// const chatAppRoutes: RoutesProps = {
//   path: "/apps/chat",
//   name: "Chat",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "message-square",
//   element: <ChatApp />,
// };

// const emailAppRoutes: RoutesProps = {
//   path: "/apps/email",
//   name: "Email",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "mail",
//   children: [
//     {
//       path: "/apps/email/inbox",
//       name: "Inbox",
//       element: <Inbox />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/email/details",
//       name: "Email Details",
//       element: <EmailDetail />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/email/compose",
//       name: "Compose Email",
//       element: <EmailCompose />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const projectAppRoutes: RoutesProps = {
//   path: "/apps/projects",
//   name: "Projects",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "uil-briefcase",

//   children: [
//     {
//       path: "/apps/projects/list",
//       name: "List",
//       element: <Projects />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/projects/details",
//       name: "Detail",
//       element: <ProjectDetail />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const taskAppRoutes: RoutesProps = {
//   path: "/apps/tasks",
//   name: "Tasks",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "clipboard",
//   children: [
//     {
//       path: "/apps/tasks/list",
//       name: "Task List",
//       element: <TaskList />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/tasks/kanban",
//       name: "Kanban",
//       element: <Kanban />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const fileAppRoutes: RoutesProps = {
//   path: "/apps/file-manager",
//   name: "File Manager",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "folder-plus",
//   element: <FileManager />,
// };

// const appRoutes = [
//   calendarAppRoutes,
//   chatAppRoutes,
//   emailAppRoutes,
//   projectAppRoutes,
//   taskAppRoutes,
//   fileAppRoutes,
// ];

// pages
// const extrapagesRoutes: RoutesProps = {
//   path: "/pages",
//   name: "Pages",
//   icon: "package",
//   header: "Custom",
//   children: [
//     {
//       path: "/pages/starter",
//       name: "Starter",
//       element: <Starter />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/pages/profile",
//       name: "Profile",
//       element: <Profile />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/pages/activity",
//       name: "Activity",
//       element: <Activity />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/pages/invoice",
//       name: "Invoice",
//       element: <Invoice />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/pages/pricing",
//       name: "Pricing",
//       element: <Pricing />,
//       route: PrivateRoute,
//     },
//   ],
// };

// ui
// const uiRoutes: RoutesProps = {
//   path: "/components",
//   name: "Components",
//   icon: "package",
//   header: "UI Elements",
//   children: [
//     {
//       path: "/components/ui-elements",
//       name: "UIElements",
//       element: <UIElements />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/components/widgets",
//       name: "Widgets",
//       element: <Widgets />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/icons",
//       name: "Icons",
//       children: [
//         {
//           path: "/icons/unicons",
//           name: "Unicons",
//           element: <Unicons />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/icons/feather",
//           name: "Feather",
//           element: <FeatherIcons />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/icons/bootstrap",
//           name: "Bootstrap Icon",
//           element: <BootstrapIcon />,
//           route: PrivateRoute,
//         },
//       ],
//     },
//     {
//       path: "/forms",
//       name: "Forms",
//       children: [
//         {
//           path: "/forms/basic",
//           name: "Basic Elements",
//           element: <BasicForms />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/forms/advanced",
//           name: "Form Advanced",
//           element: <FormAdvanced />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/forms/validation",
//           name: "Form Validation",
//           element: <FormValidation />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/forms/wizard",
//           name: "Form Wizard",
//           element: <FormWizard />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/forms/upload",
//           name: "File Upload",
//           element: <FileUpload />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/forms/editors",
//           name: "Editors",
//           element: <Editors />,
//           route: PrivateRoute,
//         },
//       ],
//     },
//     {
//       path: "/components/charts",
//       name: "Charts",
//       element: <Charts />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/tables",
//       name: "Tables",
//       children: [
//         {
//           path: "/tables/basic",
//           name: "Basic",
//           element: <BasicTables />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/tables/advanced",
//           name: "Advanced",
//           element: <AdvancedTables />,
//           route: PrivateRoute,
//         },
//       ],
//     },
//     {
//       path: "/maps",
//       name: "Maps",
//       children: [
//         {
//           path: "/maps/googlemaps",
//           name: "Google Maps",
//           element: <GoogleMaps />,
//           route: PrivateRoute,
//         },
//         {
//           path: "/maps/vectorMaps",
//           name: "Google Maps",
//           element: <VectorMaps />,
//           route: PrivateRoute,
//         },
//       ],
//     },
//   ],
// };

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/forget-password-change-password",
    name: "Change Forget Password",
    element: <ChangePasswordForgot />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/contact-support",
    name: "Contact Support",
    element: <ContactSupportMain />,
    route: Route,
  },
];

const userManualRoute: RoutesProps = {
    path: "/user-manual",
    name: "User Guide",
    element: <UserManual />,
    route: PrivateRoute,
    icon: 'help-circle',
    // children: [
    //   {
    //     path: "/user-manual/bast",
    //     name: "User Guide BAST",
    //     element: <UserManual />,
    //     route: PrivateRoute,
    //   },
    //   {
    //     path: "/user-manual/invoice",
    //     name: "User Guide Invoice",
    //     element: <Starter />,
    //     route: PrivateRoute,
    //   },
    //   {
    //     path: "/user-manual/special-payment",
    //     name: "User Guide Special Payment",
    //     element: <Starter />,
    //     route: PrivateRoute,
    //   },
    //   {
    //     path: "/user-manual/employee-claim",
    //     name: "User Guide Employee Claim",
    //     element: <Starter />,
    //     route: PrivateRoute,
    //   },
    //   {
    //     path: "/user-manual/employee-claim-cc",
    //     name: "User Guide Employee Claim CC",
    //     element: <Starter />,
    //     route: PrivateRoute,
    //   },
    // ]
}

const rkabRoute: RoutesProps = {
  path: "/rkab",
  name: "RKAB",
  route: PrivateRoute,
  icon: "table",
  element: <Rkab />,
}

const exchangeRateRoute: RoutesProps = {
  path: "/exchange-rate",
  name: "Exchange Rate User",
  route: PrivateRoute,
  icon: "table",
  element: <ExchangeRate />,
}

const newBastRoute: RoutesProps = {
    path: "/new/bast",
    name: "New BAST",
    // element: <BastListClone />,
    route: PrivateRoute,
    icon: 'book',
    children: [
      {
        path: "/new/bast/list",
        name: "New BAST List",
        element: <BastListClone />,
        route: PrivateRoute,
      },
      {
        path: "/new/bast/add",
        name: "New BAST Add",
        element: <CreateBASTClone />,
        route: PrivateRoute,
      },
      {
        path: "/new/bast/detail/:id",
        name: "New BAST Detail",
        element: <DetailBASTClone />,
        route: PrivateRoute,
      }
    ]
  }

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...rolesRoutes,
  ...masterDataRoutes,
  bastManagement,
  invoice,
  workflow,
  employee,
  valeForm,
  layouting,
  userProfileRoute,
  report,
  // roles
  superAdmin,
  sapAdmin,
  vendorRoutes,
  warehouseRoutes,
  contractOwnerRoutes,
  sapAdminRoutes,
  adminAPRoutes,
  taxRoutes,
  managerFinanceRoutes,
  presdirRoutes,
  treasuryRoutes,
  directManagerRoutes,
  newBastRoute,
  userManualRoute,
  rkabRoute,
  exchangeRateRoute,
  // ...appRoutes,
  // extrapagesRoutes,
  // uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
