import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { ApexOptions } from "apexcharts";

// components
import Loader from "../../components/Loader";

// types
import { ApexLinearChartData } from "./data";

interface MultipleYaxisChartProps {
  // multiYaxisChartData: ApexLinearChartData;
  showLoader?: boolean;
  options: ApexOptions;
  series: any[];
  title: string
}

const MiroInvoiceLeadTimeChart = ({
  // multiYaxisChartData,
  showLoader,
  options,
  series,
  title
}: MultipleYaxisChartProps) => {

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mt-0 mb-3">{title}</h4>
        {showLoader ? (
          <div style={{ height: 380, position: "relative" }}>
            <Loader />
          </div>
        ) : (
          <Chart
            options={options}
            series={series}
            type="line"
            height={380}
            className="apex-charts"
            dir="ltr"
            onClick={console.log("clicked")}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default MiroInvoiceLeadTimeChart;
