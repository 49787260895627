import { Row, Col } from "react-bootstrap";

const BastSignatureSection = ({data}: any) => {
  return (
    <Row className="mt-3">
      <Col md={4}>
        <p className="text-center m-0">Received By : {data?.requested_by}</p>
        <p className="text-center m-0">Date : {data?.requested_at}</p>
        <div
          className="m-auto w-50 text-center pt-3"
          style={{
            border: "1px solid #666",
            height: "5rem",
          }}
        >
          Signature Image
        </div>
      </Col>
      <Col md={4}>
        <p className="text-center m-0">Checked By : {data?.checked_by}</p>
        <p className="text-center m-0">Date : {data?.checked_at}</p>
        <div
          className="m-auto w-50 text-center pt-3"
          style={{
            border: "1px solid #666",
            height: "5rem",
          }}
        >
          Signature Image
        </div>
      </Col>
      <Col md={4}>
        <p className="text-center m-0">Delivered By : {data?.delivered_by}</p>
        <p className="text-center m-0">Date : {data?.delivered_at}</p>
        <div
          className="m-auto w-50 text-center pt-3"
          style={{
            border: "1px solid #666",
            height: "5rem",
          }}
        >
          Signature Image
        </div>
      </Col>
    </Row>
  );
};

export default BastSignatureSection;
