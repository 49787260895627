const TestTable = () => {
    const header = ['', 'Jan', 'Feb', "Mar", 'Apr']
    console.log(header)
    const data = [['Outstanding', 100, 200,300,400]]
    return (
        <>
            <div>Test Table</div>
            <table>
                <thead>
                    <tr style={{border: "1px solid black"}}>
                        <th style={{border: "1px solid black"}}></th>
                        <th style={{border: "1px solid black", textAlign: "center"}}
                            scope="colgroup"
                        >2023</th>
                    </tr>
                </thead>
                <thead>
                    <tr style={{border: "1px solid black"}}>
                        {/* {header.map(a => {(<th>{a}</th>)})} */}
                        {header.map((a: any, index: any) => (
                            <th style={{border: "1px solid black"}} key={'th' + index}>{a}</th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{border: "1px solid black"}}>
                    {data.map((a: any, index: any) =>(
                    <tr key={'tr' + index} style={{border: "1px solid black"}}>
                        {a.map((b: any, index2: any) =>(
                            <td style={{border: "1px solid black"}} key={'td' + index + index2}>{b}</td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default TestTable;