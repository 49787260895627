// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { CompanyActionTypes } from "./constants";

const api = new APICore();

const companies = [
  {
    id: 1,
    company_code: "1492",
    company_initial: "STM",
    company_name: "Sumba Timur Mining",
    company_phone: null,
  },
  {
    id: 2,
    company_code: "1561",
    company_initial: "VEI",
    company_name: "Vale Explorasi Indonesia",
    company_phone: null,
  },
];
const INIT_STATE = {
  currentCompany: companies[0],
  companies: companies,
};

const Company = (state: any = INIT_STATE, action: any): any => {
  switch (action.type) {
    case CompanyActionTypes.SET_USER_COMPANY: {
      return {
        ...state,
        currentCompany: companies.find(company => company.id === action.payload.data)
      };
    }

    default:
      return { ...state };
  }
};

export default Company;
