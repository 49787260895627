import { Row, Col, Card, Form, Button } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
// static data
import GroupInput from "./MyComponents/Input/GroupInput";
import ShreyuDatepicker from "./Datepicker";
import { toBase64 } from "../utils";
import { toast } from "react-toastify";
import { API_URI } from "../constants";
import Select from "react-select";
import { b64toBlob, changeUStoID, checkFile, getMasterData, onChangeMoney, removeDot, replaceToComma, replaceToDot, roleSpecialPayment, setApprovalAPI, valueOptions } from "../helpers/utils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "./FormInput";
import { useNavigate, useParams } from "react-router-dom";
import FindInPage from "@mui/icons-material/FindInPage";
import { Delete } from "@mui/icons-material";
import { useTranslation, Trans } from 'react-i18next';
import Loading from "./MyComponents/Loading";
import dayjs from 'dayjs'
import { Dialog, DialogActions, DialogContent } from "@mui/material";

interface DataProps {
  data: any
  canEdit?: boolean
  canApprove?: boolean
  isTreasury?: boolean
  mode?: string
}

const editGroup = (data: any, gr: any) =>{
    for (const a of gr) {
        if (a.type !== "select"){
            a.value = data[a.controlId]
        }
    }
}

const CreateInvoiceSAP = (props: DataProps) => {
  const { t, i18n } = useTranslation();
  // const elementRef = useRef<HTMLInputElement>(null);
  const [SupportFile, setSupportFile] = useState<any>({})
  const [listData, setListData] = useState<any>({...props.data});
  const [listData2, setListData2] = useState<any>({});
  const [listVendor, setListVendor] = useState<any>([]);
  const [listGL, setListGL] = useState<any>([]);
  const [listWBS, setListWBS] = useState<any>([]);
  const [listWHT, setListWHT] = useState<any>([]);
  const [dataCurrency, setDataCurrency] = useState<any>();
  const [dataCostCenter, setDataCostCenter] = useState<any>([]);
  const [dataHouseBank, setDataHouseBank ] = useState<any>([]);
  const [dataAccountID, setDataAccountID ] = useState<any>([]);
  const [dataPaymentMethod, setDataPaymentMethod ] = useState<any>([]);
  
  const {page} = useParams();
  const navigate = useNavigate();
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const [load, setLoad] = useState(false);
  const [isCiModalOpen, setIsCiModalOpen] = useState(page === 'create');
  // const [listDepartment, setListDepartment] = useState<any>([]);
  const getDataVendor = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'vendor-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          {id: 'company', value: currentCompany.company_initial}
      ],
      "sorting": [
          {
              "id": "id",
              "desc": false
          }
      ],
      "pagination": {
          "pageIndex": 0,
          "pageSize": 999999
      }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            const c = {
              key: b.vendor_code,
              label: b.vendor_code + " - " + b.vendor_name,
              value: b.vendor_name,
              is_vat: b.is_vat
            }
            a.push(c)
          }
          console.log(listData);
          setListVendor(a);
          console.log(a)
        }else{
          setListVendor([]);
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const getDataGL = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'general-ledger-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          {id: 'company', value: currentCompany.company_initial}
      ],
      "sorting": [
          {
              "id": "id",
              "desc": false
          }
      ],
      "pagination": {
          "pageIndex": 0,
          "pageSize": 999999
      }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            const c = {
              key: b.gl_code,
              label: b.gl_code + " - " + b.gl_name,
              value: b.gl_code
            }
            a.push(c)
          }
          // console.log(listData);
          setListGL(a);
          console.log(a)
        }else{
          setListGL([]);
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };
  const getDataWBS = async () => {
    fetch(API_URI + 'wbs-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          {id: 'company', value: currentCompany.company_initial}
      ],
      "sorting": [
          {
              "id": "id",
              "desc": false
          }
      ],
      "pagination": {
          "pageIndex": 0,
          "pageSize": 999999
      }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            const c = {
              key: b.wbs_code,
              label: b.wbs_code + " - " + b.wbs_name,
              value: b.wbs_code
            }
            a.push(c)
          }
          // console.log(listData);
          setListWBS(a);
          console.log(a)
        }else{
          setListWBS([]);
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  const sendAddEdit = async (data2: any) => {
    setLoad(true)
    // const response = await axios.get(API_URI + 'department/');
    data2.user_id = JSON.parse(sessionStorage.getItem('shreyu_user') as string)?.data?.id;
    // data2.amount_of_wht = data2.wht_amount;
    // data2.wht_no = data2.wht;
    delete data2.wht;
    delete data2.wht_amount;
    fetch(API_URI + 'special-payment', {
      method: "POST",
      body:JSON.stringify(data2),
      headers: {
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization":
          "Bearer " +
          JSON.parse(sessionStorage.getItem("shreyu_user") as string)
            .access_token,
      }
    })
    .then(response => response.json())
    .then(async(data) => {
      console.log(data2);
      if(data && data.success){
        const role = await roleSpecialPayment();
        toast.success('Success Saving data')
        navigate(`/sap/special-payment-list`);
        // }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error(data?.message ? data?.message : 'Failes to get data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoad(false)
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const getDataTax = async () => {
    fetch(API_URI + 'tax-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          {id: 'company', value: currentCompany.company_initial},
          {id: 'wht_type', value: 'wht'},
      ],
      "sorting": [
          {
              "id": "id",
              "desc": false
          }
      ],
      "pagination": {
          "pageIndex": 0,
          "pageSize": 999999
      }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            const c = {
              key: b.wht_rate,
              label: b.wht_code + " - " + b.wht_name + `(${b.wht_rate})`,
              value: b.wht_code
            }
            a.push(c)
          }
          // console.log(listData);
          setListWHT(a);
          console.log(a)
        }else{
          setListWHT([]);
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };
  
  const handleOnSubmit =((dataCreate: any) => {
    console.log("On Submit");
    const dataSend:any = [
      {
          "company_code": currentCompany?.company_code ? currentCompany?.company_code : '1492',
          "subcompany_code": dataCreate.subcompany_code,
          "vendor_code": dataCreate.vendor_code,
          "special_payment_number": dataCreate.special_payment_number,
          // "special_payment_date": "2023-09-22",
          "payment_amount": removeDot(dataCreate.payment_amount),
          "gl_no": dataCreate.gl_no,
          "wbs_no": dataCreate.wbs_no,
          "cost_center_id": dataCreate.cost_center_id,
          // "cost_description": "Cost Special Payment",
          "justification": dataCreate.justification,
          "vat_amount": removeDot(dataCreate.vat_amount),
          // "amount_of_dpp": 200000,
          // "amount_of_wht": dataCreate.wht_amount,
          "referenceNumber": dataCreate.reference_number,
          // "paid_date": "2023-09-21",
          // "paymentAdvice": "Document-1",
          "referenceFile": dataCreate.reference_file,
          "suporting_doc": dataCreate.suporting_doc,
          "sap_company_number": dataCreate.sap_company_number,
          // "approval_status_id": "0",
          "requested_by": JSON.parse(sessionStorage.getItem("shreyu_user") as string).data?.name,
          // "requested_by": 87,
          "is_vat": dataCreate.is_vat,
          // "is_relevant": 1,
          "created_by": JSON.parse(sessionStorage.getItem("shreyu_user") as string).data?.id,
          "user_id": JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id,
          // "updated_at": "2023-09-21T11:30:00.000000Z",
          // "created_at": "2023-09-21T11:30:00.000000Z"
          "amount_of_wht" : dataCreate?.wht_amount ? removeDot(dataCreate.wht_amount) : '',
          "wht_no" : dataCreate.wht,
          "wht_percentage": dataCreate.wbs_percentage,
          direct_manager: JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.direct_manager,
          fv60: dataCreate?.fv60,
          currency_id: dataCreate.currency_id,
          // house_bank: dataCreate.house_bank,
          // account_id: dataCreate.account_id,
          // payment_method: dataCreate.payment_method,
          is_ci: dataCreate.is_ci,
          referenceCi: dataCreate.referenceCi,
          referenceFileCi: dataCreate.referenceFileCi,
          ci_approval_date: dataCreate.ci_approval_date,
          ci_activity_date: dataCreate.ci_activity_date,
      }
    ]
    if (dataCreate.id) {
      dataSend[0].id = dataCreate.id
    }
    console.log(dataSend)
    sendAddEdit(dataSend)
  })

  const getDataCostCenter = () => getMasterData('cost-center-list', {
    "columnFilters": [
      {id: 'company', value: currentCompany.company_initial}
    ],
    "sorting": [],
    "pagination": {
        "pageIndex": 0,
        "pageSize": 999999
    }
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.code + ' - ' + a.names,
        value: a.code
      })
    }
    setDataCostCenter(hasil);
  })

  const getDataCurrency = () => new Promise((resolve, reject) => getMasterData('currency-list', {
    columnFilters: [],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.currency_code,
        value: a.currency_code
      })
    }
    setDataCurrency(hasil);
  }))

  const getDataHouseBank = () => getMasterData('house-bank-list', {
    columnFilters: [{
      id: 'company',
      value: currentCompany.company_initial
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.house_bank,
        value: a.house_bank
      })
    }
    setDataHouseBank(hasil);
  })

  const getDataAccountID = (house_bank: any) => {
    const columnFilter = [{
      id: 'company',
      value: currentCompany.company_initial
    }]
    if(house_bank){
      columnFilter.push({
        id: 'house_bank',
        value: house_bank
      })
    }
    getMasterData('account-bank-list', {
      columnFilters: columnFilter,
      sorting: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 10000
      },
    }).then(data => {
      const hasil = []
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.account_id + '-' + a.description,
          value: a.account_id
        })
      }
      setDataAccountID(hasil);
    })
  }

  const getDataPaymentMethod = () => getMasterData('payment-method-list', {
    columnFilters: [{
      id: 'company',
      value: currentCompany.company_initial
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.pmt_method + ' - ' + a.description,
        value: a.pmt_method
      })
    }
    setDataPaymentMethod(hasil);
  })

  // getDataVendor();
  const [flagLoad, setFlagLoad] = useState<any>(true);
  // let flag_load = true;
  useEffect(() => {
    // console.log(roleSpecialPayment())
    if(!props?.data?.reference_number || flagLoad) {
      if(props?.data?.reference_number) {
        setFlagLoad(false);
        // flag_load = false
      }
      console.log('useEffect')
      getDataCurrency();
      getDataVendor();
      getDataGL();
      getDataWBS();
      getDataCostCenter();
      getDataTax();
      // getDataHouseBank();
      // getDataPaymentMethod();
      console.log(props.data)
      setListData(props.data)
      const a = props.data;
      console.log(a);
      console.log("AMOUNT OF WHT", a.amount_of_wht)
      console.log(a.wht_no)
      // if(a?.house_bank?.name) getDataAccountID(a?.house_bank?.name)
      setListData2({
        id: a.id?.name,
        company: currentCompany.company_initial,
        subcompany_code: a.subcompany_code?.name,
        special_payment_number: a.special_payment_number?.name,
        sap_company_number: currentCompany.company_initial + ' ' + currentCompany.company_code,
        reference_number: a.reference_number?.name,
        reference_file: a.reference_file?.name,
        vendor_code: a.vendor_code?.name,
        vendor_name: a.vendor_name?.name,
        payment_amount: changeUStoID(a.payment_amount?.name),
        gl_no: a.gl_no?.name,
        wbs_no: a.wbs_no?.name,
        cost_center_id: a.cost_center_id?.name,
        justification: a.justification?.name,
        is_vat: a.is_vat?.name,
        vat_amount: changeUStoID(a.vat_amount?.name),
        requested_by: a.requested_by?.name,
        suporting_doc: a.suporting_doc?.name,
        wht_amount: changeUStoID(a.amount_of_wht?.name),
        // "amount_of_wht" : a.wht_amount,
        wht: a.wht_no?.name,
        fv60: a.fv60?.name,
        approval_status_id: a.approval_status_id?.name,
        currency_id: a.currency_id?.name,
        // house_bank: a.house_bank?.name,
        // account_id: a.account_id?.name,
        // payment_method: a.payment_method?.name,
        is_ci: a.is_ci?.name,
        referenceCi: a.referenceCi?.name,
        referenceFileCi: a.referenceFileCi?.name,
        ci_approval_date: a.ci_approval_date?.name,
        ci_activity_date: a.ci_activity_date?.name,
        // "wht_no" : a.wht,
      })
    }
    
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    props,t
  ]); 
  // useEffect(() => {
  // }, [t]) 
      
      const [selectedDate, setSelectedDate] = useState<Date>(new Date());

      /*
      * handle date change
      */
      const onDateChange = (date: Date) => {
        if (date) {
          setSelectedDate(date);
        }
      };
      const schemaResolver = yupResolver(
        yup.object().shape({
          company: yup.string().required("Please enter Company"),
          approval_status_id: yup.string().nullable(),//.required("Please enter Approval Status"),
          subcompany_code: yup.string().nullable(),//.required("Please enter Mineral/Geothermal"),
          special_payment_number: yup.string().nullable(true),//.required("Please enter Special Payment Number"),
          sap_company_number: yup.string().nullable(true),//.required("Please enter SAP Company Number"),
          reference_number: yup.string().required("Please enter Reference Number"),
          reference_file: yup.string().required("Please enter Reference File"),
          vendor_code: yup.string().required("Please enter Vendor Detail"),
          vendor_name: yup.string().required("Please enter Vendor Name"),
          payment_amount: yup.string().notOneOf( //.required("Please enter Payment Amount").oneOf(
            [
              '0,00',
              null
            ],
            "Please enter Payment Amount"
          ),
          gl_no: yup.string().required("Please enter GL"),
          wbs_no: yup.string().nullable().when("company", {
            is: 'STM',
            then: yup.string().required("Please enter WBS")
          }),
          cost_center_id: yup.string().nullable().when("company", {
            is: 'VEI',
            then: yup.string().required("Please enter Cost Center")
          }),
          justification: yup.string().required("Please enter Description & Justification"),
          is_vat: yup.string().required("Please enter VAT Applicable"),
          vat_amount: yup.string().nullable() //.required("Please enter Amount of VAT")
          .when("is_vat", {
            is: "1",
            then: yup.string().notOneOf(
              ['0,00', null], 'Please enter Amount of VAT'
            )
          }),
          wht: yup.string().nullable(),
          wht_amount: yup.string().nullable(),
          requested_by: yup.string().required("Please enter Requested By"),
          suporting_doc: yup.string().required("Please enter Supporting Doc"),
          fv60: yup.string().required("Please enter FV60"),// .nullable(),
          currency_id: yup.string().required("Please enter Currency"),
          // house_bank: yup.string().nullable().when("approval_status_id", {
          //   is: '16',
          //   then: yup.string().nullable().required("Please enter House Bank")
          // }),
          // account_id: yup.string().nullable().when("approval_status_id", {
          //   is: '16',
          //   then: yup.string().nullable().required("Please enter Account ID")
          // }),
          // payment_method: yup.string().nullable().when("approval_status_id", {
          //   is: '16',
          //   then: yup.string().nullable().required("Please enter Payment Method")
          // }),
          is_ci: yup.string().nullable().required("Please Choose Corporete Integrity"),
          referenceCi: yup.string().nullable().when("is_ci", {
            is: '1',
            then: yup.string().nullable().required("Please enter Reference Corporete Integrity")
          }),
          referenceFileCi: yup.string().nullable().when("is_ci", {
            is: '1',
            then: yup.string().nullable().required("Please enter Corporete Integrity Reference File")
          }),
          ci_approval_date: yup.string().nullable().when("is_ci", {
            is: '1',
            then: yup.string().nullable().required("Please enter Corporete Integrity Approval Date")
          }),
          ci_activity_date: yup.string().nullable().when("is_ci", {
            is: '1',
            then: yup.string().nullable().required("Please enter Corporete Integrity Activity Date")
          })
        })
      );
      const methods = useForm<any>({
        // defaultValues: listData2,
        reValidateMode:"onBlur",
        resolver: schemaResolver,
      });
      const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: { errors },
      } = methods;
      console.log(errors);
      setValue('company', listData2.company)
      setValue('approval_status_id', listData2.approval_status_id)
      setValue('subcompany_code', listData2.subcompany_code)
      setValue('special_payment_number', listData2.special_payment_number)
      setValue('sap_company_number', listData2.sap_company_number)
      setValue('reference_number', listData2.reference_number)
      setValue('reference_file', listData2.reference_file)
      setValue('vendor_code', listData2.vendor_code)
      setValue('vendor_name', listData2.vendor_name)
      setValue('payment_amount', listData2.payment_amount)
      setValue('gl_no', listData2.gl_no)
      setValue('wbs_no', listData2.wbs_no)
      setValue('cost_center_id', listData2.cost_center_id)
      setValue('justification', listData2.justification)
      setValue('is_vat', listData2.is_vat)
      setValue('vat_amount', listData2.vat_amount)
      setValue('requested_by', listData2.requested_by)
      setValue('suporting_doc', listData2.suporting_doc)
      setValue('wht', listData2.wht)
      setValue('wht_amount', listData2.wht_amount)
      setValue('fv60', listData2.fv60)
      setValue('currency_id', listData2.currency_id)
      // setValue('house_bank', listData2.house_bank)
      // setValue('account_id', listData2.account_id)
      // setValue('payment_method', listData2.payment_method)
      setValue('is_ci', listData2.is_ci)
      setValue('referenceCi', listData2.referenceCi)
      setValue('referenceFileCi', listData2.referenceFileCi)
      setValue('ci_approval_date', listData2.ci_approval_date)
      setValue('ci_activity_date', listData2.ci_activity_date)

      const changeValueIsCi = (val: any) => {
        setValue("is_ci", val);
        setListData2({
          ...listData2,
          is_ci: val,
          referenceCi: val === "1" ? listData2.referenceCi : "",
          referenceFileCi: val === "1" ? listData2.referenceFileCi : "",
          ci_approval_date: val === "1" ? listData2.ci_approval_date : "",
          ci_activity_date: val === "1" ? listData2.ci_activity_date : "",
        })
        setListData({
          ...listData,
          referenceCi: {
            ...listData.referenceCi,
            isDisabled: val === "1" ? false : true,
          },
          referenceFileCi: {
            ...listData.referenceFileCi,
            isDisabled: val === "1" ? false : true,
          },
          ci_approval_date: {
            ...listData.ci_approval_date,
            isDisabled: val === "1" ? false : true,
          },
          ci_activity_date: {
            ...listData.ci_activity_date,
            isDisabled: val === "1" ? false : true,
          },
        })
      }

  return (
    <>
      <div className="mb-3"></div>
      <Loading loading={load} />
      <Card>
        <Card.Body>
        <form
          onSubmit={handleSubmit((d) => console.log(d))}
          // onSubmit={() => {}}
          // resolver={schemaResolver}
        >
          <Row>
            <Col md={6}>
              <Card
                style={{ width: "fit-content" }}
                className="employee-claim-subtitle mb-1"
              >
                <Card.Body style={{ width: "fit-content" }} className="">
                  <h4
                    className="header-title2 mt-0 mb-0"
                    style={{ color: "#b28924", fontSize: 16 }}
                  >
                    EFT Special Payment Requisition
                  </h4>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              {currentCompany.company_initial === 'STM' && (
              <div className="d-flex justify-content-end">
                <Form.Group className="d-flex flex-direction-column align-items-center" defaultValue={props.data.geoOrStm?.name}>
                <FormInput
                    name="subcompany_code"
                    key="subcompany_code"
                    type="text"
                    readOnly={false}
                    defaultValue=""
                    value={listData2.subcompany_code}
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                  />
                  <Form.Check
                    className="me-3"
                    type="radio"
                    name="geoOrStm"
                    label="Mineral"
                    checked={listData2.subcompany_code === '001'}
                    onChange={(e) => {
                      setValue("subcompany_code", "001");
                      setListData2({
                        ...listData2,
                        subcompany_code: '001'
                      })
                    }}
                  />
                  <Form.Check type="radio" name="geoOrStm" 
                    label="Geothermal" 
                    checked={listData2.subcompany_code === '002'}
                    onChange={(e) => {
                      setValue("subcompany_code", "002");
                      setListData2({
                        ...listData2,
                        subcompany_code: '002'
                      })
                    }}
                  />
                </Form.Group>
              </div>
              )}
              <div className="d-flex justify-content-end mt-2 mb-3">
                <Form.Group className="d-flex flex-direction-column align-items-center" defaultValue={props.data.is_ci?.name}>
                <FormInput
                    name="is_ci"
                    key="is_ci"
                    type="text"
                    readOnly={false}
                    defaultValue=""
                    value={listData2.is_ci}
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                  />
                  <Form.Check
                    className="me-3"
                    type="radio"
                    name="is_ci"
                    label="With Corporate Integrity"
                    checked={listData2.is_ci === '1'}
                    onChange={(e) => {
                      changeValueIsCi('1')
                    }}
                  />
                  <Form.Check type="radio" name="is_ci" 
                    label="Without Corporate Integrity" 
                    checked={listData2.is_ci === '0'}
                    onChange={(e) => {
                      changeValueIsCi('0')
                    }}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Form>
            <Row>
            <Col md={{ span: 4, offset: 4 }}>
                <Form.Group className="mb-3" controlId="special_payment_numberAll">
                  <Form.Label>{t("Special Payment Number")}</Form.Label>
                  <FormInput
                    name="company"
                    type="text"
                    readOnly={true}
                    value={listData2.company}
                    hidden={true}
                    register={register}
                    errors={errors}
                    control={control}
                  />
                  <FormInput
                    name="special_payment_number"
                    type="text"
                    readOnly={true}
                    value={listData2.special_payment_number}
                    register={register}
                    errors={errors}
                    control={control}
                    onChange={(e) => {
                      setListData2({
                        ...listData2,
                        special_payment_number: e.target.value
                      })
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 4 }}>
                <Form.Group className="mb-3" controlId="sapCompanyNumberAll">
                  <Form.Label>{t("SAP Company Number")}</Form.Label>
                  <FormInput
                    name="sap_company_number"
                    type="text"
                    readOnly={listData.sap_company_number?.isDisabled}
                    defaultValue={listData2.sap_company_number}
                    register={register}
                    errors={errors}
                    control={control}
                    onChange={(e) => {
                      setListData2({
                        ...listData2,
                        sap_company_number: e.target.value
                      })
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <Form.Group className="mb-3" controlId="referenceNumberAll">
                  <Form.Label>{t("Reference Number")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="reference_number"
                    // key="reference_number"
                    type="text"
                    readOnly={listData.reference_number?.isDisabled}
                    defaultValue={listData2.reference_number}
                    value={listData2.reference_number}
                    register={register}
                    errors={errors}
                    control={control}
                    onChange={(e) => {
                      setListData2({
                        ...listData2,
                        reference_number: e.target.value
                      })
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 4}}>
                <Form.Group className="mb-3" controlId="attachFileReference">
                  <Form.Label>{t("Reference File")}<span className="text-danger">*</span></Form.Label>
                  <div style={{ display: "inline" }}>
                    {listData2?.reference_file && (
                      <FindInPage
                        onClick={() => {
                          console.log(listData2?.reference_file)
                          const [meta, data] = listData2?.reference_file?.split("base64,")
                          console.log(data)
                          const win: any = window.open();
                          const blob = b64toBlob(data, "application/pdf");
                          const url = URL.createObjectURL(blob);
                          win.document.write(
                            `<iframe src="${
                              url
                            }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                          );
                        }}
                        className="cursor-pointer ms-2"
                      />
                    )}
                    {listData2?.reference_file && (
                      <Delete
                        color="error"
                        className="cursor-pointer ms-2"
                        onClick={() =>{
                          setListData({
                            ...listData,
                            suporting_doc:{
                              ...listData.suporting_doc,
                              name: ''
                            }
                          })
                          setListData2({
                            ...listData2,
                            suporting_doc:''
                          })
                        }}
                      />
                    )}
                  </div>
                  <Form.Control type="file" placeholder="Input File" 
                    disabled={listData.reference_file?.isDisabled}
                    onChange={async(e: any) => {
                      if(checkFile(e)){
                        const file = await toBase64(e.target?.files[0]);
                        setListData2({...listData2, reference_file: file})
                      }
                    }}
                  />
                  <FormInput
                    name="reference_file"
                    type="text"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    placeholder="Input Reference File"
                    defaultValue={listData2.reference_file}
                    value={listData2.reference_file}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="vendor_number">
                  <Form.Label>{t("Vendor Detail")}<span className="text-danger">*</span></Form.Label>
                  {/* <div>{JSON.stringify({...listData.is_vat?.defaultValue})}</div> */}
                  <Select
                    name={"vendorCode"}
                    options={listVendor}
                    value={listVendor[valueOptions(listVendor, listData2.vendor_name)]}
                    className={`${listData.vendor_code?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                    classNamePrefix="react-select"
                    isDisabled={listData.vendor_code?.isDisabled}
                    isClearable={true}
                    escapeClearsValue={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      const x = parseFloat(replaceToDot(removeDot(listData2.payment_amount)))
                      const y = 11/100*x
                      console.log(y)
                      if(e?.is_vat == '1') {
                        setListData2({
                          ...listData2,
                          vendor_code: e?.key,
                          vendor_name: e?.value,
                          is_vat: e?.is_vat,
                          vat_amount: changeUStoID(replaceToComma(y.toString()))
                        })
                      }else {
                        setListData2({
                          ...listData2,
                          vendor_code: e?.key,
                          vendor_name: e?.value,
                          is_vat: e?.is_vat,
                          vat_amount: '0,00'
                        })
                      }
                      // setListData({
                      //   ...listData,
                      //   is_vat:{
                      //     ...listData.is_vat,
                      //     defaultValue: e.is_vat
                      //   }
                      // })
                    }}
                  ></Select>
                  <FormInput
                    name="vendor_code"
                    type="text"
                    readOnly={false}
                    defaultValue={listData2.vendor_code}
                    value={listData2.vendor_code}
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="vendor_name">
                  <Form.Label>{t("Vendor Name")}</Form.Label>
                  <FormInput
                    name="vendor_name"
                    type="text"
                    placeholder="Input Vendor Name"
                    register={register}
                    errors={errors}
                    control={control}
                    defaultValue={listData2.vendor_name}
                    readOnly={listData.vendor_name?.isDisabled}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="currency_group">
                  <Form.Label>{t("Currency")}<span className="text-danger">*</span></Form.Label>
                  <Select
                    options={dataCurrency}
                    name="currency_id"
                    value={listData2?.currency_id ? dataCurrency[valueOptions(dataCurrency, listData2?.currency_id)] : ''}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isDisabled={listData2?.is_advance === '0'}
                    onChange={(e) => {
                      setListData2({...listData2, currency_id: e.value})
                    }}
                  ></Select>
                  <FormInput
                    key="currency_id"
                    // label={column.header}
                    name="currency_id"
                    register={register}
                    hidden={true}
                    errors={errors}
                    control={control}
                    // onChange={(e) =>
                    //   setValues({ ...values, [e.target.name]: e.target.value })
                    // }
                    value={listData2?.currency_id}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="payment_amount">
                  <Form.Label>{t("Payment Amount")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="payment_amount"
                    type="text"
                    placeholder="Input Payment Amount"
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.payment_amount}
                    readOnly={listData?.payment_amount?.isDisabled}
                    onChange= {(e:any) => {
                      let z = e.target.value;
                      if (listData2?.payment_amount === '0,00'){
                        if(parseFloat(replaceToDot(z)) >= 10){
                          z = (parseFloat(replaceToDot(z)) / parseFloat('10')).toString()
                        }
                      }
                      const x = parseFloat(replaceToDot(removeDot(z)))
                      const y = 11/100*x
                      let pointer = e.target.selectionStart;
                      console.log(x,y, pointer)
                      const a = changeUStoID(z)
                      if(a === '0,00'){
                        pointer = 0
                      }
                       else if( a.length > listData2.payment_amount.length){
                        if( (a.length) === listData2.payment_amount.length +2) {
                          pointer += 1
                        }
                      } else {
                        if( (a.length) === listData2.payment_amount.length -2) {
                          pointer -= 1
                        }
                      }
                      
                      console.log(a);
                      // const pointer = e.target.selectionStart;
                      const element = e.target;
                      window.requestAnimationFrame(() => {
                        element.selectionStart = pointer;
                        element.selectionEnd = pointer;
                      });
                      if(listData2.is_vat == '1'){
                        setListData2({
                          ...listData2,
                          payment_amount: a,
                          vat_amount: changeUStoID(replaceToComma(y.toString()))
                        })
                      }else{
                        setListData2({
                          ...listData2,
                          payment_amount: a,
                          vat_amount: '0,00'
                        })
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="gl_no">
                  <Form.Label>GL<span className="text-danger">*</span></Form.Label>
                  <Select
                    name={"gl_no"}
                    options={listGL}
                    value={listGL[valueOptions(listGL, listData2?.gl_no)]}
                    className={`${listData.gl_no?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                    classNamePrefix="react-select"
                    isDisabled={listData.gl_no?.isDisabled}
                    isClearable={true}
                    escapeClearsValue={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      setListData2({...listData2, gl_no: e?.value })
                    }}
                  ></Select>
                  <FormInput
                    name="gl_no"
                    type="text"
                    placeholder="Input GL"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    defaultValue={listData2.gl_no}
                    value={listData2.gl_no}
                    readOnly={listData.gl_no?.isDisabled}
                  />
                </Form.Group>
              </Col>
              {currentCompany.company_initial === 'STM' && (
              <Col md={6}>
                <Form.Group className="mb-3" controlId="wbs_no">
                  <Form.Label>WBS<span className="text-danger">*</span></Form.Label>
                  <Select
                    name={"wbs_no"}
                    options={listWBS}
                    value={listWBS[valueOptions(listWBS, listData2.wbs_no)]}
                    className={`${listData.wbs_no?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                    classNamePrefix="react-select"
                    isDisabled={listData.wbs_no?.isDisabled}
                    isClearable={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      setListData2({...listData2, wbs_no: e?.value,
                      wbs_percentage: e.key })
                    }}
                  ></Select>
                  <FormInput
                    name="wbs_no"
                    type="text"
                    placeholder="Input WBS"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    defaultValue={listData2.wbs_no}
                    value={listData2.wbs_no}
                    readOnly={listData.wbs_no?.isDisabled}
                  />
                </Form.Group>
              </Col>
              )}
              {currentCompany.company_initial === 'VEI' && (
              <Col md={6}>
                <Form.Group className="mb-3" controlId="cost_center_id">
                  <Form.Label>Cost Center<span className="text-danger">*</span></Form.Label>
                  <Select
                    name={"cost_center_id"}
                    options={dataCostCenter}
                    value={dataCostCenter[valueOptions(dataCostCenter, listData2.cost_center_id)]}
                    className={`${listData.cost_center_id?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                    classNamePrefix="react-select"
                    isDisabled={listData.cost_center_id?.isDisabled}
                    isClearable={true}
                    escapeClearsValue={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      setListData2({...listData2, cost_center_id: e?.value })
                    }}
                  ></Select>
                  <FormInput
                    name="cost_center_id"
                    type="text"
                    placeholder="Input Cost Center"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    defaultValue={listData2?.cost_center_id}
                    value={listData2?.cost_center_id}
                    readOnly={listData.cost_center_id?.isDisabled}
                  />
                </Form.Group>
              </Col>
              )}
            </Row>
            <Row>
              {/* <Col md={4}>
                <GroupInput inputAttArr={groupInputAtt2} colSize={6} />
              </Col> */}
              <Col md={12}>
                <Form.Group className="mb-3" controlId="justificationAll">
                  <Form.Label>{t("Description & Justification")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="justification"
                    type="text"
                    placeholder="Input Description & Justification"
                    register={register}
                    errors={errors}
                    control={control}
                    defaultValue={listData2.justification}
                    readOnly={listData.justification?.isDisabled}
                    onChange= {(e:any) => {
                      console.log(e);
                      setListData2({...listData2, justification: e.target.value })
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <GroupInput inputAttArr={groupInputAtt3} colSize={6} /> */}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="is_vat">
                  <Form.Label>{t("Vat Applicable")}</Form.Label>
                  {/* <div>{JSON.stringify({...listData.is_vat?.defaultValue})}</div> */}
                  <Select
                    name={"is_vat"}
                    options={listData.is_vat?.options}
                    value={listData.is_vat?.options[valueOptions(listData.is_vat?.options, listData2.is_vat)]}
                    className="react-select-disabled react-select-container"
                    classNamePrefix="react-select"
                    isDisabled={true}
                    isClearable={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      setListData({
                        ...listData,
                        is_vat: {
                          ...listData.is_vat,
                          name: e?.value ? e?.value : null,
                        }
                      })
                    }}
                  ></Select>
                  <FormInput
                    name="is_vat"
                    type="text"
                    placeholder="Input VAT"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    defaultValue={listData2.is_vat}
                    value={listData2.is_vat}
                  />
                </Form.Group>
              </Col>
              {listData2.is_vat == '1' && (
              <Col md={6}>
                <Form.Group className="mb-3" controlId="vatAmount">
                  <Form.Label>{t("Amount of VAT")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="vat_amount"
                    key="vat_amount"
                    type="text"
                    placeholder="Input Amount of VAT"
                    register={register}
                    errors={errors}
                    control={control}
                    defaultValue={listData2.vat_amount}
                    readOnly={listData.vat_amount?.isDisabled}
                    onChange= {(e:any) => {
                      let z = e.target.value;
                      if (listData2?.vat_amount === '0,00'){
                        if(parseFloat(replaceToDot(z)) >= 10){
                          z = (parseFloat(replaceToDot(z)) / parseFloat('10')).toString()
                        }
                      }
                      const a = changeUStoID(z)
                      onChangeMoney(listData2.vat_amount, e)
                      setListData2({...listData2, vat_amount: a})
                    }}
                  />
                </Form.Group>
              </Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="wht">
                  <Form.Label>WHT</Form.Label>
                  <Select
                    name={"wht"}
                    options={listWHT}
                    value={listWHT[valueOptions(listWHT, listData2.wht)]}
                    className={`${listData.wht?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                    classNamePrefix="react-select"
                    isDisabled={listData.wht?.isDisabled}
                    isClearable={true}
                    onChange= {(e:any) => {
                      console.log(e);
                      // let z = e.target.value;
                      const amount = parseFloat(replaceToDot(removeDot(listData2.payment_amount)))
                      const wht_rate = parseFloat(e?.key.replace('%',''))
                      const hasil = wht_rate / 100 * amount;
                      setListData2({...listData2, 
                        wht: e?.value ? e?.value : null,
                        wht_percentage: wht_rate,
                        wht_amount: hasil ? changeUStoID(replaceToComma(hasil.toString())) : '0,00'})
                    }}
                  ></Select>
                  <FormInput
                    name="wht"
                    type="text"
                    register={register}
                    errors={errors}
                    control={control}
                    hidden={true}
                    placeholder="Input WHT"
                    defaultValue={listData2.wht}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="wht_amount">
                  <Form.Label>{t("Amount of WHT")}</Form.Label>
                  <FormInput
                    name="wht_amount"
                    type="text"
                    register={register}
                    errors={errors}
                    control={control}
                    hidden={false}
                    placeholder="Input WHT Amount"
                    defaultValue={listData2.wht_amount}
                    readOnly={false}
                    onChange= {(e:any) => {
                      console.log(e);
                      let z = e.target.value;
                      if (listData2?.wht_amount === '0,00'){
                        if(parseFloat(replaceToDot(z)) >= 10){
                          z = (parseFloat(replaceToDot(z)) / parseFloat('10')).toString()
                        }
                      }
                      onChangeMoney(listData2.wht_amount, e)
                      setListData2({...listData2, 
                        wht_amount: changeUStoID(z)
                      })
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="requestedBy">
                  <Form.Label>{t("Requested By")}</Form.Label>
                  <FormInput
                    name="requested_by"
                    type="text"
                    register={register}
                    errors={errors}
                    control={control}
                    placeholder="Input Requested By"
                    defaultValue={listData2.requested_by ? listData2.requested_by : JSON.parse(sessionStorage.getItem("shreyu_user") as string).data?.name}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="suportingDocAll">
                  <Form.Label>{t("Supporting Document")}<span className="text-danger">*</span></Form.Label>
                  <div style={{ display: "inline" }}>
                    {listData2?.suporting_doc && (
                      <FindInPage
                        onClick={() => {
                          const win: any = window.open();
                          const [meta, data] = listData2?.suporting_doc?.split("base64,")
                          const blob = b64toBlob(data, "application/pdf");
                          const url = URL.createObjectURL(blob);
                          win.document.write(
                            `<iframe src="${
                              url
                            }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                          );
                        }}
                        className="cursor-pointer ms-2"
                      />
                    )}
                    {listData2?.suporting_doc && (
                      <Delete
                        color="error"
                        className="cursor-pointer ms-2"
                        onClick={() =>{
                          setListData({
                            ...listData,
                            suporting_doc:{
                              ...listData.suporting_doc,
                              name: ''
                            }
                          })
                          setListData2({
                            ...listData2,
                            suporting_doc:''
                          })
                        }}
                      />
                    )}
                  </div>
                  <Form.Control type="file" placeholder="Input File" 
                    disabled={listData?.suporting_doc?.isDisabled}
                    onChange={async(e: any) => {
                      console.log(e);
                      // console.log(e.target.files)
                      if(checkFile(e, true, 31457280)){
                        const file = await toBase64(e.target?.files[0]);
                        console.log(file)
                        // setValue('suporting_doc', file)
                        setListData2({...listData2, suporting_doc: file })
                        console.log("listData2",listData2);
                        console.log("listData",listData);
                      }
                    }}
                  />
                  <FormInput
                    name="suporting_doc"
                    type="text"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    placeholder="Input Supporting Doc"
                    defaultValue={listData2.suporting_doc}
                    value={listData2.suporting_doc}
                    readOnly={false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {props.isTreasury &&
              <>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="paymentAdvice">
                  <Form.Label>Payment Advice</Form.Label>
                  <Form.Control type="file" placeholder="Input File" disabled={props.data.paymentAdvice?.isDisabled}/>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="paidDate">
                  <Form.Label>Paid Date</Form.Label>
                  <ShreyuDatepicker
                    hideAddon={true}
                    dateFormat="dd MMM yyyy"
                    value={selectedDate}
                    onChange={(date) => {
                      onDateChange(date);
                    }}
                  />
                </Form.Group>
              </Col>
              </>
}
            </Row>
            {/* {(listData2?.approval_status_id == null) && ( */}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="fv60">
                  <Form.Label>{t("FV60")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="fv60"
                    type="text"
                    register={register}
                    errors={errors}
                    control={control}
                    placeholder="Input FV60"
                    onChange= {(e:any) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      setListData2({...listData2, 
                        fv60: numericValue
                      })
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="referenceCi">
                  <Form.Label>{t("Corporate Integrity Reference")}<span className="text-danger">*</span></Form.Label>
                  <FormInput
                    name="referenceCi"
                    type="text"
                    register={register}
                    errors={errors}
                    control={control}
                    placeholder="Input Corporate Integrity Reference"
                    disabled={listData?.referenceCi?.isDisabled}
                    onChange= {(e:any) => {
                      // const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      let value = e.target.value
                      setListData2({...listData2, 
                        referenceCi: value
                      })
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="referenceFileCi">
                  <Form.Label>{t("Corporate Integrity File")}<span className="text-danger">*</span></Form.Label>
                  <div style={{ display: "inline" }}>
                    {listData2?.referenceFileCi && (
                      <FindInPage
                        onClick={() => {
                          const win: any = window.open();
                          const [meta, data] = listData2?.referenceFileCi?.split("base64,")
                          const blob = b64toBlob(data, "application/pdf");
                          const url = URL.createObjectURL(blob);
                          win.document.write(
                            `<iframe src="${
                              url
                            }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                          );
                        }}
                        className="cursor-pointer ms-2"
                      />
                    )}
                    {listData2?.referenceFileCi && (
                      <Delete
                        color="error"
                        className="cursor-pointer ms-2"
                        onClick={() =>{
                          setListData({
                            ...listData,
                            referenceFileCi:{
                              ...listData.referenceFileCi,
                              name: ''
                            }
                          })
                          setListData2({
                            ...listData2,
                            referenceFileCi:''
                          })
                        }}
                      />
                    )}
                  </div>
                  <Form.Control type="file" placeholder="Input File" 
                    disabled={listData?.referenceFileCi?.isDisabled}
                    onChange={async(e: any) => {
                      console.log(e);
                      // console.log(e.target.files)
                      if(checkFile(e)){
                        const file = await toBase64(e.target?.files[0]);
                        // console.log(file)
                        // setValue('suporting_doc', file)
                        setListData2({...listData2, referenceFileCi: file })
                      }
                    }}
                  />
                  <FormInput
                    name="referenceFileCi"
                    type="text"
                    register={register}
                    errors={errors}
                    hidden={true}
                    control={control}
                    placeholder="Input Supporting Doc"
                    // defaultValue={listData2.referenceFileCi}
                    value={listData2.referenceFileCi}
                    readOnly={false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="ci_approval_date">
                  <Form.Label>{t("Corporate Integrity Approval Date")}<span className="text-danger">*</span></Form.Label>
                  {/* <ShreyuDatepicker
                    hideAddon={true}
                    dateFormat="dd MMM yyyy"
                    // inline={true}
                    value={listData2?.start_date}
                    // startDate={calculateStartDate()}
                    // minDate={calculateStartDate()}
                    onChange={(e) => {
                      // console.log(e)
                      // const needHR = calculateUploadHR()
                      setListData2({ ...listData2, start_date: e })
                      // console.log(listData2, errors);
                    }}
                    // disabled={true}
                  /> */}
                  <Form.Control
                    type="date"
                    // defaultValue={new Date().toISOString().split("T")[0]}
                    value={listData2?.ci_approval_date ? dayjs(listData2?.ci_approval_date).add(8, 'hour').toISOString().split("T")[0] : ""}
                    // isInvalid={errors.posting_date ? true : false}
                    disabled={listData?.ci_approval_date?.isDisabled} //{listData2?.is_cash_advance === '1'}
                    // startDate={dayjs(allData.start_date).toDate()}
                    // endDate={dayjs(allData.end_date).toDate()}
                    // minDate={dayjs(allData.start_date).toDate()}
                    // maxDate={dayjs(allData.end_date).toDate()}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setListData2({ ...listData2, ci_approval_date: e.target.value })
                    }}
                  />
                  <FormInput
                    name="ci_approval_date"
                    type="text"
                    placeholder="Input Doc No."
                    hidden={true}
                    register={register}
                    errors={errors}
                    control={control}
                    // defaultValue={listData2?.start_date}
                    value={listData2?.ci_approval_date}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="ci_activity_date">
                  <Form.Label>{t("Corporate Integrity Activity Date")}<span className="text-danger">*</span></Form.Label>
                  {/* <ShreyuDatepicker
                    hideAddon={true}
                    dateFormat="dd MMM yyyy"
                    // inline={true}
                    value={listData2?.end_date}
                    // endDate={calculateStartDate()}
                    // maxDate={calculateStartDate()}
                    onChange={(e) => {
                      // console.log(e)
                      const needHR = calculateUploadHR(e)
                      setListData2({ ...listData2, end_date: e, needHR })
                      // console.log(listData2, errors);
                    }}
                    // disabled={true}
                  />*/}
                  <Form.Control
                    type="date"
                    value={listData2?.ci_activity_date ?dayjs(listData2?.ci_activity_date).add(8, 'hour').toISOString().split("T")[0] : ''}
                    // isInvalid={errors.posting_date ? true : false}
                    disabled={listData?.ci_activity_date?.isDisabled} //{listData2?.is_cash_advance}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setListData2({ ...listData2, ci_activity_date: e.target.value })
                    }}
                  />
                  <FormInput
                    name="ci_activity_date"
                    type="text"
                    placeholder="Input Doc No."
                    hidden={true}
                    register={register}
                    errors={errors}
                    control={control}
                    // defaultValue={listData2?.end_date}
                    value={listData2?.ci_activity_date}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* )} */}
            {/* {listData2?.approval_status_id == 16 && (
            <>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="house_bank">
                  <Form.Label>{t("House Bank")}<span className="text-danger">*</span></Form.Label>
                  <Select
                  name={"house_bank_select"}
                  options={dataHouseBank}
                  value={dataHouseBank[valueOptions(dataHouseBank, listData2?.house_bank)]}
                  className={`${listData.house_bank?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                  classNamePrefix="react-select"
                  isDisabled={listData.house_bank?.isDisabled}
                  isClearable={true}
                  onChange= {(e:any) => {
                    setListData2({...listData2, house_bank: e?.value ? e?.value : null})
                    getDataAccountID(e?.value ? e?.value : null)
                  }}
                ></Select>
                <FormInput
                  name="house_bank"
                  type="text"
                  register={register}
                  errors={errors}
                  control={control}
                  hidden={true}
                  placeholder="Input House Bank"
                  defaultValue={listData2.house_bank}
                  readOnly={true}
                />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="account_id_form">
                  <Form.Label>{t("Account ID")}<span className="text-danger">*</span></Form.Label>
                  <Select
                  name={"account_id_select"}
                  options={dataAccountID}
                  value={dataAccountID[valueOptions(dataAccountID, listData2?.account_id)]}
                  className={`${listData.account_id?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                  classNamePrefix="react-select"
                  isDisabled={listData.account_id?.isDisabled}
                  isClearable={true}
                  onChange= {(e:any) => {
                    setListData2({...listData2, account_id: e?.value ? e?.value : null})
                  }}
                ></Select>
                <FormInput
                  name="account_id"
                  type="text"
                  register={register}
                  errors={errors}
                  control={control}
                  hidden={true}
                  placeholder="Input Account ID"
                  defaultValue={listData2.account_id}
                  readOnly={true}
                />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="payment_method_form">
                  <Form.Label>{t("Payment Method")}<span className="text-danger">*</span></Form.Label>
                  <Select
                  name={"payment_method_select"}
                  options={dataPaymentMethod}
                  value={dataPaymentMethod[valueOptions(dataPaymentMethod, listData2?.payment_method)]}
                  className={`${listData.payment_method?.isDisabled ? "react-select-disabled" : "react-select"} react-select-container`}
                  classNamePrefix="react-select"
                  isDisabled={listData.payment_method?.isDisabled}
                  isClearable={true}
                  onChange= {(e:any) => {
                    setListData2({...listData2, payment_method: e?.value ? e?.value : null})
                  }}
                ></Select>
                <FormInput
                  name="payment_method"
                  type="text"
                  register={register}
                  errors={errors}
                  control={control}
                  hidden={true}
                  placeholder="Input Payment Method"
                  defaultValue={listData2.payment_method}
                  readOnly={true}
                />
                </Form.Group>
              </Col>
            </Row>
            </>
            )} */}
          </Form>
          {props.canApprove &&
          <div className="d-flex flex-row-reverse">
          {props.canEdit &&
          <Button className="vale-button">{t("Edit")}</Button>
          }
          <Button className="vale-button">{t("Approve")}</Button>
          <Button className="vale-button">{t("Reject")}</Button>
        </div>
}          {!props.canApprove && !props.data.viewOnly?.name &&
          <div className="d-flex flex-row-reverse">
            <Button className="vale-button" 
              onClick={
                // console.log(e);
                handleSubmit(() => {
                  // console.log(listData2)
                  if(!load){
                    if(props.data.mode === 'edit'){
                      setListData2({...listData2, id: props.data.id?.name})
                      // listData2.id = props.data.id?.name
                    }
                    console.log(listData2)
                    // listData2.id = data.id
                    handleOnSubmit(listData2)
                  }
                })
              }
              >{t("Submit")}</Button>
          </div>
}
        </form>
        </Card.Body>
      </Card>
      <CiModal
        open={isCiModalOpen}
        onClose={(e: any, reason: any)  => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") return
          setIsCiModalOpen(false)
        }}
        onYes={() => {
          changeValueIsCi("1")
          setIsCiModalOpen(false)
        }}
        onNo={() => {
          changeValueIsCi("0")
          setIsCiModalOpen(false)
        }}
      />
    </>
  );
};

export default CreateInvoiceSAP;

const CiModal = (props: any) => {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogContent style={{ width: "100%", overflow: "hidden" }} className="p-3">
          <div style={{ width: "100%" }} className="">
            <h4>Does the transaction has Corporate Integrity (CI) approval?</h4>
          </div>
        </DialogContent>
        <DialogActions className="p-3 pt-0">
          <Button
            className="btn-secondary"
            onClick={props.onNo}
          >
            No
          </Button>
          <Button
            className="vale-button"
            // aria-disabled={load}
            onClick={props.onYes}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}