import Select from "react-select";

const InvoiceTaxSelect = ({ options, value, setValue, placeholder }: any) => {
  return (
    <Select
      options={options}
      isClearable
      theme={(theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 37,
          baseUnit: 0,
        },
      })}
      value={value}
      onChange={(e) => {
        // console.log(e);
        setValue(e);
      }}
      placeholder={placeholder ? placeholder : ""}
      styles={{
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "23px",
          height: "23px",
          // boxShadow: state.isFocused ? null : null,
        }),
        container(base, props) {
          return {
            ...base,
            marginBottom: "5px",
          };
        },
        valueContainer: (provided, state) => ({
          ...provided,
          height: "20px",
          padding: "0 6px",
        }),

        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "20px",
        }),
        option: (base, state) => ({
          ...base,
          padding: "0 6px",
        }),
      }}
    />
  );
};

const InvoiceSelectDebitCredit = ({
  // options,
  value,
  setValue,
  placeholder,
}: any) => {
  return (
    <Select
      options={[
        { label: "Debit", value: "D" },
        { label: "Credit", value: "C" },
      ]}
      theme={(theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 37,
          baseUnit: 0,
        },
      })}
      isClearable
      value={value}
      onChange={(e) => {
        // console.log(e);
        setValue(e);
      }}
      placeholder={placeholder ? placeholder : ""}
      styles={{
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "23px",
          height: "23px",
          // boxShadow: state.isFocused ? null : null,
        }),
        container(base, props) {
          return {
            ...base,
            marginBottom: "5px",
          };
        },
        valueContainer: (provided, state) => ({
          ...provided,
          height: "20px",
          padding: "0 6px",
        }),

        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "20px",
        }),
        option: (base, state) => ({
          ...base,
          padding: "0 6px",
        }),
      }}
    />
  );
};

export default InvoiceTaxSelect;

export { InvoiceSelectDebitCredit };
