import { Row, Col } from "react-bootstrap";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Pdf from "../../../constants/invoice.pdf";
import bastPdf from "./evim bast sample.pdf";
import allPdf from "./VEI_ESI_6022756_USD_1,731.00.pdf";
import { API_URI } from "../../../constants";

const DetailItem2 = ({ title, value = "-", file, url, canDownload }: any) => {
  const perluButton =
value && value[0] && value[0]?.includes(".pdf") ? true : false;
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  if (value === "-" || !value) return null;
  return (
    <Row style={{ borderBottom: "1px dotted #32313121" }}>
      <Col md={3} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>{title}</p>
      </Col>
      <Col md={1} style={{ marginBottom: 0 }}>
        <p style={{ textAlign: "center", marginBottom: 0 }}>:</p>
      </Col>
      <Col md={8} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>
          {value}{" "}
          {perluButton && (
            <FindInPageIcon
              onClick={() => {
                // if (value[0] === "All Document.pdf") {
                //   openInNewTab(value[0] === "All Document.pdf" ? allPdf : Pdf);
                // } else {
                // const win: any = window.open();
                // win.document.write(`<iframe src="${file}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
                // console.log(value, "VALUE")
                if (file) {
                  // console.log({ title, value, file }, "ASD");
                  // const win: any = window.open();
                  const [meta, data] = file.split("base64,");
                  const blob = b64toBlob(data, "application/pdf");
                  // console.log(blob);
                  const url = URL.createObjectURL(blob);
                  // console.log(url);
                  // win.document.write(`<iframe src="${value[1]}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
                  // win.document.write(`<a href="${url}" id="link" download="whatever.txt">download me</a>`)
                  // win.document.write(
                  //   `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                  // );
                  const win: any = window.open(url, '_blank');
                  if (title === "All Document" && canDownload) {
                    var aDom = win.document.createElement("a");
                    aDom.setAttribute("style", "display:none");
                    aDom.setAttribute("href", url);
                    aDom.setAttribute("download", value);
                    document.body.appendChild(aDom);
                    aDom.click();
                    console.log(aDom);
                    document.body.removeChild(aDom);
                  }
                }
                if (url) {
                  // const win: any = window.open();
                  // win.document.write(
                  //   `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                  // );
                }
              }}
              className="cursor-pointer"
            />
          )}
        </p>
      </Col>
    </Row>
  );
};

export default DetailItem2;
