import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import axios from "axios";
import {
  changeUStoID,
  checkFile,
  onChangeMoney,
  removeDot,
  replaceToComma,
  replaceToDot,
} from "../../../helpers/utils";
import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";
import { toBase64 } from "../../../utils";
import { removeFirstZero } from "../../../helpers/utils2";

// types
export interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (newRow: any) => any;
  open: boolean;
  // setItem: Dispatch<SetStateAction<BastItem>>;
  item?: any;
  index?: any;
  isEdit?: boolean;
  bast_id?: any;
  getBastFormValues?: any;
  isMineral?: any;
  getBastValues?: any;
  is_po_onthespot?: any;
  po_data?: any;
  contract_data?: any;
  contractCurrency?: any;
}

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const CreateNewBastItemModal = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  bast_id,
  // setItem,
  item,
  index,
  getBastFormValues,
  isMineral,
  is_po_onthespot,
  po_data,
  contract_data,
  contractCurrency,
}: CreateModalProps) => {
  // console.log(item)
  const { t } = useTranslation();
  const isGoods = getBastFormValues("good_or_services") === "1";
  // console.log(isGoods);

  const [stockCodeOptions, setStockCodeOptions] = useState([]);
  const [uomOptions, setUomOptions] = useState([]);
  const [currOptions, setCurrOptions] = useState([]);
  // console.log(currOptions);
  const schemaBastItem = yupResolver(
    yup.object().shape({
      description: yup.string().max(50, t("Max 50 characters")).min(1, t("Required!")).required(t("Required!")),
      unit_price: yup
        .string()
        .required(t("Required!"))
        .notOneOf(["0,00", null], t("Please enter amount")),
      // currency_id: yup.string().required(t("Required!")),
      // currency_rate: yup.string().when("currency_id", {
      //   // is: 1,
      //   is: (val: any) => val !== 1,
      //   then: yup.string().required("Please input currency rate")
      //   // otherwise: yup.string().required("Please input currency rate")
      // }),
      // currency_rate: yup.string().when("currency_id", (currency_id, field) => {
      //   // console.log(typeof currency_id, field);
      //   return currency_id === "1"
      //     ? field
      //     : field.required("Required if not IDR");
      // }),
      qty: yup
        .string()
        .nullable()
        .required(t("Required!"))
        .notOneOf(["0,00", null], t("Please enter amount")),
      total_value: yup.mixed().required(t("Required!")),
      uom: yup.string().required(t("Required!")),
      is_reimbursement: yup.string().required(t("Required!")),
      // description: yup.string(),
      // stock_code: yup.string().required(t("Required!"))
      // physical_picture: isGoods ? yup.mixed().required("Required if Goods!") : yup.mixed()
      // expired_date: isGoods
      //   ? yup.string().required("Required if Goods!")
      //   : yup.mixed(),
      // product_waranty_file: isGoods
      //   ? yup.mixed().required("Required if Goods!")
      //   : yup.mixed(),
    })
  );

  const {
    register,
    // control,
    handleSubmit: hs,
    watch,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  }: any = useForm({
    defaultValues: {
      total_value: 0,
    },
    resolver: schemaBastItem,
  });
  console.log(errors);
  // const watchQty = watch("qty");
  // const watchUnitPrice = watch("unit_price");
  const watchTotalValue = watch("total_value");
  const watchTotalValueLocal = watch("total_value_idr");
  const watchALl = watch();
  // console.log(watchALl, "WATCH ALL bast items");
  // console.log(errors, "ERRORS ALL");
  // const { qty, unit_price } = getValues()
  const [stockCode, setStockCode] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [UOM, setUom] = useState<{ value: string; label: string } | null>(null);
  const [is_reimbursement, setis_reimbursement] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [curr, setCurr] = useState<{ value: string; label: string } | null>(
    null
  );
  const [uomName, setUomName] = useState<any>("");
  const [currName, setCurrName] = useState<any>("");
  const [scName, setScName] = useState<any>("");

  const handleSubmit = () => {
    //put your validation logic here
    const {
      currency_id,
      description,
      qty,
      stock_code,
      total_value,
      total_value_idr,
      unit_price,
      unit_price_in_idr,
      uom,
      is_reimbursement,
      currency_rate,
      product_waranty_file,
      // physical_picture,
      // comment,
      expired_date,
      // id,
    } = getValues();
    // console.log(getValues(), "FORM VALUES SUBMIT");

    onSubmit({
      is_reimbursement,
      stock_code,
      description,
      uom,
      qty,
      unit_price,
      unit_price_in_idr,
      total_value,
      total_value_idr,
      uomName,
      currency_id,
      currName,
      currency_rate,
      scName,
      product_waranty_file,
      // physical_picture,
      // comment,
      expired_date,
    });
    reset();
    onClose();
  };

  useEffect(() => {
    register("stock_code");
    register("uom");
    register("currency_id");
  }, [register]);

  useEffect(() => {
    const fetchOptions = async ({ url, setOptions, label }: any) => {
      try {
        const data: any = {
          pagination: {
            pageIndex: 0,
            pageSize: 1000,
          },
        };
        if (url === "/uom-list") {
          data.columnFilters = [
            { id: "company", value: userCurrentCompany?.company_initial },
          ];
        }

        const response = await axios({
          url: url,
          method: "POST",
          data,
        });
        // console.log(response?.data?.data?.data, url);
        const dataMap =
          response?.data?.data?.data?.length &&
          response?.data?.data?.data.map((el: any) => ({
            value: el.id,
            label: el[label],
          }));
        if (dataMap) setOptions(() => [...dataMap]);
      } catch (error) {
        console.log(error, "ERROR FETCH OPTIONS");
      }
    };
    // fetchOptions({
    //   url: "/stock-code-list",
    //   setOptions: setStockCodeOptions,
    //   label: "material_detail",
    // });
    // fetchOptions({
    //   url: "/currency-list",
    //   setOptions: setCurrOptions,
    //   label: "currency_code",
    // });
    fetchOptions({
      url: "/uom-list",
      setOptions: setUomOptions,
      label: "uom_name",
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }: any) => {
      // console.log(value, name, type, 'value, name, type')
      if (name === "currency_id") {
        setValue("currency_rate", "");
        // setValue("unit_price_in_idr", "");
      }
      if (name === "qty" || name === "unit_price" || name === "currency_rate") {
        if (value.currency_id === 1 || !value.currency_id) {
          // setValue("unit_price_in_idr", value.unit_price);
          // setValue(
          //   "total_value_idr",
          //   changeUStoID(
          //     replaceToComma(
          //       (
          //         replaceToDot(removeDot(value.qty || 0)) *
          //         replaceToDot(removeDot(value.unit_price || 0))
          //       ).toFixed(2)
          //     )
          //   )
          // );
          setValue(
            "total_value",
            changeUStoID(
              replaceToComma(
                (
                  replaceToDot(removeDot(value.qty || 0)) *
                  replaceToDot(removeDot(value.unit_price || 0))
                ).toFixed(2)
              )
            )
          );
        } else {
          const a = changeAmountIdr(value.unit_price, 2, value.currency_rate);
          // setValue("unit_price_in_idr", a);
          // setValue(
          //   "total_value_idr",
          //   changeUStoID(
          //     replaceToComma(
          //       (
          //         replaceToDot(removeDot(value.qty || 0)) *
          //         replaceToDot(removeDot(a || 0))
          //       ).toFixed(2)
          //     )
          //   )
          // );
          setValue(
            "total_value",
            changeUStoID(
              replaceToComma(
                (
                  replaceToDot(removeDot(value.qty || 0)) *
                  replaceToDot(removeDot(value.unit_price || 0))
                ).toFixed(2)
              )
            )
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // console.log("AAAA");
    if (item) {
      setValue("id", item.id);
      setValue("description", item.description);
      // setis_reimbursement(item.is_reimbursementDefault);
      setis_reimbursement(
        item.is_reimbursement
          ? {
              value: item.is_reimbursement,
              label: Number(item.is_reimbursement) === 0 ? "No" : "Yes",
            }
          : null
      );
      setValue("is_reimbursement", item.is_reimbursement);
      setValue("uom", item.uom);
      setUom({ label: item.uomName, value: item.uom });
      setValue("qty", item.qty);
      setValue("currency_id", item.currency_id);
      setCurr({ value: item.currency_id, label: item.currName });
      setValue("currency_rate", item.currency_rate);
      setValue("unit_price", item.unit_price);
      setValue("total_value", item.total_value);
      setValue("stock_code", item.stock_code);
    }
  }, [item, index]);

  // useEffect(() => {
  //   const subscription = watch((value: any, { name, type }: any) => {
  //     console.log(type);
  //     console.log(name);
  //     console.log(value);
  //     if (name === "unit_price" || name === "currency_rate" || name === "qty") {
  //       setValue()
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);
  // console.log(getValues("currency_id"));

  const changeAmountIdr = (
    amount: any,
    currency_id: any,
    currency_rate: any
  ) => {
    if (amount) {
      let amount_idr: any = parseFloat(replaceToDot(removeDot(amount)));
      if (currency_id !== 1 && currency_rate) {
        // const amoun = parseFloat(replaceToComma(removeDot(amount)))
        const curr_rate = parseFloat(replaceToDot(removeDot(currency_rate)));
        // console.log(amount_idr, curr_rate);
        amount_idr = replaceToComma((amount_idr * curr_rate).toFixed(2));
      }
      return changeUStoID(amount_idr);
    }
    return 0;
    // return changeUStoID(parseFloat(amount_idr).toFixed(2))
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{t("Input Data")}</DialogTitle>
      <DialogContent>
        <Form>
          <Row>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("ID")}</Form.Label>
                <Form.Control type="text" readOnly {...register("id")} />
              </Form.Group>
            </Col> */}
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Description")}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Description"
                  {...register("description")}
                  isInvalid={errors.description ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="">
                <Form.Label>{t("Expired Date")}</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Input Expired Date"
                  {...register("expired_date")}
                  isInvalid={errors.expired_date ? true : false}
                  // disabled={!isGoods || !isMineral}
                  disabled={!isGoods}
                />
              </Form.Group>
              {errors.expired_date && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "0.25rem",
                    fontSize: "0.75rem",
                    color: "#ff5c75",
                    position: "absolute",
                  }}
                >
                  {errors.expired_date?.message}
                </div>
              )}
            </Col>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Stock Code")}</Form.Label>
                <Select
                  options={stockCodeOptions}
                  // defaultInputValue={stockCodeOption[2].value}
                  classNamePrefix="react-select"
                  className="react-select-disabled"
                  isDisabled={true}
                  value={stockCode}
                  onChange={(e) => {
                    setStockCode(e);
                    setValue("stock_code", e?.value);
                    setScName(e?.label);
                  }}
                ></Select>
              </Form.Group>
            </Col> */}
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  UOM<span className="text-danger">*</span>
                </Form.Label>
                {/* <Form.Control type="text" value="Month" /> */}
                <Select
                  options={uomOptions}
                  // defaultInputValue=""
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  // isDisabled={item.readOnly}
                  value={UOM}
                  onChange={(e) => {
                    setUom(e);
                    setValue("uom", e?.value);
                    setUomName(e?.label);
                  }}
                ></Select>
                {errors.uom && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.75rem",
                      color: "#ff5c75",
                      position: "absolute",
                    }}
                  >
                    {errors.uom?.message}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Qty<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Quantity"
                  {...register("qty")}
                  onChange={(e) => {
                    const prevData = getValues("qty");
                    onChangeMoney(prevData, e);
                    let z = e.target.value;
                    const a = changeUStoID(removeFirstZero(prevData, z));
                    setValue("qty", a);
                  }}
                  isInvalid={errors.qty ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.qty?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Curr.")}
                  {/* <span className="text-danger">*</span> */}
                </Form.Label>
                {/* <Select
                  options={currOptions}
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  value={curr}
                  onChange={(e) => {
                    setCurr(e);
                    setValue("currency_id", e?.value);
                    setCurrName(e?.label);
                  }}
                ></Select>
                {errors.currency_id && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.75rem",
                      color: "#ff5c75",
                      position: "absolute",
                    }}
                  >
                    {errors.currency_id?.message}
                  </div>
                )} */}
                <Form.Control
                  type="text"
                  disabled
                  // {...register("currency_id")}
                  value={contractCurrency}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Original Unit Price")}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Price"
                  {...register("unit_price")}
                  onChange={(e) => {
                    onChangeMoney(getValues("unit_price"), e);
                    const prevData = getValues("unit_price");
                    const a = changeUStoID(
                      removeFirstZero(prevData, e.target.value)
                    );
                    setValue("unit_price", a);
                  }}
                  isInvalid={errors.unit_price ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.unit_price?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Original Total Value")}</Form.Label>
                <Form.Control
                  type="text"
                  name="total_value"
                  value={watchTotalValue}
                  {...register("total_value")}
                  disabled
                  readOnly
                  onChange={(e) => {
                    setValue("total_value", changeUStoID(e.target.value));
                  }}
                  isInvalid={errors.total_value ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.total_value?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Currency Rate")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Rate"
                  {...register("currency_rate")}
                  onChange={(e) => {
                    onChangeMoney(getValues("currency_rate"), e);
                    const prevData = getValues("currency_rate");
                    const a = changeUStoID(
                      removeFirstZero(prevData, e.target.value)
                    );
                    setValue("currency_rate", a);
                  }}
                  isInvalid={errors.currency_rate ? true : false}
                  disabled={currName === "IDR"}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.currency_rate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col> */}
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Unit Price in IDR")}</Form.Label>
                <Form.Control
                  type="text"
                  name="unit_price_in_idr"
                  {...register("unit_price_in_idr")}
                  disabled
                  readOnly
                  // value={}
                  // onChange={(e) => {
                  //   setValue("total_value", changeUStoID(e.target.value));
                  // }}
                  isInvalid={errors.unit_price_in_idr ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.unit_price_in_idr?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col> */}
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Total Value in IDR")}</Form.Label>
                <Form.Control
                  type="text"
                  name="total_value_idr"
                  value={watchTotalValueLocal}
                  {...register("total_value_idr")}
                  disabled
                  readOnly
                  onChange={(e) => {
                    setValue("total_value_idr", changeUStoID(e.target.value));
                  }}
                  isInvalid={errors.total_value_idr ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.total_value_idr?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col> */}
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Is Reimbursement")}
                  <span className="text-danger">*</span>
                </Form.Label>
                {/* <Form.Control type="text" value="Month" /> */}
                <Select
                  options={[
                    { label: "Yes", value: "1" },
                    { label: "No", value: "0" },
                  ]}
                  // defaultInputValue=""
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  // isDisabled={item.readOnly}
                  value={is_reimbursement}
                  onChange={(e) => {
                    setis_reimbursement(e);
                    setValue("is_reimbursement", e?.value);
                  }}
                ></Select>
                {errors.is_reimbursement && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.75rem",
                      color: "#ff5c75",
                      position: "absolute",
                    }}
                  >
                    {errors.is_reimbursement?.message}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={12} className="mb-2">
              <Form.Group className="">
                <Form.Label>{t("Warranty of Product")}</Form.Label>
                <Controller
                  control={control}
                  name={"product_waranty_file"}
                  render={({ field: { value, onChange, ...field } }: any) => {
                    return (
                      <Form.Control
                        {...field}
                        // value={value?.fileName}
                        onChange={async (event: any) => {
                          if (event.target?.files?.[0] && checkFile(event)) {
                            // setValue(item.controlId, event.target.files?.[0]);
                            try {
                              const b64: string = (await toBase64(
                                event.target.files[0]
                              )) as string;
                              setValue("product_waranty_file", b64);
                            } catch (error) {
                              console.log(error);
                            }
                          }
                        }}
                        type="file"
                        placeholder="Input File"
                        disabled={!isGoods}
                      />
                    );
                  }}
                />
              </Form.Group>
              {errors.product_waranty_file && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "0.25rem",
                    fontSize: "0.75rem",
                    color: "#ff5c75",
                    position: "absolute",
                  }}
                >
                  {errors.product_waranty_file?.message}
                </div>
              )}
            </Col>
            {/* <Col md={6} className="mb-2">
              <Form.Group className="mb-3">
                <Form.Label>Picture of Goods</Form.Label>
                <Controller
                  control={control}
                  name={"physical_picture"}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <Form.Control
                        {...field}
                        value={value?.fileName}
                        onChange={async (event: any) => {
                          if (event.target?.files?.[0]) {
                            // setValue(item.controlId, event.target.files?.[0]);
                            try {
                              const b64: string = (await toBase64(
                                event.target.files[0]
                              )) as string;
                              setValue("physical_picture", b64);
                            } catch (error) {
                              console.log(error);
                            }
                          }
                        }}
                        type="file"
                        placeholder="Input File"
                        // disabled={!isGoods || !isMineral}
                        disabled={!isGoods}
                      />
                    );
                  }}
                />
                {errors.physical_picture && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.75rem",
                      color: "#ff5c75",
                      position: "absolute",
                    }}
                  >
                    {errors.physical_picture?.message}
                  </div>
                )}
              </Form.Group>
            </Col> */}
            {/* <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Quantity"
                  {...register("comment")}
                  isInvalid={errors.comment ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.comment?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col> */}
          </Row>
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button
          onClick={() => {
            // reset();
            onClose();
          }}
          variant="secondary"
        >
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={hs(handleSubmit)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewBastItemModal;
