import { Button, Col, Form, Row } from "react-bootstrap";
import KpiPslChartVei from "../charts/KpiPslChartVei";
import KpiPslChartStm from "../charts/KpiPslChartStm";
import KpiTable from "./Components/ReportTable/KpiTable";
import KpiTableHorizontal from "./Components/ReportTable/KpiTableHorizontal";
import { useState, useEffect } from "react";
import MILT_STM from "../charts/MILT_STM";
import MILT_VEI from "../charts/MILT_VEI";
import { CURRENT_COMPANY } from "../../helpers/api/apiCore";
import { API_URI } from "../../constants";
import Select from "react-select";
import FormInput from "../../components/FormInput";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const MiroLeadHome = () => {
  const [range, setRange] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [kpi, setKPI] = useState<any>('7')
  const [dataAsli, setDataAsli] = useState<any>([])
  const [dataProp, setDataProp] = useState<any>({})
  const [dataTable, setDataTable] = useState<any>([])
  const [year, setYear] = useState<any>(new Date().getFullYear())
  const [yearSelected, setYearSelected] = useState<any>({
    id: new Date().getFullYear(), 
    label: new Date().getFullYear(), 
    key: new Date().getFullYear()
  })
  const company_code = parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
  const [yearOption, setYearOption] = useState<any>([])
  // console.log(range)
  const getData = async () => {
    fetch(API_URI + 'dashboard/miro', {
      method: "POST",
      body:JSON.stringify({
        start_date: year + '-01-01', //'2023-01-01',
        end_date: year + '-12-31', //'2023-12-31',
        company_code: userCurrentCompany?.company_code
      })
    })
    .then(response => response.json())
    .then((data: any) => {
      console.log(data)
      if(data && data.kpi_miro){
        setDataAsli(data?.kpi_miro);
        const bln = [1,2,3,4,5,6,7,8,9,10,11,12]
        const NumOfInvoice = []
        const NumOfVendor = []
        const avgLeadTime = []
        const avgPlanLead = []
        for (let i = 0; i < 12; i++) {
          avgPlanLead.push(kpi)
        }
        // const avgPlanLead = [7, 7, 7, 7, 7, 7, 7, 7, 7 , 7, 7, 7]
        
        for(const a of bln) {
          let ketemu = [0,0,0];
          for(const b of data?.kpi_miro) {
            if(a === parseInt(b.month_no) && b.year_no == year) {
              ketemu = [parseInt(b.total_invoice),parseInt(b.total_vendor),parseFloat(b.lead_time)]
              break;
            }
          }
          ketemu[0] === 0 ? NumOfInvoice.push(0) : NumOfInvoice.push(ketemu[0])
          ketemu[1] === 0 ? NumOfVendor.push(0) : NumOfVendor.push(ketemu[1])
          ketemu[2] === 0 ? avgLeadTime.push(0) : avgLeadTime.push(ketemu[2])
        }
        setDataProp({
          NumOfInvoice,
          NumOfVendor,
          avgLeadTime,
          avgPlanLead,
          year
        })
        console.log(NumOfInvoice)
      }
    })
  }
  const getDataTable = async () => {
    fetch(`${API_URI}dashboard/miro-table?kpi=${kpi}&company_code=${company_code}&year=${year}`, {
      method: "GET",
      // body:JSON.stringify({
      //   "kpi": 7,
      //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
      // }),
      redirect: 'follow'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      if(data){
        setDataTable(data.data);
      }
    })
  }
  
  useEffect(() => {
    const years = []
    const year2 = new Date().getFullYear()
    for (let i = 2023; i<= year2; i++){
      years.push({
        id: i, label: i.toString(), key: i
      })
    }
    setYearOption(years)
  }, [])
  useEffect(() => {
    // setYear(yearSelected.label)
    // setKPI("7")
    getData()
    getDataTable()
  },[year, kpi])
  
  return (
    <>
      <div className="mt-3"></div>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"Years"}</Form.Label>
            <Select
              name={"vendorCode"}
              options={yearOption}
              value={yearSelected}
              className={"react-select react-select-container"}
              classNamePrefix="react-select"
              isDisabled={false}
              // isClearable={true}
              // escapeClearsValue={true}
              onChange= {(e:any) => {
                console.log(e)
                setYearSelected(e)
                setYear(e.label)
              }}
            ></Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"KPI value"}</Form.Label>
            <FormInput
              name="wht"
              type="text"
              hidden={false}
              placeholder="Input KPI"
              defaultValue={"7"}
              readOnly={false}
              value={kpi}
              onChange={(e) => {
                const z = () => {
                  const val = e.target.value.replace(/[^\d]+/g, "");
                  setKPI(val)
                  if(val && parseInt(val)){
                    const a = []
                    for(let i:any = 0; i <=11 ; i++){
                      a.push(parseInt(val))
                    }
                    setDataProp({
                      ...dataProp,
                      avgPlanLead: a
                    })
                  }
                }
                z();
                // useDebounce(z, 1000)
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {/* CHART */}
      <Row>
        {/* {userCurrentCompany.company_initial === "STM" && ( */}
          <Col md={12}>
            <MILT_STM
            data={dataProp}
            // multiYaxisChartData={multiYaxisChartData}
            // showLoader={!isChatInitilized}
            />
          </Col>
        {/* )} */}
        {/* {userCurrentCompany.company_initial === "VEI" && (
          <Col md={12}>
            <MILT_VEI
            // multiYaxisChartData={multiYaxisChartData}
            // showLoader={!isChatInitilized}
            />
          </Col>
        )} */}
      </Row>
      <Row>
        <Col md={12} className="mb-3">
          <KpiTableHorizontal
            data={dataTable}
            year={year}
            delayTitle={"Delay Miro"}
            ontimeTitle={"Miro On Time"}
          // showLoader={!isChatInitilized}
          />
        </Col>
      </Row>
      <Row>
        <Button 
          onClick={() => {
            const Download = ((arrayBuffer:any, type: any) => {
              var blob = new Blob([arrayBuffer], { type: type });
              var url = URL.createObjectURL(blob);
              window.open(url);
            })
            const company_code = parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
            
            fetch(`${API_URI}dashboard/cek-miro?kpi=${kpi}&company_code=${company_code}&year=${year}`, {
              method: "GET",
              // body:JSON.stringify({
              //   "kpi": 7,
              //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
              // }),
              redirect: 'follow'
            })
            .then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Report_MIRO.xlsx';
                a.click();
              })
            });
          }}
          className="vale-button"
        >
          Download Report
        </Button>
      </Row>
    </>
  );
};

export default MiroLeadHome;
