import { useEffect, useState } from "react";

const KpiTableHorizontal = (props: any) => {
  console.log(props);
  const [td, setTd] = useState<any>([]);
  const [to, setTo] = useState<any>([]);
  const [tt, setTt] = useState<any>([]);
  useEffect(() => {
    const bln = [1,2,3,4,5,6,7,8,9,10,11,12];
      const td1 = []
      const to1 = []
      const tt1 = []
      for (const a of bln){
        let ketemu = '0';
        let ketemu2 = '0';
        let ketemu3 = '0';
        let ketemu4 = '0';
        let ketemu5 = '0';
        for (const b of props.data){
            if(a === b.month_no){
                ketemu = b.delay.toString()
                ketemu2 = b.ontime.toString()
                ketemu3 = b.total.toString()
                ketemu4 = parseFloat(b.percent_delay).toFixed(2)
                ketemu5 = parseFloat(b.percent_ontime).toFixed(2)
                break;
            }
        }
        if(ketemu === '0' && ketemu2 === '0') {
            td1.push('0');
            td1.push('0%')
            to1.push('0')
            to1.push('0%')
            tt1.push('0')
            tt1.push('100%')
        }else {
            td1.push(ketemu);
            td1.push(ketemu4);
            to1.push(ketemu2)
            to1.push(ketemu5);
            tt1.push(ketemu3);
            tt1.push('100%')
        }
    }
    console.log(td1);
    setTd([...td1])
    setTo([...to1])
    setTt([...tt1])
    
  },[props])
  return (
    <div className="kpi-table">
      <table>
        <thead>
          <tr>
            <th rowSpan={3} className="first-col">PT STM</th>
            <th colSpan={24}>{props?.year}</th>
          </tr>
          <tr>
            <th colSpan={2}>January</th>
            <th colSpan={2}>February</th>
            <th colSpan={2}>March</th>
            <th colSpan={2}>April</th>
            <th colSpan={2}>May</th>
            <th colSpan={2}>June</th>
            <th colSpan={2}>July</th>
            <th colSpan={2}>August</th>
            <th colSpan={2}>September</th>
            <th colSpan={2}>October</th>
            <th colSpan={2}>November</th>
            <th colSpan={2}>December</th>
          </tr>
          <tr>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
            <th>Quantity</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.delayTitle}</td>
            {td.map((item: any, index: any) => (
            <td>{item}</td>
      ))}
          </tr>
          <tr>
            <td>{props.ontimeTitle}</td>
            {to.map((item: any, index: any) => (
            <td>{item}</td>
            ))}
          </tr>
          {/* <tr>
            <td>Outstanding Invoice</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr> */}
          <tr>
            <td>Total</td>
            {tt.map((item: any, index: any) => (
              <td>{item}</td>
            ))}
            
          </tr>
          {/* <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default KpiTableHorizontal;
