// static data
import CreateClaimFormNonCC from "../../../components/CreateClaimFormNonCC";
import CreateInvoiceSAP from "../../../components/CreateInvoiceSAP";
import {useParams} from 'react-router-dom';

const CreateClaimNonCCAdminAp = () => {
    const dataEdit = {
        employeeName: {
            name: "Dwayne a",
            isDisabled:true
        },
        employeeID: {
            name: "10400111",
            isDisabled: true
        },
        department: {
            name: "Corporate IT",
            isDisabled: true
        },
        position: {
            name: "IT Analyst",
            isDisabled: true
        },
        haveCashAdvance: {
            name: {
                value: true,
                label: "Yes",
            },
            isDisabled: false
        },
        docNo:{
            name: "EC23-STM-SEP-001",
            isDisabled: true
        },
        docDate:{
            name: "12/07/23",
            isDisabled: false
        },
        startingDate: {
            name: "",
            isDisabled: false
        },
        endingDate:{
            name: "",
            isDisabled: false
        },
        travelRoute: {
            name: "",
            isDisabled: false
        },
        kindOfTransport: {
            name: "",
            isDisabled: false
        },
        bankRecolitionNumber: {
            name: "",
            isDisabled: false
        },
        reasonForTravelAdvanceText:{
            name:"",
            isDisabled: false
        },
        reasonForTravelAdvance:{
            name:"",
            isDisabled: false
        },
        reasonForOtherAdvance:{
            name:"",
            isDisabled: false
        },
        returnToCompany:{
            name:"",
            isDisabled: false
        },
        ca11:{
            name:"",
            isDisabled: false
        },
        ca12:{
            name:"",
            isDisabled: false
        },
        ca21:{
            name:"IDR",
            isDisabled: false
        },
        ca22:{
            name:"150,000",
            isDisabled: false
        },
        ca31:{
            name:"IDR",
            isDisabled: false
        },
        ca32:{
            name:"150,000",
            isDisabled: false
        },
        ca41:{
            name:"IDR",
            isDisabled: false
        },
        ca42:{
            name:"150,000",
            isDisabled: false
        },
        employeeSign:{
            name:"",
            isDisabled: false
        },
        employeeSignDate:{
            name:"",
            isDisabled: false
        },
        employeeSignName:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSign:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSignDate:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSignName:{
            name:"",
            isDisabled: false
        }
      }
      const dataAdd = {
        employeeName: {
            name: "Dwayne a",
            isDisabled:true
        },
        employeeID: {
            name: "10400111",
            isDisabled: true
        },
        department: {
            name: "Corporate IT",
            isDisabled: true
        },
        position: {
            name: "IT Analyst",
            isDisabled: true
        },
        haveCashAdvance: {
            name: 1,
            isDisabled: false
        },
        docNo:{
            name: "EC23-STM-SEP-001",
            isDisabled: true
        },
        docDate:{
            name: "",
            isDisabled: false
        },
        startingDate: {
          name: "",
          isDisabled: false
        },
        endingDate:{
            name: "",
            isDisabled: false
        },
        travelRoute: {
            name: "",
            isDisabled: false
        },
        kindOfTransport: {
            name: "",
            isDisabled: false
        },
        bankRecolitionNumber: {
            name: "",
            isDisabled: false
        },
        reasonForTravelAdvanceText:{
            name:"",
            isDisabled: false
        },
        reasonForTravelAdvance:{
            name:"",
            isDisabled: false
        },
        reasonForOtherAdvance:{
            name:"",
            isDisabled: false
        },
        returnToCompany:{
            name:"",
            isDisabled: false
        },
        ca11:{
            name:"",
            isDisabled: false
        },
        ca12:{
            name:"",
            isDisabled: false
        },
        ca21:{
            name:"",
            isDisabled: false
        },
        ca22:{
            name:"",
            isDisabled: false
        },
        ca31:{
            name:"",
            isDisabled: false
        },
        ca32:{
            name:"",
            isDisabled: false
        },
        ca41:{
            name:"",
            isDisabled: false
        },
        ca42:{
            name:"",
            isDisabled: false
        },
        employeeSign:{
            name:"",
            isDisabled: false
        },
        employeeSignDate:{
            name:"",
            isDisabled: false
        },
        employeeSignName:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSign:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSignDate:{
            name:"",
            isDisabled: false
        },
        immediateSupervisorSignName:{
            name:"",
            isDisabled: false
        }
      }
  const {page} = useParams();
  let data;
  if(page === 'create') {
    data = dataAdd;
  } else if (page === 'edit') {
    data = dataEdit;
  } else {
    data = dataAdd;
  }
  return (
    <>
      <CreateClaimFormNonCC 
        data={data} 
        canApprove={false} 
        isEdit={page==="edit" ? true : false}
        isAdminAP={true}
        />
    </>
  );
};

export default CreateClaimNonCCAdminAp;
