import { Card, Form } from "react-bootstrap";

const LayoutInputContainer = () => {
  return (
    <>
      <h4>React Bootstrap Form Control</h4>
      <div>
        <Card>
          <Card.Body>
            <Form.Label>String input</Form.Label>
            <Form.Control size="lg"></Form.Control>
            <Form.Control></Form.Control>
            <Form.Control size="sm"></Form.Control>

            <Form.Label>Text Area</Form.Label>
            <Form.Control as="textarea"></Form.Control>

            <Form.Label>Readonly</Form.Label>
            <Form.Control size="lg" readOnly></Form.Control>
            <Form.Label>disabled</Form.Label>
            <Form.Control size="lg" disabled></Form.Control>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default LayoutInputContainer;
