// static data
import CreateInvoiceSAP from "../../../components/CreateInvoiceSAP";
import {useParams} from 'react-router-dom';

const AddInvoiceSAPTreasury = () => {
  const dataEdit = {
    sap_number: {
      name: "STM 1492", // VEI 156",
      isDisabled: true,
    },
    geoOrStm: {
      name: 1,
      isDisabled: true,
    },
    cost_desc: {
      name: "HERTZ Australia",
      isDisabled: true,
    },
    vendorNumber: {
      name: "SPE002-81",
      isDisabled: true,
    },
    vendorName: {
      name: "FA",
      isDisabled: true,
    },
    paymentAmount: {
      name: "50,000,000",
      isDisabled: true,
    },
    gl: {
      name: "5030500001",
      isDisabled: true,
    },
    wbs: {
      name: "R001828.04.08.19",
      isDisabled: true,
    },
    vatApplicable: {
      name: {
        value: true,
        label: "Yes",
      },
      isDisabled: true,
    },
    amountOfDPP: {
      name: "0",
      isDisabled: false,
    },
    amountOfVAT: {
      name: "0",
      isDisabled: false,
    },
    amountOfWHT: {
      name: "0",
      isDisabled: false,
    },
    isAllRelevant: {
      name: {
        value: true,
        label: "Yes",
      },
      isDisabled: true,
    },
    requestedBy: {
      name: "ZULFIKAR",
      isDisabled: true,
    },
    viewOnly: {
      name: false,
      isDisabled: true,
    },
    attachFile: {
      name: "",
      isDisabled: true,
    },
    paymentAdvice: {
      name: "",
      isDisabled: false,
    },
    paidDate: {
      name: "",
      isDisabled: false,
    },
  };
  const dataAdd = {
    sap_number: {
      name: "STM 1492",
      isDisabled: true,
    },
    geoOrStm: {
      name: 1,
      isDisabled: false,
    },
    cost_desc: {
      name: "",
      isDisabled: false,
    },
    vendorNumber: {
      name: "",
      isDisabled: false,
    },
    vendorName: {
      name: "",
      isDisabled: false,
    },
    paymentAmount: {
      name: "",
      isDisabled: false,
    },
    gl: {
      name: "",
      isDisabled: false,
    },
    wbs: {
      name: "",
      isDisabled: false,
    },
    vatApplicable: {
      name: false,
      isDisabled: false,
    },
    amountOfDPP: {
      name: "0",
      isDisabled: false,
    },
    amountOfVAT: {
      name: "0",
      isDisabled: false,
    },
    amountOfWHT: {
      name: "0",
      isDisabled: false,
    },
    isAllRelevant: {
      name: false,
      isDisabled: false,
    },
    requestedBy: {
      name: "",
      isDisabled: false,
    },
    viewOnly: {
      name: false,
      isDisabled: false,
    },
    attachFile: {
      name: "",
      isDisabled: false,
    },
  };
  const {page} = useParams();
  let data;
  if(page === 'create') {
    data = dataAdd;
  } else if (page === 'edit') {
    data = dataEdit;
  }
  return (
    <>
      <CreateInvoiceSAP data={data} canApprove={false} isTreasury={true}/>
    </>
  );
};

export default AddInvoiceSAPTreasury;
