import { useMemo } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';

type InvoiceColumn = {
  geothermal: string;
  sapCompanyNo: string;
  vendorNo: string;
  vendorName: string;
  paymentCurrency: string;
  paymentAmount: string;
  glNo: string;
  wbsNo: string;
  costDescription: string;
  vatApplicable: string;
  vatAmount: string;
  isRelevant: string;
  requestedBy: string;
  paidDate: string;
  status: string;
};

const ReportSpecialPayment = () => {
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "geothermal",
        header: "Mineral / Geothermal",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "sapCompanyNo",
        header: "SAP Company No",
        size: 80,
      },
      {
        accessorKey: "vendorNo",
        header: "Vendor No",
        size: 80,
      },
      {
        accessorKey: "vendorName",
        header: "Vendor Name",
        size: 80,
      },
      {
        accessorKey: "paymentCurrency",
        header: "Payment Currency",
        size: 80,
      },
      {
        accessorKey: "paymentAmount",
        header: "Payment Amount",
        size: 80,
      },
      {
        accessorKey: "glNo",
        header: "GL",
        size: 80,
      },
      {
        accessorKey: "wbsNo",
        header: "WBS",
        size: 80,
      },
      {
        accessorKey: "costDescription",
        header: "Cost Description",
        size: 80,
      },
      {
        accessorKey: "vatApplicable",
        header: "VAT Applicable",
        size: 80,
      },
      {
        accessorKey: "vatAmount",
        header: "VAT Amount",
        size: 80,
      },
      {
        accessorKey: "isRelevant",
        header: "Is Relevant",
        size: 80,
      },
      {
        accessorKey: "requestedBy",
        header: "Requested By",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        // Cell: ({ cell }) => {
        //   const value = String(cell.getValue());
        //   const isIncludeAppr = value.includes("pproval");
        //   return (
        //     <div
        //       className={`invoice-${
        //         isIncludeAppr
        //           ? "waiting-approval"
        //           : value.toLowerCase().split(" ").join("-")
        //       }`}
        //     >
        //       <p>{value}</p>
        //     </div>
        //   );
        // },
      },
    //   {
    //     header: "Action",
    //     accessor: "action",
    //     sort: true,
    //   }
    ],
    []
  );
  const data: InvoiceColumn[] = [
    {
      geothermal: "Geothermal",
      sapCompanyNo: "STM 1492",
      costDescription: "HERTZ Australia",
      vendorNo: "SPE002-81",
      vendorName: "FA",
      paymentCurrency: "IDR",
      paymentAmount: "50,000,000",
      glNo: "5030500001",
      wbsNo: "R001828.04.08.19",
      vatApplicable: "Yes",
      vatAmount: "0",
      isRelevant: "Yes",
      requestedBy: "Zulfikar",
      paidDate: "23 Juni 2023",
      status: "Paid",
    },
  ];
  const changeCamel = (data: string) => {
    return data.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
  }
  const data2 = (data: InvoiceColumn[]) => {
    let hasil = []
    for (const a of data) {
      const hasilObj:any = {}
      for (const [key, value] of Object.entries(a)) {
        hasilObj[changeCamel(key)] = value
      }
      hasil.push(hasilObj)
    }
    return hasil
  }
  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              // enableSelectAll={true}
              // enableMultiRowSelection={true}
              // enableRowSelection={(row) => row.original.canEdit} 
              // enableEditing
              enableRowNumbers
              enablePinning
              initialState={{ columnPinning: { right: ['status'] } }}
              data={data}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(data2(data));
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                    XLSX.writeFile(workbook, "Export Special Payment.xlsx");
                  }}
                  className="vale-button"
                >
                  Export Excel
                </Button>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};



export default ReportSpecialPayment;
