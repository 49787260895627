import React, { useState, useMemo, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DetailList from "../../components/MyComponents/Detail/DetailList";
import logoVale from "../../assets/images/stm-logo.png";
import logoValeVei from "../../assets/images/logo-vale-with-text-image.jpg";
// import logoVale from "../../assets/images/logo-dashboard.png";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {history} from "./historyData";
import HistoryComponent from "../../components/MyComponents/History/HistoryComponent";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Check, Close, Edit, FindInPage } from "@mui/icons-material";
import Pdf from '../../constants/invoice.pdf';
import { changeUStoID, getMasterData, getWorkflowRoleCC, mergePDF, openFilePDF, roleEmployeeClaim, roleSpecialPayment } from "../../helpers/utils";
import { API_URI } from "../../constants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from 'dayjs'
import DetailList3 from "../../components/MyComponents/Detail/DetailList3";
import HistoryEC from "../../components/MyComponents/History/HistoryEC";
import PaymentAdvice from "../../components/MyComponents/PaymentAdvice/PaymentAdvice";
import ConfirmationModalEC from "../../components/MyComponents/Modal/ConfirmationModalEC";
import PaymentAdvice3 from "../../components/MyComponents/PaymentAdvice/PaymentAdvice3";
import { useNavigate } from "react-router-dom";
import ConfirmationModalCC from "../../components/MyComponents/Modal/ConfirmationModalCC";
import PaymentAdvice4 from "../../components/MyComponents/PaymentAdvice/PaymentAdvice4";
import { useTranslation, Trans } from 'react-i18next';

type ExpenditureColumn = {
  id?: number;
  date_detail: string;
  type_of_expense_id: string;
  justification: string;
  wbs_id?: string;
  cost_center_id?: string;
  cost_element_id?: string;
  currency_id: string;
  amount: string;
  currency_rate: string;
  amount_idr: string;
  reject_reason: string;
  attachFile?: string;
  attachFile_hr?: string;
  status?: string | undefined;
  department?: {
    id?: number;
    name?: string;
  }
  typeofexpense?: {
    type_code?: string
  };
  costelement?: {
    gl_code?: string;
    gl_name?: string;
    gabungan?: string;
  };
  wbs?: {
    wbs_code?: string;
    wbs_name?: string;
    gabungan?: string;
  }
  costcenter?: {
    code?: string;
    name?: string;
    gabungan?: string;
  }
  missing_receipts?: string;
};

const dataView = {
    "Company": "PT.STM",
    "Advanced / Settlement": "Settlement",
    "Employee Name": "Dwayne a",
    "Employee ID": "10400111",
    "Department": "Corporate IT",
    "Position": "IT Analyst",
    "Have Cash Advance": "Yes",
    "Previous Advanced Form": "CA23-STM-AUG-001",
    "Doc No":"EC23-STM-SEP-001",
    "Doc Date":new Date().getDate() +"/"+ new Date().getMonth() + "/" + new Date().getFullYear(),
    "Starting Date": "18 July 2023",
    "Ending Date":"19 July 2023",
    "Travel Route": "",
    "Kind Of Transport": "",
    "Reason For Travel Advance": "ke site",
    "Reason File": "reason.pdf",
    "Reason for other advance": "",
    "Cash Advance": "IDR 50.000.000",
    "Total Expense Claim": "IDR 100.000.000",
    // "Difference": "IDR 50.000.000",
    "Company to Pay Employee": 'IDR 0',
    "Return To Company": "IDR 50.000.000"
  }

const ViewClaimFormCC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeIntoTitleValue = (data: any) => {
    const a = Object.entries(data);
    // console.log(a)
    const b = []
    for (const c of a) {
      // // console.log(a)
      if(c.length >= 2) {
        const d = Array.isArray(c[1]) ? c[1] : [c[1]]
        if(c[1]){
          if((d.length === 2 && d[1] !== ' - ') && (d.length === 2 && d[0] !== ' - ')){
            b.push({
              title: t(c[0]),
              value: d
            })
          }else if(d.length === 1){
            b.push({
              title: t(c[0]),
              value: d
            })
          }else if(d.length === 3){
            b.push({
              title: t(c[0]),
              value: d
            })
          }
        }
      }
    }
    // console.log(b)
    return b
}
  const items = changeIntoTitleValue(dataView);
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const [expenditureData, setExpenditureData] = useState<any>([]);
  const [dataGL, setDataGL] = useState<any>();
  const [dataWBS, setDataWBS] = useState<any>();
  const [dataCostCenter, setDataCostCenter] = useState<any>();
  const [dataTypeOfExpense, setDataTypeOfExpense] = useState<any>();
  const [dataCurrency, setDataCurrency] = useState<any>();
  const [dataAsli, setDataAsli] = useState<any>();
  const [dataUtama, setDataUtama] = useState<any>();
  const [dataC, setDataC] = useState<any>();
  const [history, setHistory] = useState<any>([]);
  const {id} = useParams();
  const role = getWorkflowRoleCC('10');
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  type ModalTypeState = "delete" | "approve" | "reject" | "rejectItem" | "";
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const [dataReject, setDataReject] = useState<any>({});
  const [canApprove, setCanApprove] = useState<any>(true);
  const [permission, setPermission] = useState<any>({});
  const session_storage = JSON.parse(sessionStorage?.getItem("shreyu_user")as string)
  const permissions = session_storage?.data?.permissions;

  const getEmployeeClaimPDF = ( () => {
    return new Promise(async(result, reject) => {
      // console.log('IN GETSP PDF')
      const res = await fetch(API_URI + "employee-claim-pdf/" + id,{
        method: "GET",
        // headers: {
        //   "Accept":"application/json",
        //   "Content-Type":"application/json",
        //   "Access-Control-Allow-Origin": "*"
        // }
      })
      // console.log(res)
      const blob = await res.blob()
      // console.log(blob)
      const reader = new FileReader();
      reader.onload = function () {
        // // console.log(this.result);
        if (this.result) {
          result(this.result);
          // setBastDetail((prev) => ({ ...prev, bast_file: this.result }));
          // allFile.push(this.result);
        }
      }; // <--- `this.result` contains a base64 data URI
      await reader.readAsDataURL(blob)
    })
  })

  const getDataVendor = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + `employee-claim-cc/${id}`, {
      method: "GET"
    })
    .then(response => response.json())
    .then(async(data) => {
      // console.log(data);
      if(permissions) {
        console.log('permissions:', permissions);
        for (const a of permissions){
          if (a.modules_id === '10'){
            console.log('permission:' ,a);
            setPermission(a)
          }
        }
      }
      if(data && data.success){
        if(data.data) {
          // console.log(data.data)
          const b = data.data
          // console.log(role);
          let ketemu = false;
          for(const aa of role?.role){
            if(b.approval_status?.status_name?.includes(aa)){
              ketemu = true;
            }
          }
          // console.log('KETEMU', ketemu)
          b.canEdit = (
            b.approval_status?.status_name === 'Rejected' && 
            (b.approval_status?.id === 430 && (role?.employee?.id === b.employee?.id))) && 
            role?.canEdit ? true : false
          b.canDelete = (
            b.approval_status?.status_name === 'Rejected' && 
            ((b.approval_status?.id === 430 && role?.role.includes('SAP Admin'))) && 
            role?.canDelete) ? true : false
          if(role?.role.includes('System Admin')){
            // b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && role?.canApprove) ? true : false
            // b.canReject = (b.approval_status?.status_name?.includes('Waiting') && role?.canReject) ? true : false
          }else {
            // console.log(role?.employee, b.direct_manager, b.approval_status_id)
            // if (b.approval_status_id == '423'){
              // if((role?.employee?.payroll_name?.toLowerCase() === b.direct_manager?.toLowerCase() ||
              // role?.employee?.payroll_name?.toLowerCase() === b.assign_direct_manager?.toLowerCase()
              // ) && b.approval_status_id == '423'){
              //   // console.log('masuk ke lvl direct manager')
              //   b.canApprove = true
              //   b.canReject = true
              //   b.canEdit = false
              //   b.canDelete = false
              // } else {
              //   b.canApprove = false
              //   b.canReject = false
              //   b.canEdit = false
              //   b.canDelete = false
              // }
            // }else if (role?.role === 'President Director' && (b.approval_status_id == '426' || b.approval_status_id == '428')){
            //   b.canApprove = true;
            //   b.canReject = true
            //   b.canEdit = false
            //   b.canDelete = false
            //   b.approval_status.status_color = '#e9ab13'
            //   b.approval_status.status_name = 'Waiting Approval President Director'
            // }else{
            b.canApprove = false;
            b.canReject = false;
            if(b?.approval_status?.id === 423){
              if (b.direct_manager === role?.employee?.payroll_name){
                b.canApprove = true;
                b.canReject = true;  
              } else {
                b.canApprove = false;
                b.canReject = false;
              }
            }else if(b?.approval_status?.status_name?.includes('Treasury') && role?.role?.includes('Treasury')){ // || role?.role?.includes('Direct Manager')){
              b.canEdit = true
              b.canApprove = true
              b.canReject = true
            }
            // }
          }
          // console.log(b)
          if(b?.claim_details){
            for (const d of b?.claim_details){
              if(d && d.wbs){
                d.wbs.gabungan = d?.wbs?.wbs_code + ' - ' + d?.wbs?.wbs_name
              }
              if(d && d.costcenter) {
                d.costcenter.gabungan = d?.costcenter?.code + ' - ' + d?.costcenter?.names
              }
              d.costelement.gabungan = d.costelement?.gl_code + ' - ' + d.costelement?.gl_name
            }
            // setDataAsli({...a})
          }
          // console.log(b);
          setDataAsli({...b});
          const allFile: any = []
          getEmployeeClaimPDF().then(async(specialPDF) => {
            // if(b.paymentAdvice) {allFile.push(b.paymentAdvice)}
            if(specialPDF) {allFile.push(specialPDF)}
            if(b.attachFile_hr) {allFile.push(b.attachFile_hr)}
            if(b.billing_statement) {allFile.push(b.billing_statement)}
            for (const x of b.claim_details){
              // // console.log(x.attachFile)
              if(x.attachFile) {allFile.push(x.attachFile)}
            }
            const all_doc = await mergePDF(allFile)
            const all_docs1 = [b.doc_no + '.pdf', all_doc, ' ']
            const all_docs2 = [b.doc_no + '.pdf', all_doc, '']
            const c:any = {
              "All Document": all_docs1, // (b.approval_status_id == 429 || b.approval_status_id == 428 ) ? all_docs1 : all_docs2,
              "Company": b.company_code === '1492' ? 'STM' : 'VEI',
              "Mineral / Geothermal" : b.subcompany_code === '001' ? "Mineral" : "Geothermal",
              "Department": b.department?.name ? b.department?.name : ' - ',
              // "Advanced / Settlement": b.is_advance == 1 ? "Advanced" : "Settlement",
              "Employee Name" : b.employee?.payroll_name,
              "Employee ID": b.employee_id,
              "Position": b.position,
              "Doc No": b.employee_claim_number,
              "Doc Date": dayjs(b.doc_date).format('DD MMM YYYY'),
              // "Have Cash Advance": b.is_cash_advance === '1' ? "Yes": "No",
              // "Previous Advance Form": b.previous_form,
              // "REASON FOR TRAVEL ADVANCE": b.reason_travel_advance,
              // "Attach travel approval form": b.attach_travel_approval ? ["travel_approval.pdf", b.attach_travel_approval] : [" - ", b.attach_travel_approval],
              // "Payment Advice File": b.paymentAdvice ? ["payment_advice.pdf", b.paymentAdvice] : [" - ", b.paymentAdvice],
              "Employee Claim File": specialPDF ? ['employee_claim.pdf', specialPDF] : [' - ', specialPDF],
              "Attachment Approval Document": b.attachFile_hr ? ['attach_approval.pdf', b.attachFile_hr] : [' - ', b.attachFile_hr],
              "Attachment Billing Statement": b.billing_statement ? ['billing_statement.pdf', b.billing_statement] : [' - ', b.billing_statement],
              // "Starting Date": dayjs(b.start_date).format('DD MMM YYYY'),
              // "Ending Date": dayjs(b.end_date).format('DD MMM YYYY'),
              // "Travel Route": b.travel_route,
              // "Kind of Transport": b.kind_of_transport,
              // "Reason for Other Advance": b.reason_other_advance,
              // "Currency": b.currency_id,
              // "Cash Advance": changeUStoID(b.cash_advance),
              "Total Expense Claimed": changeUStoID(b.total_expense_claimed),
              // "Difference": changeUStoID(b.difference),
              "Company To Pay": changeUStoID(b.company_to_pay),
              // "Return To Company": changeUStoID(b.return_to_company)
            }
            if(currentCompany.company_initial === 'VEI') {
              delete c["Mineral / Geothermal"]
            }
            setDataC(c);
            const items = changeIntoTitleValue(c);
            setDataUtama(items);
            let hasil = true;
            if(b.claim_details){
              for (const d of b.claim_details){
                if(d.wbs){
                  d.wbs.gabungan = d.wbs.wbs_code + ' - ' + d.wbs.wbs_name
                }
                if(d.costelemen) {
                  d.costelement.gabungan = d.costelement.gl_code + ' - ' + d.costelement.gl_name
                }
                if(d.status === '2') {
                  hasil = false
                }
              }
              setExpenditureData(b.claim_details);
              setCanApprove(hasil);
            }
          })
        }else{
          setDataUtama([])
        }
      }else{
        // console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      // console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };

  const getHistory =(() => {
    // setIsLoad(true);
    fetch(API_URI + `employee-claim-cc-history/${id}`, {
      method: "POST",
      body:JSON.stringify({
        sorting:[{
          id: 'created_at',
          desc: true
        }],
        pagination:{
          "pageIndex": 0,
          "pageSize": 1000
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          // // console.log(data.data)
          const b = data.data.data
          // console.log(data.data.data)
          const c = []
          for (const a of b) {
            c.push({
              date: a.created_at,
              company: a.employee?.payroll_name,
              user: a.user?.name,
              process: a.process,
              activity: a.read_status,
              comment: a.reason
            })
          }
          // console.log(c)
          setHistory(c);
          // setIsLoad(false);
        }else{
          setHistory([])
          // console.log('Tidak ada Data')
          // setIsLoad(false);
          // // console.log(a)
        }
      }else{
        // console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data history', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setIsLoad(false);
      }
    })
    .catch(err => {
      // console.log(err)
      toast.error('Failed getting data history', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // setIsLoad(false);
    })
    // // console.log(response.json());
    // setDataApi(response);
  })

  const getDataGL = () => getMasterData('general-ledger-list', {
    columnFilter: [{
      id: 'is_expense',
      value: '1'
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.gl_code + ' - ' + a.gl_name,
        value: a.id
      })
    }
    setDataGL(hasil);
  })

  const getDataWBS = () => getMasterData('wbs-list', {
    columnFilter: [{
      id: 'company',
      value: currentCompany
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.wbs_code + ' - ' + a.wbs_name,
        value: a.id
      })
    }
    setDataWBS(hasil);
  })

  const getDataTypeExpense = () => getMasterData('type-of-expense-list', {
    columnFilter: [{
      id: 'company',
      value: currentCompany
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.type_code,
        value: a.id
      })
    }
    setDataTypeOfExpense(hasil);
  })

  const getDataCostCenter = () => getMasterData('cost-center-list', {
    columnFilter: [{
      id: 'company',
      value: currentCompany
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.code + ' - ' + a.names,
        value: a.id
      })
    }
    setDataCostCenter(hasil);
  })

  const getDataCurrency = () => getMasterData('currency-list', {
    columnFilter: [{
      id: 'company',
      value: currentCompany
    }],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.currency_code,
        value: a.currency_code
      })
    }
    setDataCurrency(hasil);
  })
  const columnExpenditureMRT = useMemo<MRT_ColumnDef<ExpenditureColumn>[]>(
    () => [
      {
        accessorKey: "date_detail",
        header: t("Date (dd/mm/yyyy)"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 180,
        Cell: ({ cell }) => <div>{dayjs(cell.getValue() as string).format('DD MMM YYYY')}</div>,
      },
      {
        accessorKey: "typeofexpense.type_code",
        header: t("Description (Type of expense)"),
        // muiTableBodyCellEditTextFieldProps: {
          // select: true, //change to select for a dropdown
          // children: dataTypeOfExpense?.map((curr: any) => (
          //   <MenuItem key={curr.label} value={curr.value}>
          //     {curr.label}
          //   </MenuItem>
          // )),
        // },
        size: 180,
        // Cell: ({ cell }) => <div>{dataTypeOfExpense?.length > 0 ? dataTypeOfExpense[valueOptions(dataTypeOfExpense, cell.getValue())]?.label : ''}</div>,
      },
      {
        accessorKey: "justification",
        header: t("Description (Detail of expenditure)"),
        size: 180,
      },
      {
        accessorKey: "wbs.gabungan",
        header: t("WBS (STM)"), // Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costcenter.gabungan",
        header: t("Cost Center (VEI)"), // Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costelement.gabungan",
        header: t("Cost Element"),
        size: 180,
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 180,
      },
      {
        accessorKey: "amount",
        header: t("Amount"),
        size: 300,
      },
      {
        accessorKey: "currency_rate",
        header: t("Curency Rate"),
        size: 180,
      },
      {
        accessorKey: "amount_idr",
        header: t("Amount in IDR"),
        size: 300,
      },
      {
        accessorKey: "reject_reason",
        header: t("Reject Reason"),
        size: 300,
      },
      {
        accessorKey: "missing_receipts",
        header: t("Missing Receipts"),
        size: 300,
        Cell: ({ cell }) => <div>{cell.getValue() === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        accessorKey: "attachFile",
        header: t("File"),
        size: 300,
        Cell: ({ cell }) => 
          <FindInPage
            onClick={() => {
              openFilePDF(cell.getValue())
              // const win: any = window.open();
              // win.document.write(
              //   `<iframe src="${
              //     cell?.getValue()
              //   }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
              // );
            }}
            className="cursor-pointer ms-2"
          />
          // <div>{changeUStoID(cell.getValue())}</div>,
      },
    ],
    [t]
  );
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  useEffect(() => {
    getDataCostCenter()
    getDataGL()
    getDataTypeExpense()
    getDataCurrency()
    getDataVendor()
    getHistory()
  },[id])
  useEffect(() => {
    if(dataC) {
      // console.log('UseEffect t');
      const items = changeIntoTitleValue(dataC);
      setDataUtama(items);
    }
  }, [t])
return (
  <div className="card mb-12">
    {/* <div className="card-header bg-vale">Detail Claim Non CC</div> */}
    <div className="card-header bg-vale d-flex align-items-center justify-content-between">
      <p className="m-0">Detail Claim CC</p>
      <div className="d-flex align-items-center ms-2">
        <p className="m-0" style={{ color: "white" }}>
          Status:
        </p>
        <div
          className={`status-${
            dataAsli?.approval_status?.status_name?.includes("Waiting") || dataAsli?.approval_status?.status_name?.includes("Input")
              ? "pending"
              : dataAsli?.approval_status?.status_name?.includes("Completed") ||
                dataAsli?.approval_status?.status_name?.includes("Paid") ||
                dataAsli?.approval_status?.status_name?.includes("Approved")
              ? "complete"
              : "reject"
          } ms-2`}
          style={{backgroundColor: `${dataAsli?.approval_status?.status_color}`}}
        >
          <p>{dataAsli?.approval_status?.status_name}</p>
        </div>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      { currentCompany.company_initial === 'STM' ? 
        <img src={logoVale} height="100em" alt="" /> :
        <img src={logoValeVei} height="100em" alt="" />
      }
      <h5>Claim Credit Card</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          <DetailList3 items={dataUtama} />
        </Card.Body>
        <MaterialReactTable
          columns={columnExpenditureMRT}
          data={expenditureData}
          enablePagination={false}
          enableEditing
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Supporting Document">
                <IconButton onClick={() => {
                  // console.log(row);
                  openFilePDF(row.original.attachFile)
                  // const win: any = window.open();
                  // win.document.write(
                  //   `<iframe src="${
                  //     row?.original?.attachFile
                  //   }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                  // );
                }}>
                  <FindInPage />
                </IconButton>
              </Tooltip>
              {row?.original?.status === "0" &&
                dataAsli?.direct_manager === role?.employee?.payroll_name &&
                // (role.role.includes('Direct Manager'))&& 
                dataAsli?.approval_status_id == 423 && (
                <>
                  <Tooltip arrow placement="right" title={t("Good")}>
                    <IconButton
                      onClick={() => {
                        // // console.log(row);
                        const z = [...expenditureData]
                        for (const a of expenditureData){
                          if(a.id === row.original.id) {
                            a.status = '1'
                          }
                        }
                        setExpenditureData([...z])
                        // fetch(API_URI + `employee-claim-detail`, {
                        //   method: "POST",
                        //   body: JSON.stringify([{
                        //     "id": row.original.id,
                        //     "status": "1"
                        //   }])
                        // })
                        // .then(response => response.json())
                        // .then(async(data) => {
                        //   // console.log(data);
                        //   getDataVendor();
                        // })
                      }}
                    >
                      
                      <Check />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title={t("Not Good")}>
                    <IconButton
                      color="error"
                      onClick={() => {
                        const z = [...expenditureData]
                        for (const a of expenditureData){
                          if(a.id === row.original.id) {
                            a.status = '2'
                          }
                        }
                        setExpenditureData([...z]);
                        setCanApprove(false);
                        // setModalType("rejectItem");
                        // // console.log('Reject');
                        // setIsModalConfirmationOpen(true);
                        // // console.log(dataAsli.approval_status_id, "dataAsli")
                        // setDataReject({
                        //   id: id,
                        //   approval_status_id: dataAsli.approval_status_id,
                        //   approved: false,
                        //   reason: null,
                        //   approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
                        //   employee_id: JSON.parse(sessionStorage.getItem('shreyu_user')as string).data.employee.payroll_id
                        // });
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </>
                )}
                {row.getValue("date_detail") && row.original?.status === "1" &&
                <>
                  <Tooltip arrow placement="right" title={t("Good")}>
                    <IconButton>
                      <Check />
                    </IconButton>
                  </Tooltip>
                </>
                }
                {row.getValue("date_detail") && row.original?.status === "2" &&
                <>
                <Tooltip arrow placement="right" title={t("Not Good")}>
                    <IconButton
                      color="error"
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </>
                }
            </Box>
          )}
          initialState={{ columnVisibility: { 
            "wbs.gabungan": currentCompany.company_initial === 'STM' ? true : false,
            "costcenter.gabungan": currentCompany.company_initial === 'STM' ? false : true,
            // cost_element_id: currentCompany.company_initial === 'VEI' ? false : true
          } }}
        />
      </Card>
    </div>
    {/* {(role?.employee?.payroll_name === dataAsli?.direct_manager || role.role === 'System Admin') && dataAsli?.approval_status_id == 423 && (
    <div
      style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
      className="mx-3 mb-3"
    >
      <Button
        onClick={() => {
          const hasil = []
          for(const a of (expenditureData)) {
            hasil.push({
              id: a.id,
              status: a.status
            })
          }
          // console.log(hasil);
          fetch(API_URI + `employee-claim-cc-detail`, {
            method: "POST",
            body: JSON.stringify(hasil)
          })
          .then(response => response.json())
          .then(async(data) => {
            // console.log(data);
            getDataVendor();
            toast.success('success submiting detail list', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          })
        }}
        className="vale-button d-flex align-items-center justify-content-center"
        // disabled={isLoad}
      >
        <p className="m-0">Submit</p>
        <Check />
      </Button>
    </div>
    )} */}
    {/* <Row style={{ borderBottom: "1px dotted #32313121" }}>
      <Col md={{ span: 3, offset: 2 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>Employee Sign Claim Received</p>
      </Col>
      <Col md={{ span: 3, offset: 3 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>Immediate Supervisor Sign</p>
      </Col>
    </Row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <Row>
      <Col
        md={{ span: 1, offset: 1 }}
        style={{ marginBottom: 0, textAlign: "center" }}
      >
        <p style={{ marginBottom: 0 }}></p>
      </Col>
      <Col
        md={{ span: 1, offset: 2 }}
        style={{ marginBottom: 0, textAlign: "center" }}
      >
        <p style={{ marginBottom: 0 }}>{dataAsli?.employee?.payroll_name}</p>
      </Col>
      <Col md={{ span: 1, offset: 2 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}></p>
      </Col>
      <Col md={{ span: 1, offset: 2 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}></p>
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 1, offset: 1 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>Date</p>
      </Col>
      <Col
        md={{ span: 1, offset: 2 }}
        style={{ marginBottom: 0, textAlign: "center" }}
      >
        <p style={{ marginBottom: 0 }}>Name</p>
      </Col>
      <Col md={{ span: 1, offset: 2 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>Date</p>
      </Col>
      <Col md={{ span: 1, offset: 2 }} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>Name</p>
      </Col>
    </Row> */}
    {/* {(dataAsli?.approval_status?.status_name?.includes('Waiting') && dataAsli?.approval_status?.status_name?.includes(role.role) && role.canApprove) && ( */}
        <div
          style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
          className="mx-3 mb-3"
        >
          {/* {(parseInt(dataAsli?.approval_status_id) >= 423 && 
            parseInt(dataAsli?.approval_status_id) <= 428  && role.canApprove) && (
            <Button
              onClick={() => {
                // setModalType("reject");
                // setIsModalConfirmationOpen(true);
                // setDataReject(dataUtama);
                navigate(`/sap/special-payment/edit/${id}`)
              }}
              // disabled={isLoad}
              className="edit-button d-flex align-items-center justify-content-center"
            >
              <p className="m-0">Edit</p>
              <Edit />
            </Button>
          )} */}
          
          {/* <div>{dataAsli.canApprove}</div> */}
          {permission.is_update === '1' && 
            (
              (
                (dataAsli?.approval_status?.status_name === 'Rejected'  || dataAsli?.approval_status?.id === 423) &&
                (dataAsli?.employee_id === role?.employee.payroll_id)
              ) ||
              (
                dataAsli?.approval_status?.id === 426 &&
                role?.role?.includes('Treasury')
              )
            ) &&
            (
            <Button
              onClick={() => {
                navigate(`/employee-claim/ec/edit/${id}`)
              }}
              className="vale-button d-flex align-items-center justify-content-center"
              // disabled={isLoad}
            >
              <p className="m-0">{t("Edit")}</p>
            </Button>
          )}
          {( dataAsli?.canApprove ) && (
          <>
          <Button
            onClick={() => {
              const hasil = []
              for(const a of (expenditureData)) {
                hasil.push({
                  id: a.id,
                  status: a.status
                })
              }
              // console.log(hasil);
              fetch(API_URI + `employee-claim-cc-detail`, {
                method: "POST",
                body: JSON.stringify(hasil)
              })
              .then(response => response.json())
              .then(async(data) => {
                // console.log(data);
                getDataVendor();
              })
              setModalType("reject");
              setIsModalConfirmationOpen(true);
              setDataReject(dataAsli);
            }}
            className="reject-button d-flex align-items-center justify-content-center"
            // disabled={isLoad}
          >
            <p className="m-0">{t("Reject")}</p>
            <Close />
          </Button>
          { canApprove && (
          <Button
            onClick={() => {
              const hasil = []
              for(const a of (expenditureData)) {
                hasil.push({
                  id: a.id,
                  status: a.status
                })
              }
              // console.log(hasil);
              fetch(API_URI + `employee-claim-cc-detail`, {
                method: "POST",
                body: JSON.stringify(hasil)
              })
              .then(response => response.json())
              .then(async(data) => {
                // console.log(data);
                getDataVendor();
              })
              setModalType("approve");
              setIsModalConfirmationOpen(true);
              setDataReject(dataAsli);
            }}
            className="vale-button d-flex align-items-center justify-content-center"
            // disabled={isLoad}
          >
            <p className="m-0">{t("Approve")}</p>
            <Check />
          </Button>
          )}
          </>
          )}
        </div>
    {/* {(role.role === 'Treasury' || role.role === 'System Admin' )&&(
    <PaymentAdvice4 data={dataAsli}/>
    )} */}
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">Employee Claim History</p>
      </div>
      <HistoryEC data={history} />
    </div>
    <ConfirmationModalCC
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
        bastStatus={dataReject}
        getData={getDataVendor}
    />
  </div>
);
    }
export default ViewClaimFormCC;