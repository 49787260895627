import { Row, Col } from "react-bootstrap";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Pdf from '../../../constants/invoice.pdf';
import bastPdf from "./evim bast sample.pdf";
import allPdf from "./VEI_ESI_6022756_USD_1,731.00.pdf";

const DetailItem3 = ({ title, value = "-", file }: any) => {
  let perluButton = value && value[0] && value[0].includes(".pdf") ? true : false;
  // console.log(value[0], value[1])
  if(value[1]?.length >= 7 && perluButton) {perluButton = true} else {perluButton = false}
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const b64toBlob = (b64Data: any, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  return (
    <Row style={{ borderBottom: "1px dotted #32313121" }}>
      <Col md={3} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>{title}</p>
      </Col>
      <Col md={1} style={{ marginBottom: 0 }}>
        <p style={{ textAlign: "center", marginBottom: 0 }}>:</p>
      </Col>
      <Col md={8} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>
          {value[0]}{" "}
          {perluButton && (
            <FindInPageIcon
              onClick={() => {
                if (value[0] === "BAST.pdf" || value[0] === "All Document.pdf") {
                  openInNewTab(
                  value[0] === "BAST.pdf"
                  ? bastPdf
                  : value[0] === "All Document.pdf"
                      ? allPdf
                      : Pdf
                  );
                } else {
                  // console.log(value[1])
                  if(value[1]) {
                    // const win: any = window.open();
                    const [meta, data] = value[1].split("base64,")
                    const blob = b64toBlob(data, "application/pdf");
                    // blob.name = 'STM.pdf'
                    // console.log(blob)
                    // const url = new File([blob], 'STM')
                    const url = URL.createObjectURL(blob);
                    // console.log(url);
                    // win.document.write(`<iframe src="${value[1]}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
                    // win.document.write(`<a href="${url}" id="link" download="whatever.txt">download me</a>`)
                    // var fileLink = win.document.createElement('a');
                    // fileLink.href = url;
                    // fileLink.download = 'STM.pdf';
                    //win.document.write(`<div><a href="${url}" download="STM.pdf">Download</a> </div>
                    // win.document.write(`
                    // <iframe name="STM.pdf" src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
                    const win: any = window.open(url, '_blank');
                    if(value[2] === ' '){
                      var aDom = win.document.createElement("a");
                      aDom.setAttribute('style', 'display:none');
                      aDom.setAttribute('href', url);
                      aDom.setAttribute('download', value[0]);
                      document.body.appendChild(aDom);
                      aDom.click();
                      console.log(aDom)
                      document.body.removeChild(aDom);
                    }
                  }
                }
              }
              }
              className="cursor-pointer"
            />
          )}
        </p>
      </Col>
    </Row>
  );
};

export default DetailItem3;
