import { useMemo } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';

type InvoiceColumn = {
  geothermal: string;
  bastNo: string;
  contractNo: string;
  sapInvoice: string;
  invoiceDate: string;
  supplierName: string;
  invoiceNumber: string;
  subTotalJasa: string;
  vat: string;
  total: string;
  taxNoFaktur: string;
  tglFaktur: string;
  status: string;
};

const ReportInvoice = () => {
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "geothermal",
        header: "Mineral / Geothermal",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "bastNo",
        header: "BAST No",
        size: 80,
      },
      {
        accessorKey: "contractNo",
        header: "Contract/BPO No",
        size: 80,
      },
      {
        accessorKey: "sapInvoice",
        header: "SAP Invoice",
        size: 80,
      },
      {
        accessorKey: "invoiceDate",
        header: "Invoice Date",
        size: 80,
      },
      {
        accessorKey: "supplierName",
        header: "Supplier Name",
        size: 80,
      },
      {
        accessorKey: "invoiceNumber",
        header: "Invoice Number",
        size: 80,
      },
      {
        accessorKey: "subTotalJasa",
        header: "Sub Total Jasa",
        size: 80,
      },
      {
        accessorKey: "vat",
        header: "VAT",
        size: 80,
      },
      {
        accessorKey: "total",
        header: "Total",
        size: 80,
      },
      {
        accessorKey: "taxNoFaktur",
        header: "Tax No Faktur",
        size: 80,
      },
      {
        accessorKey: "tglFaktur",
        header: "Tgl Faktur",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        // Cell: ({ cell }) => {
        //   const value = String(cell.getValue());
        //   const isIncludeAppr = value.includes("pproval");
        //   return (
        //     <div
        //       className={`invoice-${
        //         isIncludeAppr
        //           ? "waiting-approval"
        //           : value.toLowerCase().split(" ").join("-")
        //       }`}
        //     >
        //       <p>{value}</p>
        //     </div>
        //   );
        // },
      },
    //   {
    //     header: "Action",
    //     accessor: "action",
    //     sort: true,
    //   }
    ],
    []
  );
  const data: InvoiceColumn[] = [
    {
      geothermal: "Geothermal",
      bastNo: "1005464366",
      contractNo: "STM-CN22-085",
      sapInvoice: "",
      invoiceDate: "987234669",
      invoiceNumber: "4509791607",
      supplierName: "ESIT CANADA ENTERPRISE SERVICES CO",
      subTotalJasa: "362,058,285",
      vat: "39,826,411",
      total: "401,884,696",
      taxNoFaktur: "010.003-23.87981009",
      tglFaktur: "15 Agustus 2023",
      status: "Paid",
    },
  ];
  const changeCamel = (data: string) => {
    return data.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
  }
  const data2 = (data: InvoiceColumn[]) => {
    let hasil = []
    for (const a of data) {
      const hasilObj:any = {}
      for (const [key, value] of Object.entries(a)) {
        hasilObj[changeCamel(key)] = value
      }
      hasil.push(hasilObj)
    }
    return hasil
  }
  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              // enableSelectAll={true}
              // enableMultiRowSelection={true}
              // enableRowSelection={(row) => row.original.canEdit} 
              // enableEditing
              enableRowNumbers
              enablePinning
              initialState={{ columnPinning: { right: ['status'] } }}
              data={data}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(data2(data));
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                    XLSX.writeFile(workbook, "Export Invoice.xlsx");
                  }}
                  className="vale-button"
                >
                  Export Excel
                </Button>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};



export default ReportInvoice;
