import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import axios from "axios";
import { useState } from "react";
  import { Button, Form } from "react-bootstrap";
  import { toast } from "react-toastify";
import { setApprovalAPI } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { API_URI } from "../../../constants";
import Loading from "../Loading";
  
  const ConfirmationModalEC = ({ open, setOpen, modalType, bastStatus, getData, isNavigate=false, navigateUrl="" }: any) => {
    // console.log({ open, setOpen, modalType, bastStatus }, 'CONFIRMATION MODAL PROPS')
    const [reasonMsg, setReasonMSG] = useState("");
    const [load, setLoad] = useState(false);
    const id = bastStatus?.id
    console.log(bastStatus);
    const approval_status_id = bastStatus?.approval_status_id

    // const employee_id = JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.payroll_id
    const employee_id = bastStatus?.employee_id
    const title =
      modalType === "delete"
        ? "Deletion"
        : modalType === "approve"
        ? "Approval"
        : modalType === "reject"
        ? "Rejection"
        : modalType === "rejectItem"
        ? "Detail Rejection"
        : "";
    const navigate = useNavigate();
    let hasilCanApprove = true;
    // if(bastStatus.claim_details) {
    //   for(const a of bastStatus.claim_details) {
    //     if(a.status = '2'){
    //       hasilCanApprove = false;
    //     }
    //   }
    // }
    const updateStatusApproval = async (obj: any) => {
      let {id, approval_status_id, approved, reasonMsg, employee_id} = obj
      console.log(obj)
      setLoad(true)
    //   approval_status_id = approval_status_id
      try {
        // id: id,
        // approval_status_id: row.original.approval_status_id,
        // approved: true,
        // reason: null,
        // approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
        // employee_id: employee_id,
        // previous_form: row.original?.previous_form
        setApprovalAPI([{
            id: [id],
            approval_status_id: [parseInt(approval_status_id)],
            approved,
            reason: reasonMsg,
            approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
            employee_id: [employee_id],
            previous_form: bastStatus.previous_form
          }], "employee-claim-approval").then(() => {
            if(isNavigate && navigateUrl) {
              setLoad(false)
              navigate(navigateUrl);  
            } else {
              // setLoad(false)
              getData()
            }
            setLoad(false)
            navigate(`/employee-claim/ec-list`);
            toast.success("Update Success.")
          }).catch(() => {
            setLoad(false)
            toast.error("Failed to Update Employee Claim Status!")
          })
      } catch (error) {
        setLoad(false)
        toast.error("Failed to Update Employee Claim Status!")
      } finally {
        setLoad(false)
        setOpen(false)
      }
    }

    const updateStatusItem = ((obj: any) => {
      fetch(API_URI + `employee-claim-detail`, {
        method: "POST",
        body: JSON.stringify([{
          "id": obj.id,
          "status": "2"
        }])
      })
      .then(response => response.json())
      .then(async(data) => {
        console.log(data);
      })
    })
  
    return (
      <>
      <Loading loading={load} />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setOpen(false);
          }
        }}
        className="modal-approval-confirmation-class"
      >
        <DialogTitle>{title} Confirmation</DialogTitle>
        <DialogContent style={{ width: "100%", overflow: "hidden" }}>
          {(modalType === "reject" || modalType === 'rejectItem') ? (
            <div style={{ width: "100%" }}>
              <p>Please add your rejection reason.</p>
              <Form>
                <Form.Control value={reasonMsg}
                    onChange={(e) => {
                        setReasonMSG(e.target.value)
                        // console.log(e.target.value)
                    }}
                    as="textarea"
                    rows={8}
                />
              </Form>
            </div>
          ) : (
            <p>Are you sure you want to {modalType}?</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-secondary"
            onClick={() => {
            setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            className="vale-button"
            // aria-disabled={load}
            onClick={() => {
              if (modalType === "approve") {
                const approved = true
                updateStatusApproval({id, approval_status_id, approved, reasonMsg, employee_id})
              } else if (modalType === 'reject') {
                const approved = false
                updateStatusApproval({id, approval_status_id, approved, reasonMsg, employee_id})
              } else if (modalType === 'rejectItem') {
                const approved = false
                updateStatusItem(bastStatus);
                updateStatusApproval({id, approval_status_id, approved, reasonMsg, employee_id})
              }
            }}
            disabled={(((modalType === 'reject' || modalType === 'rejectItem') && reasonMsg === "") || load) ? true : false}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </>
    );
  };
  
  // const Alert = () => {
  //   return (
  //     <div
  //       role="alert"
  //       className="fade alert alert-danger alert-dismissible show"
  //     >
  //       <button
  //         type="button"
  //         className="btn-close"
  //         onClick={() => {
  //           // setShowAlert(false);
  //         }}
  //         aria-label="Close alert"
  //       ></button>
  //       A simple danger alert—check it out!
  //     </div>
  //   );
  // };
  
  export default ConfirmationModalEC;
  