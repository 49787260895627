import DetailItem3 from "./DetailItem3";

const DetailList3 = ({ items }: any) => {
  return (
    <>
      {items && items.map((item: any, index: any) => (
        <DetailItem3 title={item.title} value={item.value} file={item.file} key={index} />
      ))}
    </>
  );
};

export default DetailList3;
