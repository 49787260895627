import React, { useState, useMemo } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CreateClaimNonCC from "./CreateClaimNonCC";
import ConfirmationModal from "../../../components/MyComponents/Modal/ConfirmationModal";

type InvoiceColumn = {
  no: number;
  vendorNumber: string;
  cost_desc: string;
  vendorName: string;
  status: string;
  action: string;
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
  canReject: boolean;
};
type ModalTypeState = "delete" | "approve" | "reject" | "";

const ClaimCCListHeadDept = () => {
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "no",
        header: "NO",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "vendorNumber",
        header: "Doc No",
        size: 80,
      },
      {
        accessorKey: "cost_desc",
        header: "Description",
        size: 80,
      },
      {
        accessorKey: "vendorName",
        header: "Vendor Name",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        Cell: ({ cell }) => {
          const value = String(cell.getValue());
          const isIncludeAppr = value.includes("pproval");
          return (
            <div
              className={`invoice-${
                isIncludeAppr
                  ? "waiting-approval"
                  : value.toLowerCase().split(" ").join("-")
              }`}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
      //   {
      //     header: "Action",
      //     accessor: "action",
      //     sort: true,
      //   }
    ],
    []
  );

  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              //   enableSelectAll={true}
              //   enableRowSelection={true}
              //   enableMultiRowSelection={true}
              enableRowSelection={(row) => row.original.canApprove}
              enableEditing
              enablePinning
              initialState={{ columnPinning: { right: ["status"] } }}
              data={[
                {
                  no: 1,
                  vendorNumber: "V-00081",
                  cost_desc: "HERTZ Australia",
                  vendorName: "Galih Lutfihadi Perdana",
                  status: "Waiting Approval Direct Manager",
                  action: "View",
                  canEdit: false,
                  canDelete: false,
                  canApprove: true,
                  canReject: false,
                },
                {
                  no: 2,
                  vendorNumber: "V-00079",
                  cost_desc: "HERTZ Australia",
                  vendorName: "Galih Lutfihadi Perdana",
                  status: "Completed",
                  action: "View",
                  canEdit: false,
                  canDelete: false,
                  canApprove: false,
                  canReject: false,
                },
              ]}
              muiTableBodyCellProps={({ cell, column, row }) => ({
                onClick: (event) => {
                  // console.log(cell.column.id);
                  // console.log('Cell: ', cell);
                  console.log("column: ", column.id);
                  console.log("row: ", Object.keys(row.original));
                  // console.log('table : ', table );
                  if (Object.keys(row.original).includes(column.id)) {
                    navigate(`/employee-claim/head-dept/cc-view`, {
                      state: row.original,
                    });
                    // console.log(cell.getValue())
                    // console.log(row.original);
                  }
                },
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => navigate("/employee-claim/cc/create")}
                  className="vale-button"
                >
                  Create New
                </Button>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {row.original.canEdit && (
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        onClick={() => navigate("/employee-claim/cc/edit")}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canDelete && (
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => console.log(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canApprove && (
                    <Tooltip arrow placement="right" title="Approve">
                      <IconButton
                        onClick={() => {
                          setModalType("approve");
                          setIsModalConfirmationOpen(true);
                        }}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canApprove && (
                    <Tooltip arrow placement="right" title="Reject">
                      <IconButton
                        color="error"
                        onClick={() => {
                          setModalType("reject");
                          setIsModalConfirmationOpen(true);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            />
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
      />
    </>
  );
};

export default ClaimCCListHeadDept;
