// static data
import {useParams} from 'react-router-dom';
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { useEffect, useState } from "react";
import CreateClaimFormCC from "../../components/CreateClaimFormCC";


const CreateClaimCC = () => {
  const dataAdd = {
    employeeName: {
        name: "",
        isDisabled:true
    },
    employeeID: {
        name: "",
        isDisabled: true
    },
    department: {
        name: "",
        isDisabled: true
    },
    position: {
        name: "",
        isDisabled: true
    },
    docNo:{
        name: "",
        isDisabled: true
    },
    docDate:{
        name: "",
        isDisabled: false
    },
    returnToCompany:{
        name:"",
        isDisabled: false
    },
    ca11:{
        name:"",
        isDisabled: false
    },
    'total_expense_claimed':{
        name:"",
        isDisabled: true
    },
    "ca21":{
        name:"",
        isDisabled: false
    },
    "difference":{
        name:"",
        isDisabled: true
    },
    ca31:{
        name:"",
        isDisabled: false
    },
    "company_to_pay":{
        name:"",
        isDisabled: true
    },
    ca41:{
        name:"",
        isDisabled: false
    },
    "return_to_company":{
        name:"",
        isDisabled: true
    },
    employeeSign:{
        name:"",
        isDisabled: false
    },
    employeeSignDate:{
        name:"",
        isDisabled: false
    },
    employeeSignName:{
        name:"",
        isDisabled: false
    },
    immediateSupervisorSign:{
        name:"",
        isDisabled: false
    },
    immediateSupervisorSignDate:{
        name:"",
        isDisabled: false
    },
    immediateSupervisorSignName:{
        name:"",
        isDisabled: false
    },
    mode: 'create'
  }
  const sap_admin_disabled = [
    'total_expense_claimed',
    'difference',
    'company_to_pay',
    'return to company'
  ]
  const {page, id} = useParams();
  const [dataUtama, setDataUtama] = useState<any>([]);
  const getDataVendor = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + `employee-claim-cc/${id}`, {
      method: "GET"
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data) {
          console.log(data.data)
          const b:any = {};
          for (const [key, value] of Object.entries(data.data)) {
            b[key] = {
              name: value,
              isDisabled: false,
              isHide: false,
            }
            if(sap_admin_disabled.includes(key)) {
              b[key].isDisabled = true
            }
          }
          b['employee_name'] = {
            name: data?.data?.employee?.payroll_name,
            isDisabled: true,
            isHide: false,
          }
          b['department_name'] = {
            name: data?.data?.department?.name,
            isDisabled: true,
            isHide: false,
          }
          b["subcompany_code"] = {
            name: data?.data?.subcompany_code === '001' ? "mineral" : 'geothermal',
            isDisabled: false
          }
          b.mode = 'edit'
          console.log("b",b)
          setDataUtama(b);
        }else{
          setDataUtama([])
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  };
  useEffect(() => {
    if(page === 'create') {
      // dataAdd.is_vat.defaultValue = dataAdd.is_vat.options[valueOptions(dataAdd.is_vat.options, dataAdd.is_vat.name)]
      // dataAdd.sap_company_number.name = getCompanySession[0]
      dataAdd.mode = 'create'
      setDataUtama(dataAdd);
    } else if (page === 'edit') {
      dataAdd.mode = 'edit'
      getDataVendor();
    } else {
      setDataUtama(dataAdd)
    }
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    
  ]);
  return (
    <>
      <CreateClaimFormCC data={dataUtama} canApprove={false} mode={page} isEdit={page==="edit" ? true : false}/>
    </>
  );
};

export default CreateClaimCC;