import { toast } from "react-toastify";
import { API_URI } from "../../../constants";
import axios from "axios";

import { getUserVendorData } from "../../../helpers/getUserVendor";
const { vendor_code } = getUserVendorData();

export const getDataById = async ({
  id,
  setData,
  setIsError,
  fetchContract,
  setLoading,
}: any) => {
  // toastRef.current = toast.loading("Loading.")
  fetch(API_URI + `bast-clone/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          .access_token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log(data, "ALLRESPONSEDATA");
      if (data && data.success) {
        if (data.data) {
          // console.log(data.data, "ONLYDATA");
          setData((prev: any) => ({ ...prev, ...data.data }));
          setIsError(false);
          if (fetchContract) {
            fetchContract({ setLoading, setData }).catch((e: any) => {
              console.log(e, "ERR FETCH CONTRACT");
            });
          }
        }
      } else {
        console.log(data.message);
        setIsError(true);
        toast.warn("Failed getting data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      setIsError(true);
      toast.warn("Failed getting data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
};

export const fetchContract = async ({ setLoading, setData }: any) => {
  setLoading(true);
  const res = await axios({
    url: "/contract-list",
    method: "POST",
    data: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnFilters: vendor_code
        ? [{ id: "vendor_code", value: vendor_code }]
        : [],
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem('shreyu_user')as string).access_token,
    },
  });
  // console.log(res.data.data.data, 'DATA CONTRACT')
  const options = res?.data?.data?.data?.map((el: any) => {
    return {
      value: el.code,
      label: el.code,
      contract_sap: el.agreement_code,
    };
  });
  setData((prev: any): any => {
    const contract_no: any = {
      name: options.find((el: any) => (el.value = prev.contract_no)),
    };
    delete contract_no.contract_sap;
    // delete contract_no.name.contract_sap
    // console.log(contract_no, "CONNONIOJOI")
    contract_no.options = options;
    contract_no.isDisabled = false;
    return {
      ...prev,
      contract_no,
    };
  });
  setLoading(false);
};
