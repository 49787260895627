import axios from "axios";
import { toast } from "react-toastify";

export const fetchContractDept = async ({
  company,
  vendor_code,
  newData,
  setLoading,
  translate,
}: any) => {
  setLoading(true);

  let t = (a: any) => a;
  if (translate) t = (a: any) => translate(a);
  try {
    const res = await axios({
      // url: "/contract-list",
      url: "/contract/department/",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer " +
          JSON.parse(sessionStorage.getItem("shreyu_user") as string)
            .access_token,
      },
      data: JSON.stringify({
        pagination: {
          pageIndex: 0,
          pageSize: 1000,
        },
        columnFilters: [
          { id: "vendor_code", value: vendor_code },
          {
            id: "company",
            value: company,
          },
        ],
      }),
    });
    // console.log(res)
    // console.log(res?.data?.data, "DATA CONTRACT");
    if (res?.data?.data?.data) {
      // console.log(res?.data?.data.data, "DATA CONTRACT");
      const options = res?.data?.data?.data?.map((el: any) => {
        return {
          ...el,
          value: el.code,
          label: el.code,
          contract_sap: el.agreement_code,
        };
      });
      newData({
        contract_no: {
          options,
        },
      });
      setLoading(false);
    } else {
      toast.warn(t("No contract's data"));
    }
  } catch (e) {
    console.log(e, "ERR FETCH CONTRACT");
    toast.error(t("Failed to Get Contracts"));
  }
};
