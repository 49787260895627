import { API_URI } from "../../constants";
import { APICore } from "./apiCore";

const api = new APICore();

// account
async function login(params: { email: string; password: string }) {
  const baseUrl = "/login";
  //  TO BE ENCRYPTED 

  return api.create(`${baseUrl}`, params);
  const res = await fetch(API_URI + 'login', {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(params)
  })
  const d = await res.json()
  // console.log(d)
  return d
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function fakeLogin(params: { email: string; password: string }): any {
  const users = [
    {
      email: "sysadmin@email.com",
      role: "sysadmin",
      message: "Hi admin, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "System Admin",
            description: "System Admin",
          },
        ],
        name: "Nathan Cunningham",
        email: "sysadmin@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
    {
      email: "vendor-admin@email.com",
      role: "vendor-admin",
      message: "Hi vendor admin, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "Vendor Admin",
            description: "Vendor Admin",
          },
        ],
        name: "Nicholas vendor admin",
        email: "vendor-admin@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
    {
      email: "vendor-user@email.com",
      role: "vendor-user",
      message: "Hi admin, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "Vendor User",
            description: "Vendor User",
          },
        ],
        name: "Nathaniel vendor user",
        email: "vendor-user@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
    {
      email: "warehouse-officer@email.com",
      role: "warehouse-officer",
      message: "Hi warehouse officer, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "Warehouse Officer",
            description: "Warehouse Officer",
          },
        ],
        name: "Nabil Warehouse Officer",
        email: "warehouse-officer@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
    {
      email: "contract-owner@email.com",
      role: "contract-owner",
      message: "Hi Contract Owner, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "Contract Owner",
            description: "Contract Owner",
          },
        ],
        name: "Nadia Contract Owner",
        email: "contract-owner@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
    {
      email: "sap-admin@email.com",
      role: "sap-admin",
      message: "Hi SAP Admin, welcome to home",
      access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
      token_type: "Bearer",
      data: {
        id: 1,
        mapping: [],
        role_id: "1",
        role: [
          {
            title: "SAP Admin",
            description: "SAP Admin",
          },
        ],
        name: "Nassim SAP Admin",
        email: "sap-admin@email.com",
        is_verified: "1",
        created_at: "2023-09-26T07:03:52.260000Z",
        updated_at: "2023-09-26T07:04:01.503000Z",
      },
    },
  ];
  // console.log(params, "PARRAMS")
  const user = users.find((el: any) => params.email ===  el.email)
  return user ? user : {
    email: "admin@admin.com",
    role: "Admin",
    message: "Hi Admin, welcome to home",
    access_token: "12|ho4zDtW4GP2zxloqMDCR1o0lC8bQgVe8HpqBFXjn6ccebd0b",
    token_type: "Bearer",
    data: {
      id: 1,
      mapping: [],
      role_id: "1",
      role: [
        {
          title: "Admin",
          description: "Admin",
        },
      ],
      name: "Admin",
      email: "admin@admin.com",
      is_verified: "1",
      created_at: "2023-09-26T07:03:52.260000Z",
      updated_at: "2023-09-26T07:04:01.503000Z",
    },
  }
}

export { login, logout, signup, forgotPassword, fakeLogin };
