import { API_URI } from "../constants";
import { CURRENT_COMPANY } from "./api/apiCore";
import { replaceToDot } from "./utils";

const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"));
const ucc = sessionStorage.getItem(CURRENT_COMPANY);
let userCurrentCompany;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

export const getWorkFlowByModule = () => {
  const obj = {
    Bast: {
      module_id: "1",
      module_name: "Bast",
      wf_role: "wf_role",
      workflow: {
        "department_name - department_description": {
          department_id: "department_id",
          department_name: "department_name",
          department_description: "department_description",
          step: "step",
          id: "id",
        },
      },
    },
  };
  let w;
  if (session_storage?.workflow?.length) {
    w = session_storage.workflow;
  } else {
    return;
  }
  const workflow = {};
  for (let a of w) {
    if (Object.keys(workflow).includes(a.module_name)) {
    } else {
      workflow[a.module_name] = {
        module_id: a.module_id,
        module_name: a.module_name,
        // workflow: {
        //   [`${a.department_name} - ${a.department_description}`]: {
        //     department_id: "department_id",
        //     department_name: "department_name",
        //     department_description: "department_description",
        //     step: "step",
        //     id: "id",
        //   },
        // },
        workflow: [
          {
            department_id: a.department_id,
            department_name: a.department_name,
            department_description: a.department_description,
            wf_role: [a.wf_role],
            step: a.step,
            ids: [a.id],
            user_ids: JSON.parse(a.user_id),
          },
        ],
      };
    }
  }
};

export const getWfRole = (module) => {
  let workflow;
  // console.log(session_storage?.workflows?.length)
  // console.log(session_storage?.workflows)
  if (session_storage?.workflows?.length) {
    workflow = session_storage.workflows;
  } else {
    return [];
  }
  // console.log(workflow)
  const wf_role = [];
  for (const a of workflow) {
    if (a.module_id === module && !wf_role.includes(a.wf_role)) {
      wf_role.push(a.wf_role);
    }
  }
  return wf_role;
};

export const getWfRoleWithDept = (module) => {
  let workflow;
  // console.log(session_storage?.workflows?.length)
  // console.log(session_storage?.workflows)
  if (session_storage?.workflows?.length) {
    workflow = session_storage.workflows;
  } else {
    return [];
  }
  // console.log(workflow)
  const wf_role_dept = [];

  for (const a of workflow) {
    const idx = wf_role_dept.findIndex((el) => {
      // console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++=====')
      // console.log(el)
      // console.log(a.wf_role)
      // console.log(el.name === a.wf_role)

      return el.name === a.wf_role;
    });
    // console.log(idx)
    if (a.module_id === module && idx === -1) {
      wf_role_dept.push({
        name: a.wf_role,
        department_ids: [a.department_id],
      });
    } else if (a.module_id === module && idx !== -1) {
      wf_role_dept[idx]?.department_ids.push(a.department_id);
    }
  }
  return wf_role_dept;
};

export const getUserContractCode = () => {
  let contract;
  if (session_storage?.contract?.length) {
    contract = session_storage.contract;
  } else {
    return [];
  }
  const contract_codes = [];
  for (const a of contract) {
    contract_codes.push(a.code);
  }
  // console.log(contract_codes)
  return contract_codes;
};

export const setContractOwnerFilter = () => {
  const contract_codes = getUserContractCode();
  let filters = [];
  for (const a of contract_codes) {
    filters.push({
      id: "contract_no",
      value: a,
    });
  }
  // console.log(filters)
  return filters;
};

export const getPermission = () => {
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"));
  const userPermission = session_storage?.data?.permissions;
  const permission = {};
  // console.log(userPermission)
  if (userPermission?.length > 0) {
    userPermission?.map((el) => {
      permission[el?.modules?.module_name] = {
        ...el,
        module: el?.modules?.module_name,
      };
      return el;
    });
  }
  console.log(permission);
  return permission;
};

export const removeFirstZero = (prev, val) => {
  let out = val;
  if (prev === "0,00") {
    if (parseFloat(replaceToDot(out)) >= 10) {
      out = (parseFloat(replaceToDot(out)) / parseFloat("10")).toString();
    }
  }
  return out;
};

export const checkNewApprovalBast = ({
  approval_status_id,
  roleCondition,
  dateParam,
  good_or_services,
}) => {
  if (roleCondition) {
    let output;
    // const a = new Date()
    // const b = Date.now()
    // console.log("======================================")
    // console.log(a)
    // console.log(b)
    // console.log(Date.now(a))
    // console.log(new Date(b))
    // console.log(Date.now(a)-b)
    // console.log("======================================")
    // const dateNow = Date.now();
    const finalDateNewFlow = 1723997137069;
    const finalDateNewFlowIso = new Date(1723997137069).toISOString();

    const dateNewFlow2 = "2024-08-19T07:05:00.000Z";
    const dateNewFlow1 = new Date(dateNewFlow2);
    const dateNewFlow = Date.parse(dateNewFlow1);
    Date.parse(new Date("2024-08-19T07:05:00.000Z"));
    // console.log(dateNow, "dateNow")
    // console.log(dateNewFlow2, "dateNewFlow2");
    // console.log(dateNewFlow1, "dateNewFlow1");
    // console.log(dateNewFlow, "dateNewFlow");
    const dateParamA = new Date(dateParam);
    const bastDate = Date.parse(dateParamA.toISOString());
    // const bastDateIso = dateParamA.toISOString();
    // console.log(dateParam, "dateParam");
    // console.log(dateParamA, "dateParamA");
    // console.log(dateParamA.toISOString(), "dateParamA iso");
    // console.log(bastDate, "bastDate");
    // const isOld = dateNow - bastDate > 0
    // const isOld = dateNow - dateNewFlow < 0
    // console.log(bastDate - dateNewFlow);
    // const isOld = bastDate - dateNewFlow < 0;
    // const isOld = bastDate - finalDateNewFlow < 0;
    const isOld = dateParam < finalDateNewFlowIso;
    // console.log(dateParamA, "dateParamA")
    // console.log(dateNow, "dateNOw1")
    // console.log(new Date(dateNow), "dateNOw2")
    // console.log(bastDate, "bastDate1")
    // console.log(new Date(bastDate), "bastDate2")
    console.log(isOld, "isold");
    // CONTRACT OWNER
    // service old: to sap admin
    if (isOld && approval_status_id === "2" && good_or_services === "2") {
      // console.log(1);
      output = "3";
    }
    // service new: to bast completed
    else if (!isOld && approval_status_id === "2" && good_or_services === "2") {
      // console.log(2);
      output = "4";
    }
    // SAP ADMIN
    // old: to bast completed
    else if (isOld && approval_status_id === "3" && good_or_services === "2") {
      // console.log(3);
      output = "4";
    }
    // new: to contract inspector
    else if (!isOld && approval_status_id === "3" && good_or_services === "2") {
      // console.log(4);
      output = "31";
    }
    // else if (isOld && approval_status_id === "434" && good_or_services === "2") {
    //   output = "4"
    // }
    else if (approval_status_id === "434" && good_or_services === "2") {
      output = "31"
    }
    // division manager
    else if (
      // !isOld &&
      approval_status_id === "4" &&
      good_or_services === "1"
    ) {
      // console.log(4)
      output = "4";
    }
    // division manager
    else if (
      // !isOld &&
      approval_status_id === "4" &&
      good_or_services === "2"
    ) {
      // console.log(4)
      output = "4";
    }
    return output;
  }
};

export const checkNotificationNumber = (document) => {
  // return 
  fetch(API_URI + "dashboard/notification", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("shreyu_user")).access_token,
    },
    body: JSON.stringify({
      columnFilters: [
        {
          id: "company",
          value: userCurrentCompany?.company_initial,
        },
      ],
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const dt = data?.data;
      if (dt) {
        console.log(dt.bast);
        const a = document.getElementById("dashboard-notif-list-bast");
        console.log(a);
        a.innerHTML = dt.bast || 0;
        const b = document.getElementById("dashboard-notif-list-invoice");
        b.innerHTML = dt.invoice || 0;
        const c = document.getElementById(
          "dashboard-notif-list-special-payment"
        );
        c.innerHTML = dt.special_payment || 0;
        const d = document.getElementById(
          "dashboard-notif-list-employee-claim"
        );
        d.innerHTML = dt.employee_claim || 0;
        const e = document.getElementById(
          "dashboard-notif-list-employee-claim-cc"
        );
        e.innerHTML = dt.employee_claim_cc || 0;
      } else {
        const a = document.getElementById("dashboard-notif-list-bast");
        a.innerHTML = 0;
        const b = document.getElementById("dashboard-notif-list-invoice");
        b.innerHTML = 0;
        const c = document.getElementById(
          "dashboard-notif-list-special-payment"
        );
        c.innerHTML = 0;
        const d = document.getElementById(
          "dashboard-notif-list-employee-claim"
        );
        d.innerHTML = 0;
        const e = document.getElementById(
          "dashboard-notif-list-employee-claim-cc"
        );
        e.innerHTML = 0;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
