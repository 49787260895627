import DetailItem from "./DetailItem";

const DetailList = ({ items }: any) => {
  return (
    <>
      {items.map((item: any, index: any) => (
        <DetailItem title={item.title} value={item.value} key={index} />
      ))}
    </>
  );
};

export default DetailList;
