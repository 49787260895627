import LoadingOverlay from "react-loading-overlay";

const Loading = ({ loading }: any) => {
  return (
    <>
      <div
        className="position-relative"
        style={{ width: "100%", height: "100%" }}
      >
        <div
          style={{
            display: loading ? "block" : "none",
            position: "fixed",
            zIndex: "999",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            // style={{
            //   display: "flex",
            //   width: "100%",
            //   height: "100%",
            // }}
            className="d-flex w-100 h-100 justify-content-center align-items-center"
          >
            <LoadingOverlay
              active={true}
              spinner={true}
              text="Loading..."
            ></LoadingOverlay>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading