import { AUTH_SESSION_KEY } from "./api/apiCore";

export const getUserVendorData = () => {
  const authSession = JSON.parse(
    sessionStorage.getItem(AUTH_SESSION_KEY) as string
  );
  let vd: any;
  if (authSession?.data) {
    vd = authSession.data?.vendor;
  }
  // console.log(vd, "USer Vendor Data");
  return {
    id: vd?.id,
    company_code: vd?.company_code,
    vendor_code: vd?.vendor_code,
    vendor_name: vd?.vendor_name,
    vendor_address: vd?.vendor_address,
    vendor_type: vd?.vendor_type,
    terms_ofpayment: vd?.terms_ofpayment,
    bank_code: vd?.bank_code,
    bank_name: vd?.bank_name,
    bank_account_no: vd?.bank_account_no,
    telephone: vd?.telephone,
    email: vd?.email,
    npwp: vd?.npwp,
    is_vat: vd?.is_vat,
    percentage: vd?.percentage,
    bank_branch: vd?.bank_branch,
    bank_customer_name: vd?.bank_customer_name,
  };
};
