import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Delete, Edit, Check, Close, RestartAlt } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";

// components
import PageTitle from "../../components/PageTitle";
import { Button, Card, Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import DetailList from "../../components/MyComponents/Detail/DetailList";
import logoVale from "../../assets/images/logo-dashboard.png";
import logoSTM from "../../assets/images/stm-logo.png";
import HistoryBAST from "../../components/MyComponents/History/HistoryBAST";
import HeaderDetailWithStatus from "../../components/MyComponents/HeaderDetailWithStatus";
import { useLocation, useParams } from "react-router-dom";
import BastSignatureSection from "./components/BastSignatureSection";
import { getDataById } from "./fetch/getBastByIdClone";
import DetailList2 from "../../components/MyComponents/Detail/DetailList2";
import { useTranslation } from "react-i18next";
import { useGetUserRole } from "../../hooks";
import {
  changeUStoID,
  mergePDF,
  replaceToComma,
  replaceToDot,
} from "../../helpers/utils";
import { API_URI } from "../../constants";
import { useNavigate } from "react-router-dom";
import {
  getWfRole,
  getWfRoleWithDept,
  setContractOwnerFilter,
} from "../../helpers/utils2";
import { APICore } from "../../helpers/api/apiCore";
import ConfirmationModalClone from "../../components/MyComponents/Modal/ConfirmationModalClone";

type AddStockCol = {
  id?: any;
  stock_code: string;
  description: string;
  uom_name: string;
  qty: number | string;
  currency_name?: string;
  currency_rate?: string;
  currency?: any;
  unit_price: number | string;
  total_value?: number | string;
  unit_price_in_idr?: number | string;
  total_value_idr?: number | string;
  is_reimbursement?: any;
  checking_status?: any;
  stock_codes?: any;
  reason?: any;
};

const wf_role = getWfRole("1");
const wf_role_dept = getWfRoleWithDept("1");
const contractOwnerFilters = setContractOwnerFilter();
const loginData = new APICore().getLoggedInUser();
const userData = loginData?.data;
const employeeData = userData?.employee;
const employeeDept = employeeData?.department;
// console.log(contractOwnerFilters)
// console.log(wf_role)
// console.log('==============================================')
// console.log(wf_role_dept)

const DetailBASTClone = () => {
  const navigate = useNavigate();
  const [modalType, setModalType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const [bastDetail, setBastDetail] = useState<any>({});
  // console.log(bastDetail, "bast Detail")
  const canThisContractOwnerApprove = contractOwnerFilters.some((el: any) => {
    return el.value === bastDetail?.contract_no;
  });
  const canThisSapAdminApprove = wf_role_dept.some((el: any) => {
    return (
      el.name === "SAP Admin" &&
      el.department_ids.includes(bastDetail?.department_id)
    );
  });
  const wareHouseCannotSee =
    wf_role.includes("Warehouse Officer") ||
    wf_role_dept.some((el: any) => {
      return (
        el.name === "Warehouse Officer" &&
        el.department_ids.includes(bastDetail?.department_id)
      );
    });
  // console.log(canThisSapAdminApprove)
  const [isError, setIsError] = useState(false);
  const [bastItems, setBastItems] = useState<any>([]);
  // console.log(bastItems, "bast Detail item");
  const { t } = useTranslation();
  const { userRole, userId } = useGetUserRole();

  const { state } = useLocation();
  const [bastStatus, setBastStatus] = useState<any>({
    id: "",
    approval_status_id: "",
  });
  const [bastItemStatus, setBastItemStatus] = useState<any>({
    id: "", //bast detail_id
    checking_status: null,
    "reason ": null,
    "checking_by ": userId,
  });
  // console.log(bastStatus)
  const isStm = bastDetail?.company_code === "1492" ? true : false;
  const logo = isStm ? logoSTM : logoVale;

  const isWarehouseCanApprove = !bastItems.some(
    (el: any) => String(el.checking_status) === "2"
  );

  const columnMRTCreateBAST = useMemo<MRT_ColumnDef<AddStockCol>[]>(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "NO",
      //   enableColumnOrdering: false,
      //   enableEditing: false, //disable editing on this column
      //   enableSorting: false,
      //   size: 80,
      // },
      // {
      //   accessorKey: "checking_status",
      //   header: "Stock Code",
      //   size: 80,
      // },
      {
        accessorKey: "stock_code",
        header: "Stock Code",
        size: 80,
        Cell: ({ row }) => {
          return <div>{row.original.stock_codes?.stock_code}</div>;
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 80,
      },
      {
        accessorKey: "uom_name",
        header: "UOM",
        size: 80,
      },
      {
        accessorKey: "qty",
        header: "QTY",
        size: 80,
        Cell: ({ row }) => {
          return (
            <div>
              {changeUStoID(
                replaceToComma(
                  String(row.original.qty ? row.original.qty : "0")
                )
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "currency_name",
        header: "Curr",
        size: 80,
        Cell: ({ row, cell }: any) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <div>
              {wf_role.includes("Warehouse Officer") ? "" : cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: "currency_rate",
        header: "Curr Rate",
        size: 80,
        Cell: ({ row }) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          if (
            row.original.currency?.currency_code !== "IDR" &&
            row.original.currency_rate
          )
            return (
              <p className="m-0">
                {changeUStoID(
                  replaceToComma(
                    row.original.currency_rate
                      ? row.original.currency_rate
                      : "0"
                  )
                )}
              </p>
            );
        },
      },
      {
        accessorKey: "unit_price",
        header: "Original Unit Price",
        size: 80,
        Cell: ({ row }) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <div>
              {changeUStoID(
                replaceToComma(
                  String(
                    row.original.unit_price ? row.original.unit_price : "0"
                  )
                )
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "total_value",
        header: "Original Total Value",
        size: 300,
        Cell: ({ row }) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <div>
              {changeUStoID(
                replaceToComma(
                  String(
                    row.original.total_value ? row.original.total_value : "0"
                  )
                )
              )}
            </div>
          );
        },
        Footer: () => (
          <Stack>
            <Box>
              Grand Total:{" "}
              {changeUStoID(
                replaceToComma(
                  bastDetail?.grand_total ? bastDetail.grand_total : "0"
                )
              )}
            </Box>
          </Stack>
        ),
      },
      {
        accessorKey: "unit_price_in_idr",
        header: "Local Unit Price",
        size: 80,
        Cell: ({ row }) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <div>
              {changeUStoID(
                replaceToComma(
                  String(
                    row.original.unit_price_in_idr
                      ? row.original.unit_price_in_idr
                      : "0"
                  )
                )
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "total_value_idr",
        header: "Local Total Value",
        size: 80,
        Cell: ({ row }) => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <div>
              {changeUStoID(
                replaceToComma(
                  String(
                    row.original.total_value_idr
                      ? row.original.total_value_idr
                      : "0"
                  )
                )
              )}
            </div>
          );
        },
        Footer: () => {
          if (wf_role.includes("Warehouse Officer")) return "";
          return (
            <Stack>
              <Box>
                Grand Total Local:{" "}
                {changeUStoID(
                  replaceToComma(
                    bastDetail?.grand_total_idr
                      ? bastDetail.grand_total_idr
                      : ""
                  )
                )}
              </Box>
            </Stack>
          );
        },
      },
      {
        accessorKey: "is_reimbursement",
        header: t("Is Reimbursement"),
        size: 80,
      },
      {
        accessorKey: "reason",
        header: t("Reason of Rejection"),
        size: 80,
        Cell: ({ row }) => {
          // if (userRole === "Warehouse Officer") return "";
          // return (
          //   <div>
          //     {changeUStoID(
          //       replaceToDot(
          //         String(
          //           row.original.total_value ? row.original.total_value : ""
          //         )
          //       )
          //     )}
          //   </div>
          // );
          if (
            parseInt(row.original.checking_status) === 2 &&
            bastDetail?.approval_status_id === "1"
          ) {
            // console.log(row)
            return (
              <div>
                <Form.Control
                  onChange={(e) => {
                    // console.log(e.target.value)
                    // console.log(bastItems[row.index])
                    setBastItems((prev: any): any => {
                      prev[row.index].reason = e.target.value;
                      return [...prev];
                    });
                  }}
                ></Form.Control>
              </div>
            );
          } else {
            return (
              <div>
                <p className="m-0">{row.original.reason}</p>
              </div>
            );
          }
        },
      },
    ],
    [bastDetail, bastItems]
  );

  useEffect(() => {
    getDataById({
      id,
      setData: setBastDetail,
      setIsError,
      // fetchContract,
      // setLoading,
    });
  }, []);
  useEffect(() => {
    if (bastDetail?.details?.length) {
      let b: any = [];
      for (const a of bastDetail.details) {
        let c: any = { ...a };
        c.is_reimbursement = a.is_reimbursement === "1" ? "Yes" : "No";
        // c.total_value = Number(a.total_value).toLocaleString("id-ID");
        // c.unit_price = Number(a.unit_price).toLocaleString("id-ID");
        // c.qty = Number(a.qty).toLocaleString("id-ID");
        c.currency_name = a.currency?.currency_name;
        c.stock_code = a.stock_code ? a.stock_code : "";
        c.uom_name = a.uom?.uom_name;
        b.push(c);
      }
      setBastItems(b);
    }
  }, [bastDetail?.details]);
  useEffect(() => {
    const getBastFile = async () => {
      // console.log("IN GETBASTFILE");
      const res = await fetch(API_URI + "bast/bast-pdf/" + id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(res);
      const blob: any = await res.blob();
      // console.log(blob);
      const reader = new FileReader();
      reader.onload = function () {
        // console.log(this.result);
        if (this.result) {
          setBastDetail((prev: any) => ({ ...prev, bast_file: this.result }));
          // allFile.push(this.result);
        }
      }; // <--- `this.result` contains a base64 data URI
      await reader.readAsDataURL(blob);
    };
    getBastFile();
  }, []);
  useEffect(() => {
    const getAllFile = async () => {
      // console.log("IN GETALLFILE");
      const allFile = [];
      if (bastDetail.bast_file && !wareHouseCannotSee) {
        allFile.push(bastDetail.bast_file);
      }
      if (bastDetail.timesheet_file) {
        allFile.push(bastDetail.timesheet_file);
      }
      if (bastDetail.sumary_reimburse_file) {
        allFile.push(bastDetail.sumary_reimburse_file);
      }
      if (bastDetail.proforma_invoice_file && !wareHouseCannotSee) {
        allFile.push(bastDetail.proforma_invoice_file);
      }
      if (bastDetail.delivery_order_file) {
        allFile.push(bastDetail.delivery_order_file);
      }
      if (bastDetail.bill_of_leading_file) {
        allFile.push(bastDetail.bill_of_leading_file);
      }
      if (bastDetail.airway_bill_file) {
        allFile.push(bastDetail.airway_bill_file);
      }
      if (bastDetail.pib_file) {
        allFile.push(bastDetail.pib_file);
      }
      if (bastDetail.po_file) {
        allFile.push(bastDetail.po_file);
      }
      if (bastDetail.contract_file) {
        allFile.push(bastDetail.contract_file);
      }
      if (bastDetail.others_file) {
        allFile.push(bastDetail.others_file);
      }
      // console.log(allFile)
      const all_doc = await mergePDF(allFile);
      setBastDetail((prev: any) => {
        return { ...prev, all_file: all_doc };
      });
    };
    if (bastDetail.bast_file && !wareHouseCannotSee) getAllFile();
  }, [bastDetail.bast_file, bastDetail.contract_file]);

  if (!bastDetail.bast_no) return <></>;
  return (
    <>
      <div className="mt-3"></div>
      <div className="card mb-12">
        <HeaderDetailWithStatus
          // status={bastDetail?.approval_status?.[0]?.status_name}
          data={bastDetail?.approval_status}
        />
        <div className="d-flex flex-column align-items-center mt-4 mb-3">
          <img src={logo} height="100em" alt="" />
          <h5>BERITA ACARA SERAH TERIMA</h5>
          <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5>
        </div>
        <div className="card-body">
          <Card>
            <Card.Body>
              <DetailList2
                items={[
                  {
                    title: "All Document",
                    value: !wareHouseCannotSee
                      ? [
                          bastDetail?.doc_no
                            ? `${bastDetail.doc_no}.pdf`
                            : "All Document.pdf",
                        ]
                      : "-",
                    file: bastDetail.all_file,
                  },
                  {
                    title: "BAST No.",
                    value: bastDetail.bast_no
                      ? bastDetail.bast_no
                      : bastDetail.doc_no,
                  },
                  {
                    title: "Goods or Services",
                    value:
                      bastDetail.good_or_services === "1"
                        ? "Goods"
                        : bastDetail.good_or_services === "2"
                        ? "Services"
                        : "-",
                  },
                  {
                    title: "Mineral or Geothermal",
                    value:
                      bastDetail.company_sub_id === "1"
                        ? "Mineral"
                        : bastDetail.company_sub_id === "2"
                        ? "Geothermal"
                        : "-",
                  },
                  {
                    title: "MIGO or SES No.",
                    value: bastDetail?.migo_or_ses,
                  },
                  // {
                  //   title: "GL & WBS No.",
                  //   value: `${bastDetail.gl_no || ""} ${
                  //     bastDetail.gl_no && bastDetail.wbs_no ? "&" : ""
                  //   } ${bastDetail.wbs_no || ""}`,
                  // },
                  {
                    title: "GL No.",
                    value: bastDetail?.gl_no
                      ? bastDetail.gl_no.includes("[")
                        ? bastDetail.gl_no
                            .replace(/["'\[\]]/g, "")
                            .replace(/[,]/g, ", ")
                        : bastDetail.gl_no
                      : "",
                  },
                  {
                    title: "WBS No.",
                    value: bastDetail?.wbs_no
                      ? bastDetail.wbs_no.includes("[")
                        ? bastDetail.wbs_no
                            .replace(/["'\[\]]/g, "")
                            .replace(/[,]/g, ", ")
                        : bastDetail.wbs_no
                      : "",
                  },
                  {
                    title: "Cost Center No.",
                    value: bastDetail?.coscenter_no
                      ? bastDetail.coscenter_no.includes("[")
                        ? bastDetail.coscenter_no
                            .replace(/["'\[\]]/g, "")
                            .replace(/[,]/g, ", ")
                        : bastDetail.coscenter_no
                      : "",
                  },
                  {
                    title: "Posting Date",
                    value: bastDetail.posting_date,
                  },
                  {
                    title: "Contract No.",
                    value: bastDetail.contract_no,
                  },
                  {
                    title: "SAP Contract",
                    value: bastDetail.contract_sap,
                  },
                  {
                    title: "PO No.",
                    value: bastDetail.po_no,
                  },
                  {
                    title: "Supplier Name",
                    value: bastDetail.suplier_name,
                  },
                  {
                    title: "Vendor Type",
                    value: bastDetail?.vendor?.vendor_type,
                  },
                ]}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <DetailList2
                items={[
                  { title: "Delivery Note", value: bastDetail.delivery_note },
                  {
                    title: "Bill of Lading",
                    value: bastDetail.bill_of_leading,
                  },
                  { title: "Airway Bill", value: bastDetail.airway_bill },
                  {
                    title: "Requested By",
                    value: bastDetail.users?.name,
                  },
                  {
                    title: "Notes",
                    value: bastDetail.notes,
                  },
                  {
                    title: "BAST Doc",
                    // value: [bastDetail.bast_no ? `${bastDetail.bast_no.split('_')[2]}.pdf` : "BAST.pdf"],
                    value: !wareHouseCannotSee
                      ? [
                          bastDetail.bast_no
                            ? `${bastDetail.bast_no}.pdf`
                            : "BAST.pdf",
                        ]
                      : "-",
                    file: bastDetail.bast_file,
                  },
                  {
                    title: "Timesheet",
                    value: bastDetail.timesheet_file ? ["timesheet.pdf"] : "-",
                    file: bastDetail.timesheet_file,
                  },
                  {
                    title: "Summary Reimburse",
                    value: bastDetail.sumary_reimburse_file
                      ? ["sumary_reimburse.pdf"]
                      : "-",
                    file: bastDetail.sumary_reimburse_file,
                  },
                  {
                    title: "Proforma Invoice",
                    value:
                      bastDetail.proforma_invoice_file && !wareHouseCannotSee
                        ? ["proforma_invoice.pdf"]
                        : "-",
                    file: bastDetail.proforma_invoice_file,
                  },
                  {
                    title: "Delivery Order",
                    value: bastDetail.delivery_order_file
                      ? ["delivery_order.pdf"]
                      : "-",
                    file: bastDetail.delivery_order_file,
                  },
                  {
                    title: "Bill of Lading Attachment",
                    value: bastDetail.bill_of_leading_file
                      ? ["bill_of_lading.pdf"]
                      : "-",
                    file: bastDetail.bill_of_leading_file,
                  },
                  {
                    title: "Airway Bill Attachment",
                    value: bastDetail.airway_bill_file
                      ? ["airway_bill.pdf"]
                      : "-",
                    file: bastDetail.airway_bill_file,
                  },
                  {
                    title: "PB1 Attachment",
                    value: bastDetail.pib_file ? ["pb1.pdf"] : "-",
                    file: bastDetail.pib_file,
                  },
                  {
                    title: "Purchase Order Attachment",
                    value: bastDetail.po_file ? ["po.pdf"] : "-",
                    file: bastDetail.po_file,
                  },
                  {
                    title: "Confirmation Sheet",
                    value: bastDetail.contract_file ? ["contract.pdf"] : "-",
                    file: bastDetail.contract_file,
                  },
                  {
                    title: "Others Attachment",
                    value: bastDetail.others_file ? ["others.pdf"] : "-",
                    file: bastDetail.others_file,
                  },
                ]}
              />
            </Card.Body>
          </Card>
          <div>
            <MaterialReactTable
              columns={columnMRTCreateBAST}
              data={bastItems}
              enablePagination={false}
              enableRowNumbers
              initialState={{
                columnVisibility: {
                  currency_name: !wf_role.includes("Warehouse Officer"),
                  currency_rate: !wf_role.includes("Warehouse Officer"),
                  unit_price: !wf_role.includes("Warehouse Officer"),
                  unit_price_in_idr: !wf_role.includes("Warehouse Officer"),
                  total_value: !wf_role.includes("Warehouse Officer"),
                  total_value_idr: !wf_role.includes("Warehouse Officer"),
                },
                columnPinning: { right: ["status"] },
              }}
              // enableRowActions={userRole === "Warehouse Officer"}
              enableRowActions
              renderRowActions={({ row, table }) => {
                // console.log(row);
                if (
                  wf_role.includes("Warehouse Officer") &&
                  bastDetail.approval_status_id === "1"
                ) {
                  return (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      {(!bastItems[row.index].checking_status ||
                        String(bastItems[row.index].checking_status) ===
                          "1") && (
                        <Tooltip arrow placement="left" title="Good">
                          <IconButton
                            onClick={() => {
                              // setModalType("approve item");
                              // setOpenModal(true);
                              // setBastItemStatus({
                              //   id: row.original.id,
                              //   checking_status: 1,
                              //   // "reason ": "test",
                              //   checking_by: userId,
                              // });
                              // console.log(row);
                              if (!bastItems[row.index].checking_status) {
                                setBastItems((prev: any): any => {
                                  prev[row.index].checking_by = userId;
                                  prev[row.index].checking_status = 1;
                                  return [...prev];
                                });
                              }
                            }}
                          >
                            <Check
                              color={
                                String(bastItems[row.index].checking_status) ===
                                "1"
                                  ? "disabled"
                                  : "primary"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(!bastItems[row.index].checking_status ||
                        String(bastItems[row.index].checking_status) ===
                          "2") && (
                        <Tooltip arrow placement="right" title="Not Good">
                          <IconButton
                            onClick={() => {
                              // setModalType("reject item");
                              // setOpenModal(true);
                              // setBastItemStatus({
                              //   id: row.original.id,
                              //   checking_status: 2,
                              //   // "reason ": "test",
                              //   checking_by: userId,
                              // });
                              // console.log(row.original);
                              if (!bastItems[row.index].checking_status) {
                                setBastItems((prev: any): any => {
                                  prev[row.index].checking_by = userId;
                                  prev[row.index].checking_status = 2;
                                  // prev[row.index].reason = 1
                                  return [...prev];
                                });
                              }
                            }}
                          >
                            <Close
                              color={
                                String(bastItems[row.index].checking_status) ===
                                "2"
                                  ? "disabled"
                                  : "error"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/* bastItems[row.index].checking_status && (
                        <Tooltip arrow placement="right" title="Reset">
                          <IconButton
                            onClick={() => {
                              if (bastItems[row.index].checking_status) {
                                setBastItems((prev: any): any => {
                                  prev[row.index].checking_by = "";
                                  prev[row.index].checking_status = "";
                                  prev[row.index].reason = null;
                                  // prev[row.index].reason = 1
                                  return [...prev];
                                });
                              }
                            }}
                          >
                            <RestartAlt
                              color={
                                // String(bastItems[row.index].checking_status) ===
                                // "2"
                                //   ? "disabled"
                                // :
                                "info"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) */}
                    </Box>
                  );
                } else {
                  return (
                    <>
                      {row.original.checking_status === "1" && (
                        <Tooltip arrow placement="left" title="Check">
                          <IconButton>
                            <Check color={"disabled"} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {row.original.checking_status === "2" && (
                        <Tooltip arrow placement="left" title="Rejected">
                          <IconButton>
                            <Close color={"disabled"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  );
                }
              }}
            />
            {/* <BastSignatureSection
              data={{
                requested_by: bastDetail.users?.name,
                requested_at: bastDetail.requested_at,
                checked_by: bastDetail.checked_by,
                checked_at: bastDetail.checked_at,
                delivered_by: bastDetail.vendor?.vendor_name,
                delivered_at: bastDetail.delivered_at,
              }}
            /> */}
          </div>
        </div>
        {((canThisSapAdminApprove && bastDetail?.approval_status_id === "3") ||
          (wf_role.includes("Warehouse Officer") &&
            bastDetail?.approval_status_id === "30")) && (
          <div className="d-flex justify-content-end mx-3 mb-3">
            <Button
              onClick={() => {
                navigate("/bast/input-ses-migo/edit/" + id);
              }}
              className="d-flex align-items-center justify-content-center vale-button"
            >
              Input SES/MIGO
            </Button>
          </div>
        )}
        {((wf_role.includes("Warehouse Officer") &&
          bastDetail?.approval_status_id === "1") ||
          (canThisContractOwnerApprove &&
            bastDetail?.approval_status_id === "2")) && (
          // bastDetail?.approval_status?.status_name
          // ?.toLowerCase()
          // .includes(userRole?.toLowerCase()) &&
          // !wf_role.includes("SAP Admin") &&
          <div
            style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
            className="mx-3 mb-3"
          >
            <Button
              onClick={() => {
                if (
                  // isWarehouseCanApprove &&
                  wf_role.includes("Warehouse Officer")
                ) {
                  setModalType("reject item");
                  setOpenModal(true);
                  // setBastStatus({
                  //   id: bastDetail?.id,
                  //   approval_status_id: "5",
                  //   vendor_code: bastDetail?.vendor_code,
                  // });
                } else {
                  setModalType("reject");
                  setOpenModal(true);
                  setBastStatus({
                    id: bastDetail?.id,
                    approval_status_id: "5",
                    vendor_code: bastDetail?.vendor_code,
                  });
                }
              }}
              className="reject-button d-flex align-items-center justify-content-center"
            >
              <p className="m-0">Reject</p>
              <Close />
            </Button>
            {((isWarehouseCanApprove &&
              wf_role.includes("Warehouse Officer")) ||
              !wf_role.includes("Warehouse Officer")) && (
              <Button
                onClick={() => {
                  if (
                    isWarehouseCanApprove &&
                    wf_role.includes("Warehouse Officer")
                  ) {
                    setModalType("approve item");
                    setOpenModal(true);
                    setBastStatus({
                      id: bastDetail?.id,
                      approval_status_id: String(
                        Number(bastDetail?.approval_status_id) + 1
                      ),
                      vendor_code: bastDetail?.vendor_code,
                      isInputMigo:
                        contractOwnerFilters?.length > 0 &&
                        bastDetail.good_or_services === "1"
                          ? true
                          : false,
                    });
                  } else {
                    setModalType("approve");
                    setOpenModal(true);
                    setBastStatus({
                      id: bastDetail?.id,
                      approval_status_id: String(
                        Number(bastDetail?.approval_status_id)
                      ),
                      vendor_code: bastDetail?.vendor_code,
                      isInputMigo:
                        contractOwnerFilters?.length > 0 &&
                        bastDetail.good_or_services === "1"
                          ? true
                          : false,
                    });
                  }
                }}
                disabled={
                  !isWarehouseCanApprove &&
                  wf_role.includes("Warehouse Officer")
                }
                className="vale-button d-flex align-items-center justify-content-center"
              >
                <p className="m-0">Approve</p>
                <Check />
              </Button>
            )}
          </div>
        )}
      </div>
      {/* panel content */}
      <div className="card mb-12">
        <div className="card-header bg-vale">
          <p className="m-0">BAST History</p>
        </div>
        <HistoryBAST id={id} />
      </div>
      <ConfirmationModalClone
        open={openModal}
        setOpen={setOpenModal}
        modalType={modalType}
        bastStatus={bastStatus}
        getData={() =>
          getDataById({
            id,
            setData: setBastDetail,
            setIsError,
            // fetchContract,
            // setLoading,
          })
        }
        bastItemStatus={bastItemStatus}
        moveToList={true}
        bastItems={bastItems}
      />
    </>
  );
};

export default DetailBASTClone;
