import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DetailList from "../../../components/MyComponents/Detail/DetailList";
import logoVale from "../../../assets/images/stm-logo.png";
// import logoVale from "../../../assets/images/logo-dashboard.png";
import {history} from "../historyData"
import HistoryComponent from "../../../components/MyComponents/History/HistoryComponent";
import PaymentAdvice from "../../../components/MyComponents/PaymentAdvice/PaymentAdvice";

const dataView = {
  "Special Payment Number": "SPC00001",
  "SAP Company Number": "STM 1492", // VEI 156",
  "Geothermal / Mineral": "Geothermal",
  "Justification": "HERTZ Australia",
  "Vendor Number": "SPE002-81",
  "Vendor Name": "FA",
  "Payment Amount": "IDR 50,000,000",
  GL: "5030500001",
  WBS: "R001828.04.08.19",
  "Vat Applicable": "Yes",
  // "Amount Of DPP": "PPH23 2% - 5010400013",
  "Amount Of VAT": "IDR 5,500,000",
  // "Amount Of WHT": "PPH23 2% - 5010300099",
  // "Is All Relevant Paperwork Attach": "Yes",
  "Requested By": "ZULFIKAR",
  "Supporting Document": "invoice.pdf",
  "Payment Advice": "payment.pdf",
  "Paid Date": "23 Juni 2023",
  "Reference Number": "",
  "Reference File": "reference.pdf",
  "All Document": "Doc-SPC00001.pdf"
};
const changeIntoTitleValue = (data:any) => {
    const a = Object.entries(data);
    console.log(a)
    const b = []
    for (const c of a) {
        b.push({
            title: c[0],
            value: [c[1]]
        })
    }
    return b
}

const ViewInvoiceSAPTreasury = () => {
const items = changeIntoTitleValue(dataView);
return (
  <div className="card mb-12 mt-3">
    <div className="card-header bg-vale">Detail Special Payment</div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      <img src={logoVale} height="100em" alt="" />
      <h5>Special Payment</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          <DetailList items={items} />
        </Card.Body>
      </Card>
    </div>
    <div className="m-3">
      <Row>
        <Col md={4}>
          <Button className="vale-button me-2">
            Export Special Payment Form
          </Button>
        </Col>
      </Row>
    </div>
    <br />
    <PaymentAdvice />
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">Special Payment History</p>
      </div>
      <HistoryComponent data={history} />
    </div>
    {/* <div className="d-flex justify-content-end mt-3">
      <Button className="vale-button me-2">Submit</Button>
    </div> */}
  </div>
);
    }
export default ViewInvoiceSAPTreasury;