import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import bcrypt from 'bcryptjs'

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import { fakeLogin } from "../../helpers/api/auth";

interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { email, password },
  type,
}: UserData): SagaIterator {
  let err
  try {
    // console.log("SAGA ++++++++++++")
    // console.log("SAGA", {email, password})
    // console.log("SAGA ++++++++++++")
    const response = yield call(loginApi, { email, password });
    // const response = fakeLogin({ email, password });
    // console.log(response)
    // return 

    const user = response.data;
    if (user?.response_code === 401) {
      err = '401'
      yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, user.message || "Error"));
      api.setLoggedInUser(null);
      setAuthorization(null);
      return
    }

    // const user = response;
    // if (user?.response_code === 401) {
    //   // console.log('A')
    //   err = 'error'
    //   yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, user?.message || "Error"));
    //   api.setLoggedInUser(null);
    //   setAuthorization(null);
    //   return
    // }
    // if (!user.access_token) {
    //   err = 'error'
    //   // console.log('B')
    //   yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Unauthorized"));
    //   api.setLoggedInUser(null);
    //   setAuthorization(null);
    //   return
    // }
    
    // const user = response.data;
    // console.log("SAGA after y1 ++++++++++++")
    // console.log("SAGA after y1", user)
    // console.log("SAGA after y1 ++++++++++++")
    // if (!user?.email) throw {user}
    // NOTE - You can change this according to response format from your api
    api.setLoggedInUser(user);

    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);

    sessionStorage.setItem('w', hash)
    sessionStorage.removeItem('is_locked')
    setAuthorization(user["access_token"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    console.log(error, "Err");
    err = error
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  } finally {
    if (!err) {
      window.location.reload()
    }
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    // yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
