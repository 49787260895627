const trData = {
  "Log In": "Log In",
  "Don't have an account?": "Don't have an account?",
  "Sign Up": "Sign Up",
  "Success": "Success",
  "Enter your email address and we'll send you an email with instructions to reset your password.":
    "Enter your email address and we'll send you an email with instructions to reset your password.",
  "Welcome back!": "Welcome back!",
  "Email Address": "Email Address",
  "Username": "Username",
  "Password": "Password",
  "Name": "Name",
  "Your full name": "Your full name",
  "Enter your Password": "Enter your Password",
  "Sign in with": "Sign in with",
  "Forgot your password?": "Forgot your password?",
  "See you again !": "See you again !",
  "Remember me": "Remember me",
  "With Google": "With Google",
  "With Facebook": "With Facebook",
  "You are now successfully sign out.": "You are now successfully sign out.",
  "Back to": "Back to",
  "Sign In": "Sign In",
  "Sign up using": "Sign up using",
  "Already have account?": "Already have account?",
  "Log in": "Log in",
  "Login": "Login",
  "Reset Password": "Reset Password",
  "Submit": "Submit",
  "I simply love it!": "I simply love it!",
  "It's a elegent templete. I love it very much!":
    "It's a elegent templete. I love it very much!",
  "Admin User": "Usuario administradora",
  "Create your account": "Create your account",
  "Create a free account and start using Shreyu":
    "Create a free account and start using Shreyu",
  "I accept Terms and Conditions": "I accept Terms and Conditions",
  "Actions": "Actions",
  "Invoice No": "Invoice No",
  "Description": "Description",
  "Vendor Name": "Vendor Name",
  "Create New": "Create New",
  "Paid Date": "Paid Date",
  "Import Paid Date": "Import Paid Date",
  "Doc No": "Doc No",
  "Approve": "Approve",
  "Reject": "Reject",
  "Edit": "Edit",
  "Delete": "Delete",
  "Contract No": "Contract No",
  "Contract/BPO No": "Contract/BPO No",
  "Contract No.": "Contract No.",
  "Contract/BPO No.": "Contract/BPO No.",
  "Supplier Name": "Supplier Name",
  "Amount": "Amount",
  "List BAST": "List BAST",
  "Create Invoice": "Create Invoice",
  "Quantity": "Quantity",
  "Posting Date": "Posting Date",
  "Contract SAP": "Contract SAP",
  "Delivery Note": "Delivery Note",
  "Bill of Lading (if any)": "Bill of Lading (if any)",
  "Airway Bill (if any)": "Airway Bill (if any)",
  "Requested By": "Requested By",
  "Notes": "Notes",
  "Timesheet": "Timesheet",
  "Summary Reimburse": "Summary Reimburse",
  "Proforma Invoice": "Proforma Invoice",
  "Delivery Order": "Delivery Order",
  "Bill of Lading Attachment": "Bill of Lading Attachment",
  "Airway Bill Attachment": "Airway Bill Attachment",
  "PB1 Attachment": "PB1 Attachment",
  "Purchase Order Attachment": "Purchase Order Attachment",
  "Contract Attachment": "Contract Attachment",
  "Others Attachment": "Others Attachment",
  "Stock Code": "Stock Code",
  "Unit Price": "Unit Price",
  "Total Value": "Total Value",
  "Goods": "Goods",
  "Services": "Services",
  "Add Row": "Add Row",
  "Cancel": "Cancel",
  "Doc No": "Doc No",
  "Special Payment Number": "Special Payment Number",
  "SAP Company Number": "SAP Company Number",
  "Reference Number": "Reference Number",
  "Vendor Number": "Vendor Number",
  "Payment Amount": "Payment Amount",
  "Justification": "Justification",
  "Vat Applicable": "Vat Applicable",
  "Amount of VAT": "Amount of VAT",
  "Amount of WHT": "Amount of WHT",
  "Supporting Document": "Supporting Document",
  "IAS File": "IAS File",
  "Reference File": "Reference File",
  "Payment Advice": "Payment Advice",
  "Special Payment PDF": "Special Payment PDF",
  "Detail Special Payment": "Detail Special Payment",
  "Special Payment History": "Special Payment History",
  "Approved by Admin AP": "Approved by Admin AP",
  "Approved by Finance Manager": "Approved by Finance Manager",
  "Rejected by President Director": "Rejected by President Director",
  "Updated Special Payment": "Updated Special Payment",
  "Approved by": "Approved by",
  "Date (dd/mm/yyyy)": "Date (dd/mm/yyyy)",
  "Description (Type of expense)": "Description (Type of expense)",
  "Description (Detail of expenditure)": "Description (Detail of expenditure)",
  "WBS (STM)": "WBS (STM)",
  "Cost Center (VEI)": "Cost Center (VEI)",
  "Cost Element": "Cost Element",
  "Currency": "Currency",
  "Curency Rate": "Curency Rate",
  "Amount in IDR": "Amount in IDR",
  "Reject Reason": "Reject Reason",
  "File": "File",
  "Tick Company Used": "Tick Company Used",
  "Tick Mineral / Geothermal": "Tick mineral / Geothermal",
  "Tick Advance / Settlement": "Tick Advance / Settlement",
  "Tick Travel / Other": "Tick Travel / Other",
  "Tick Travel / Others": "Tick Travel / Others",
  "Employee Name": "Employee Name",
  "Employee ID": "Employee ID",
  "Input": "Input",
  "Input Employee Name": "Input Employee Name",
  "Input Employee ID": "Input Employee ID",
  "Have Cash Advance": "Have Cash Advance",
  "Previous Advance Form": "Previous Advance Form",
  "Department": "Department",
  "Input Department": "Input Department",
  "Position": "Position",
  "Input Doc No": "Input Doc No",
  "Doc Date (DD/MM/YY)": "Doc Date (DD/MM/YY)",
  "Input Doc Date": "Input Doc Date",
  "Starting Date": "Starting Date",
  "Ending Date": "Ending Date",
  "REASON FOR TRAVEL": "REASON FOR TRAVEL",
  "Attach travel approval form": "Attach travel approval form",
  "Attach travel approval settlement form": "Attach travel approval settlement form",
  "Travel Route": "Travel Route",
  "Kind of Transport": "Kind of Transport",
  "Input Kind of Transport": "Input Kind of Transport",
  "Reason for Others": "Reason for Others",
  "Input Reason For Other Expenses": "Input Reason For Other expenses",
  "Attach Other approval form": "Attach Other approval form",
  "Attach other approval settlement form": "Attach other approval settlement form",
  "Attach travel approval form from HR": "Attach travel approval form from HR",
  "Summary": "Summary",
  "TOTAL EXPENSE CLAIMED ( Detail Below )": "TOTAL EXPENSE CLAIMED ( Detail Below )",
  "DIFFERENCE": "DIFFERENCE",
  "COMPANY TO PAY TO EMPLOYEE": "COMPANY TO PAY TO EMPLOYEE",
  "RETURN TO COMPANY": "RETURN TO COMPANY",
  "Attach Transfer Proof": "Attach Transfer Proof",
  "Company": "Company",
  "Input Position": "Input Position",
  "Attach billing statement": "Attach billing statement",
  "Receipt": "Receipt",
  "Import Payment Date": "Import Payment Date",
  "Download Template": "Download Template",
  "Upload Paid Date File": "Upload Paid Date File",
  "Validate Data": "Validate Data",
  "Double-Click a Cell to Edit": "Double-Click a Cell to Edit",
  "Mineral / Geothermal": "Mineral / Geothermal",
  "Advanced / Settlement": "Advanced / Settlement",
  "Travel / Others": "Travel / Others",
  "Doc Date": "Doc Date",
  "REASON FOR TRAVEL ADVANCE": "REASON FOR TRAVEL ADVANCE",
  "Attach other approval form": "Attach other approval form",
  "Payment Advice File": "Payment Advice File",
  "Employee Claim File": "Employee Claim File",
  "Attach HR File": "Attach HR File",
  "Attach transfer proof": "Attach transfer proof",
  "Reason for Other Advance": "Reason for Other Advance",
  "Total Expense Claimed": "Total Expense Claimed",
  "Difference": "Difference",
  "Company To Pay": "Company To Pay",
  "Return To Company": "Return To Company",
  "Good": "Good",
  "Not Good": "Not Good",
  "Attachment Approval Document": "Attachment Approval Document",
  "Attachment Billing Statement": "Attachment Billing Statement",
  
  "Create BAST": "Create BAST",
  "Invoice": "Invoice",
  "Create": "Create",
  "Invoice Date": "Invoice Date",
  "Invoice Number": "Invoice Number",
  "Mineral or Geothermal": "Mineral or Geothermal",
  "*Required": "*Required",
  "Required": "Required",
  "Confirmation Sheet": "Confirmation Sheet",
  "Purchase Order Attachment": "Purchase Order Attachment",
  "Original Unit Price": "Original Unit Price",
  "Original Total Value": "Original Total Value",
  "Local Unit Price": "Local Unit Price",
  "Local Total Value": "Local Total Value",
  "Is Reimbursement": "Is Reimbursement",
  "Curr.": "Curr.",
  "Curr": "Curr",
  "Curr Rate": "Curr Rate",
  "Currency Rate": "Currency Rate",
  "Unit Price in IDR": "Unit Price in IDR",
  "Total Value in IDR": "Total Value in IDR",
  "Yes": "Yes",
  "No": "No",
  "Actions": "Actions",
  "All Document": "All Document",
  "Goods or Services": "Goods or Services",
  "Stock Code": "Stock Code",
  "UOM": "UOM",
  "Reason of Rejection": "Reason of Rejection",
  "Vendor Name": "Vendor Name",
  "Date": "Date",
  "SAP Contract": "SAP Contract",
  "Invoice Description": "Invoice Description",
  "BAST file": "BAST file",
  "Contract of summary expenditure file": "Contract of summary expenditure file",
  "Invoice Doc": "Invoice Doc",
  "Detail Invoice": "Detail Invoice",
  "Invoice History": "Invoice History",
  "VAT Amount": "VAT Amount",
  "WHT Amount": "WHT Amount",
  "Other Expense": "Other Expense",
  "Invoice Amount (Excl. VAT)": "Invoice Amount (Excl. VAT)",
  "Invoice Amount (Incl. VAT)": "Invoice Amount (Incl. VAT)",
  "Withholding Tax": "Withholding Tax",
  "Cumulative Invoice to Date": "Cumulative Invoice to Date",
  "Estimated Value of Work Not Yet Invoiced (if any)": "Estimated Value of Work Not Yet Invoiced (if any)",
  "Due Date": "Due Date",
  "Payment Date": "Payment Date",
  "SAP Payment No. (F110)": "SAP Payment No. (F110)",
  "Received Date of Payment": "Received Date of Payment",
  "Bank Clearing No.*) (F-53)": "Bank Clearing No.*) (F-53)",
  "Outstanding Contract Value": "Outstanding Contract Value",
  "Remark (if any)": "Remark (if any)",
  "Summary of Contract Expenditures": "Summary of Contract Expenditures",
  "Download Summary Expenditures": "Download Summary Expenditures",
  "TAX": "TAX",
  "Process": "Process",
  "Activity": "Activity",
  "Comment": "Comment",
  "Approval Extends Form": "Approval Extends Form",
  "Export Invoice": "Export Invoice",
  "Choose Date Range": "Choose Date Range",
  "Choose Month": "Choose Month",
  "Progress Report": "Progress Report",
  "Physical Picture": "Physical Picture",
  "List Goods/Services Receipt": "List Goods/Services Receipt",
  "Hand Over Document": "Hand Over Document",
  "Good Receipt Note": "Good Receipt Note",
  "Current Password": "Current Password",
  "New Password": "New Password",
  "Confirm Your New Password": "Confirm Your New Password",
  "Change Password": "Change Password",
  "Profile": "Profile",
  "Contact Information": "Contact Information",
  "About": "About",
  "Address": "Address",
  "Input Signature": "Input Signature",
  "Save Signature": "Save Signature",
  "Leave Permission": "Leave Permission",
  "On Leave": "On Leave",
  "Start date": "Start date",
  "End date": "End date",
  "Choose User": "Choose User",
  "My Account": "My Account",
  "Created Date": "Created Date",
  "Doc No.": "Doc No.",
  "Other Adjustment": "Other Adjustment",
  "Previous Page": "Previous Page",
  "Tax Slip": "Tax Slip",
  "View Tax Slip": "View Tax Slip",
  "View Faktur Pajak": "View Faktur Pajak",
  "View Payment Advice": "View Payment Advice",
  "Tutorial How to Create Invoice": "Tutorial How to Create Invoice",
  "Tutorial How to Create BAST": "Tutorial How to Create BAST",
  "BAST Manual for Contract Owner (Services)": "BAST Manual for Contract Owner (Services)",
  "BAST Manual for Contract Owner (Goods)": "BAST Manual for Contract Owner (Goods)",
  "BAST Manual for Warehouse Officer": "BAST Manual for Warehouse Officer",
  "BAST Manual for SAP Admin": "BAST Manual for SAP Admin",
  "No. Faktur": "No. Faktur",
  "Faktur Date": "Faktur Date",
  "Update Success.": "Update Success.",
  "Failed to Update BAST Status!": "Failed to Update BAST Status!",
  "Failed To save Payment Advice": "Failed To save Payment Advice",
  "Success Save Payment Advice": "Success Save Payment Advice",
  "Failed getting data": "Failed getting data",
  "Failed to Update Invoice Status!": "Failed to Update Invoice Status!",
  "Delete Invoice Success.": "Delete Invoice Success.",
  "Failed to Delete Invoice!": "Failed to Delete Invoice!",
  "Failed getting contract expenditure": "Failed getting contract expenditure",
  "Invoice file required": "Invoice file required",
  "Tax invoice required": "Tax invoice required",
  "IAS Reveiw is required!": "IAS Reveiw is required!",
  "You are not allowed to edit this invoice": "You are not allowed to edit this invoice",
  "The invoice number has already been taken.": "The invoice number has already been taken.",
  "Failed": "Failed",
  "Failed getting tax list": "Failed getting tax list",
  "BAST Added": "BAST Added",
  "At least 1 item must be added": "At least 1 item must be added",
  "Failed to Submit BAST": "Failed to Submit BAST",
  "If is reimbursement, summary reimbursement file must be attached": "If is reimbursement, summary reimbursement file must be attached",
  "BAST Updated": "BAST Updated",
  "Failed to Update BAST": "Failed to Update BAST",
  "MIGO / SES Updated": "MIGO / SES Updated",
  "Failed to Update MIGO / SES": "Failed to Update MIGO / SES",
  "Failed to Update Stock Code": "Failed to Update Stock Code",
  "Fail to get list department": "Fail to get list department",
  "File must be below 30MB": "File must be below 30MB",
  "File must be PDF": "File must be PDF",
  "Please select month year": "Please select month year",
  "Please select date range": "Please select date range",
  "Date range must be less than 61 days": "Date range must be less than 61 days",
  "Date range must be greater than 0 days": "Date range must be greater than 0 days",
  "Failed getting history data": "Failed getting history data",
  "Failed to approve BAST item": "Failed to approve BAST item",
  "Failed to reject BAST item": "Failed to reject BAST item",
  "Approval cannot null.": "Approval cannot null.",
  "Success Update Payment Advice.": "Success Update Payment Advice.",
  "Failed To Update Payment Advice": "Failed To Update Payment Advice",
  "Success input tax slip": "Success input tax slip", 
  "Failed to Get Contracts": "Failed to Get Contracts",
  "Failed to Get PO data": "Failed to Get PO data",
  "Failed to Get Department data": "Failed to Get Department data",
  "No contract's data": "No contract's data",
  "Success update IAS": "Success update IAS",
  "Failed update IAS": "Failed update IAS",
  "Error reading tax barcode. Please check and input manually": "Error reading tax barcode. Please check and input manually",
  "Error connecting to DJPP. Visit link to see basrcode data": "Error connecting to DJPP. Visit link to see basrcode data",
  "Error tax barcode": "Error tax barcode",
  "Contract not found": "Contract not found",
  "Failed to save data": "Failed to save data",
  "Reset Password Successfully!": "Reset Password Successfully!",
  "The password field must match confirm password.": "The password field must match confirm password.",
  "Password changed successfully": "Password changed successfully",
  "Failed to change password": "Failed to change password",
  "Please enter amount": "Please enter amount",
  "Max 50 characters": "Max 50 characters",
};

export const translationEn = () => {
  const trArr = Object.keys(trData);
  return trArr.map((val) => {
    return { [val]: val };
  });
};
