import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import ShreyuDatepicker from "../../../components/Datepicker";
import { BorderColor } from "@mui/icons-material";

interface SelectOption {
  value: string | boolean;
  label: string;
}

interface InputAttribute {
  controlId: string;
  label: string;
  type: string;
  typeas?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: any;
  feedback?: {
    valid?: string;
    invalid?: string;
  };
  value?: any;
  readOnly?: boolean;
  options?: SelectOption[];
  onChange?: any;
  hide?: boolean;
}

interface GroupInputProps {
  inputAttArr: InputAttribute[];
  colSize?: number | { span: number; offset: number };
}

const GroupInput = ({ inputAttArr, colSize = 12 }: GroupInputProps) => {
  // console.log(inputAttArr, "inputAttArr");
  return (
    <Row>
      {inputAttArr.map((item: any, index: any) => {
        if (item.type === "select" && !item.hide)
          return (
            <Col md={item.colSize || colSize}>
              <Form.Group
                key={index}
                className="mb-3 position-relative"
                controlId={item.controlId}
              >
                <div className="position-absolute" style={{ right: 0 }}>
                  {item.validation}
                </div>
                <Form.Label>{item.label}</Form.Label>
                {item.readOnly ? (
                  <Select
                    name={item.controlId}
                    options={item.options}
                    defaultValue={item.defaultValue}
                    className="react-select-disabled react-select-container"
                    classNamePrefix="react-select"
                    isDisabled={item.readOnly}
                    value={item.defaultValue}
                    onChange={item.onChange}
                  ></Select>
                ) : (
                  <Select
                    name={item.controlId}
                    options={item.options}
                    defaultValue={item.defaultValue}
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    isDisabled={item.readOnly}
                    value={item.defaultValue}
                    onChange={item.onChange}
                  ></Select>
                )}
                <Form.Control.Feedback>
                  {item.feedback?.valid}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {item.feedback?.invalid}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        if (item.type === "date" && !item.hide)
          return (
            <Col md={item.colSize || colSize}>
              <Form.Group
                key={index}
                className="mb-3"
                controlId={item.controlId}
              >
                <Form.Label>{item.label}</Form.Label> <br />
                <ShreyuDatepicker
                  hideAddon={!item.readOnly}
                  dateFormat="dd-MM-yyyy"
                  onChange={item.onChange}
                  value={item.value}

                />
              </Form.Group>
            </Col>
          );
        if (item.type === "datebt" && !item.hide)
          return (
            <Col md={item.colSize || colSize}>
              <Form.Group
                key={index}
                className="mb-3"
                controlId={item.controlId}
              >
                <Form.Label>{item.label}</Form.Label> <br />
                <Form.Control
                    id="example-date"
                    type="date"
                    name="date"
                    defaultValue={item.defaultValue}
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                    disabled={item.readOnly}
                  />
              </Form.Group>
            </Col>
          );
        if (!item.hide)
          return (
            <Col md={item.colSize || colSize} className="mb-3">
              <Form.Group
                key={index}
                className=""
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                controlId={item.controlId}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Form.Label>{item.label}</Form.Label>
                </div>
                <div>
                  <Form.Control
                    style={{ float: "inline-end" }}
                    required={item.required}
                    type={item.type}
                    placeholder={"Input " + item.label}
                    defaultValue={item.value || item.defaultValue}
                    readOnly={item.readOnly}
                    // value={item.value}
                    onChange={item.onChange}
                  />
                </div>

                <Form.Control.Feedback>
                  {item.feedback?.valid}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {item.feedback?.invalid}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
      })}
    </Row>
  );
};

export default GroupInput;
