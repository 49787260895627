import React, { useState, useMemo } from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

// components
import PageTitle from "../../components/PageTitle";
import { Button, Card, Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import DetailList from "../../components/MyComponents/Detail/DetailList";
// import logoVale from "../../assets/images/logo-dashboard.png";
import logoVale from "../../assets/images/stm-logo.png";
import HistoryBAST from "../../components/MyComponents/History/HistoryBAST";
import ConfirmationModal from "../../components/MyComponents/Modal/ConfirmationModal";
import HeaderDetailWithStatus from "../../components/MyComponents/HeaderDetailWithStatus";
import { useLocation } from "react-router-dom";
import BastSignatureSection from "./components/BastSignatureSection";

type AddStockCol = {
  no: number | string;
  stockCode: string;
  description: string;
  UOM: string;
  qty: number | string;
  // condition: string;
  reasonOfRejection?: any;
  // curr: string;
  // unitPrice: number | string;
  // totalValue?: number | string;
};

const DetailBASTWarehouse = () => {
  const [modalType, setModalType] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { state } = useLocation();

  const columnMRTCreateBAST = useMemo<MRT_ColumnDef<AddStockCol>[]>(
    () => [
      {
        accessorKey: "no",
        header: "NO",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "stockCode",
        header: "Stock Code",
        size: 80,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 80,
      },
      {
        accessorKey: "UOM",
        header: "UOM",
        size: 80,
      },
      {
        accessorKey: "qty",
        header: "QTY",
        size: 80,
      },
      {
        accessorKey: "reasonOfRejection",
        header: "Reason of Rejection",
        size: 80,
        Cell: ({ cell, row }) => {
          // const value = String(cell.getValue());
          if (row.original.UOM === "TOTAL QUANTITY") return <></>;
          return <Form.Label></Form.Label>;
        },
        // accessorKey: "condition",
        // header: "Condition",
        // size: 80,
        // Cell: ({ cell }) => {
        //   const value = String(cell.getValue());
        //   return <Form.Check></Form.Check>;
        // },
      },
      // {
      //   accessorKey: "curr",
      //   header: "Curr",
      //   size: 80,
      // },
      // {
      //   accessorKey: "unitPrice",
      //   header: "Unit Price",
      //   size: 80,
      // },
      // {
      //   accessorKey: "totalValue",
      //   header: "Total Value",
      //   size: 80,
      // },
    ],
    []
  );

  return (
    <>
      <div className="mt-3"></div>
      {/* alert */}
      {/* <div style={{ position: "fixed", backgroundColor: "green" }}>
        <div
          role="alert"
          className="alert-danger d-flex align-items-center"
          style={{ position: "absolute", top: "380px" }}
        >
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              // setShowAlert(false);
            }}
            aria-label="Close alert"
          ></button>
          <p className="m-0 text-center">A simple danger alert—check it out!</p>
        </div>
      </div> */}

      {/* tabs */}

      <div className="card mb-12">
        <HeaderDetailWithStatus status={state.status} />
        <div className="d-flex flex-column align-items-center mt-4 mb-3">
          <img src={logoVale} height="100em" alt="" />
          <h5>BERITA ACARA SERAH TERIMA</h5>
          <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5>
        </div>
        <div className="card-body">
          <Card>
            <Card.Body>
              <DetailList
                items={[
                  { title: "BAST No,", value: "112214991" },
                  { title: "MIGO or SES No.", value: "" },
                  { title: "Goods or Services", value: "Goods" },
                  { title: "Mineral or Geothermal", value: "Mineral" },
                  {
                    title: "GL & WBS No.",
                    value: "5030100099 & 3381586",
                  },
                  {
                    title: "Posting Date",
                    value: "9 August 2023",
                  },
                  {
                    title: "Contract No.",
                    value: "STM-CN22-085",
                  },
                  {
                    title: "SAP Contract",
                    value: "460234701",
                  },
                  {
                    title: "PO No.",
                    value: "4509791607",
                  },
                  {
                    title: "Supplier Name",
                    value: "ESIT CANADA ENTERPRISE SERVICES CO",
                  },
                  {
                    title: "Vendor Type",
                    value: "Internaltional",
                  },
                ]}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <DetailList
                items={[
                  { title: "Delivery Note" },
                  { title: "Bill of Lading" },
                  { title: "Airway Bill" },
                  {
                    title: "Requested By",
                  },
                  {
                    title: "Notes",
                  },
                  {
                    title: "BAST Doc",
                    value: ["BAST.pdf"],
                  },
                  {
                    title: "Timesheet",
                    value: ["timesheet.pdf"],
                  },
                  {
                    title: "Summary Reimburse",
                  },
                  {
                    title: "Proforma Invoice",
                  },
                  {
                    title: "All Document",
                    value: ["All Document.pdf"],
                  },
                ]}
              />
            </Card.Body>
          </Card>
          <div>
            <MaterialReactTable
              columns={columnMRTCreateBAST}
              enableEditing
              enableRowNumbers
              // renderBottomToolbar={() => {
              //   return <div>sasdf</div>;
              // }}
              renderRowActions={({ row }) => {
                return (
                  row.original.UOM !== "TOTAL QUANTITY" && (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <>
                        <Tooltip arrow placement="right" title="Approve">
                          <IconButton
                            onClick={() => {
                              setModalType("approve");
                              setOpenModal(true);
                            }}
                          >
                            <Check />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Reject">
                          <IconButton
                            color="error"
                            onClick={() => {
                              setModalType("reject");
                              setOpenModal(true);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </>
                    </Box>
                  )
                );
              }}
              data={[
                {
                  no: 1,
                  stockCode: "",
                  description: "DXC July 2023 - NSSR Reshape",
                  UOM: "",
                  qty: 1,
                  // curr: "USD",
                  // unitPrice: "643.69",
                  // totalValue: "643.69",
                  // condition: "good",
                },
                {
                  no: 2,
                  stockCode: "",
                  description: "DXC July 2023 - Reshape",
                  UOM: "",
                  qty: 1,
                  // curr: "USD",
                  // unitPrice: "1,087.31",
                  // totalValue: "1,087.31",
                  // condition: "good",
                },
                {
                  no: "",
                  stockCode: "",
                  description: "",
                  UOM: "TOTAL QUANTITY",
                  qty: "2",
                  // curr: "USD",
                  // unitPrice: "",
                  // totalValue: `1,731`,
                  // condition: "good",
                },
              ]}
              enablePagination={false}
            />
            {/* <Row className="mt-3">
              <Col md={4}>
                <Row>
                  <Col>
                    <p>Received By :</p>
                    <p>Arini Pratiwi</p>
                  </Col>
                  <Col>
                    <p>Date :</p>
                    <p>9 August 2023</p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col>
                    <p>Checked By :</p>
                    <p>Christianus Mangiwa</p>
                  </Col>
                  <Col>
                    <p>Date :</p>
                    <p>9 August 2023</p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col>
                    <p>Delivered By :</p>
                    <p>Aiswarya. T.A</p>
                  </Col>
                  <Col>
                    <p>Date :</p>
                    <p>9 August 2023</p>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <BastSignatureSection />
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
          className="mx-3 mb-3"
        >
          <Button
            onClick={() => {
              setModalType("reject");
              setOpenModal(true);
            }}
            className="reject-button d-flex align-items-center justify-content-center"
          >
            <p className="m-0">Reject</p>
            <Close />
          </Button>
          <Button
            onClick={() => {
              setModalType("approve");
              setOpenModal(true);
            }}
            className="vale-button d-flex align-items-center justify-content-center"
          >
            <p className="m-0">Approve</p>
            <Check />
          </Button>
        </div>
      </div>
      {/* panel content */}
      <div className="card mb-12">
        <div className="card-header bg-vale">
          <p className="m-0">BAST History</p>
        </div>
        <HistoryBAST />
      </div>
      <ConfirmationModal
        modalType={modalType}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
};

export default DetailBASTWarehouse;
