import { Card } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { API_URI } from "../../../constants";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { AUTH_SESSION_KEY } from "../../../helpers/api/apiCore";

// types
type HistoryBASTColumn = {
  date: any;
  company: any;
  user: any;
  process: any;
  activity: any;
  comment: string;
};

// dummy data

const HistoryBAST = ({ id, type, flagToRefresh = 1 }: any) => {
  const { t } = useTranslation();
  const [historyList, setHistoryList] = useState([]);
  // console.log(historyList);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getHistory = () => {
      let path = "";
      if (type === "invoice") {
        path = `invoices/list-approval/${id}`;
      } else {
        path = `bast/list-approval/${id}`;
        // path = `bast-clone-history/${id}`
      }
      fetch(API_URI + path, {
        method: "GET",
        // method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data, "bast/list-approval DATA");
          if (data && data.success) {
            if (data.data) {
              // console.log(data.data, "ONLYDATA");
              const a = data.data;
              // const a = data.data?.data;
              const newState: any = [];
              for (const b of a) {
                let [date, time] = b?.updated_at.split("T");
                time = time.split(".")[0];

                const obj = {
                  // date: date + " " + time,
                  date: b?.updated_at,
                  company:
                    typeof b?.vendor === "object"
                      ? b?.vendor?.vendor_name
                      : b?.vendor,
                  user: b?.user?.name ? b.user.name : b?.vendor?.vendor_name,
                  process: b?.process,
                  activity: b?.read_status,
                  comment: b?.comment,
                };
                newState.push(obj);
              }
              setHistoryList((prev: any): any => [...newState]);
              setIsError(false);
            }
          } else {
            console.log(data.message);
            setIsError(true);
            toast.warn(t("Failed getting history data"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
          toast.warn(t("Failed getting history data"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    };
    if (flagToRefresh) getHistory();
  }, [flagToRefresh]);

  const columnMRT = useMemo<MRT_ColumnDef<HistoryBASTColumn>[]>(
    () => [
      {
        accessorKey: "date",
        header: t("Date"),
        maxSize: 40,
        Cell: ({ cell }) => {
          let od = cell.getValue() as string;
          let d = new Date(od);
          return (
            <>
              <div>{dayjs(d).format("DD MMM YYYY HH:mm")}</div>
            </>
          );
        },
      },
      {
        accessorKey: "company",
        header: t("Vendor Name"),
        size: 80,
      },
      {
        accessorKey: "user",
        header: t("User"),
        size: 80,
        Cell: ({ cell }) => {
          return (
            <>
              <div>
                {!JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY) as string)
                  ?.data?.vendor?.id
                  ? (cell.getValue() as string)
                  : ""}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "process",
        header: t("Process"),
        size: 80,
      },
      {
        accessorKey: "activity",
        header: t("Activity"),
        size: 80,
      },
      {
        accessorKey: "comment",
        header: t("Comment"),
        size: 80,
      },
    ],
    [t]
  );

  return (
    <MaterialReactTable
      columns={columnMRT}
      data={historyList}
      muiTablePaginationProps={{
        rowsPerPageOptions: [5, 10, 25, 50],
      }}
      initialState={{
        columnVisibility: {
          user: !JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY) as string)
            ?.data?.vendor?.id,
        },
      }}
    />
  );
};

export default HistoryBAST;
