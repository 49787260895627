import { ApexOptions } from "apexcharts";
import { ApexLinearChartData } from "./data";
import KpiPslChart from "./KpiPslChart ";
import { useState } from "react";
import MiroInvoiceLeadTimeChart from "./MiroInvoiceLeadTimeChart";

interface MultipleYaxisChartProps {
  multiYaxisChartData: ApexLinearChartData;
  showLoader?: boolean;
}

const MILT_STM = (
    data: any
  // {
// }: MultipleYaxisChartProps
) => {
  console.log(data);
  const multiYaxisChartData: ApexLinearChartData = {
    NumOfInvoice: data.data.NumOfInvoice,//[136, 212, 159, 184, 195, 144, 232, 221],
    NumOfVendor: data.data.NumOfVendor, //[84, 122, 102, 103, 91, 138, 97, 95],
    avgLeadTime: data.data.avgLeadTime, //[7, 6, 7, 6, 7, 6, 5, 7],
    avgPlanLead: data.data.avgPlanLead,
    // NumOfInvoice: [136, 212, 159, 184, 195, 144, 232, 221],
    // NumOfVendor: [84, 122, 102, 103, 91, 138, 97, 95],
    // avgLeadTime: [7, 6, 7, 6, 7, 9, 5, 11],
    // avgPlanLead: [7, 7, 7, 7, 7, 7, 7, 7, 7 , 7, 7, 7],
  };
  // console.log(multiYaxisChartData)

  const [isChatInitilized, setIsChatInitilized] = useState<boolean>(true)
  
  const options: ApexOptions = {
    chart: {
      stacked: false,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -40,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
        // resetIcon: {
        //     offsetX: -10,
        //     offsetY: 0,
        //     fillColor: '#fff',
        //     strokeColor: '#37474F'
        // },
        // selection: {
        //     background: '#90CAF9',
        //     border: '#0D47A1'
        // }    
      }
    },
    stroke: {
      width: [0, 0, 3, 3],
      dashArray: [0, 0, 0, 2],
    },
    // markers: {
      // size: 5,
    // },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3],
      // formatter: function (val, opts) {
      //   // console.log(val)
      //   // console.log(opts)
      //   // return opts.seriesIndex !== 0 ? val + "%" : val + ''
      //   return val
      // }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        dataLabels: {
          position: 'center'
        }
      },
    },
    xaxis: {
      categories: [
        "Jan-" + data?.data?.year,
        "Feb-" + data?.data?.year,
        "Mar-" + data?.data?.year,
        "Apr-" + data?.data?.year,
        "May-" + data?.data?.year,
        "Jun-" + data?.data?.year,
        "Jul-" + data?.data?.year,
        "Aug-" + data?.data?.year,
        "Sep-" + data?.data?.year,
        "Oct-" + data?.data?.year,
        "Nov-" + data?.data?.year,
        "Des-" + data?.data?.year,
      ],
      tickPlacement: 'on'
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#40A0FC",
        },
        title: {
          text: "NUMBER OF MIRO INVOICE",
        },
        seriesName: 'Number of Invoice (Qty)'
      },

      {
        show: false,
        seriesName: 'Number of Invoice (Qty)'
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#F4BF60",
        },
        tickAmount : 5,
        labels: {
          style: {
            colors: ["#23b397"],
          },
        },
        title: {
          text: "NUMBER OF WORKING DAYS",
        },
        max: 15,
        seriesName: "Average Lead Time (Days)"
      },
      {
        show: false,
        max: 15,
        seriesName: "Average Lead Time (Days)"
      },
    ],
    tooltip: {
      followCursor: true,
      // y: {
      //   formatter: function (y) {
      //     if (typeof y !== "undefined") {
      //       return y + " thousand crores";
      //     }
      //     return y;
      //   },
      // },
    },
    grid: {
      borderColor: "#f1f3fa",
    },
    legend: {
      offsetY: 7,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: {
            show: false,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Number of Invoice (Qty)",
      type: "column",
      data: data.data.NumOfInvoice || [],
    },
    {
      name: "Number of Vendor (Qty)",
      type: "column",
      data: data.data.NumOfVendor || [],
    },
    {
      name: "Average Lead Time (Days)",
      type: "line",
      data: data.data.avgLeadTime || [],
    },
    {
      name: "Average Planned Lead Time (Days)",
      type: "line",
      data: data.data.avgPlanLead || [],
    },
  ];

  

  return <MiroInvoiceLeadTimeChart series={series} options={options} title={'MIRO INVOICE PROCESSING SAP LEAD TIME PT SUMBAWA TIMUR MINING'} showLoader={!isChatInitilized} />
}

export default MILT_STM