// static data
import CreateClaimFormNonCC from "../../../components/CreateClaimFormNonCC";
import CreateInvoiceSAP from "../../../components/CreateInvoiceSAP";
import {useParams} from 'react-router-dom';

const CreateClaimNonCCTreasury = () => {
  const dataEdit = {
    employeeName: {
        name: "Dwayne a",
        isDisabled:true
    },
    employeeID: {
        name: "10400111",
        isDisabled: true
    },
    department: {
        name: "Corporate IT",
        isDisabled: true
    },
    position: {
        name: "IT Analyst",
        isDisabled: true
    },
    haveCashAdvance: {
        name: {
            value: true,
            label: "Yes",
        },
        isDisabled: true
    },
    docNo:{
        name: "V-",
        isDisabled: true
    },
    docDate:{
        name: "12/07/23",
        isDisabled: true
    },
    startingDate: {
        name: "",
        isDisabled: true
    },
    endingDate:{
        name: "",
        isDisabled: true
    },
    travelRoute: {
        name: "",
        isDisabled: true
    },
    kindOfTransport: {
        name: "",
        isDisabled: true
    },
    bankRecolitionNumber: {
        name: "",
        isDisabled: false
    },
    reasonForTravelAdvanceText:{
        name:"",
        isDisabled: false
    },
    reasonForTravelAdvance:{
        name:"",
        isDisabled: true
    },
    reasonForOtherAdvance:{
        name:"",
        isDisabled: true
    },
    returnToCompany:{
        name:"",
        isDisabled: true
    },
    ca11:{
        name:"",
        isDisabled: true
    },
    ca12:{
        name:"",
        isDisabled: true
    },
    ca21:{
        name:"IDR",
        isDisabled: true
    },
    ca22:{
        name:"150,000",
        isDisabled: true
    },
    ca31:{
        name:"IDR",
        isDisabled: true
    },
    ca32:{
        name:"150,000",
        isDisabled: true
    },
    ca41:{
        name:"IDR",
        isDisabled: true
    },
    ca42:{
        name:"150,000",
        isDisabled: true
    },
    employeeSign:{
        name:"",
        isDisabled: true
    },
    employeeSignDate:{
        name:"",
        isDisabled: true
    },
    employeeSignName:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSign:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSignDate:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSignName:{
        name:"",
        isDisabled: true
    }
  }
  const dataAdd = {
    employeeName: {
        name: "",
        isDisabled:false
    },
    employeeID: {
        name: "",
        isDisabled: false
    },
    department: {
        name: "",
        isDisabled: false
    },
    position: {
        name: "",
        isDisabled: false
    },
    haveCashAdvance: {
        name: 1,
        isDisabled: false
    },
    docNo:{
        name: "",
        isDisabled: false
    },
    docDate:{
        name: "",
        isDisabled: false
    },
    startingDate: {
      name: "",
      isDisabled: false
    },
    endingDate:{
        name: "",
        isDisabled: false
    },
    travelRoute: {
        name: "",
        isDisabled: false
    },
    kindOfTransport: {
        name: "",
        isDisabled: false
    },
    bankRecolitionNumber: {
        name: "",
        isDisabled: false
    },
    reasonForTravelAdvanceText:{
        name:"",
        isDisabled: false
    },
    reasonForTravelAdvance:{
        name:"",
        isDisabled: true
    },
    reasonForOtherAdvance:{
        name:"",
        isDisabled: true
    },
    returnToCompany:{
        name:"",
        isDisabled: true
    },
    ca11:{
        name:"",
        isDisabled: true
    },
    ca12:{
        name:"",
        isDisabled: true
    },
    ca21:{
        name:"",
        isDisabled: true
    },
    ca22:{
        name:"",
        isDisabled: true
    },
    ca31:{
        name:"",
        isDisabled: true
    },
    ca32:{
        name:"",
        isDisabled: true
    },
    ca41:{
        name:"",
        isDisabled: true
    },
    ca42:{
        name:"",
        isDisabled: true
    },
    employeeSign:{
        name:"",
        isDisabled: true
    },
    employeeSignDate:{
        name:"",
        isDisabled: true
    },
    employeeSignName:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSign:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSignDate:{
        name:"",
        isDisabled: true
    },
    immediateSupervisorSignName:{
        name:"",
        isDisabled: true
    }
  }
  const {page} = useParams();
  let data;
  if(page === 'create') {
    data = dataAdd;
  } else if (page === 'edit') {
    data = dataEdit;
  } else {
    data = dataAdd;
  }
  return (
    <>
      <CreateClaimFormNonCC data={data} canApprove={false} isEdit={page==="edit" ? true : false} isTreasury={true}/>
    </>
  );
};

export default CreateClaimNonCCTreasury;
