import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  publicRoutes,
} from "./index";

import { APICore } from "../helpers/api/apiCore";
import Maintenance from "../pages/other/Maintenance";

interface RoutesProps {}

const AllRoutes = (props: RoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));
  const location = useLocation();
  // console.log(location, "location");
  if (
    location.pathname.includes("/auth/login") &&
    location.pathname !== "/auth/login"
  ) {
    return <Navigate to={`/auth/login${location.search}`} />;
  }

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
  const api = new APICore();

  return (
    <React.Fragment>
      <Routes>
        {/* START NON MAINTENANCE */}
        <Route
          path="/maintenance"
          element={<Navigate to="/" replace />}
        ></Route>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => {
            // console.log("routes.tsx");
            // console.log(route.roles);
            // console.log("api", api);
            return (
              <Route
                path={route.path}
                element={
                  api.isUserAuthenticated() === false ||
                  sessionStorage.getItem("is_locked") === "1" ? (
                    <Navigate
                      to={{
                        pathname:
                          sessionStorage.getItem("is_locked") === "1"
                            ? "auth/lock-screen"
                            : "auth/login",
                        search:
                          sessionStorage.getItem("is_locked") === "1"
                            ? ""
                            : "next=" + route.path,
                      }}
                    />
                  ) : (
                    <Layout {...props}>{route.element}</Layout>
                  )
                }
                key={idx}
              />
            );
          })}
        </Route>
        {/* END NON MAINTENANCE */}

        {/* <Route path="*" element={<Navigate to="/error-404" replace />} /> */}
        
        {/* START MAINTENANCE */}
        {/* <Route
          path="/maintenance"
          element={
            <DefaultLayout {...props} layout={layout}>
              <Maintenance />
            </DefaultLayout>
          }
        />
        <Route
          path="*"
          element={<Navigate to="/maintenance" replace />}
        /> */}
        {/* END MAINTENANCE */}
      </Routes> 
    </React.Fragment>
  );
};

export default AllRoutes;
