import { title } from "process";
import corDgtInfo from "./Withholding Tax Article 26.pdf";

interface InvoiceRecords {
  no: number;
  contractNumber: string;
  migoses?: string;
  supplierName: string;
  amount: number;
  status: string;
}

interface AddInvoiceColRecord {
  stock_code: any;
  description: string;
  uom_name: any;
  qty: any;
  currency_name: any;
  unit_price: any;
  total_value: any;
}

interface SelectOptionInvoiceAdd {
  value: string;
  label: string;
}

interface FormItemInvoiceAdd {
  controlId: string;
  label: string;
  type: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  feedback?: {
    valid?: string;
    invalid?: string;
  };
  value?: any;
  readOnly?: boolean;
  options?: SelectOptionInvoiceAdd[];
}

const records: InvoiceRecords[] = [
  {
    no: 1,
    contractNumber: "STM-CN02-11",
    migoses: "",
    supplierName: "PT ABC",
    amount: 2150000,
    status: "Submitted",
  },
  {
    no: 2,
    contractNumber: "STM-CN02-12",
    migoses: "1234567890",
    supplierName: "PT ABCD",
    amount: 150000,
    status: "Complete",
  },
  {
    no: 3,
    contractNumber: "STM-CN02-13",
    migoses: "",
    supplierName: "PT XYZ",
    amount: 250000,
    status: "Approved",
  },
  {
    no: 4,
    contractNumber: "STM-CN02-14",
    migoses: "",
    supplierName: "PT EFG",
    amount: 210000,
    status: "Rejected",
  },
];
const createRecord: AddInvoiceColRecord[] = [
  {
    stock_code: "13007887",
    description: "Account Payable Services on site",
    uom_name: "Month",
    qty: 2,
    currency_name: "IDR",
    unit_price: "33,400,000.00",
    total_value: "66,800,000.00",
  },
];

const formData: FormItemInvoiceAdd[] = [
  {
    controlId: "attachFile",
    label: "Attach File",
    type: "file",
    required: true,
  },
  {
    controlId: "migoses",
    label: "MIGO (SES) No.",
    type: "text",
    value: "Readonly value",
    readOnly: true,
  },
  {
    controlId: "gl",
    label: "GL No.",
    type: "text",
    value: "Readonly value",
    readOnly: true,
  },
  {
    controlId: "gl",
    label: "WBS No.",
    type: "text",
    value: "Readonly value",
    readOnly: true,
  },
  {
    controlId: "contract",
    label: "Contract/BPO No.",
    type: "select",
    options: [],
  },
  {
    controlId: "po",
    label: "PO No.",
    type: "select",
    options: [],
  },
];
const columnMRT = [
  {
    accessorKey: "description",
    header: "Description",
    enableColumnOrdering: false,
    enableEditing: false, //disable editing on this column
    enableSorting: false,
    size: 200,
  },
  {
    accessorKey: "month",
    header: "Month",
    size: 80,
  },
  {
    accessorKey: "services",
    header: "Services",
    size: 80,
  },
  {
    accessorKey: "estQty",
    header: "Est. Qty (services x month)",
    size: 80,
  },
  {
    accessorKey: "unit",
    header: "Unit",
    size: 80,
  },
  {
    accessorKey: "unitPrice",
    header: "Unit Price",
    size: 80,
  },
  {
    accessorKey: "estPrice",
    header: "Est Price (IDR)",
    size: 80,
  },
];

const groupInputAtt1: any[] = [
  {
    controlId: "bast_no",
    label: "No BAST",
    type: "text",
    readOnly: true,
    value: "",
  },
  {
    controlId: "contract_no",
    label: "Contract/BPO No",
    type: "text",
    readOnly: true,
    value: "",
  },
  {
    controlId: "contract_sap",
    label: "Contract SAP",
    type: "text",
    readOnly: true,
    value: "",
    feedback: {
      valid: "valid",
      invalid: "invalid",
    },
    validation: "*Required",
  },
  {
    controlId: "sap_invoice_miro",
    label: "SAP Invoice (MIRO)",
    type: "text",
    readOnly: true,
    value: "",
  },
  {
    controlId: "invoice_date",
    label: "Invoice Date",
    readOnly: false,
    type: "date",
    // type: "text",
    value: "",
  },
  {
    controlId: "suplier_name",
    label: "Supplier Name",
    readOnly: true,
    type: "text",
    value: "",
  },
  {
    controlId: "invoice_number",
    readOnly: false,
    label: "Invoice Number",
    type: "text",
    value: "",
  },
  {
    controlId: "description_invoice",
    readOnly: false,
    label: "Invoice Description",
    type: "text",
    value: "",
  },
  {
    controlId: "po_no",
    readOnly: true,
    label: "PO No.",
    type: "text",
    value: "",
  },
];
const groupInputAtt2 = [
  // {
  //   controlId: "surat_jalan_file",
  //   label: "Surat Jalan / Pengantar",
  //   type: "file",
  //   readOnly: false,
  // },
  // {
  //   controlId: "confirmation_sheet_file",
  //   label: "Confirmation Sheet",
  //   type: "file",
  //   readOnly: false,
  // },
  {
    controlId: "cod_cor_dgt_file",
    label: "COD/COR & DGT",
    type: "file",
    readOnly: false,
    tooltip: {
      title:
        "Attach COD/COR & DGT for Tax Treaty. Click icon to read instruction.",
      file: corDgtInfo,
    },
  },
  {
    controlId: "sk_pembebeasan_file",
    label: "SK Pembebasan Pajak",
    type: "file",
    readOnly: false,
  },
  {
    controlId: "other_invoice_file",
    // label: "Contract File",
    label: "Other Invoice Attachments",
    type: "file",
    // readOnly: true,
  },
  {
    controlId: "timesheet_file",
    label: "Timesheet",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "others_doc_file",
    label: "Other BAST Attachments",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "bast_file",
    label: "BAST Document",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "delivery_order_file",
    label: "Delivery Order File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "sumary_reimburse_file",
    label: "Summary Reimburse File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "bill_of_leading_file",
    label: "Bill of Lading File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "airway_bill_file",
    label: "Airway Bill File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "pib_file",
    label: "PB1 File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "po_file",
    label: "Purchase Order File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "contract_file",
    // label: "Contract File",
    label: "Confirmation File",
    type: "file",
    readOnly: true,
  },
  {
    controlId: "good_receipt_note",
    // label: "Contract File",
    label: "Good Receipt Note",
    type: "file",
    readOnly: true,
  },
  // {
  //   controlId: "proforma_invoice_file",
  //   label: "Proforma Invoice File",
  //   type: "file",
  //   readOnly: true,
  // },
];
const groupInputAtt3 = [
  {
    controlId: "faktur_no",
    label: "No. Faktur",
    type: "text",
    value: "",
  },
  {
    controlId: "faktur_date",
    label: "Faktur Date",
    type: "date",
    value: "",
  },
  {
    controlId: "faktur_file",
    label: "Attach File",
    type: "file",
  },
  {
    controlId: "faktur_address",
    label: "Address",
    type: "text",
  },
  {
    controlId: "jumlahDpp",
    label: "DPP Amount",
    type: "text",
  },
  {
    controlId: "jumlahPpn",
    label: "PPN Amount",
    type: "text",
  },
];

const invoiceField = {
  bast_id: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  bast_no: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  company_code: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  subcompany_code: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  good_or_services: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  invoice_number: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  contract_sap: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  sap_invoice_miro: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  invoice_date: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  suplier_name: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  vendor_type: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  notes: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  surat_jalan_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  purchase_order_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  cod_cor_dgt_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  sk_pembebeasan_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  timesheet_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  others_doc_file: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  wbs_costcenter: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  currency_id: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  dpp: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  subtotal: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  vat_type: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  vat_amount: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  net_payment: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  requested_by: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  requested_at: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  recieved_by: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  recieved_at: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  delivered_by: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  delivered_at: {
    value: null,
    defaultValue: null,
    isDisabled: true,
  },
  invoice_detail: [
    {
      is_reimbursement: "1", //is reimbursement
      stock_code: "stc12345",
      uom: "0001",
      qty: "2",
      currency_id: "2",
      unit_price: "2",
      total_value: "4000000",
      description: "tester detail",
    },
    {
      is_reimbursement: "1", //is reimbursement
      stock_code: "stc12346",
      uom: "0002",
      qty: "2",
      currency_id: "2",
      unit_price: "2",
      total_value: "4000000",
      description: "tester detail",
    },
  ],
};

const iasData = {
  suplier_name: {
    title: "Vendor Name",
    value1: "",
  },
  invoice_number: {
    title: "Invoice No.",
    value1: "",
    // value2: "21",
  },
  paymentDesc: {
    title: "Payment Description",
    // value1: "*WHT 21,23,4(2),25,VAT Period Jul 23*",
    value1: "",
  },
  invoice_date: {
    title: "Invoice Date",
    value1: "",
    // value2: "37",
  },
  checkList: [
    {
      checked: false,
      desc: "Invoice dan kuitansi dengan materai (jika ada)",
      label: "is_invoice",
    },
    {
      checked: false,
      desc: "Faktur Pajak (For PKP Only)",
      label: "is_faktur_pajak",
    },
    {
      checked: false,
      desc: "Delivery Order (For Goods Only)",
      label: "is_delivery_order",
    },
    {
      checked: false,
      desc: "BAST Complete with SES Number",
      label: "is_bast_complete",
    },
    {
      checked: false,
      desc: "Summary of Contract Expenditure (For Contract & Long term PO)",
      label: "is_contract_expenditure",
    },
    {
      checked: false,
      desc: "Purchase Order (For Spot Purchase Only)",
      label: "is_purchase_order",
    },
    {
      checked: false,
      desc: "Confirmation Sheet (For Contract & Long term PO)",
      label: "is_confirmation_sheet",
    },
    {
      checked: false,
      desc: "COD & DGT (For Overseas Services Only))",
      label: "is_cod_dgt",
    },
    {
      checked: false,
      desc: "Invoice/kuitansi Reimbursment (jka ada)",
      label: "is_invoice_kwitansi",
    },
    {
      checked: false,
      desc: "Timesheet (jika ada)",
      label: "is_timesheet",
    },
    {
      checked: false,
      desc: "SK Pembebasan PPh 23 (jika ada)",
      label: "is_sk_pembebasan",
    },
    {
      checked: false,
      desc: "Dokumen Pendukung Lainnya (jika ada)",
      label: "is_others",
    },
  ],
  recieved_at: "",
  recieved_by: "",
  delivered_by: "",
  wbs_costcenter: "",
  gl_no: "",
  poNo: "-",
  migoSes: "-",
  sap_invoice_miro: "",
  paymentNo: "",
  // userDeptHead: "APPROVAL by USER on BAST",
  userDeptHead: "",
  // financeStaff: "Ansyar",
  financeStaff: "",
  // financeHead: "EMAIL APPROVAL BY PAK LUHUT",
  financeHead: "",
  // poaDir: "EMAIL APPROVAL BY PAK BEDE",
  poaDir: "",
  // curr: "IDR",
  curr: "",
  // dpp: "2,277,430,419",
  dpp: "",
  vat: {
    percentage: "-",
    amount: "-",
  },
  wht: {
    percentage: "-",
    amount: "-",
  },
  net_payment: "",
  // approval: [
  //   ["Finance", "EMAIL APPROVAL BY PAK LUHUT"],
  //   ["Taxation", "Ansyar"],
  // ],
  approval: [
    ["Finance", ""],
    ["Taxation", ""],
  ],
  notes: "",
};

export {
  records,
  // expandableRecords,
  createRecord,
  formData,
  columnMRT,
  groupInputAtt1,
  groupInputAtt2,
  groupInputAtt3,
  invoiceField,
  iasData,
};
