import { Delete, Edit, FindInPage, Help } from "@mui/icons-material";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { toBase64 } from "../../../utils";
import { checkFile } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";

const GroupInputFile = ({
  colSize = 3,
  data,
  control,
  setValue,
  errors,
  prevData,
  setPrevData,
  getValues,
  watch,
  setData,
  isEditing,
  specialConditional,
}: any) => {
  const { t } = useTranslation();
  const curVal = getValues();
  const watchAll = watch?.();
  // console.log(watchAll);
  // console.log(prevData)
  // console.log(data)
  const isRequired = (item: any) => {
    let out =
      // item.controlId === "contract_file" ||
      // (item.controlId === "proforma_invoice_file") ||
      (item.controlId === "delivery_order_file" &&
        watchAll?.good_or_services === "1") ||
      (item.controlId === "po_file" && watchAll?.good_or_services === "1") ||
      // (item.controlId === "timesheet_file" &&
      //   watchAll?.good_or_services === "2") ||
      // (item.controlId === "physical_picture" &&
      //   watchAll?.good_or_services === "1") ||
      (item.controlId === "airway_bill_file" &&
        watchAll?.airway_bill?.length > 0) ||
      (item.controlId === "bill_of_leading_file" &&
        watchAll?.bill_of_leading?.length > 0) ||
      // (item.controlId === "cod_cor_dgt_file" &&
      //   specialConditional?.isInternationalVendor) ||
      // (item.controlId === "confirmation_sheet_file" &&
      //   specialConditional?.isCreate) ||
      (item.controlId === "sumary_reimburse_file" &&
        specialConditional?.is_reimbursement);
    return out;
  };

  useEffect(() => {}, [
    specialConditional?.isMineral,
    specialConditional?.isServices,
  ]);

  return (
    <Row>
      {data?.map((item: any, index: any) => {
        return (
          <Col md={colSize} key={index}>
            <Form.Group className="mb-3 position-relative">
              {isRequired(item) && !item?.readOnly && (
                <div className="position-absolute" style={{ right: 0 }}>
                  <span className="text-danger">*</span>
                  {t("Required")}
                </div>
              )}
              <Form.Label>{t(item.label)}</Form.Label>
              {item?.controlId === "monthly_report_file" && (
                <span>{" ( .xlsx )"}</span>
              )}
              <div style={{ display: "inline" }}>
                {(!!prevData?.[item.controlId] || !!item.value) && (
                  <FindInPage
                    onClick={() => {
                      const b64toBlob = (
                        b64Data: any,
                        contentType = "",
                        sliceSize = 512
                      ) => {
                        const byteCharacters = atob(b64Data);
                        const byteArrays = [];

                        for (
                          let offset = 0;
                          offset < byteCharacters.length;
                          offset += sliceSize
                        ) {
                          const slice = byteCharacters.slice(
                            offset,
                            offset + sliceSize
                          );

                          const byteNumbers = new Array(slice.length);
                          for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                          }

                          const byteArray = new Uint8Array(byteNumbers);
                          byteArrays.push(byteArray);
                        }

                        const blob = new Blob(byteArrays, {
                          type: contentType,
                        });
                        return blob;
                      };
                      const file = prevData[item.controlId]
                        ? prevData[item.controlId]
                        : item.value;
                      // console.log(file);
                      // console.log(meta);
                      const [meta, data] = file.split("base64,");
                      let url;
                      if (meta === "data:application/pdf;") {
                        const blob = b64toBlob(data, "application/pdf");
                        url = URL.createObjectURL(blob);
                      } else {
                        url = file;
                      }

                      const win: any = window.open();
                      win.document.write(
                        `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                      );
                    }}
                    className="cursor-pointer ms-2"
                  />
                )}
                {(prevData?.[item.controlId] ||
                  curVal?.[item.controlId] ||
                  watchAll?.[item.controlId]) &&
                  !item.readOnly && (
                    <Delete
                      color="error"
                      className="cursor-pointer ms-2"
                      onClick={() => {
                        setValue(item.controlId, "");
                        if (setData) {
                          setData?.((prev: any) => {
                            prev[index] = {
                              ...prev[index],
                              value: "",
                            };
                            return [...prev];
                          });
                        }
                        if (setPrevData) {
                          setPrevData?.((prev: any) => {
                            return { ...prev, [item.controlId]: "" };
                          });
                        }
                      }}
                    />
                  )}
              </div>

              <Controller
                control={control}
                name={item.controlId}
                render={({ field: { value, onChange, ...field } }: any) => {
                  return (
                    <Form.Control
                      {...field}
                      value={value?.fileName}
                      onChange={async (event: any) => {
                        const file_size = event.target.files[0].size;
                        if (item.controlId === "monthly_report_file") {
                          if (file_size > 31457280) {
                            toast.warn(t("File must be below 30MB"), {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            return;
                          }
                          try {
                            const b64: string = (await toBase64(
                              event.target.files[0]
                            )) as string;
                            setValue(item.controlId, b64);
                          } catch (error) {
                            console.log(error);
                          }
                        } else if (
                          item.controlId === "others_file" ||
                          item.controlId === "timesheet_file" ||
                          item.controlId === "other_invoice_file"
                        ) {
                          const file_type = event.target.files[0].type;
                          if (file_size > 31457280) {
                            toast.warn(t("File must be below 30MB"), {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            return;
                          }
                          if (file_type.includes("pdf")) {
                            try {
                              const b64: string = (await toBase64(
                                event.target.files[0]
                              )) as string;
                              // console.log(b64);
                              setValue(item.controlId, b64);
                            } catch (error) {
                              console.log(error);
                            }
                          } else {
                            toast.warn(t("File must be PDF"), {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            return false;
                          }
                        } else if (
                          event.target?.files?.[0] &&
                          (item.controlId === "physical_picture"
                            ? checkFile(event, false)
                            : checkFile(event))
                        ) {
                          // setValue(item.controlId, event.target.files?.[0]);
                          try {
                            const b64: string = (await toBase64(
                              event.target.files[0]
                            )) as string;
                            setValue(item.controlId, b64);
                          } catch (error) {
                            console.log(error);
                          }
                        }
                      }}
                      type="file"
                      placeholder="Input File"
                      isInvalid={errors[item.controlId] ? true : false}
                      disabled={
                        // item.controlId === "monthly_report_file"
                        //   ? !specialConditional?.isMineral
                        //   : item?.readOnly
                        item?.readOnly
                      }
                    />
                  );
                }}
              />
              {errors[item.controlId] && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "0.25rem",
                    fontSize: "0.75rem",
                    color: "#ff5c75",
                    position: "absolute",
                  }}
                >
                  {errors[item.controlId]?.message}
                </div>
              )}
              {item.tooltip?.title &&
                specialConditional?.isInternationalVendor && (
                  <div
                    style={{
                      // width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.75rem",
                      color: "#ff5c75",
                      position: "absolute",
                      bottom: "0.8em",
                      right: "0.7em",
                    }}
                  >
                    <Tooltip
                      arrow
                      placement="top"
                      title={item.tooltip?.title}
                      style={{}}
                    >
                      <IconButton
                        style={{
                          padding: 0,
                          margin: 0,
                        }}
                        onClick={() => {
                          const file = item.tooltip?.file;
                          const win: any = window.open();
                          win.document.write(
                            `<iframe src="${file}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                          );
                        }}
                      >
                        <Help style={{ width: "0.7em", height: "0.7em" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
            </Form.Group>
          </Col>
        );
      })}
    </Row>
  );
};

export default GroupInputFile;
