import { Row, Col, Button } from "react-bootstrap";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  changeUStoID,
  removeDot,
  replaceToComma,
  replaceToDot,
} from "../../../helpers/utils";
import { useCallback, useEffect, useMemo } from "react";
import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";
import { useTranslation } from "react-i18next";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const TaxSimulation = ({ isAdminApEdit, dataState }: any) => {
  const {
    getValues,
    vat,
    setVat,
    wht,
    setWht,
    dpp,
    setDpp,
    glDpp,
    set_net_payment,
    net_payment,
    invoiceDetail,
    glData,
    wbsData,
    costCenterData,
    currency_code,
    otherData,
    setOtherData,
  } = dataState;
  // console.log(vat, wht, dpp, net_payment)
  // const {t} = useTranslation()
  let gl: any = [],
    wbs: any = [],
    costCenter: any = [];
  // console.log(glData);
  if (glData) {
    gl = Object.entries(glData);
  }
  if (wbsData) {
    wbs = Object.entries(wbsData);
  }
  if (costCenterData) {
    costCenter = Object.entries(costCenterData);
  }

  // const calcTotalOther = useCallback(() => {
  //   return otherData
  //     ? otherData.reduce((t: any, c: any) => {
  //         console.log(c);
  //         const amount = c.amount ? c.amount : "0";
  //         return (
  //           t +
  //           parseFloat(
  //             replaceToDot(amount.includes(",") ? removeDot(amount) : amount)
  //           )
  //         );
  //       }, 0)
  //     : 0;
  // }, []);

  // const totalOther = useMemo(() => calcTotalOther(), [otherData]);
  const totalOther = otherData.length
    ? otherData.reduce((t: any, c: any) => {
        let amount = c.amount ? c.amount : "0";
        // console.log(amount);
        if (c?.debit_or_credit?.value === "C" || c?.debit_or_credit === "C") {
          const a = parseFloat(
            replaceToDot(amount.includes(",") ? removeDot(amount) : amount)
          );
          amount = a * -1;
        }
        // console.log(amount);
        return (
          t +
          parseFloat(
            typeof amount === 'number' ? amount : replaceToDot(amount.includes(",") ? removeDot(amount) : amount)
          )
        );
      }, 0)
    : 0;
  // console.log(otherData);
  // console.log(totalOther);

  // useEffect(() => {
  //   if (set_net_payment) {
  //     console.log(dpp)
  //     console.log(vat)
  //     console.log(wht)
  //     console.log(totalOther)
  //     console.log(
  //       (
  //         parseFloat(dpp) +
  //         (parseFloat(dpp) * vat?.value) / 100 -
  //         (parseFloat(dpp) * wht?.value) / 100 +
  //         totalOther
  //       ).toFixed(2)
  //     );
  //     set_net_payment(
  //       (
  //         parseFloat(dpp) +
  //         (parseFloat(dpp) * vat?.value) / 100 -
  //         (parseFloat(dpp) * wht?.value) / 100 +
  //         totalOther
  //       ).toFixed(2)
  //     );
  //   }
  // }, [vat, wht]);

  return (
    <div className="card mt-3">
      <div className="card-header bg-vale">Tax Simulation</div>
      <div className="card-body">
        <Row style={{ borderBottom: "1px solid rgba(50, 49, 49, 0.13)" }}>
          {/* 1 */}
          <Col>
            <p className="card-text">GL No.</p>
          </Col>
          {/* 2 */}
          <Col md={2}>
            <p className="card-text">GL Desc.</p>
          </Col>
          {/* 3 */}
          <Col>
            <p className="card-text">
              {userCurrentCompany?.company_code === "1561"
                ? "Cost Center No."
                : "WBS No."}
            </p>
          </Col>
          {/* 4 */}
          <Col md={2}>
            <p className="card-text">
              {userCurrentCompany?.company_code === "1561"
                ? "Cost Center Desc."
                : "WBS Desc."}
            </p>
          </Col>
          {/* 5 */}
          <Col>
            <p className="card-text">Description</p>
          </Col>
          {/* 6 */}
          <Col>
            <p className="card-text">%</p>
          </Col>
          {/* 7 */}
          <Col>
            <p className="card-text">Curr</p>
          </Col>
          {/* 8 */}
          <Col md={2}>
            <p className="card-text">Amount</p>
          </Col>
        </Row>
        {/*  */}
        <Row style={{ borderBottom: "1px dotted rgba(50, 49, 49, 0.13)" }}>
          {/* 1 */}
          <Col>
            <p className="card-text">
              {/* glDpp?.includes("[")
          ? glDpp.replace(/["'\[\]]/g, "").replace(/[,]/g, ", ")
        : glDpp */}
              {gl.length > 0 ? Object.keys(glData).join(", ") : ""}
            </p>
          </Col>
          {/* 2 */}
          <Col md={2}>
            {gl.length > 0 ? Object.values(glData).join(", ") : ""}
          </Col>
          {/* 3 */}
          <Col>
            {userCurrentCompany?.company_code === "1561"
              ? costCenter.length > 0
                ? Object.keys(costCenterData).join(", ")
                : ""
              : ""}
            {userCurrentCompany?.company_code === "1492"
              ? wbs.length > 0
                ? Object.keys(wbsData).join(", ")
                : ""
              : ""}
          </Col>
          {/* 4 */}
          <Col md={2}>
            <p className="m-0 text-wrap">
              {userCurrentCompany?.company_code === "1561"
                ? costCenter.length > 0
                  ? Object.values(costCenterData).join(", ")
                  : // ? Object.values(costCenterData).join("\n")
                    ""
                : ""}
              {userCurrentCompany?.company_code === "1492"
                ? wbs.length > 0
                  ? Object.values(wbsData).join(", ")
                  : ""
                : ""}
            </p>
          </Col>
          {/* 5 */}
          <Col>
            <p className="card-text">DPP</p>
          </Col>
          {/* 6 */}
          <Col>
            <p className="card-text">-</p>
          </Col>
          {/* 7 */}
          <Col>
            <p className="card-text">{currency_code}</p>
          </Col>
          {/* 8 */}
          <Col md={2}>
            {invoiceDetail?.id ? (
              <>
                <p className="card-text">
                  {invoiceDetail?.dpp &&
                    changeUStoID(replaceToComma(String(invoiceDetail.dpp)))}
                </p>
              </>
            ) : (
              <>
                <p className="card-text">
                  {dpp && changeUStoID(replaceToComma(String(dpp)))}
                </p>
              </>
            )}
          </Col>
        </Row>
        {/* VAT */}
        <Row style={{ borderBottom: "1px dotted rgba(50, 49, 49, 0.13)" }}>
          {/* 1 */}
          {invoiceDetail?.id && !isAdminApEdit ? (
            <Col>
              <p className="card-text">{invoiceDetail?.vat?.gl_account}</p>
            </Col>
          ) : (
            <Col>
              <p className="card-text">
                {vat?.gl_account
                  ? vat?.gl_account
                  : invoiceDetail?.vat?.gl_account}
              </p>
            </Col>
          )}
          {/* 2 */}
          <Col md={2}>
            {invoiceDetail?.id && !isAdminApEdit ? (
              <p className="card-text">{invoiceDetail?.vat?.name}</p>
            ) : (
              <p className="card-text">
                {vat?.name
                  ? vat?.name
                  : // : invoiceDetail?.vat?.gl?.gl_name}
                    ""}
              </p>
            )}
          </Col>
          {/* 3 */}
          <Col></Col>
          {/* 4 */}
          <Col md={2}></Col>
          {/* 5 */}
          <Col>
            <p className="card-text">VAT</p>
          </Col>
          {/* 6 */}
          {invoiceDetail?.id && !isAdminApEdit ? (
            <Col>
              <p className="card-text">
                {invoiceDetail?.vat?.wht_rate ||
                invoiceDetail?.vat?.wht_rate === 0
                  ? invoiceDetail?.vat?.wht_rate
                  : "-"}
              </p>
            </Col>
          ) : (
            <Col>
              <p className="card-text">
                {vat?.percentage || vat?.percentage === 0
                  ? vat?.percentage
                  : "-"}
              </p>
            </Col>
          )}
          {/* 7 */}
          <Col>
            <p className="card-text">{currency_code}</p>
          </Col>
          {/* 8 */}
          <Col md={2}>
            {invoiceDetail?.id &&
            (!isAdminApEdit || invoiceDetail?.approval_status_id === "33") ? (
              <>
                <p className="card-text">
                  {invoiceDetail?.vat_amount &&
                    changeUStoID(
                      replaceToComma(String(invoiceDetail.vat_amount))
                    )}
                </p>
              </>
            ) : (
              <>
                <p className="card-text">
                  {getValues ? getValues("vat_amount") : ""}
                </p>
              </>
            )}
          </Col>
        </Row>
        {/* WHT */}
        <Row style={{ borderBottom: "1px dotted rgba(50, 49, 49, 0.13)" }}>
          {/* 1 */}
          {invoiceDetail?.id && !isAdminApEdit ? (
            <Col>
              <p className="card-text">{invoiceDetail?.wht?.gl_account}</p>
            </Col>
          ) : (
            <Col>
              <p className="card-text">
                {wht?.gl_account
                  ? wht?.gl_account
                  : invoiceDetail?.wht?.gl_account}
              </p>
            </Col>
          )}
          {/* 2 */}
          <Col md={2}>
            {invoiceDetail?.id && !isAdminApEdit ? (
              <p className="card-text">{invoiceDetail?.wht?.name}</p>
            ) : (
              <p className="card-text">
                {wht?.name
                  ? wht?.name
                  : // : invoiceDetail?.wht?.gl?.gl_name}
                    ""}
              </p>
            )}
          </Col>
          {/* 3 */}
          <Col></Col>
          {/* 4 */}
          <Col md={2}></Col>
          {/* 5 */}
          <Col>
            <p className="card-text">WHT</p>
          </Col>
          {/* 6 */}
          {invoiceDetail?.id && !isAdminApEdit ? (
            <Col>
              <p className="card-text">
                {invoiceDetail?.wht?.wht_rate ||
                invoiceDetail?.wht?.wht_rate === 0
                  ? invoiceDetail?.wht?.wht_rate
                  : "-"}
              </p>
            </Col>
          ) : (
            <Col>
              <p className="card-text">
                {wht?.percentage || wht?.percentage === 0
                  ? wht?.percentage
                  : "-"}
              </p>
            </Col>
          )}
          {/* 7 */}
          <Col>
            <p className="card-text">{currency_code}</p>
          </Col>
          {/* 8 */}
          <Col md={2} className="position-relative">
            {invoiceDetail?.id &&
            (!isAdminApEdit || invoiceDetail?.approval_status_id === "33") ? (
              <>
                {invoiceDetail.wht_amount && <span className="position-absolute" style={{ left: 0 }}>
                  -
                </span>}
                <p className="card-text">
                  {invoiceDetail?.wht_amount &&
                    changeUStoID(
                      replaceToComma(String(invoiceDetail.wht_amount))
                    )}
                </p>
              </>
            ) : (
              <>
                <span className="position-absolute" style={{ left: 0 }}>
                  -
                </span>
                <p className="card-text">
                  {getValues ? getValues("wht_amount") : ""}
                </p>
              </>
            )}
          </Col>
        </Row>
        {/* OTHER EXPENSE */}
        <OtherExp
          invoiceDetail={invoiceDetail}
          otherData={otherData}
          setOtherData={setOtherData}
          currency_code={currency_code}
        />
        {/* NET PAYMENT */}
        <Row style={{ borderBottom: "1px solid rgba(50, 49, 49, 0.13)" }}>
          {/* 1 */}
          <Col>
            <p className="card-text">
              {currency_code === "IDR" ? "221011001" : "211022001"}
            </p>
          </Col>
          {/* 2 */}
          <Col md={2}>
            <p className="card-text">
              {currency_code === "IDR"
                ? "Billed Third party suppliers DO - local Currency"
                : "Billed Third party suppliers FO - other currency"}
            </p>
          </Col>
          {/* 3 */}
          <Col></Col>
          {/* 4 */}
          <Col md={2}></Col>
          {/* 5 */}
          <Col>
            <p className="card-text">Net Payment</p>
          </Col>
          {/* 6 */}
          <Col>
            <p className="card-text">-</p>
          </Col>
          {/* 7 */}
          <Col>
            <p className="card-text">{currency_code}</p>
          </Col>
          {/* 8 */}
          <Col md={2}>
            {(invoiceDetail?.id && !isAdminApEdit) ||
            (isAdminApEdit && invoiceDetail?.approval_status_id === "33") ? (
              <>
                <p className="card-text">
                  {invoiceDetail?.net_payment
                    ? changeUStoID(replaceToComma(invoiceDetail?.net_payment))
                    : "0"}
                </p>
              </>
            ) : (
              <>
                <p className="card-text">
                  {getValues ? getValues("net_payment") : "0"}
                </p>
              </>
            )}
          </Col>
        </Row>
        {/*  <Row>
          <div style={{ borderTop: "1px dotted rgba(50, 49, 49, 0.13)" }}></div>
        </Row> */}
        {/* DIFFERENCE */}
        <Row>
          {/* 1 */}
          <Col></Col>
          {/* 2 */}
          <Col md={2}></Col>
          {/* 3 */}
          <Col></Col>
          {/* 4 */}
          <Col md={2}></Col>
          {/* 5 */}
          <Col>
            <p className="card-text">Difference</p>
          </Col>
          {/* 6 */}
          <Col>
            <p className="card-text">-</p>
          </Col>
          {/* 7 */}
          <Col>
            <p className="card-text">{currency_code}</p>
          </Col>
          {/* 8 */}
          <Col md={2}>
            {invoiceDetail?.id && !isAdminApEdit ? (
              <>
                <p className="card-text">
                  {changeUStoID(
                    replaceToComma(
                      (
                        parseFloat(invoiceDetail?.dpp || 0) +
                        parseFloat(invoiceDetail?.vat_amount || 0) -
                        (parseFloat(invoiceDetail?.net_payment || 0) +
                          parseFloat(invoiceDetail?.wht_amount || 0)) +
                        totalOther
                      ).toFixed(2)
                    )
                  )}
                </p>
              </>
            ) : (
              <>
                <p className="card-text">
                  {changeUStoID(
                    replaceToComma(
                      (getValues
                        ? parseFloat(dpp) +
                          parseFloat(
                            replaceToDot(
                              removeDot(getValues("vat_amount") || "0")
                            )
                          ) -
                          (parseFloat(
                            replaceToDot(
                              removeDot(getValues("wht_amount") || "0")
                            )
                          ) +
                            parseFloat(
                              replaceToDot(
                                removeDot(getValues("net_payment") || "0")
                              )
                            )) +
                          totalOther
                        : 0
                      ).toFixed(2)
                    )
                  )}
                </p>
              </>
            )}
          </Col>
        </Row>
        {/* {parseFloat(dpp)
        }
        <br />
        {parseFloat(
          replaceToDot(removeDot(getValues("vat_amount") || "0"))
        )}
        <br />
        {dpp * wht?.value / 100}
        <br />
        {typeof (dpp * wht?.value / 100)}
        <br />
        {parseFloat(
          replaceToDot(removeDot(getValues("net_payment") || "0"))
        )} */}
      </div>
      {/* !isAdminApEdit && (
        <div
          style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
          className="mx-3 mb-3"
        >
          <Button
            onClick={() => {
              // setModalType("approve");
              // setOpenModal(true);
              navigate("/invoice/edit", { state: { isAdminApEdit: true } });
            }}
            className="vale-button d-flex align-items-center justify-content-center"
          >
            <p className="m-0">Edit</p>
            <Edit className="ms-1" />
          </Button>
        </div>
      ) */}
    </div>
  );
};

export default TaxSimulation;

const OtherExp = ({
  invoiceDetail,
  otherData,
  setOtherData,
  currency_code,
}: any) => {
  const { t } = useTranslation();

  return (
    otherData?.length &&
    otherData.map((el: any, index: any) => {
      return (
        <>
          {/* VAT */}
          <Row
            style={{ borderBottom: "1px dotted rgba(50, 49, 49, 0.13)" }}
            key={index}
          >
            {/* 1 */}
            {/* invoiceDetail?.id ? (
              <Col>
                <p className="card-text">{invoiceDetail?.vat?.gl_account}</p>
              </Col>
            ) : (
              <Col>
                <p className="card-text">{vat?.gl_account}</p>
              </Col>
            ) */}
            {}
            <Col>
              <p className="card-text">{el.gl?.gl_code}</p>
            </Col>
            {/* 2 */}
            <Col md={2}>
              {/* invoiceDetail?.id ? (
                <p className="card-text">
                  {invoiceDetail?.vat?.gl?.gl_account}
                </p>
              ) : (
                <p className="card-text">{vat?.gl_account}</p>
              ) */}
              {<p className="card-text">{el.gl?.gl_account}</p>}
            </Col>
            {/* 3 */}
            <Col>
              {
                <p className="card-text">
                  {userCurrentCompany?.company_initial === "VEI"
                    ? el.costcenter?.code
                    : el.wbs?.wbs_code}
                </p>
              }
            </Col>
            {/* 4 */}
            <Col md={2}>
              {
                <p className="card-text">
                  {userCurrentCompany?.company_initial === "VEI"
                    ? el.costcenter?.names
                    : el.wbs?.wbs_name}
                </p>
              }
            </Col>
            {/* 5 */}
            <Col>
              <p className="card-text">{t("Other Adjustment")}:</p>
            </Col>
            {/* 6 */}
            {/* invoiceDetail?.id && !isAdminApEdit ? (
              <Col>
                <p className="card-text">{invoiceDetail?.vat?.wht_rate}</p>
              </Col>
            ) : (
              <Col>
                <p className="card-text">{vat?.value}</p>
              </Col>
            ) */}
            <Col>
              <p className="card-text">-</p>
            </Col>
            {/* 7 */}
            <Col>
              <p className="card-text">{currency_code}</p>
            </Col>
            {/* 8 */}
            <Col md={2} className="position-relative">
              {/* invoiceDetail?.id && !isAdminApEdit ? (
                <>
                  <p className="card-text">
                    {invoiceDetail?.vat_amount &&
                      changeUStoID(
                        replaceToComma(String(invoiceDetail.vat_amount))
                      )}
                  </p>
                </>
              ) : (
                <>
                  <p className="card-text">
                    {getValues ? getValues("vat_amount") : ""}
                  </p>
                </>
               )*/}
              {(el.debit_or_credit === "C" ||
                el.debit_or_credit?.value === "C") && (
                <span className="position-absolute" style={{ left: 0 }}>
                  -
                </span>
              )}
              <p className="card-text">
                {el?.amount
                  ? !el?.amount.includes(",")
                    ? changeUStoID(replaceToComma(el?.amount))
                    : el?.amount
                  : ""}
              </p>
            </Col>
          </Row>
        </>
      );
    })
  );
};
