import DetailItem2 from "./DetailItem2";

const DetailList2 = ({ items }: any) => {
  return (
    <>
      {items.map((item: any, index: any) => (
        <DetailItem2 title={item.title} value={item.value} file={item.file} key={index} url={item.url} canDownload={item.canDownload} />
      ))}
    </>
  );
};

export default DetailList2;
