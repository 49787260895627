import { ApexOptions } from "apexcharts";
import { ApexLinearChartData } from "./data";
import KpiPslChart from "./KpiPslChart ";
import { useState } from "react";

interface MultipleYaxisChartProps {
  multiYaxisChartData: ApexLinearChartData;
  showLoader?: boolean;
}

const KpiPslChartStm = ( {
  data, categories, title = 'KPI INVOICE PAYMENT SERVICE LEVEL PT SUMBAWA TIMUR MINING'
}: any
  // {
// }: MultipleYaxisChartProps
) => {
  // console.log(data)
  // const multiYaxisChartData: ApexLinearChartData = data ? data : {
  //   data1: [306, 270, 250, 215, 225, 278, 308, 246,],
  //   data2: [62, 90, 90, 94, 91, 95, 97, 95],
  //   data3: [90, 90, 90, 90, 90, 90, 90, 90, 90 , 90, 90, 90],
  //   // numberOfPaidInvoice: [306, 270, 250, 215, 225, 278, 308, 246,],
  //   // onTimePayment: [62, 90, 90, 94, 91, 95, 97, 95],
  //   // target: [90, 90, 90, 90, 90, 90, 90, 90, 90 , 90, 90, 90],
  // };
  const multiYaxisChartData: ApexLinearChartData = data ? data : {
    data1: [],
    data2: [],
    data3: [90, 90, 90, 90, 90, 90, 90, 90, 90 , 90, 90, 90],
    // numberOfPaidInvoice: [306, 270, 250, 215, 225, 278, 308, 246,],
    // onTimePayment: [62, 90, 90, 94, 91, 95, 97, 95],
    // target: [90, 90, 90, 90, 90, 90, 90, 90, 90 , 90, 90, 90],
  };

  const [isChatInitilized, setIsChatInitilized] = useState<boolean>(true)
  
  const options: ApexOptions = {
    chart: {
      stacked: false,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -40,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    stroke: {
      width: [0, 4, 3],
      dashArray: [0, 0, 2],
    },
    // markers: {
      // size: 5,
      
    // },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2],
      formatter: function (val, opts) {
        // console.log(val)
        // console.log(opts)
        return opts.seriesIndex !== 0 ? val + "%" : val + ''
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
        dataLabels: {
          position: 'center'
        }
      },
    },
    xaxis: {
      categories: categories ? categories : [
        "Jan-23",
        "Feb-23",
        "Mar-23",
        "Apr-23",
        "May-23",
        "Jun-23",
        "Jul-23",
        "Aug-23",
        "Sep-23",
        "Oct-23",
        "Nov-23",
        "Des-23",
      ],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#675db7",
        },
        labels: {
          style: {
            colors: ["#675db7"],
          },
        },
        title: {
          text: "NUMBER OF PAID INVOICE",
        },
      },

      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#23b397",
        },
        tickAmount : 2,
        labels: {
          style: {
            colors: ["#23b397"],
          },
          // offsetX: 10,
          formatter: (value) => value.toFixed(0) +'%',
        },
        title: {
          text: "% ON TIME",
        },
        max: 100,
      },
      {
        show: false,
        max: 100
      },
    ],
    tooltip: {
      followCursor: true,
      // y: {
      //   formatter: function (y) {
      //     if (typeof y !== "undefined") {
      //       return y + " thousand crores";
      //     }
      //     return y;
      //   },
      // },
    },
    grid: {
      borderColor: "#f1f3fa",
    },
    legend: {
      offsetY: 7,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: {
            show: false,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Number of Invoice STM (Qty)",
      type: "column",
      data: multiYaxisChartData.data1 || [],
    },
    {
      name: "STM Payment Service Level",
      type: "line",
      data: multiYaxisChartData.data2 || [],
    },
    {
      name: "Planned Service Level",
      type: "line",
      data: multiYaxisChartData.data3 || [],
    },
  ];

  

  return <KpiPslChart series={series} options={options} title={title} showLoader={!isChatInitilized} />
}

export default KpiPslChartStm