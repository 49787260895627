import { toast } from "react-toastify";
import { API_URI } from "../../../constants";
import axios from "axios";

import { getUserVendorData } from "../../../helpers/getUserVendor";
import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";
import { checkNotificationNumber } from "../../../helpers/utils2";

const { vendor_code } = getUserVendorData();
const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

export const getDataById = async ({
  id,
  setData,
  setIsError,
  fetchContract,
  setLoading,
  fetchPo,
  fetchDepartment,
  setValue,
  set_net_payment,
  setFlagToRefreshHistory,
  translate,
}: any) => {
  let t = (a: any) => a;
  if (translate) t = (a: any) => translate(a);
  // toastRef.current = toast.loading("Loading.")
  fetch(API_URI + `bast/${id}`, {
    // fetch(API_URI + `bast-clone/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          .access_token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log(data, "ALLRESPONSEDATA");
      if (data && data.success) {
        if (setFlagToRefreshHistory)
          setFlagToRefreshHistory((prev: any) => prev + 1);
        if (data.data) {
          // console.log(data.data, "ONLYDATA");
          setData((prev: any) => ({ ...prev, ...data.data }));
          setIsError(false);
          if (fetchContract) {
            fetchContract({ setLoading, setData }).catch((e: any) => {
              console.log(e, "ERR FETCH CONTRACT");
            });
          }
          if (fetchPo) {
            fetchPo({ setLoading, setData }).catch((e: any) => {
              console.log(e, "ERR FETCH CONTRACT");
            });
          }
          if (fetchDepartment) {
            fetchDepartment({ setLoading, setData }).catch((e: any) => {
              console.log(e, "ERR FETCH DEPARTMENT");
            });
          }
          if (setValue && set_net_payment) {
            setValue("net_payment", data.data.grand_total);
            set_net_payment(data.data.grand_total);
          }
        }
      } else {
        console.log(data.message);
        setIsError(true);
        toast.warn(t("Failed getting data"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      setIsError(true);
      toast.warn(t("Failed getting data"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    .finally(() => {
      checkNotificationNumber(document);
    });
};

export const fetchContract = async ({ setLoading, setData }: any) => {
  setLoading(true);
  try {
    const res = await axios({
      // url: "/contract-list",
      url: "/contract/department",
      method: "POST",
      data: {
        pagination: {
          pageIndex: 0,
          pageSize: 10000,
        },
        columnFilters: vendor_code
          ? [
              { id: "vendor_code", value: vendor_code },
              {
                id: "company",
                value: userCurrentCompany?.company_initial,
              },
            ]
          : [
              {
                id: "company",
                value: userCurrentCompany?.company_initial,
              },
            ],
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer " +
          JSON.parse(sessionStorage.getItem("shreyu_user") as string)
            .access_token,
      },
    });
    // console.log(res.data.data.data, "DATA CONTRACT");
    // const options: any = await res?.data?.data?.data?.map((el: any) => {
    //   console.log(el)
    //   const out = {
    //     ...el,
    //     contract_sap: el.agreement_code,
    //     value: el.code,
    //     label: el.code,
    //   }
    //   out.contract_sap = el.agreement_code
    //   console.log(out)
    //   return out;
    // });
    const options: any = [];
    for (let i = 0; i < res.data.data.data.length; i++) {
      const a = res.data.data.data[i];
      const contract_sap1 = a.agreement_code;
      // console.log(contract_sap1);
      options.push({
        ...a,
        contract_sap: contract_sap1,
        value: a.code,
        label: a.code,
      });
    }
    // console.log(options);
    setData((prev: any): any => {
      const name = options.find((el: any) => el.value === prev.contract_no);
      // console.log(name);
      const contractNo: any = {
        name,
      };
      // delete contract_no.contract_sap;
      // delete contract_no.name.contract_sap
      // console.log(contract_no, "CONNONIOJOI")
      contractNo.options = options;
      contractNo.isDisabled = false;
      // console.log(contractNo);
      return {
        ...prev,
        contractNo,
      };
    });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const fetchPo = async ({ setLoading, setData }: any) => {
  setLoading(true);
  const res = await axios({
    // url: "/contract-list",
    url: "/purchase-order/department",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          .access_token,
    },
    data: {
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
      columnFilters: [
        { id: "vendor_code", value: vendor_code },
        {
          id: "company",
          value: userCurrentCompany?.company_initial,
        },
      ],
    },
  });
  // console.log(res);
  // console.log(res?.data?.data, "DATA PO");
  if (res?.data?.data?.data) {
    // console.log(res?.data?.data.data, "DATA CONTRACT");

    const options = res?.data?.data?.data?.map((el: any) => {
      return {
        ...el,
        value: el.po_onthespot,
        label: el.po_onthespot,
      };
    });
    setData((prev: any): any => {
      const a = JSON.parse(JSON.stringify(prev));
      return {
        ...a,
        poNo: {
          isDisabled: false,
          options,
        },
      };
    });
    setLoading(false);
  } else {
    // toast.info("No po data");
  }
};

export const fetchDepartment = async ({ setLoading, setData }: any) => {
  setLoading(true);
  const res = await axios({
    // url: "/contract-list",
    url: "/department-list",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          .access_token,
    },
    data: {
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
      columnFilters: [
        // { id: "vendor_code", value: vendor_code },
        {
          id: "company",
          value: userCurrentCompany?.company_initial,
        },
      ],
    },
  });
  // console.log(res);
  // console.log(res?.data?.data, "DATA department");
  if (res?.data?.data?.data) {
    // console.log(res?.data?.data.data, "DATA CONTRACT");

    const options = res?.data?.data?.data
      ?.map((el: any) => {
        return {
          ...el,
          value: el.id,
          label: el.name,
        };
      })
      ?.filter((el: any) => {
        const isRemove =
          el.name === "Project Huu" ||
          el.name === "Corporate Marketing" ||
          el.name === "Senior Surveyor" ||
          el.name === "External Affairs & Sustainability" ||
          el.name === "Sustainability & External Affair" ||
          el.name === "Sustainability & External Affairs" ||
          el.name === "General Affair" ||
          el.name === "General Affairs" ||
          el.name === "Corporate Affair" ||
          el.name === "Corporate Affairs" ||
          el.name === "External Affairs & Sustainability";
        return !isRemove;
      });
    setData((prev: any): any => {
      const a = JSON.parse(JSON.stringify(prev));
      return {
        ...a,
        departments: {
          isDisabled: false,
          options,
        },
      };
    });
    setLoading(false);
  } else {
    // toast.info("No po data");
  }
};
