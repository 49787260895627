import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import axios from "axios";
import { useState } from "react";
  import { Button, Form } from "react-bootstrap";
  import { toast } from "react-toastify";
import { setApprovalAPI } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
  
  const ConfirmationModal2 = ({ open, setOpen, modalType, bastStatus, getData, isNavigate=false, navigateUrl="" }: any) => {
    // console.log({ open, setOpen, modalType, bastStatus }, 'CONFIRMATION MODAL PROPS')
    const [reasonMsg, setReasonMSG] = useState("");
    const [load, setLoad] = useState(false);
    const id = bastStatus?.id
    const approval_status_id = bastStatus?.approval_status_id
    const vendor_code = bastStatus?.vendor?.vendor_code
    const title =
      modalType === "delete"
        ? "Deletion"
        : modalType === "approve"
        ? "Approval"
        : modalType === "reject"
        ? "Rejection"
        : "";
    const navigate = useNavigate();
    const updateStatusApproval = async (obj: any) => {
      let {id, approval_status_id, approved, reasonMsg, vendor_code} = obj
      console.log(obj)
    //   approval_status_id = approval_status_id
    setLoad(true)
      try {
        setApprovalAPI([{
            id: [id],
            approval_status_id: [approval_status_id],
            approved,
            reason: reasonMsg,
            approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
            vendor_code: [vendor_code]
          }]).then(() => {
            if(isNavigate && navigateUrl) {
              setLoad(false)
              navigate(navigateUrl);  
            } else {
              // setLoad(false)
              getData()
            }
            setLoad(false)
            navigate(`/sap/special-payment-list`);
            toast.success("Update Success.")
          }).catch(() => {
            setLoad(false)
            toast.error("Failed to Update Special Payment Status!")
          })
      } catch (error) {
        setLoad(false)
        toast.error("Failed to Update Special Payment Status!")
      } finally {
        setOpen(false)
        setLoad(false)
      }
    }
  
  
    return (
      <>
      <Loading loading={load} />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setOpen(false);
          }
        }}
        className="modal-approval-confirmation-class"
      >
        <DialogTitle>{title} Confirmation</DialogTitle>
        <DialogContent style={{ width: "100%", overflow: "hidden" }}>
          {modalType === "reject" ? (
            <div style={{ width: "100%" }}>
              <p>Please add your rejection reason.</p>
              <Form>
                <Form.Control 
                    value={reasonMsg}
                    onChange={(e) => {
                        setReasonMSG(e.target.value)
                        // console.log(e.target.value)
                    }}
                    as="textarea"
                    rows={8}
                />
              </Form>
            </div>
          ) : (
            <p>Are you sure you want to {modalType}?</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-secondary"
            onClick={() => {
            setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            className="vale-button"
            // aria-disabled={load}
            onClick={() => {
              if (modalType === "approve") {
                const approved = true
                updateStatusApproval({id, approval_status_id, approved, reasonMsg, vendor_code})
              } else if (modalType === 'reject') {
                const approved = false
                updateStatusApproval({id, approval_status_id, approved, reasonMsg, vendor_code})
              }
            }}
            disabled={((modalType === 'reject' && reasonMsg === "") || load) ? true : false}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </>
    );
  };
  
  // const Alert = () => {
  //   return (
  //     <div
  //       role="alert"
  //       className="fade alert alert-danger alert-dismissible show"
  //     >
  //       <button
  //         type="button"
  //         className="btn-close"
  //         onClick={() => {
  //           // setShowAlert(false);
  //         }}
  //         aria-label="Close alert"
  //       ></button>
  //       A simple danger alert—check it out!
  //     </div>
  //   );
  // };
  
  export default ConfirmationModal2;
  