import React, { useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import DetailList from "../../../components/MyComponents/Detail/DetailList";
import logoVale from "../../../assets/images/stm-logo.png";
// import logoVale from "../../../assets/images/logo-dashboard.png";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {history} from "../historyData";
import HistoryComponent from "../../../components/MyComponents/History/HistoryComponent";
import PaymentAdvice from "../../../components/MyComponents/PaymentAdvice/PaymentAdvice";

type ExpenditureColumn = {
    date: string;
    description: string;
    justification: string;
    wbs: string;
    costElement: string;
    curr: string;
    amount: string;
    currRate: string;
    amountInIdr: string;
  };

const dataView = {
  "Company": "PT.STM",
  "Advanced / Settlement": "Settlement",
  "Employee Name": "Dwayne a",
  "Employee ID": "10400111",
  "Department": "Corporate IT",
  "Position": "IT Analyst",
  "Have Cash Advance": "Yes",
  "Previous Advanced Form": "CA23-STM-AUG-001",
  "Doc No":"EC23-STM-SEP-001",
  "Doc Date":new Date().getDate() +"/"+ new Date().getMonth() + "/" + new Date().getFullYear(),
  "Starting Date": "18 July 2023",
  "Ending Date":"19 July 2023",
  "Travel Route": "",
  "Kind Of Transport": "",
  "Reason For Travel Advance": "ke site",
  "Reason File": "reason.pdf",
  "Reason for other advance": "",
  "Cash Advance": "IDR 50.000.000",
  "Total Expense Claim": "IDR 100.000.000",
  "Difference": "IDR 50.000.000",
  "Company to Pay Employee": 'IDR 0',
  "Return To Company": "IDR 50.000.000"
  }
const changeIntoTitleValue = (data:any) => {
    const a = Object.entries(data);
    console.log(a)
    const b = []
    for (const c of a) {
        b.push({
            title: c[0],
            value: [c[1]]
        })
    }
    return b
}

const ViewClaimFormTreasury = () => {
const items = changeIntoTitleValue(dataView);
const [expenditureData, setExpenditureData] = useState([
    {
      date: "19/07/2023",
      description: "Other expense",
      justification: "Services",
      wbs: "VEI employee",
      costElement: "9090909",
      curr: "IDR",
      amount: "100.000",
      currRate: "",
      amountInIdr: "100.000",
    },
  ]);
  const columnExpenditureMRT = useMemo<MRT_ColumnDef<ExpenditureColumn>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date (dd/mm/yyyy)",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 180,
      },
      {
        accessorKey: "description",
        header: "Description (Type of expense)",
        size: 180,
      },
      {
        accessorKey: "justification",
        header: "Justification (Detail of expenditure)",
        size: 180,
      },
      {
        accessorKey: "wbs",
        header: "WBS (STM) / Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costElement",
        header: "Cost Element",
        size: 180,
      },
      {
        accessorKey: "curr",
        header: "Cur",
        size: 180,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 300,
      },
      {
        accessorKey: "currRate",
        header: "Currency Rate",
        size: 180,
      },
      {
        accessorKey: "amountInIdr",
        header: "Amount In IDR",
        size: 300,
      },
    ],
    []
  );
return (
  <div className="card mb-12">
    <div className="card-header bg-vale">Detail Claim Non CC</div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      <img src={logoVale} height="100em" alt="" />
      <h5>Claim Non Credit Card</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          <DetailList items={items} />
        </Card.Body>
        <MaterialReactTable
          columns={columnExpenditureMRT}
          data={expenditureData}
          enablePagination={false}
        />
      </Card>
    </div>
    <PaymentAdvice />
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">Employee Claim History</p>
      </div>
      <HistoryComponent data={history} />
    </div>
  </div>
);
    }
export default ViewClaimFormTreasury;