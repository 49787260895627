// components
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const IASAdminAPSpecial = ({ pageTitle, logo, sheetNo, data, edit, handleClick }: any) => {
  const {
    vendorName,
    invoiceNo,
    paymentDesc,
    invoiceDate,
    checkList,
    receivedDate,
    receivedBy,
    deliveredBy,
    wbsNo,
    glNo,
    poNo,
    migoSes,
    miro,
    paymentNo,
    userDeptHead,
    financeStaff,
    curr,
    financeHead,
    poaDir,
    dpp,
    vat,
    wht,
    netPayment,
    approval,
    notes,
  } = data;

  useEffect(() => {
    console.log(checkList)
  },[checkList])

  return (
    <div className="ias-container">
      <Card className="mt-3 pb-3">
        <div className="card-header bg-vale">{pageTitle}</div>
        <Card.Body className="py-0">
          <div className="d-flex flex-column align-items-center mt-2 mb-2">
            <img src={logo} height="40em" alt="" />
            <h5>INVOICE APPROVAL & SAP FORM</h5>
          </div>
          {/* <div className="mb-2">
            <p className="mb-0 ps-5">
              Sheet No.
              <span className="ms-5 mb-0">{sheetNo}</span>
            </p>
          </div> */}
          <Card className="px-1 pb-2 pt-1">
            <IasTable1
              data={[
                {
                  title: "Vendor Name",
                  value1: vendorName?.value1,
                  value2: vendorName?.value2,
                },
                {
                  title: "Invoice No.",
                  value1: invoiceNo?.value1,
                  value2: invoiceNo?.value2,
                },
                {
                  title: "Payment Description",
                  value1: paymentDesc?.value1,
                  value2: paymentDesc?.value2,
                },
                {
                  title: "Invoice Date",
                  value1: invoiceDate?.value1,
                  value2: invoiceDate?.value2,
                },
                {
                  title: "INVOICE CHECKLIST",
                },
              ]}
            />
            <IasTable2 data={checkList} edit={edit} handleClick={handleClick}/>
          </Card>
        </Card.Body>

        <table className="ias-table-3">
          <tr>
            <th>Received Date</th>
            <th>Received By</th>
            <th>Delivered By</th>
          </tr>
          <tr>
            <td>{receivedDate}</td>
            <td>{receivedBy}</td>
            <td>{deliveredBy}</td>
          </tr>
          <tr>
            <th>WBS No. / Cost Center</th>
            <th>GL No.</th>
            <th>PO No.</th>
          </tr>
          <tr>
            <td>{wbsNo}</td>
            <td>
              <p>{glNo}</p>
            </td>
            <td>{poNo}</td>
          </tr>
          <tr>
            <th>SES / MIGO No.</th>
            <th>MIRO/FV60 No.</th>
            <th>Payment No.</th>
          </tr>
          <tr>
            <td>{migoSes}</td>
            <td>{miro}</td>
            <td>{paymentNo}</td>
          </tr>
        </table>
        <table className="ias-table-3-1">
          <tr>
            <th>User Dept. Head</th>
            <th>Finance Staff</th>
            <th>Finance Head</th>
            <th>PoA Director</th>
          </tr>
          <tr>
            <td>{userDeptHead}</td>
            <td>{financeStaff}</td>
            <td>{financeHead}</td>
            <td>{poaDir}</td>
          </tr>
        </table>

        <div className="container-ias-table">
          <table className="ias-table-4">
            <tr>
              <th colSpan={2}>Description</th>
              <th>%</th>
              <th>Curr.</th>
              <th>Amount</th>
            </tr>
            <tr>
              <td colSpan={2}>DPP</td>
              <td></td>
              <td>{curr}</td>
              <td>{dpp}</td>
            </tr>
            <tr>
              <td>VAT</td>
              {/* <td>{vat?.percentage}</td> */}
              <td>{vat?.name}</td>
              <td> - </td>
              <td>{curr}</td>
              <td>{vat?.amount}</td>
            </tr>
            <tr>
              <td>WHT</td>
              <td>{wht?.name}</td>
              <td>{wht?.percentage}</td>
              <td>{curr}</td>
              <td>-{wht?.amount}</td>
            </tr>
            <tr>
              <td colSpan={2}>Net Payment</td>
              <td></td>
              <td>{curr}</td>
              <td>{netPayment}</td>
            </tr>
          </table>
          <table className="ias-table-5">
            <tr>
              <th>Approval</th>
            </tr>
            <tr>
              <th>{approval?.[0]?.[0]}</th>
            </tr>
            <tr>
              <td>{approval?.[0]?.[1]}</td>
            </tr>
            <tr>
              <th>{approval?.[1]?.[0]}</th>
            </tr>
            <tr>
              <td>{approval?.[1]?.[1]}</td>
            </tr>
          </table>
        </div>
        <div className="mx-3 mt-2">
          <p className="mb-0">Notes: {notes}</p>
        </div>
      </Card>
    </div>
  );
};

const IasTable1 = ({ data }: any) => {
  return (
    <table className="ias-table-1">
      <colgroup>
        <col span={1} style={{ width: "14%" }} />
        <col span={1} style={{ width: "1%" }} />
        <col span={1} style={{ width: "40%" }} />
        <col span={1} style={{ width: "2%" }} />
      </colgroup>
      {data.map((item: any, index: number) => {
        return (
          <tr key={index}>
            <td className="ias-table-1-title">{item?.title}</td>
            <td className="ias-table-1-colon">:</td>
            <td className="ias-table-1-value-1">{item?.value1 || ""}</td>
            <td className="ias-table-1-value-2">{item?.value2 || ""}</td>
          </tr>
        );
      })}
    </table>
  );
};

const IasTable2 = ({ data, edit, handleClick }: any) => {
  return (
    <table className="ias-table-2">
      {data && data?.map((item: any, index: number) => {
        return <IasTableRow index={index} item={item} edit={edit} handleClick={handleClick}/>;
      })}
    </table>
  );
};

const IasTableRow = ({ index, item, edit, handleClick }: any) => {
  const [isChecked, setIsChecked] = useState(item.checked);
  return (
    <tr key={index}>
      <td className="ias-table-2-title">
        <p>{index + 1}</p>
      </td>
      <td
        className="ias-table-2-check"
        onClick={() => handleClick(index, item)}
      >
        <p>{item.checked && "V"}</p>
      </td>
      <td className="ias-table-2-desc">
        <p>{item?.desc}</p>
      </td>
    </tr>
  );
};

export default IASAdminAPSpecial;
