import { useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { getUserVendorData } from "../../helpers/getUserVendor";
import { CURRENT_COMPANY } from "../../helpers/api/apiCore";
import FormBASTClone from "./FormBASTClone";
import { fetchContractDept } from "./fetch/getContracts";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const formData = {
  bast_no: {
    value: "",
    label: 'BAST No.',
    isDisabled: true,
    type: 'text'
  },
  good_or_services: {
    value: "",
    label: 'Goods or Services',
    isDisabled: false,
    type: 'radio'
  },
  company_sub_id: {
    value: "",
    label: 'Mineral or Geothermal',
    isDisabled: false,
    type: 'radio'
  },
  migo_or_ses: {
    value: "",
    // label: 'Mineral or Geothermal',
    isDisabled: false,
  },
  gl_no: {
    value: "",
    isDisabled: false,
  },
  wbs_no: {
    value: "",
    isDisabled: false,
  },
  coscenter_no: {
    value: "",
    isDisabled: false,
  },
  posting_date: {
    value: "",
    isDisabled: false,
  },
  contract_no: {
    value: "",
    isDisabled: false,
  },
  contract_sap: {
    value: "",
    isDisabled: false,
  },
  po_no: {
    value: "",
    isDisabled: false,
  },
  suplier_name: {
    value: "",
    isDisabled: false,
  },
  delivery_note: {
    value: "",
    isDisabled: false,
  },
  bill_of_leading: {
    value: "",
    isDisabled: false,
  },
  airway_bill: {
    value: "",
    isDisabled: false,
  },
  requested_by: {
    value: "",
    isDisabled: false,
  },
  notes: {
    value: "",
    isDisabled: false,
  },
  bastTableItem: [],
};

const CreateBASTClone = () => {
  const [data, setData] = useState(formData);
  const [loading, setLoading] = useState(false);
  const { vendor_code } = getUserVendorData();

  useEffect(() => {
    fetchContractDept({company:userCurrentCompany?.company_initial, vendor_code, setLoading, newData: (data: any) => {
      setData((prev: any): any => {
        const a = JSON.parse(JSON.stringify(prev));
        return {
          ...a,
          contract_no: {
            ...data.contract_no,
            isDisabled: false,
          },
        };
      });
    }})
  }, []);

  return (
    <FormBASTClone
      formData={data}
      userType="vendor"
      pageTitle="Create BAST"
      loading={loading}
    />
  );
};

export default CreateBASTClone;
