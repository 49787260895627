import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";
import { getMasterData } from "../../../helpers/utils";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

export const getDataGL = () =>
getMasterData("general-ledger-list", {
  columnFilters: [
    {
      id: "is_expense",
      value: "1",
    },
    {
      id: "company",
      value: userCurrentCompany?.company_initial,
    },
  ],
  pagination: {
    pageIndex: 0,
    pageSize: 10000,
  },
  sorting: [],
}).then((data) => {
  const hasil = [];
  // console.log(data, 'GL')
  for (const a of data.data.data) {
    hasil.push({
      ...a,
      key: a.id,
      label: a.gl_code + " - " + a.gl_name,
      value: a.gl_code,
    });
  }
  // console.log(hasil)
  return (hasil);
});

export const getDataWBS = (company_initial: any) =>
getMasterData("wbs-list", {
  columnFilters: [
    {
      id: "company",
      value: company_initial
        // ? company_initial
        // : userCurrentCompany.company_initial,
    },
  ],
  pagination: {
    pageIndex: 0,
    pageSize: 10000,
  },
  sorting: [],
}).then((data) => {
  // console.log(data, 'WBS')
  const hasil = [];
  for (const a of data.data.data) {
    hasil.push({
      ...a,
      key: a.id,
      label: a.wbs_code + " - " + a.wbs_name,
      value: a.wbs_code,
    });
  }
  // console.log(hasil)
  return (hasil);
});

export const getDataCostCenter = (company_initial: any) =>
getMasterData("cost-center-list", {
  columnFilters: [
    {
      id: "company",
      value: company_initial
        // ? company_initial
        // : userCurrentCompany.company_initial,
    },
  ],
  pagination: {
    pageIndex: 0,
    pageSize: 10000,
  },
  sorting: [],
}).then((data) => {
  // console.log(data, 'WBS')
  const hasil = [];
  for (const a of data.data.data) {
    hasil.push({
      ...a,
      key: a.id,
      label: a.code + " - " + a.names,
      value: a.code,
    });
  }
  // console.log(hasil)
  return (hasil);
});