import { Button, Card } from "react-bootstrap";
import DetailList from "../../../components/MyComponents/Detail/DetailList";
import logoVale from "../../../assets/images/stm-logo.png";
// import logoVale from "../../assets/images/logo-dashboard.png";
import {history} from "../historyData";
import HistoryComponent from "../../../components/MyComponents/History/HistoryComponent";
import { Check, Close, Edit } from "@mui/icons-material";
import ConfirmationModal from "../../../components/MyComponents/Modal/ConfirmationModal";
import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TaxSimulation from "../../invoice/components/TaxSimulation";

const dataView = {
  "Special Payment Number": "SPC00001",
  "SAP Company Number": "STM 1492",
  "Geothermal / Mineral": "Geothermal",
  "Justification": "HERTZ Australia",
  "Vendor Number": "SPE002-81",
  "Vendor Name": "FA",
  "Payment Amount": "IDR 50,000,000",
  GL: "5030500001",
  WBS: "R001828.04.08.19",
  "Vat Applicable": "Yes",
  // "Amount Of DPP": "PPH23 2% - 5010400013",
  "Amount Of VAT": "IDR 5,500,000",
  // "Amount Of WHT": "PPH23 2% - 5010300099",
  // "Is All Relevant Paperwork Attach": "Yes",
  "Requested By": "ZULFIKAR",
  "Supporting Document": "invoice.pdf",
  "Reference Number": "",
  "Reference File": "reference.pdf",
  "All Document": "Doc-SPC00001.pdf"
};
const changeIntoTitleValue = (data:any) => {
    const a = Object.entries(data);
    console.log(a)
    const b = []
    for (const c of a) {
        b.push({
            title: c[0],
            value: [c[1]]
        })
    }
    return b
}

const ViewInvoiceSAPAdminAp= () => {
  const [modalType, setModalType] = useState("");
const [openModal, setOpenModal] = useState(false);
const items = changeIntoTitleValue(dataView);
const navigate = useNavigate();
const [vat, setVat] = useState({ value: 11, label: null });
const [wht, setWht] = useState({ value: 0, label: null });
const [dpp, setDpp] = useState(50000000);
return (
  <div className="card mb-12 mt-3">
    <div className="card-header bg-vale">Detail Special Payment</div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      <img src={logoVale} height="100em" alt="" />
      <h5>Special Payment</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          <DetailList items={items} canEdit={true} canApprove={true}/>
        </Card.Body>
      </Card>
    </div>
    <div
      style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
      className="mx-3 mb-3"
    >
      <Button
        onClick={() => {
          navigate(`/sap/special-payment/edit`);
        }}
        className="edit-button d-flex align-items-center justify-content-center"
      >
        <p className="m-0">Edit</p>
        <Edit />
      </Button>
      <Button
        onClick={() => {
          setModalType("reject");
          setOpenModal(true);
        }}
        className="reject-button d-flex align-items-center justify-content-center"
      >
        <p className="m-0">Reject</p>
        <Close />
      </Button>
      <Button
        onClick={() => {
          setModalType("approve");
          setOpenModal(true);
        }}
        className="vale-button d-flex align-items-center justify-content-center"
      >
        <p className="m-0">Approve</p>
        <Check />
      </Button>
    </div>
    <TaxSimulation
      // isAdminApEdit={state.status !== "Waiting Approval Ap Admin"}
      dataState={{ vat, setVat, wht, setWht, dpp, setDpp }}
    />
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">Special Payment History</p>
      </div>
      <HistoryComponent data={history} />
    </div>
  </div>
);
    }
export default ViewInvoiceSAPAdminAp;