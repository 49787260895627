import { useMemo } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';

type InvoiceColumn = {
  company: string;
  settlement: string;
  employeeName: string;
  employeeId: string;
  department: string;
  position: string;
  haveCashAdvance: string;
  docNo: string;
  docDate: string;
  startDate: string;
  endDate: string;
  travelRoute: string;
  kindOfTransport: string;
  reason: string;
  currency: string;
  cashAdvance: string;
  expenseClaim: string;
  difference: string;
  companyToPayEmployee: string;
  paidDate: string;
  status: string;
};

const ReportEmployeeClaim = () => {
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "company",
        header: "Company",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "settlement",
        header: "Settlement",
        size: 80,
      },
      {
        accessorKey: "employeeName",
        header: "Employee Name",
        size: 80,
      },
      {
        accessorKey: "employeeId",
        header: "Employee Id",
        size: 80,
      },
      {
        accessorKey: "department",
        header: "Department",
        size: 80,
      },
      {
        accessorKey: "position",
        header: "Position",
        size: 80,
      },
      {
        accessorKey: "startDate",
        header: "Starting Date",
        size: 80,
      },
      {
        accessorKey: "endDate",
        header: "Ending Date",
        size: 80,
      },
      {
        accessorKey: "travelRoute",
        header: "Travel Route",
        size: 80,
      },
      {
        accessorKey: "kindOfTransport",
        header: "Kind of Transport",
        size: 80,
      },
      {
        accessorKey: "reason",
        header: "Reason for Other Advance",
        size: 80,
      },
      {
        accessorKey: "currency",
        header: "Currency",
        size: 80,
      },
      {
        accessorKey: "cashAdvance",
        header: "Cash Advance",
        size: 80,
      },
      {
        accessorKey: "expenseClaim",
        header: "Total Expence Claim",
        size: 80,
      },
      {
        accessorKey: "difference",
        header: "Difference",
        size: 80,
      },
      {
        accessorKey: "companyToPayEmployee",
        header: "Company To Pay Employee",
        size: 80,
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        // Cell: ({ cell }) => {
        //   const value = String(cell.getValue());
        //   const isIncludeAppr = value.includes("pproval");
        //   return (
        //     <div
        //       className={`invoice-${
        //         isIncludeAppr
        //           ? "waiting-approval"
        //           : value.toLowerCase().split(" ").join("-")
        //       }`}
        //     >
        //       <p>{value}</p>
        //     </div>
        //   );
        // },
      },
    //   {
    //     header: "Action",
    //     accessor: "action",
    //     sort: true,
    //   }
    ],
    []
  );
  const data: InvoiceColumn[] = [
    {
      company: "STM",
      settlement: "Settlement",
      employeeName: "Dwayne a",
      employeeId: "10400111",
      department: "Corporate IT",
      position: "IT Analyst",
      haveCashAdvance: "Yes",
      docNo: "V-00079",
      docDate: "12 July 2023",
      startDate: "",
      endDate: "",
      travelRoute: "",
      kindOfTransport: "",
      reason: "",
      currency: "IDR",
      cashAdvance: "",
      expenseClaim: "150,000",
      difference: "150,000",
      companyToPayEmployee: "150,000",
      paidDate: "23 Juni 2023",
      status: "Paid"
    },
  ]
  const changeCamel = (data: string) => {
    return data.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
  }
  const data2 = (data: InvoiceColumn[]) => {
    let hasil = []
    for (const a of data) {
      const hasilObj:any = {}
      for (const [key, value] of Object.entries(a)) {
        hasilObj[changeCamel(key)] = value
      }
      hasil.push(hasilObj)
    }
    return hasil
  }
  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              // enableSelectAll={true}
              // enableMultiRowSelection={true}
              // enableRowSelection={(row) => row.original.canEdit} 
              // enableEditing
              enableRowNumbers
              enablePinning
              initialState={{ columnPinning: { right: ['status'] } }}
              data={data}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(data2(data));
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                    XLSX.writeFile(workbook, "Export Employee Claim.xlsx");
                  }}
                  className="vale-button"
                >
                  Export Excel
                </Button>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};



export default ReportEmployeeClaim;
