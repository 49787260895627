import { useMemo, useState, useCallback } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTableProps,
} from "material-react-table";
import { Navigate, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

//dummy data
import { records as data } from "./usersData";

type UserColumns = {
  no: number;
  userId: string;
  fullName: string;
  email: string;
  activate: any;
};

const dataSelectionProps: any = {};

const VendorAdminUsers = () => {
  const [rowSelection, setRowSelection] = useState<any>(dataSelectionProps);
  const [propsForm, setPropsForm] = useState<any>(dataSelectionProps);
  const [searchTable, setSearchTable] = useState<string>("");
  const [listUser, setListUser] = useState<any>(data);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleDeleteRow = useCallback(
    (row: MRT_Row<UserColumns>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("name")}`
        )
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      listUser.splice(row.index, 1);
      setListUser([...listUser]);
    },
    [listUser]
  );

  const columnMRT = useMemo<MRT_ColumnDef<UserColumns>[]>(
    () => [
      {
        accessorKey: "no",
        header: "NO",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "userId",
        header: "ID User",
        size: 80,
      },
      {
        accessorKey: "fullName",
        header: "Full Name",
        size: 80,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 80,
      },
      {
        accessorKey: "activate",
        header: "Activate",
        size: 80,
        Cell: (row: any) => {
          return <Button className="vale-button">Activate</Button>
        }
      },
    ],
    []
  );

  const handleSaveRowEdits: MaterialReactTableProps<UserColumns>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      // console.log(exitEditingMode, row, values);
      listUser[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      // console.log(hasil);
      setListUser([...listUser]);
      exitEditingMode(); //required to exit editing mode and close modal
    };

  const handleCancelRowEdits = () => {};

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title2 ">List User</h4>
              <MaterialReactTable
                columns={columnMRT}
                data={listUser}
                renderTopToolbarCustomActions={() => (
                  <Button
                    onClick={() => setCreateModalOpen(true)}
                    className="vale-button"
                  >
                    Create User
                  </Button>
                )}
                enableRowSelection
                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        onClick={() => {
                          // console.log("ROW", row);
                          table.setEditingRow(row);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                editingMode="modal" //default
                // enableColumnOrdering
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                // displayColumnDefOptions={{
                //   "mrt-row-actions": {
                //     muiTableHeadCellProps: {
                //       align: "center",
                //     },
                //     size: 120,
                //   },
                // }}
              />
              <CreateNewUserModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={(data: any) => {
                  setListUser((prev: any) => [
                    ...prev,
                    {
                      no: 3,
                      userId: (
                        <Link
                          to={
                            "/sys-admin/master-data/user-management/users/STM-CN02-13"
                          }
                          state={{
                            fullName: data.fullname,
                            userId: "STM-CN02-14",
                            email: data.email,
                          }}
                        >
                          STM-CN02-13
                        </Link>
                      ),
                      fullName: data.fullname,
                      email: data.email,
                    },
                  ]);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface CreateModalProps {
  // columns: MRT_ColumnDef<UserColumns>[];
  onClose: () => void;
  onSubmit: (data: any) => any;
  open: boolean;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewUserModal = ({
  open,
  onClose,
  onSubmit,
}: CreateModalProps) => {
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit({email, fullname});
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create User Form</DialogTitle>
      <DialogContent>
        <Form.Group className="mb-3" controlId="fullname">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" value={fullname} onChange={(e) => setFullname(e.target.value)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit} >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VendorAdminUsers;
