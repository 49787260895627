import { useMemo, useEffect, useState, useCallback, useRef } from "react";
import { Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  MaterialReactTable,
  // MRT_ColumnDef,
  MRT_Row,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_Cell,
} from "material-react-table";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TableHead,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';
import { API_URI } from "../../constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, VerticalForm } from "../../components";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { changeUStoID, get_header_row, listCompanyOptions, onChangeMoney, removeDot, replaceToComma, replaceToDot, valueOptions } from "../../helpers/utils";

type EmployeeColumn = {
  id?: any;
  house_bank?: string;
  account_id?: string;
  description?: string;
  company?: string;
};

const dataSelectionProps: any = {};
let divRef: any;
const AccountBank = () => {
  const getData = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'account-bank-list', {
      method: "POST",
      body:JSON.stringify({
        columnFilters,
        sorting,
        pagination
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            // b.company = b.company?.company_code ? b.company.company_code : null
            // b.department = b.department?.name ? b.department.name : null
            a.push(b)
          }
          setListEmployee(a);
          setRowCount(data.data.total)
          console.log(a)
          setIsError(false);
        }else{
          setListEmployee([]);
          setRowCount(0)
          // console.log(a)
          setIsError(false);
        }
      }else{
        console.log(data.message);
        setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };
  const getDataDepartement = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'house-bank-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
            
        ],
        "sorting": [
            {
                "id": "id",
                "desc": false
            }
        ],
        "pagination": {
            "pageIndex": 0,
            "pageSize": 10000
        }
    }
    )
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const dir = []
          for(const a of data.data.data) {
            let ketemu = false;
            for(const b of dir) {
                if (b.key === a.house_bank) {
                    ketemu = true
                }
            }
            if (!ketemu) {
                dir.push({
                key: a.house_bank,
                value: a.house_bank,
                label: a.house_bank
                })
            }
          }
          console.log(dir);
          setListDepartment(dir)
          // setRowCount(data.data.total)
          // setIsError(false);
        }
      }else{
        console.log(data.message);
        // setIsError(true);
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // setIsError(true);
    })
    // console.log(response.json());
    // setDataApi(response);
  };
  const handleAddUpdateApi = ((dataCreate: any) => {
    for (const a of dataCreate){
      if(a.company_code ) {
        if (a.company_code === 'STM' || a.company_code === 'VEI') {
          a.company = a.company_code
        }
        delete a.company_code;
      }
      if(a.withholding_tax_type){
        a.wht_type = a.withholding_tax_type
        delete a.withholding_tax_type;
      }
      if(a.withholding_type_name) {
        a.wht_name = a.withholding_type_name
        delete a.withholding_type_name;
      }
      if(a.withholding_tax_code) {
        a.wht_code = a.withholding_tax_code
        delete a.withholding_tax_code;
      }
      if(a.withholding_tax_rate) {
        // a.withholding_tax_rate = a.withholding_tax_rate + '%'
        a.wht_rate = a.withholding_tax_rate
        delete a.withholding_tax_rate;
      }
      delete a.gl
      delete a.deleted_at
      delete a.created_at
      delete a.created_by
      delete a.updated_at
      delete a.updated_by
    }
    fetch(API_URI + 'account-bank', {
      method: "POST",
      body:JSON.stringify(dataCreate)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data && data.success) {
        getData()
        toast.success('Success Saving data', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      } else {
        toast.error('Failed saving data', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed saving data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  })
  const [rowSelection, setRowSelection] = useState<any>(dataSelectionProps);
  const [propsForm, setPropsForm] = useState<any>(dataSelectionProps);
  const [searchTable, setSearchTable] = useState<string>("");
  const [listEmployee, setListEmployee] = useState<any>([]);
  const [listDepartment, setListDepartment] = useState<any>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModal2Open, setCreateModal2Open] = useState(false);
  const [createModal3Open, setCreateModal3Open] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  //table state
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  useEffect(() => {
    console.log('columnFilters', columnFilters)
    console.log('sorting', sorting)
    console.log('globalFilter', globalFilter)
    console.log('pagination', pagination)
    console.log(API_URI + 'department')
    getData();
    getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);
  const navigate = useNavigate();

  const handleDeleteRow = useCallback(
    (row: MRT_Row<EmployeeColumn>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("name")}`
        )
      ) {
        return;
      }
      fetch(API_URI + `account-bank/${row.getValue('id')}`, {
        method: "POST",
        body: JSON.stringify({
          "_method": "DELETE"
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success('Success Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getData()
      })
      .catch(err => {
        console.log(err)
        setIsError(true);
        toast.error('Failed Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    },
    [listEmployee]
  );
  
  const columnMRT = useMemo<MRT_ColumnDef<EmployeeColumn>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        // enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "company",
        header: "Company Code",
        size: 80,
        type: 'select',
        options: listCompanyOptions,
        filterSelectOptions:[{ text: "STM", value: "STM" }, {text: "VEI", value: 'VEI'}],
        filterVariant: 'select'
      },
      {
        accessorKey: "house_bank",
        header: "House Bank",
        // enableColumnOrdering: false,
        size: 220,
      },
      {
        accessorKey: "account_id",
        header: "Account ID",
        // enableColumnOrdering: false,
        size: 220,
      },
      {
        accessorKey: "description",
        header: "Description",
        // enableColumnOrdering: false,
        size: 230,
      },
    ],
    []
  );

  const handleSaveRowEdits: MaterialReactTableProps<EmployeeColumn>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      console.log(exitEditingMode, row, values);
      listEmployee[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      // console.log(hasil);
      setListEmployee([...listEmployee]);
      handleAddUpdateApi([values])
      exitEditingMode(); //required to exit editing mode and close modal
    };

  const handleCancelRowEdits = () => {};
  const [CSVData, setCSVData] = useState<EmployeeColumn[]>([]);
  const [CSVHead, setCSVHead] = useState();
  const [file, setFile] = useState({});
  const [dataModalCreate, setDataModalCreate] = useState({});
  const [dataModalCreateMode, setDataModalCreateMode] = useState("");

  const checkValid = (head: any, jsa: any) => {
    let valid = true;
    for(let a in jsa) {
      for (const b of head) {
        let ketemu = false;
        console.log(b)
        for (let [key, value] of Object.entries(jsa[a])) {
          console.log(b, key)
          if(b === key) {
            if (b === 'withholding_tax_code'){ketemu = true;break;}
            if (b === 'description'){ketemu = true;}
            if(value !== ''){ketemu = true;}
          } else {
            if (b === 'withholding_tax_code'){ketemu = true;}
            if (b === 'description'){ketemu = true;}
          }
        }
        if(!ketemu){
          console.log(`${b} is required!`)
          // jsa[a][b] = `${b} is required!`
          valid = false;
        }
      }
    }
    setIsValid(valid);
    return valid;
  }

  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(e.target.result);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = get_header_row(sheet);
      // console.log(head);
      // console.log(jsa[4])
      const valid = checkValid(head, jsa)
      // setIsValid(checkValid(head, jsa));
      if(valid) {
        handleAddUpdateApi(jsa);
      } else {
        setCSVData(jsa);
        setCSVHead(head);
        setCreateModal2Open(true);
      }
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const onChangeUploadCSV = (e: any) => {
    console.log(e)
    setFile(e);
  }

  const handleSaveCell = (cell: MRT_Cell<EmployeeColumn>, value: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    console.log(value);
    CSVData[cell.row.index][cell.column.id as keyof EmployeeColumn] = value;
    //send/receive api updates here
    setCSVData([...CSVData]); //re-render with new data
    setIsValid(checkValid(CSVHead, CSVData));
  };

  const handleExport = async() => {
    const res = await fetch(
      API_URI + `account-bank-to-excel`,
      {
        method: "POST",
        body:JSON.stringify({
          // columnFilters,
        }),
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(
              sessionStorage.getItem("shreyu_user") as string
            ).access_token,
        },
      }
    );
    const blob: any = await res.blob();
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = 'Account-Bank.xlsx';
    a.click();
  }

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title2 ">List Account Bank</h4>
              <MaterialReactTable
                columns={columnMRT}
                data={listEmployee}
                initialState={{ 
                  density: 'compact',
                  showColumnFilters: true,
                  columnPinning: { 
                    left: ["mrt-row-actions"],
                  } 
                }}
                renderTopToolbarCustomActions={() => (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Button
                      onClick={() => setCreateModalOpen(true)}
                      className="vale-button"
                    >
                      Import Data
                    </Button>
                    <Button
                      onClick={() => handleExport()}
                      className="vale-button"
                    >
                      Export Data
                    </Button>
                    <Button
                      onClick={() => {
                        setDataModalCreate({});
                        setDataModalCreateMode("Create New")
                        setCreateModal3Open(true)}
                      }
                      className="vale-button"
                    >
                      Create Data
                    </Button>
                  </Box>
                )}
                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        onClick={() => {
                          // table.setEditingRow(row);
                          console.log(row.original);
                          setDataModalCreate(row.original)
                          setDataModalCreateMode("Save")
                          setCreateModal3Open(true)
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                editingMode="modal" //default
                enableColumnOrdering
                enableEditing
                enableRowNumbers
                manualFiltering
                manualPagination
                manualSorting
                enableMultiSort={true}
                enableGlobalFilter={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                  columnFilters,
                  globalFilter,
                  // isLoading,
                  pagination,
                  showAlertBanner: isError,
                  // showProgressBars: isRefetching,
                  sorting,
                }}
                // onEditingRowSave={handleSaveRowEdits}
                // onEditingRowCancel={handleCancelRowEdits}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    size: 120,
                  },
                }}
              />
              {createModalOpen &&<CreateNewEmployeeModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={() => submitImport()}
                onFileUpload={(e: any) => onChangeUploadCSV(e)}
              />}
              {createModal2Open &&<ValidateExcelModal
                open={createModal2Open}
                onClose={() => setCreateModal2Open(false)}
                onSubmit={(data) => {
                  for (const a of data) {
                    // a.company = a.company_code
                    delete a.company_code
                  }
                  handleAddUpdateApi(data)
                }}
                onCellEdit={(cell, value) => handleSaveCell(cell, value)}
                isValid={isValid}
                CSV={CSVData!}
                CSVHead={CSVHead!}
                department={listDepartment}
              />}
              {createModal3Open &&
              <CreateNewEmployeeModalSingle
                columns={columnMRT}
                department={listDepartment}
                open={createModal3Open}
                onClose={() => setCreateModal3Open(false)}
                onSubmit={(dataCreate) => {
                  console.log(dataCreate);
                  if(!(dataModalCreateMode === 'Save')) {
                    delete dataCreate.id;
                  }
                  handleAddUpdateApi([dataCreate])
                }}
                data={dataModalCreate}
                mode={dataModalCreateMode}
              />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: () => void;
  onFileUpload: (e: any) => void;
  open: boolean;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewEmployeeModal = ({
  open,
  onClose,
  onSubmit,
  onFileUpload,
}: CreateModalProps) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  const [stmOrVei, setStmOrVei] = useState("stm");
  const templateFile = API_URI  + 'account-bank-export';
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Import Account Bank Data</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
            <a href={templateFile} target="_blank" rel="noopener noreferrer" download>
              <Button>
                  <i className="fas fa-download"/>
                  Download Template
              </Button>
            </a>
            </Col>
        </Row>
        <br></br>
        <Form>
          <Form.Group className="mb-3" controlId="bulkInputEmployee">
            <Form.Label>Attach File</Form.Label>
            <Form.Control ref={divRef} type="file" id="upload_csv_file" onChange={(e) => onFileUpload(e)}/>
            <Form.Text>Upload XSLX File (format .xslx)</Form.Text>
          </Form.Group>
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateModal2Props {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (data: any[]) => void;
  onCellEdit: (cell: any, value: any) => void;
  // onFileUpload: (e: any) => void;
  CSV: any[];
  CSVHead: [];
  open: boolean;
  isValid: boolean;
  department: any;
}

//example of creating a mui dialog modal for creating new rows
export const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  onCellEdit,
  // onFileUpload,
  CSV,
  CSVHead,
  isValid,
  department
}: CreateModal2Props) => {
  const handleSubmit = (data: any[]) => {
    onSubmit(data);
    onClose();
  };
  const [stmOrVei, setStmOrVei] = useState("stm");

  const checkValidation = ((cell:any) => {
    const val = cell.getValue();
    if(val && val !== ''){
      return <div>{cell.getValue() as string}</div>
    } else {
      return <div style={{border: '1px solid red', color:'red'}}>{cell.getValue() as string}<br/>{cell.column.columnDef.header} is required!</div>;
    }
  })

  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
        {
            accessorKey: "company_code",
            header: "Company Code",
            // enableColumnOrdering: false,
            enableEditing: true, //disable editing on this column
            enableSorting: false,
            muiTableBodyCellEditTextFieldProps: {
              select: true, //change to select for a dropdown
              children: listCompanyOptions.map((a) => (
                <MenuItem key={a.label} value={a.label}>
                  {a.label}
                </MenuItem>
              )),
            },
            size: 80,
            Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "house_bank",
        header: "House Bank",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: department.map((a: any) => (
            <MenuItem key={a.label} value={a.label}>
              {a.label}
            </MenuItem>
          )),
        },
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "account_id",
        header: "Account ID",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "description",
        header: "Description",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">Validate Data</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          onBlur: (event) => {
            onCellEdit(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
            Double-Click a Cell to Edit
          </Typography>
        )}
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" 
          onClick={() => {
            for(const a of CSV) {
              a.company = a.company_code
              delete a.company_code
            }
            console.log(CSV);
            handleSubmit(CSV)
          }} 
          disabled={!isValid}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateModalProps3 {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  columns: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  data: any;
  mode:string;
  department: any;
}

export const CreateNewEmployeeModalSingle = ({
  open,
  columns,
  onClose,
  onSubmit,
  data,
  mode,
  department
}: CreateModalProps3) => {
  const defValue = {
    "company": valueOptions(listCompanyOptions, data.company),
    "house_bank": valueOptions(department, data.house_bank),
  }
  
  const columnMRT = useMemo<MRT_ColumnDef<EmployeeColumn>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        // enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        size: 80,
        required: false,
      },
      {
        accessorKey: "company",
        header: "Company Code",
        size: 80,
        value: valueOptions(listCompanyOptions, data.company),
        type: 'select',
        options: listCompanyOptions,
        required: true,
      },
      {
        accessorKey: "house_bank",
        type: 'select',
        value: valueOptions(department, data.house_bank),
        options: department,
        header: "House Bank",
        // enableColumnOrdering: false,
        size: 80,
        required: true,
      },
      {
        accessorKey: "account_id",
        header: "Account ID",
        size: 80,
        required: true,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 80,
        required: false,
      },
    ],
    []
  );
  const initialValue = 
    columnMRT.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any)
  data = {
    ...data,
    company: listCompanyOptions[defValue.company]?.value,
  }
  const [values, setValues] = useState<any>(data)

  const schemaResolver = yupResolver(
    yup.object().shape({
      id: yup.number(),
      company: yup.string().required("Please enter Company"),
      house_bank: yup.string().required("Please enter House Bank"),
      account_id: yup.string().required("Please enter Account ID"),
      description: yup.string().nullable(),
    })
  );
  const methods = useForm<EmployeeColumn>({
    resolver: schemaResolver,
  });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  setValue('company', values.company)
  setValue('house_bank', values.house_bank)
  setValue('account_id', values.account_id)
  setValue('description', values.description)

  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">{mode}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit((d) => console.log(d))}
        >
          <Form.Group as={Row} className="mb-3">
            {columnMRT.map((column: any) => (
            <>
            <Form.Label column md={2} className="mb-3">
              {column.header}
              {column.required ? <span className="text-danger">*</span> : null}
            </Form.Label>
              <Col md={4} className="mb-3">
                {column.type === 'select' ?
                <>
                <Select
                  options={column.options}
                  name={column.accessorKey as string}
                  defaultValue={column.options[column.value]}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={column.readOnly}
                  onChange={(e) => {
                    setValues({ ...values, [column.accessorKey]: e.value })
                    console.log(values, errors);
                  }}
                ></Select>
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  hidden={true}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  // onChange={(e) =>
                  //   setValues({ ...values, [e.target.name]: e.target.value })
                  // }
                  value={values[column.accessorKey as string]}
                  defaultValue={column.options[column.value]?.value}
                />
                </>
                :
                <>
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  onChange={(e) => {
                    if(column.accessorKey ==='rate') {
                      onChangeMoney(values[column.accessorKey as string], e);
                      setValues({ ...values, [e.target.name]: changeUStoID(e.target.value) })
                    } else {
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  }}
                  value={values[column.accessorKey as string]}
                />
                </>
                }
              </Col>
            </>
            ))}
          </Form.Group>
          {/* </Stack> */}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => {
          setValues(initialValue);
          onClose();
        }}>Cancel</Button>
        {/* <Button color="secondary" onClick={handleSubmit} variant="contained"> */}
        {/* <Button onClick={handleSubmit((values)=>console.log(values))}> */}
        <Button onClick={
          handleSubmit(() => {
            console.log(values)
            values.id = data.id
            onSubmit(values)
            onClose()
          })
        }
        >
        {/* <Button variant="primary" type="submit"> */}
          {/* Create New */}
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountBank;
