import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetUserRole } from "../../../hooks";
import { useTranslation } from "react-i18next";

// import { getUserVendorData } from "../../../helpers/getUserVendor"
// const { vendor_code, company_code, vendor_type } = getUserVendorData()

const ConfirmationModalClone = ({
  open,
  setOpen,
  modalType,
  bastStatus,
  getData,
  moveToList,
  deleteId,
  rowId,
  setBastItems,
  bastItemStatus,
  bastItems,
  bulkStatus,
}: any) => {
  // console.log({ open, setOpen, modalType, bastStatus }, 'CONFIRMATION MODAL PROPS')
  const { t } = useTranslation();
  const { userId } = useGetUserRole();
  const id = bastStatus?.id;
  const approval_status_id = bastStatus?.approval_status_id;
  const vendor_code = bastStatus?.vendor_code;
  const title =
    modalType === "delete"
      ? "Deletion"
      : modalType === "approve"
      ? "Approval"
      : modalType === "bulk approve"
      ? "Approval"
      : modalType === "reject"
      ? "Rejection"
      : modalType === "delete item"
      ? "Delete Item"
      : modalType === "approve item"
      ? "Approval Item"
      : modalType === "reject item"
      ? "Rejection Item"
      : "";
  const [reason, setReason] = useState("");

  const disabled =
    (approval_status_id === "5" && reason.length < 1) ||
    (bastItemStatus?.checking_status === 2 && reason.length < 1);
  let eButton: any

  const navigate = useNavigate();

  const bulkUpdateStatusApproval = async (bulkStatus: any) => {
    // console.log(bulkStatus);
    // return
    try {
      const response: any = await axios({
        url: "/bast-approval-clone",
        method: "POST",
        data: bulkStatus,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        getData();
        // setReason("");
        toast.success("Update Success.");
      } else {
        toast.error("Failed to Update BAST Status!");
      }
    } catch (error) {
      toast.error("Failed to Update BAST Status!");
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const updateStatusApproval = async (obj: any) => {
    let { id, approval_status_id, comment } = obj;
    const data = {
      bast_id: id,
      approval_status_id,
      comment,
      module_id: 1,
      user_id: userId,
      vendor_code,
    };
    if (!(approval_status_id === "5")) delete data.comment;
    // console.log(data);
    try {
      const response: any = await axios({
        url: "/bast-approval-clone",
        method: "POST",
        data: [data],
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        if (moveToList) {
          navigate(-1);
        } else if (getData) {
          getData();
        }
        setReason("");
        toast.success("Update Success.");
      } else {
        toast.error("Failed to Update BAST Status!");
      }
    } catch (error) {
      toast.error("Failed to Update BAST Status!");
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const deleteBast = async (obj: any) => {
    let { id } = obj;
    try {
      const response: any = await axios({
        url: "/bast/" + id,
        method: "POST",
        data: {
          _method: "DELETE",
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        if (moveToList) {
          navigate(-1);
        } else {
          getData();
        }
        setReason("");
        toast.success("Update Success.");
      } else {
        toast.error("Failed to Update BAST Status!");
      }
    } catch (error) {
      toast.error("Failed to Update BAST Status!");
    } finally {
      setOpen(false);
    }
  };
  const deleteBastItem = async (obj: any) => {
    // let { id } = obj;
    try {
      const response: any = await axios({
        url: "/bastdetail/" + deleteId,
        method: "POST",
        data: {
          _method: "DELETE",
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        setBastItems((prev: any[]): any => {
          prev.splice(rowId, 1);
          return [...prev];
        });
        setReason("");
        // toast.success("Update Success.");
      } else {
        toast.error("Failed to Update BAST Status!");
      }
    } catch (error) {
      toast.error("Failed to Update BAST Status!");
    } finally {
      setOpen(false);
    }
  };
  const approveBastItem = async () => {
    // let { id } = obj;
    const data: any = [];
    for (let a of bastItems) {
      const b: any = {
        id: a.id,
        checking_status: a.checking_status,
        // "reason ": "test",
        checking_by: a.checking_by,
      };
      if (a.reason) b.reason = a.reason;
      data.push(b);
    }
    // const shouldUpdateItemStatus = data.some((el: any) => el.checking_status)
    // console.log(data)
    // return
    try {
      let response: any;
      // if (shouldUpdateItemStatus) {
      response = await axios({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
        url: "/bast/warehouse",
        method: "POST",
        // data: [{ ...bastItemStatus }],
        data,
      });
      // }
      const response1: any = await axios({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
        url: "/bast-approval-clone",
        method: "POST",
        data: [
          {
            bast_id: id,
            approval_status_id: 2,
            comment: reason,
            module_id: 1,
            user_id: userId,
            vendor_code,
          },
        ],
      });

      // console.log(response, "RES approveBastItem");
      // console.log(response1, "RES");
      if (response?.data?.success || response1?.data?.success) {
        setReason("");
        getData();
        toast.success("Update Success.");
      } else {
        toast.error("Failed to approve BAST item");
      }
    } catch (error) {
      toast.error("Failed to approve BAST item");
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const rejectBastItem = async () => {
    // let { id } = obj;
    const data: any = [];
    for (let a of bastItems) {
      const b: any = {
        id: a.id,
        checking_status: a.checking_status,
        // "reason ": "test",
        checking_by: a.checking_by,
      };
      if (a.reason) b.reason = a.reason;
      data.push(b);
    }
    // console.log(bastItems)
    // console.log(data)
    // return
    try {
      const response: any = await axios({
        url: "/bast/warehouse",
        method: "POST",
        // data: [{ ...bastItemStatus, reason }],
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES rejectBastItem");
      if (response?.data?.success) {
        setReason("");
        getData();
        toast.success("Update Success.");
      } else {
        toast.error("Failed to reject BAST item!");
      }
    } catch (error) {
      toast.error("Failed to reject BAST item!");
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          setOpen(false);
        }
      }}
    >
      <DialogTitle>{title} Confirmation</DialogTitle>
      <DialogContent>
        {modalType === "reject" ? (
          <div>
            <p>Please add your rejection reason.</p>
            <Form>
              <Form.Control
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <Form.Text style={{ color: "red" }}>{t("*Required")}</Form.Text>
            </Form>
          </div>
        ) : modalType === "reject item" ? (
          <div>
            <p>Please add your rejection reason.</p>
            <Form>
              <Form.Control
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <Form.Text style={{ color: "red" }}>{t("*Required")}</Form.Text>
            </Form>
          </div>
        ) : (
          <p>Are you sure you want to {modalType}?</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          No
        </Button>
        <Button
          className="vale-button"
          onClick={(e: any) => {
            eButton = e.target 
            eButton.disabled = true;
            if (modalType === "bulk approve") {
              bulkUpdateStatusApproval(bulkStatus);
            }
            if (modalType === "approve") {
              updateStatusApproval({
                id,
                approval_status_id: bastStatus?.isInputMigo
                  ? 30
                  : approval_status_id
                  ? String(Number(approval_status_id) + 1)
                  : "1",
              });
            }
            if (modalType === "reject") {
              updateStatusApproval({
                id,
                approval_status_id: "5",
                comment: reason,
              });
            }
            if (modalType === "delete") {
              deleteBast({ id });
            }
            if (modalType === "delete item") {
              deleteBastItem({ deleteId });
            }
            if (modalType === "reject item") {
              rejectBastItem();
            }
            if (modalType === "approve item") {
              approveBastItem();
            }
          }}
          disabled={disabled}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const Alert = () => {
//   return (
//     <div
//       role="alert"
//       className="fade alert alert-danger alert-dismissible show"
//     >
//       <button
//         type="button"
//         className="btn-close"
//         onClick={() => {
//           // setShowAlert(false);
//         }}
//         aria-label="Close alert"
//       ></button>
//       A simple danger alert—check it out!
//     </div>
//   );
// };

export default ConfirmationModalClone;
