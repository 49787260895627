const history = [
    {
        date: "09 Sep 2023",
        company: "ABC",
        user: "user1",
        process: "",
        activity: "submit",
        comment: "",
      },
      {
        date: "10 Sep 2023",
        company: "ABC",
        user: "user2",
        process: "",
        activity: "reject",
        comment: "Incomplete information",
      },
      {
        date: "11 Sep 2023",
        company: "ABC",
        user: "user1",
        process: "",
        activity: "re-submit",
        comment: "",
      },
      {
        date: "12 Sep 2023",
        company: "ABC",
        user: "user2",
        process: "",
        activity: "approve",
        comment: "",
      },
]
export {history}