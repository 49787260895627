import { Link } from "react-router-dom";

interface UserRecord {
  no: number;
  userId: any;
  fullName: string;
  email: string;
}

const records: UserRecord[] = [
  {
    no: 1,
    userId: (
      <Link
        to={"/sys-admin/master-data/user-management/users/STM-CN02-11"}
        state={{
          fullName: "Agus",
          userId: "STM-CN02-11",
          email: "agus@mail.co",
        }}
      >
        VL-CN02-11
      </Link>
    ),
    fullName: "Agus",
    email: "agus@mail.co",
  },
  {
    no: 2,
    userId: (
      <Link
        to={"/sys-admin/master-data/user-management/users/STM-CN02-12"}
        state={{
          fullName: "Budi",
          userId: "STM-CN02-12",
          email: "budi@mail.co",
        }}
      >
        STM-CN02-12
      </Link>
    ),
    fullName: "Firman",
    email: "firman@mail.co",
  },
];

export { records };
