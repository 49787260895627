import { useMemo } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';

type InvoiceColumn = {
  migoNo: string;
  gl: string;
  wbs: string;
  postingDate: string;
  contractNo: string;
  poNo: string;
  supplierName: string;
  deliveryNote: string;
  billLading: string;
  requestedBy: string;
  notes: string;
  receivedBy: string;
  receivedDate: string;
  checkedBy: string;
  checkedDate: string;
  deliveredBy: string;
  deliveredDate: string;
  status: string;
};

const ReportBAST = () => {
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "migoNo",
        header: "Migo/SES No",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "gl",
        header: "GL",
        size: 80,
      },
      {
        accessorKey: "wbs",
        header: "WBS",
        size: 80,
      },
      {
        accessorKey: "postingDate",
        header: "Posting Date",
        size: 80,
      },
      {
        accessorKey: "contractNo",
        header: "Contract/BPO No",
        size: 80,
      },
      {
        accessorKey: "poNo",
        header: "Po No",
        size: 80,
      },
      {
        accessorKey: "supplierName",
        header: "Supplier Name",
        size: 80,
      },
      {
        accessorKey: "deliveryNote",
        header: "Delivery Note",
        size: 80,
      },
      {
        accessorKey: "billLading",
        header: "Bill of Lading",
        size: 80,
      },
      {
        accessorKey: "requestedBy",
        header: "Requested By",
        size: 80,
      },
      {
        accessorKey: "notes",
        header: "Notes",
        size: 80,
      },
      {
        accessorKey: "receivedBy",
        header: "Received By",
        size: 80,
      },
      {
        accessorKey: "receivedDate",
        header: "Received Date",
        size: 80,
      },
      {
        accessorKey: "checkedBy",
        header: "Checked By",
        size: 80,
      },
      {
        accessorKey: "checkedDate",
        header: "Checked Date",
        size: 80,
      },
      {
        accessorKey: "deliveredBy",
        header: "Delivered By",
        size: 80,
      },
      {
        accessorKey: "deliveredDate",
        header: "Delivered Date",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        // Cell: ({ cell }) => {
        //   const value = String(cell.getValue());
        //   const isIncludeAppr = value.includes("pproval");
        //   return (
        //     <div
        //       className={`invoice-${
        //         isIncludeAppr
        //           ? "waiting-approval"
        //           : value.toLowerCase().split(" ").join("-")
        //       }`}
        //     >
        //       <p>{value}</p>
        //     </div>
        //   );
        // },
      },
    //   {
    //     header: "Action",
    //     accessor: "action",
    //     sort: true,
    //   }
    ],
    []
  );
  const data = [
    {
      migoNo: "",
      gl: "5030100099",
      wbs: "3381586",
      postingDate: "9 August 2023",
      contractNo: "3439791123",
      poNo: "4509791607",
      supplierName: "ESIT CANADA ENTERPRISE SERVICES CO",
      deliveryNote: "",
      billLading: "",
      requestedBy: "",
      notes: "",
      receivedBy: "Arini Pratiwi",
      receivedDate: "9 August 2023",
      checkedBy: "Christianus Mangiwa",
      checkedDate: "10 August 2023",
      deliveredBy: "Aiswarya. T.A",
      deliveredDate: "11 August 2023",
      status: "BAST Approved",
    },
  ];
  const changeCamel = (data: string) => {
    return data.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
  }
  const data2 = (data: InvoiceColumn[]) => {
    let hasil = []
    for (const a of data) {
      const hasilObj:any = {}
      for (const [key, value] of Object.entries(a)) {
        hasilObj[changeCamel(key)] = value
      }
      hasil.push(hasilObj)
    }
    return hasil
  }
  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              // enableSelectAll={true}
              // enableMultiRowSelection={true}
              // enableRowSelection={(row) => row.original.canEdit} 
              // enableEditing
              enableRowNumbers
              enablePinning
              initialState={{ columnPinning: { right: ['status'] } }}
              data={data}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(data2(data));
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                    XLSX.writeFile(workbook, "Export BAST.xlsx");
                  }}
                  className="vale-button"
                >
                  Export Excel
                </Button>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};



export default ReportBAST;
