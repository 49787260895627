import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import GroupInput from "./MyComponents/Input/GroupInput";
import { Box, IconButton, MenuItem, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { Check, Close, Delete, Edit, FileUpload, FindInPage } from "@mui/icons-material";
import Select from 'react-select';
import ConfirmationModal from "./MyComponents/Modal/ConfirmationModal";
import FormInput from "./FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { b64toBlob, changeUStoID, checkFile, getMasterData, listCompanyOptions, mineralOrGeo, onChangeMoney, openFilePDFImg, removeDot, replaceToComma, replaceToDot, roleSpecialPayment, valueOptions } from "../helpers/utils";
import ShreyuDatepicker from "./Datepicker";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { API_URI } from "../constants";
import { toast } from "react-toastify";
import { toBase64 } from "../utils";
import ConfirmationModalEC from "./MyComponents/Modal/ConfirmationModalEC";
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loading from "./MyComponents/Loading";
import { useTranslation, Trans } from 'react-i18next';

type ExpenditureColumn = {
  id?: number;
  date_detail: string;
  type_of_expense_id: string;
  justification: string;
  wbs_id?: string;
  cost_center_id?: string;
  cost_element_id?: string;
  currency_id: string;
  amount: string;
  currency_rate: string;
  amount_idr: string;
  reject_reason: string;
  attachFile?: string;
  attachFile_hr?:string;
  typeofexpense?: {
    type_code?: string
  };
  costelement?: {
    gl_code?: string;
    gl_name?: string;
    gabungan?: string;
  };
  wbs?: {
    wbs_code?: string;
    wbs_name?: string;
    gabungan?: string;
  };
  costcenter?: {
    code?:string;
    names?: string;
    gabungan?: string;
  }
};
interface DataProps {
    data: any
    canApprove?: boolean
    isEdit?: boolean
    isAdminAP?: boolean
    isTreasury?:boolean
    isCC?:boolean
    mode?: string
  }

const CreateClaimFormCC = (props: DataProps) => {
  const { t, i18n } = useTranslation();
  const role = roleSpecialPayment();
  const navigate = useNavigate();
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  type ModalTypeState = "delete" | "approve" | "reject" | "";
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const [listData, setListData] = useState<any>();
  const [listData2, setListData2] = useState<any>();
  const [createModal3Open, setCreateModal3Open] = useState(false);
  const [dataModalCreate, setDataModalCreate] = useState({});
  const [dataModalId, setDataModalId] = useState(0);
  const [dataModalCreateMode, setDataModalCreateMode] = useState(props.mode);
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const [dataListAdvance, setDataListAdvance] = useState<any>();
  const currencyOptions = [
    { value: "IDR", label: "IDR" },
    { value: "USD", label: "USD" },
    { value: "AUD", label: "AUD" },
    { value: "CAD", label: "CAD" },
    { value: "SGD", label: "SGD" },
    { value: "MYR", label: "MYR" },
    { value: "BRL", label: "BRL" },
  ];
  
  let a = [
    {
      // id: '',
      date_detail: '',
      type_of_expense_id: '',
      justification: '',
      wbs_id: '',
      cost_center_id: '',
      cost_element_id: '',
      currency_id: '',
      amount: '',
      currency_rate: '',
      amount_idr: '',
      reject_reason: '',
      attachFile: '',
      typeofexpense:{
        type_code: ''
      }
    }
  ];
  const onChangeStartingDate = (e: any) => {
    // console.log(e)
  }
  const [advanceOrSettlement, setAdvanceOrSettlement] = useState("settlement");
  const [stmOrVei, setStmOrVei] = useState("stm");
  const [dataGL, setDataGL] = useState<any>();
  const [dataWBS, setDataWBS] = useState<any>();
  const [load, setLoad] = useState<any>(false);
  const [dataCostCenter, setDataCostCenter] = useState<any>();
  const [dataTypeOfExpense, setDataTypeOfExpense] = useState<any>();
  const [dataCurrency, setDataCurrency] = useState<any>();
  const userId = JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.id

  const handleAddUpdateApi = ((dataCreate: any) => {
    setLoad(true)
    for(const a of dataCreate) {
      delete a.needHR;
      if(props.isEdit === false) {
        a.approval_status_id = '423'
        a.department_id = JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.department?.id
      }
      a.company_code = JSON.parse(sessionStorage.getItem('current_company')as string).company_code
      a.subcompany_code = a.company_code === '1492' ? a.subcompany_code : '';
      a.company = null; delete a.company;
      delete a.employee_name;
      a.cash_advance = removeDot(a.cash_advance)
      a.total_expense_claimed = removeDot(a.total_expense_claimed)
      a.difference = removeDot(a.difference);
      a.company_to_pay = removeDot(a.company_to_pay)
      a.return_to_company = removeDot(a.return_to_company)
      a.user_id = userId
      a.employee_id = JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.payroll_id
      a.direct_manager = JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.direct_manager
      // a.doc_date = dayjs(a.doc_date).format('YYYY-MM-DD')
      if(a.detail) {
        for (const b of a.detail) {
          b.typeofexpense = null; delete b.typeofexpense;
          b.wbs = null; delete b.wbs;
          b.costelement = null; delete b.costelement;
          b.costcenter = null; delete b.costcenter;
          b.reject_reason = null
          // a.type_employee_claims = removeDot(a.type_employee_claims)
        }
      }
    }
    // console.log(dataCreate)
    fetch(API_URI + 'employee-claim-cc', {
      method: "POST",
      body:JSON.stringify(dataCreate),
      headers: {
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*"
      }
      // mode: 'no-cors'
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      // getData()
      if(data.success) {
        // if( role.canEdit && props.mode === 'edit'){
        //   setApprovalAPI([{
        //     id: [listData.id.name],
        //     approval_status_id: listData.approval_status_id.name,
        //     approved: true,
        //     reason: null,
        //     approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id
        //   }]).then(() => {
        //     toast.success('Success Saving data')
        //     navigate(`/sap/special-payment-list`);
        //   })
        // }else {
        //   toast.success('Success Saving data')
        //   navigate(`/sap/special-payment-list`);
        // }
        toast.success('Success Saving data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error('Failed saving data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoad(false)
      navigate(`/employee-claim/cc-list`);
    })
    .catch(err => {
      // console.log(err)
      // setIsError(true);
      toast.error('Failed saving data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    })
  })
  const getDataGL = () => new Promise((resolve, reject) => 
    getMasterData('general-ledger-list', {
      columnFilters: [{
        "id": 'is_expense',
        "value": '1'
      },{
        "id": 'company',
        "value": currentCompany.company_initial
      }],
      sorting: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      
    }).then(data => {
      const hasil = []
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.gl_code + ' - ' + a.gl_name,
          value: a.id
        })
      }
      setDataGL(hasil);
    })
    .catch(err => console.log(err))
    .finally(() => resolve(true))
  )

  const getDataWBS = () => new Promise((resolve, reject) => 
    getMasterData('wbs-list', {
      columnFilters: [{
        id: 'company',
        value: currentCompany.company_initial
      }],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      if(data && data?.data && data?.data?.data) {
        for(const a of data.data.data){
          hasil.push({
            key: a.id,
            label: a.wbs_code + ' - ' + a.wbs_name,
            value: a.id
          })
        }
      }
      setDataWBS(hasil);
    })
    .catch(err => console.log(err))
    .finally(() => resolve(true))
  )

  const getDataTypeExpense = () => new Promise((resolve, reject) => 
    getMasterData('type-of-expense-list', {
      columnFilters: [{
        id: 'company',
        value: currentCompany.company_initial
      }],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      if (data && data?.data && data?.data?.data){
        for(const a of data?.data?.data){
          hasil.push({
            key: a.id,
            label: a.type_code,
            value: a.id
          })
        }
      }
      setDataTypeOfExpense(hasil);
    })
    .catch(err => console.log(err))
    .finally(() => resolve(true))
  )

  const getDataCostCenter = () => new Promise((resolve, reject) => 
    getMasterData('cost-center-list', {
      columnFilters: [{
        id: 'company',
        value: currentCompany.company_initial
      }],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      for(const a of data?.data?.data){
        hasil.push({
          key: a.id,
          label: a.code + ' - ' + a.names,
          value: a.id
        })
      }
      setDataCostCenter(hasil);
    })
    .catch(err => console.log(err))
    .finally(() => resolve(true))
  )

  const getDataCurrency = () => new Promise((resolve, reject) => 
    getMasterData('currency-list', {
      columnFilters: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.currency_code,
          value: a.currency_code
        })
      }
      setDataCurrency(hasil);
    })
    .catch(err => console.log(err))
    .finally(() => resolve(true))
  )

  const columnExpenditureMRT = useMemo<MRT_ColumnDef<ExpenditureColumn>[]>(
    () => [
      {
        accessorKey: "date_detail",
        header: t("Date (dd/mm/yyyy)"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 180,
        Cell: ({ cell }) => <div>{dayjs(cell.getValue() as string).format('DD MMM YYYY')}</div>,
      },
      {
        accessorKey: "typeofexpense.type_code",
        header: t("Description (Type of expense)"),
        // muiTableBodyCellEditTextFieldProps: {
          // select: true, //change to select for a dropdown
          // children: dataTypeOfExpense?.map((curr: any) => (
          //   <MenuItem key={curr.label} value={curr.value}>
          //     {curr.label}
          //   </MenuItem>
          // )),
        // },
        size: 180,
        // Cell: ({ cell }) => <div>{dataTypeOfExpense?.length > 0 ? dataTypeOfExpense[valueOptions(dataTypeOfExpense, cell.getValue())]?.label : ''}</div>,
      },
      {
        accessorKey: "justification",
        header: t("Description (Detail of expenditure)"),
        size: 180,
      },
      {
        accessorKey: "wbs.gabungan",
        header: t("WBS (STM)"), // Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costcenter.gabungan",
        header: t("Cost Center (VEI)"), // Cost Center (VEI)",
        size: 180,
      },
      {
        accessorKey: "costelement.gabungan",
        header: t("Cost Element"),
        size: 180,
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 180,
      },
      {
        accessorKey: "amount",
        header: t("Amount"),
        size: 300,
      },
      {
        accessorKey: "currency_rate",
        header: t("Curency Rate"),
        size: 180,
      },
      {
        accessorKey: "amount_idr",
        header: t("Amount in IDR"),
        size: 300,
      },
      {
        accessorKey: "reject_reason",
        header: t("Reject Reason"),
        size: 300,
      },
      {
        accessorKey: "attachFile",
        header: t("File"),
        size: 300,
        Cell: ({ cell }) => 
          <FindInPage
            onClick={() => {
              openFilePDFImg(cell.getValue() as string)
            }}
            className="cursor-pointer ms-2"
          />
          // <div>{changeUStoID(cell.getValue())}</div>,
      },
    ],
    [t]
  );

  const schemaResolver = yupResolver(
    yup.object().shape({
      // id: yup.number(),
      company: yup.string().required('Please enter Company'),
      subcompany_code: yup.string().when("company", {
        is: 'STM',
        then: yup.string().required("Please enter Mineral / Geothermal")
      }),
      attachFile_hr: yup.string().nullable().when("needHR", {
        is: true,
        then: yup.string().required("Please attach File From HR")
      }),
      needHR: yup.boolean(),
      employee_id: yup.string().nullable(),
      position: yup.string().nullable(),
      doc_no: yup.string().nullable(),
      doc_date: yup.string().nullable(),
      cash_advance: yup.string().required("Please enter cash advance"),
      total_expense_claimed: yup.string().required(),
      difference: yup.string().required(),
      company_to_pay: yup.string().required(),
      return_to_company: yup.string().required(),
      currency_id: yup.string().required(),
      billing_statement: yup.string().required(),
    })
  );
  const methods = useForm<any>({
    // defaultValues: listData2,
    reValidateMode:"onBlur",
    resolver: schemaResolver,
  });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;
  // console.log(errors);
  // console.log(listData2)
  // console.log(errors)
  setValue('company', currentCompany.company_initial);
  setValue('subcompany_code', listData2?.subcompany_code);
  setValue('employee_id', listData2?.employee_id);
  setValue('position', listData2?.position);
  setValue('doc_no', listData2?.doc_no);
  setValue('doc_date', listData2?.doc_date);
  setValue('cash_advance', listData2?.cash_advance);
  setValue('total_expense_claimed', listData2?.total_expense_claimed);
  setValue('difference', listData2?.difference);
  setValue('company_to_pay', listData2?.company_to_pay);
  setValue('return_to_company', listData2?.return_to_company);
  setValue('needHR', listData2?.needHR);
  setValue('attachFile_hr', listData2?.attachFile_hr);
  // setValue('currency_id', listData2?.currency_id);
  setValue('currency_id', listData2?.currency_id);
  setValue('billing_statement', listData2?.billing_statement)

  const calculateMoney = (async(advance: any, total_expense: any, obj:any = {}) => {
    const adv = advance ? parseFloat(replaceToDot(removeDot(advance))) : 0
    const total = total_expense ? parseFloat(replaceToDot(removeDot(total_expense))) : 0
    const diff = adv - total;
    const company_to_pay_employee = diff < 0 ? -diff : 0
    const return_to_company = diff > 0 ? diff : 0
    // console.log(adv, total, diff, company_to_pay_employee, return_to_company);
    // // console.log(changeUStoID(adv), changeUStoID(total), changeUStoID(diff), company_to_pay_employee, return_to_company);
    await setListData2({
      ...listData2,
      cash_advance: changeUStoID(replaceToComma(adv.toFixed(2))),
      total_expense_claimed: changeUStoID(replaceToComma(total.toFixed(2))),
      difference: changeUStoID(replaceToComma(diff.toFixed(2))),
      company_to_pay: changeUStoID(replaceToComma(company_to_pay_employee.toFixed(2))),
      return_to_company: changeUStoID(replaceToComma(return_to_company.toFixed(2))),
      ...obj
    })
  })

  const calculateUploadHR = ((obj: any) => {
    let needHR = false;
    for (const a of obj){
      const tgl = dayjs(a.date_detail).add(60, 'day')
      // console.log("tgl", tgl)
      // console.log(dayjs(listData2.doc_date))
      if (dayjs().isAfter(tgl)){
        needHR = true;
      }
      // console.log(needHR)
    }
    return needHR
  })
  const [flagLoad, setFlagLoad] = useState<any>(true);
  // let flag_load = true;
  useEffect(() => {
    // // console.log(roleSpecialPayment())
    dayjs.extend(customParseFormat)
    const a = props?.data;
    if(!a?.docNo || flagLoad) {
      if(a?.docNo) {
        setFlagLoad(false)
        // flag_load = false;
      }
      // console.log(a);
      setLoad(true);
      Promise.all([
        getDataGL(),
        getDataWBS(),
        getDataCostCenter(),
        getDataTypeExpense(),
        getDataCurrency()
      ]).then(() => {
        console.log('sukses')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        if(a.claim_details){
          for (const d of a.claim_details?.name){
            if (d.wbs) {
              d.wbs.gabungan = d.wbs?.wbs_code + ' - ' + d.wbs?.wbs_name
            }
            if(d.costelement) {
              d.costelement.gabungan = d.costelement?.gl_code + ' - ' + d.costelement?.gl_name
            }
            if(d.costcenter) {
              d.costcenter.gabungan = d.costcenter?.code + ' - ' + d.costcenter?.names
            }
          }
        }
        const c = {
          ...a,
          employee_id:{
            ...a.employee_id,
            name: props.isEdit ? a.employee_id?.name : JSON.parse(sessionStorage.getItem('shreyu_user')as string).data?.employee?.payroll_id,
          },
          employee_name:{
            ...a.employee_name,
            name: props.isEdit ? a.employee?.name?.payroll_name : JSON.parse(sessionStorage.getItem('shreyu_user') as string).data?.name,
          },
        }
        if(a.is_advance?.name === '1'){ 
          c.is_cash_advance = {
            ...a.is_cash_advance,
            isHide: true
          }
          c.previous_form = {
            ...a.previous_form,
            isHide: true
          }
          // console.log(c);
        } else { 
          c.is_cash_advance = {
            ...a.is_cash_advance,
            isHide: false
          }
          c.previous_form = {
            ...a.previous_form,
            isHide: false
          }
        }
        // console.log(c);
        setListData(c)
        setListData2({
          id: a.id?.name,
          approval_status_id: a.approval_status_id?.name,
          company: currentCompany,
          // is_advance: a.is_advance?.name,
          currency_id: 'IDR',//a.currency_id?.name,
          subcompany_code: a.subcompany_code?.name,
          department_name: props.isEdit ? a.department_name?.name : JSON.parse(sessionStorage.getItem('shreyu_user') as string).data?.employee?.department?.name,
          employee_id: props.isEdit ? a.employee_id?.name : JSON.parse(sessionStorage.getItem('shreyu_user') as string).data?.employee?.payroll_id,
          employee_name: props.isEdit ? a.employee_name?.name : JSON.parse(sessionStorage.getItem('shreyu_user') as string).data?.name,
          position: props.isEdit ? a.position?.name : JSON.parse(sessionStorage.getItem('shreyu_user') as string).data?.employee?.position,
          doc_no: a.doc_no?.name,
          doc_date: dayjs(a.doc_date?.name).toDate(),
          attachFile_hr: a.attachFile_hr?.name,
          billing_statement: a.billing_statement?.name,
          // is_cash_advance: a.is_cash_advance?.name,
          // reason_travel_advance: a.reason_travel_advance?.name,
          // attach_travel_approval: a.attach_travel_approval?.name,
          // start_date: dayjs(a.start_date?.name).toDate(),
          // end_date: dayjs(a.end_date?.name).toDate(),
          // travel_route: a.travel_route?.name,
          // kind_of_transport: a.kind_of_transport?.name,
          // reason_other_advance: a.reason_other_advance?.name,
          cash_advance: changeUStoID(a.cash_advance?.name),
          total_expense_claimed: changeUStoID(a.total_expense_claimed?.name),
          difference: changeUStoID(a.difference?.name),
          company_to_pay: changeUStoID(a.company_to_pay?.name),
          return_to_company: changeUStoID(a.return_to_company?.name),
          detail: a.claim_details?.name
        })
        const b = JSON.parse(sessionStorage?.getItem('current_company') as string)
        if(b) {
          // // console.log()
          setStmOrVei(b.company_initial);
          // setCurrentCompany(b.company_initial);
        }
        setLoad(false)
      })
      // getDataGL()
      // getDataWBS()
      // getDataCostCenter()
      // getDataTypeExpense()
      // getDataCurrency()
    }
  }, [props, t])

  // useEffect(() => {
  // }, [t])

  return (
    <>
      <div className="mb-3"></div>
      <Loading loading={load} />
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Card className="employee-claim-subtitle mb-1">
                  <p
                    className="text-center mx-2 my-1 d-flex align-items-center justify-content-center"
                    style={{ height: "3em" }}
                  >
                    {props.isCC
                      ? "TRAVEL & OTHER EXP.ADVANCE/EXP.CLAIM FORM (FOR CORPORATE CREDIT CARD)"
                      : "TRAVEL & OTHER EXP.ADVANCE/EXP.CLAIM FORM"}
                  </p>
                </Card>
              </Col>
              <Col md={6} style={{}}>
                <Form.Group className="mb-2">
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md="4">
                      <Form.Label
                        style={{fontWeight: 400,fontSize: 11}}
                        className="mb-0"
                      >
                        {t("Tick Company Used")}<span className="text-danger">*</span>
                      </Form.Label>
                    </Col>

                    <Col
                      md="4"
                      style={{display: "flex",justifyContent: "center",alignItems: "center"}}
                    >
                      <FormInput
                        readOnly={true}
                        type="text"
                        name="company"
                        value={JSON.parse(sessionStorage?.getItem('current_company') as string)}
                        className="my-auto"
                        // checked={stmOrVei === "STM"}
                        hidden={true}
                        register={register}
                        errors={errors}
                        control={control}
                      />
                      <Form.Control
                        type="text"
                        className="me-1"
                        style={{ height: 21 }}
                        readOnly
                        defaultValue={"PT STM"}
                      />
                      <FormInput
                        readOnly={true}
                        type="radio"
                        name="stmVei"
                        value="stm"
                        className="my-auto"
                        disabled={true}
                        checked={stmOrVei === "STM"}
                        // register={register}
                        // errors={errors}
                        // control={control}
                        onChange={(e) => {
                          const a = JSON.parse(sessionStorage?.getItem('current_company') as string)
                          if(a) {
                            setStmOrVei(a.company_initial);
                            setListData2({...listData2.company = a.company_initial})
                          }
                        }}
                      ></FormInput>
                    </Col>

                    <Col
                      md="4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Form.Control
                        type="text"
                        className="me-1"
                        defaultValue={"PT VEI"}
                        readOnly
                        style={{ height: 21 }}
                      />
                      <FormInput
                        type="radio"
                        name="stmVei"
                        value="vei"
                        className="my-auto"
                        checked={stmOrVei === "VEI"}
                        disabled={true}
                        // register={register}
                        // errors={errors}
                        // control={control}
                        onChange={(e) => {
                          const a = JSON.parse(sessionStorage?.getItem('current_company') as string)
                          if(a) {
                            setStmOrVei(a.company_initial);
                            setListData2({...listData2.company = a.company_initial})
                          }
                        }}
                      ></FormInput>
                    </Col>
                  </Row>
                </Form.Group>
                {!props.isCC && (
                  <>
                  {stmOrVei === 'STM' && (
                  <Form.Group className="my-auto mb-2">
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md="4">
                        <Form.Label
                          style={{
                            fontWeight: 400,
                            fontSize: 11,
                          }}
                          className="mb-0"
                        >
                          {t("Tick Mineral / Geothermal")}<span className="text-danger">*</span>
                        </Form.Label>
                      </Col>
                      <Col
                        md="4"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormInput
                          readOnly={true}
                          type="text"
                          name="subcompany_code"
                          value={listData2?.subcompany_code}
                          className="my-auto"
                          hidden={true}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                        <Form.Control
                          type="text"
                          className="me-1"
                          defaultValue={"Mineral"}
                          readOnly
                          style={{ height: 21 }}
                        />
                        <FormInput
                          type="radio"
                          name="mineralOrGeo"
                          value="Mineral"
                          checked={listData2?.subcompany_code === "mineral"}
                          className="my-auto"
                          onChange={(e) => {
                            // console.log(e)
                            setListData2({
                              ...listData2,
                              subcompany_code: "mineral"
                            })
                          }}
                        />
                      </Col>

                      <Col
                        md="4"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Control
                          type="text"
                          className="me-1"
                          defaultValue={"Geothermal"}
                          readOnly
                          style={{ height: 21 }}
                        />
                        <FormInput
                          type="radio"
                          name="mineralOrGeo"
                          value="Geothermal"
                          checked={listData2?.subcompany_code === "geothermal"}
                          onChange={(e) => {
                            // console.log(e);
                            setListData2({
                              ...listData2,
                              subcompany_code: "geothermal"
                            })
                          }}
                          className="my-auto"
                        ></FormInput>
                      </Col>
                    </Row>
                  </Form.Group>
                  )}
                </>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                {/* <GroupInput inputAttArr={employeeData} colSize={6} /> */}
                <Form.Group className="mb-3" controlId="employee_name">
                  <Form.Label>{t("Employee Name")}</Form.Label>
                  <FormInput
                    name="employee_name"
                    type="text"
                    placeholder={t("Input Employee Name")}
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.employee_name}
                    // readOnly={listData?.employee_name?.isDisabled}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="employee_id">
                  <Form.Label>{t("Employee ID")}</Form.Label>
                  <FormInput
                    name="employee_id"
                    type="text"
                    placeholder={t("Input Employee ID")}
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.employee_id}
                    // readOnly={listData?.employee_id?.isDisabled}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="department_name">
                  <Form.Label>{t("Department")}</Form.Label>
                  <FormInput
                    name="department_name"
                    type="text"
                    placeholder={t("Input Department")}
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.department_name}
                    // readOnly={listData?.department_name?.isDisabled}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="position">
                  <Form.Label>{t("Position")}</Form.Label>
                  <FormInput
                    name="position"
                    type="text"
                    placeholder={t("Input Position")}
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.position}
                    // readOnly={listData?.position?.isDisabled}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="doc_no">
                  <Form.Label>{t("Doc No")}</Form.Label>
                  <FormInput
                    name="doc_no"
                    type="text"
                    placeholder={t("Input Doc No")}
                    register={register}
                    errors={errors}
                    control={control}
                    value={listData2?.doc_no}
                    // readOnly={listData?.doc_no?.isDisabled}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="doc_date">
                  <Form.Label>{t("Doc Date (DD/MM/YY)")}</Form.Label>
                  {/* <ShreyuDatepicker
                    hideAddon={false}
                    dateFormat="dd/MM/yyyy"
                    value={listData2?.doc_date}
                    onChange={(e) => {
                      // console.log(e)
                      setListData2({ ...listData2, doc_date: e })
                      // console.log(listData2, errors);
                    }}
                    // disabled={true}
                  /> */}
                  <FormInput
                    name="doc_date2"
                    type="text"
                    placeholder={t("Input Doc No")}
                    hidden={false}
                    disabled={true}
                    // register={register}
                    // errors={errors}
                    // control={control}
                    // defaultValue={listData2?.doc_date}
                    value={dayjs(listData2?.doc_date).format('DD MMM YYYY')}
                  />
                  <FormInput
                    name="doc_date"
                    type="text"
                    placeholder={t("Input Doc No")}
                    hidden={true}
                    disabled={true}
                    register={register}
                    errors={errors}
                    control={control}
                    // defaultValue={listData2?.doc_date}
                    value={listData2?.doc_date}
                  />
                </Form.Group>
              </Col>
              {/* <Col md={5} className="ms-4">
                <GroupInput inputAttArr={havecashAdvance} colSize={5}/>
              </Col> */}
            </Row>
            {listData2?.needHR && (
            <Row>
              <Form.Label>
                {t("Attach travel approval form from HR")} :<span className="text-danger">*</span>
                <div style={{ display: "inline" }}>
                {listData2?.attachFile_hr && (
                    <FindInPage
                    onClick={() => {
                      const win: any = window.open();
                      const [meta, data] = listData2?.attach_travel_approval_hr?.attachFile.split("base64,")
                      const blob = b64toBlob(data, "application/pdf");
                      const url = URL.createObjectURL(blob);
                    win.document.write(
                        `<iframe src="${
                        url
                        }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                        );
                    }}
                    className="cursor-pointer ms-2"
                    />
                    )}
                {listData2?.attachFile_hr && (
                    <Delete
                    color="error"
                    className="cursor-pointer ms-2"
                    onClick={() =>{
                    setListData2({
                        ...listData2,
                        attachFile_hr: ''
                    })
                    }}
                    />
                    )}
                </div>
              </Form.Label>
              <Form.Control
                type="file"
                disabled={listData?.attachFile_hr?.isDisabled}
                onChange={async(e: any) => {
                  if(checkFile(e)){
                    const file = await toBase64(e.target?.files[0]);
                    // console.log(file)
                    // setValue('suporting_doc', file)
                    setListData2({...listData2, attachFile_hr: file })
                  }
                }}
              />
              <FormInput
                name="attachFile_hr"
                type="text"
                placeholder="Attach"
                hidden={true}
                register={register}
                errors={errors}
                control={control}
                value={listData2?.attachFile_hr}
              />
            </Row>
            )}
            <Row>
              <Form.Label>
                {t("Attach billing statement")} :<span className="text-danger">*</span>
                <div style={{ display: "inline" }}>
                    {listData2?.billing_statement && (
                    <>
                    <FindInPage
                    onClick={() => {
                      const win: any = window.open();
                      const [meta, data] = listData2?.billing_statement?.split("base64,")
                      const blob = b64toBlob(data, "application/pdf");
                      const url = URL.createObjectURL(blob);
                    win.document.write(
                        `<iframe src="${
                        url
                        }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                        );
                    }}
                    className="cursor-pointer ms-2"
                    />
                    <Delete
                    color="error"
                    className="cursor-pointer ms-2"
                    onClick={() =>{
                    setListData2({
                        ...listData2,
                        billing_statement: ''
                    })
                    }}
                    />
                    </>
                    )}
                </div>
              </Form.Label>
              <Form.Control
                type="file"
                disabled={listData?.billing_statement?.isDisabled}
                onChange={async(e: any) => {
                  if(checkFile(e, true, 10485760 * 30)){
                    const file = await toBase64(e.target?.files[0]);
                    // console.log(file)
                    // setValue('suporting_doc', file)
                    setListData2({...listData2, billing_statement: file })
                  }
                }}
              />
              <FormInput
                name="billing_statement"
                type="text"
                placeholder="Attach"
                hidden={true}
                register={register}
                errors={errors}
                control={control}
                value={listData2?.billing_statement}
              />
            </Row>
            <br></br>
              <MaterialReactTable
                columns={columnExpenditureMRT}
                data={listData2?.detail ? listData2?.detail : []}
                // enableRowActions
                renderTopToolbarCustomActions={() => (
                  <Button
                    onClick={() => {
                      setDataModalCreateMode('Create New');
                      setCreateModal3Open(true)
                      setDataModalCreate({})
                    }}
                    className="vale-button"
                  >
                    {t("Add Row")}
                  </Button>
                )}
                // muiTopToolbarProps={({row})}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {},
                  sx: {
                    cursor: "pointer", //you might want to change the cursor too when adding an onClick
                  },
                })}
                renderRowActions={({ row, table }) => (
                  // {row. }
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    {row.getValue("date_detail") && (
                      <Tooltip arrow placement="left" title={t("Edit")}>
                        <IconButton
                          onClick={() => {
                            // console.log(row);
                            setDataModalCreate(row.original)
                            setDataModalCreateMode("Save")
                            setCreateModal3Open(true)
                            setDataModalId(row.index)
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                    {row.getValue("date_detail") && (
                      <Tooltip arrow placement="right" title={t("Delete")}>
                        <IconButton 
                          color="error"
                          onClick={() => {
                            // console.log(row);
                            const hasil = listData2?.detail
                            hasil.splice(row.index,1)
                            // console.log(hasil);
                            let hasilDetail = 0
                            for (const z of hasil) {
                              hasilDetail += parseFloat(replaceToDot(removeDot(z.amount_idr)))
                            }
                            // const needHR = calculateUploadHR(a);
                            calculateMoney(listData2.cash_advance, changeUStoID(replaceToComma(hasilDetail.toFixed(2))), {detail: [...hasil]})
                            // calculateMoney(listData2.cash_advance, listData2.total_expense_claimed, 
                            //   {detail: [...hasil]})
                            // setListData2({...listData2, detail: [...hasil]})
                            // setModalType("delete");
                            // setIsModalConfirmationOpen(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
                // editingMode="modal"
                enableColumnOrdering
                enableEditing
                editingMode="modal"
                initialState={{ columnVisibility: { 
                  "wbs.gabungan": currentCompany.company_initial === 'STM' ? true : false,
                  "costcenter.gabungan": currentCompany.company_initial === 'STM' ? false : true,
                  // cost_element_id: currentCompany.company_initial === 'VEI' ? false : true
                } }}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    size: 120,
                  },
                }}
              />
            <div className="my-1">
              <h3>{t("Summary")}</h3>
            </div>
            <Row>
              {/* <Col md={2}>
                <Form.Group className="mb-3 " controlId="returnToCompany">
                  <Form.Label>RETURN TO COMPANY: </Form.Label>
                  <Form.Control
                    type="text"
                    value={"-"}
                    disabled={props.data?.returnToCompany?.isDisabled}
                  />
                </Form.Group>
              </Col> */}
              <Col
                md={4}
                className="d-flex flex-column align-items-end justify-content-between"
              >
                {/* <Form.Label>CASH ADVANCE : </Form.Label> */}
                <Form.Label className="mb-3">
                  {t("TOTAL EXPENSE CLAIMED ( Detail Below )")} :
                </Form.Label>
                {/* <Form.Label className="mb-3">DIFFERENCE :</Form.Label> */}
                {/* <Form.Label className="mb-3">{t("COMPANY TO PAY TO EMPLOYEE")} :</Form.Label> */}
                {/* <Form.Label className="mb-3">RETURN TO COMPANY: </Form.Label> */}
              </Col>
              <Col
                md={1}
                className="d-flex flex-column align-items-end justify-content-between"
              >
                <Form.Label className="mb-5">Curr :</Form.Label>
                {/* <Form.Label className="mb-3">Curr :</Form.Label> */}
                {/* <Form.Label className="mb-3">Curr :</Form.Label> */}
                {/* <Form.Label className="mb-3">Curr :</Form.Label> */}
                {/* <Form.Label>Curr :</Form.Label> */}
              </Col>
              <Col md={7}>
                <Row>
                  <Col
                    md={4}
                    className="d-flex flex-column align-items-start justify-content-between"
                  >
                    {/* <Select
                      options={dataCurrency}
                      name="currency_id"
                      value={listData2?.currency_id ? dataCurrency[valueOptions(dataCurrency, listData2?.currency_id)] : ''}
                      className="react-select-container mb-2"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setListData2({...listData2, currency_id: e.value})
                      }}
                    ></Select> */}
                    {/* <FormInput
                      key="currency_id"
                      // label={column.header}
                      name="currency_id"
                      className="mb-2"
                      register={register}
                      hidden={true}
                      errors={errors}
                      control={control}
                      // onChange={(e) =>
                      //   setValues({ ...values, [e.target.name]: e.target.value })
                      // }
                      value={listData2?.currency_id}
                    /> */}
                    <input
                      type="text"
                      className="form-control mb-2"
                      style={{ width: "100%", height: "60%" }}
                      // value={props.data?.ca31?.name}
                      // disabled={props.data?.ca31?.isDisabled}
                      readOnly
                      defaultValue={listData2?.currency_id}
                    />
                    {/* <input
                      // name="cash_advance"
                      type="text"
                      className="form-control mb-1"
                      style={{ width: "100%", height: "15%" }}
                      // value={props.data?.ca21?.name}
                      // disabled={props.data?.ca21?.isDisabled}
                      // readOnly
                      defaultValue={curr || ""}
                      // disabled
                    /> */}
                    {/* <input
                      type="text"
                      className="form-control mb-1"
                      style={{ width: "100%", height: "15%" }}
                      // value={props.data?.ca21?.name}
                      // disabled={props.data?.ca21?.isDisabled}
                      readOnly
                      defaultValue={listData2?.currency_id}
                      // disabled
                    /> */}
                    {/* <input
                      type="text"
                      className="form-control mb-3"
                      style={{ width: "100%", height: "20%" }}
                      // value={props.data?.ca31?.name}
                      // disabled={props.data?.ca31?.isDisabled}
                      readOnly
                      defaultValue={listData2?.currency_id}
                    /> */}
                    {/* <input
                      type="text"
                      className="form-control mb-3"
                      style={{ width: "100%", height: "20%" }}
                      // value={props.data?.ca41?.name}
                      // disabled={props.data?.ca41?.isDisabled}
                      readOnly
                      defaultValue={listData2?.currency_id}
                    /> */}
                    {/* <input
                      type="text"
                      className="form-control mb-3"
                      style={{ width: "100%", height: "15%" }}
                      // value={props.data?.ca41?.name}
                      // disabled={props.data?.ca41?.isDisabled}
                      readOnly
                      defaultValue={listData2?.currency_id}
                    /> */}
                  </Col>
                  <Col
                    md={8}
                    className="flex-column align-items-start justify-content-between"
                  >
                    {/* <Form.Group className="mb-3" controlId="cash_advance">
                      <FormInput
                        name="cash_advance"
                        type="text"
                        // placeholder="Input Reason For Other Advance"
                        hidden={false}
                        register={register}
                        errors={errors}
                        control={control}
                        defaultValue={listData2?.cash_advance}
                        value={listData2?.cash_advance}
                        readOnly={listData?.cash_advance?.isDisabled}
                        onChange={(e) => {
                          // console.log(e)
                          onChangeMoney(listData2.cash_advance, e)
                          calculateMoney(e.target.value, listData2.total_expense_claimed)
                          // setListData2({ ...listData2, cash_advance: changeUStoID(e.target.value) })
                          // console.log(listData2, errors);
                        }}
                      />
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="total_expense_claimed">
                      {/* <Form.Label>Reason for Other Advance :</Form.Label> */}
                      <FormInput
                        name="total_expense_claimed"
                        type="text"
                        // placeholder="Input Reason For Other Advance"
                        hidden={false}
                        register={register}
                        errors={errors}
                        control={control}
                        defaultValue={listData2?.total_expense_claimed}
                        value={listData2?.total_expense_claimed}
                        readOnly={listData?.total_expense_claimed?.isDisabled}
                        onChange={(e) => {
                          // console.log(e)
                          onChangeMoney(listData2.total_expense_claimed, e)
                          calculateMoney(listData2.cash_advance, e.target.value)
                          // setListData2({ ...listData2, total_expense_claimed: changeUStoID(e.target.value) })
                          // console.log(listData2, errors);
                        }}
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="difference">
                      <FormInput
                        name="difference"
                        type="text"
                        // placeholder="Input Reason For Other Advance"
                        hidden={false}
                        register={register}
                        errors={errors}
                        control={control}
                        defaultValue={listData2?.difference}
                        value={listData2?.difference}
                        readOnly={listData?.difference?.isDisabled}
                        onChange={(e) => {
                          // console.log(e)
                          onChangeMoney(listData2.difference, e)
                          setListData2({ ...listData2, difference: changeUStoID(e.target.value) })
                          // console.log(listData2, errors);
                        }}
                      />
                    </Form.Group> */}
                    {/* <Form.Group className="mb-3" controlId="company_to_pay">
                      <Form.Label>Reason for Other Advance :</Form.Label>
                      <FormInput
                        name="company_to_pay"
                        type="text"
                        // placeholder="Input Reason For Other Advance"
                        hidden={false}
                        register={register}
                        errors={errors}
                        control={control}
                        defaultValue={listData2?.company_to_pay}
                        value={listData2?.company_to_pay}
                        readOnly={listData?.company_to_pay?.isDisabled}
                        onChange={(e) => {
                          // console.log(e)
                          onChangeMoney(listData2.company_to_pay, e)
                          setListData2({ ...listData2, company_to_pay: changeUStoID(e.target.value) })
                          // console.log(listData2, errors);
                        }}
                      />
                    </Form.Group> */}
                    {/* <Form.Group className="mb-3" controlId="return_to_company">
                      <Form.Label>Reason for Other Advance :</Form.Label>
                      <FormInput
                        name="return_to_company"
                        type="text"
                        // placeholder="Input Reason For Other Advance"
                        hidden={false}
                        register={register}
                        errors={errors}
                        control={control}
                        defaultValue={listData2?.return_to_company}
                        value={listData2?.return_to_company}
                        readOnly={listData?.return_to_company?.isDisabled}
                        onChange={(e) => {
                          // console.log(e)
                          onChangeMoney(listData2.return_to_company, e)
                          setListData2({ ...listData2, return_to_company: changeUStoID(e.target.value) })
                          // console.log(listData2, errors);
                        }}
                      />
                    </Form.Group> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button 
                disabled={load}
                className="vale-button me-2"
                onClick={
                  handleSubmit(() => {
                    handleAddUpdateApi([listData2])
                  })
                }
              >
                {t("Submit")}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <ConfirmationModalEC
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
      />
      {createModal3Open &&
        <CreateNewEmployeeModalSingle
          columns={columnExpenditureMRT}
          cost_element={dataGL}
          currency={dataCurrency}
          type_of_expense={dataTypeOfExpense}
          cost_center={dataCostCenter}
          wbs={dataWBS}
          open={createModal3Open}
          onClose={() => setCreateModal3Open(false)}
          onSubmit={async(dataCreate) => {
            const a = listData2?.detail ? listData2?.detail : []
            if(dataModalCreateMode === 'Save') {
              // console.log(dataCreate);
              // console.log(`Updating row ${dataModalId}`)
              if(dataCreate.status === '2'){
                dataCreate.status = '0'
              }
              a[dataModalId] = dataCreate;
              
            } else {
              // console.log(dataCreate);
              // console.log(`Insert row`)
              a.push(dataCreate);
            }
            let hasilDetail = 0
            for (const z of a) {
              hasilDetail += parseFloat(replaceToDot(removeDot(z.amount_idr)))
            }
            const needHR = calculateUploadHR(a);
            // console.log("needHR", needHR)
            await calculateMoney(listData2.cash_advance, changeUStoID(replaceToComma(hasilDetail.toFixed(2))), {detail: [...a], needHR})
            // setListData2({
            //   ...listData2,
            //   detail: [...a],
            //   ...b
            //   // total_expense_claimed: changeUStoID(replaceToComma(hasilDetail.toFixed(2)))
            // })
            // })
            
            // dataCreate.id = null;
            // if(!(dataModalCreateMode === 'Save')) {
            //   delete dataCreate.id;
            // }
            // // dataCreate.company = dataCreate.company_code
            // delete dataCreate.company_code
            // delete dataCreate.company_mappings
            // delete dataCreate.company_sub_id
            // delete dataCreate.department_code
            // delete dataCreate.employee_type
            // handleAddUpdateApi([dataCreate])
          }}
          data={dataModalCreate}
          allData={listData2}
          mode={dataModalCreateMode as string}
        />}
    </>
  );
};

interface CreateModalProps3 {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  columns: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  data: any;
  mode:string;
  cost_element: any;
  currency: any;
  type_of_expense: any;
  cost_center: any;
  wbs: any;
  allData: any
}

export const CreateNewEmployeeModalSingle = ({
  open,
  columns,
  onClose,
  onSubmit,
  data,
  mode,
  cost_element,
  currency,
  type_of_expense,
  cost_center,
  wbs,
  allData
}: CreateModalProps3) => {
  // // console.log(type_of_expense);
  const { t, i18n } = useTranslation();
  const missingArr = [{key: 0, label: t("No"), value: 0}, {key: 1, label: t("Yes"), value: 1}]
  if(!data.missing_receipts){
    data.missing_receipts = 0
    // console.log('Tidak ada missing_receipts')
  }
  const defValue = {
    // "cost_center": valueOptions(cost_center, data.cost_element_id),
    "cost_element": valueOptions(cost_element, data.cost_element_id),
    "currency": valueOptions(currency, data.currency_id),
    "type_of_expense": valueOptions(type_of_expense, data.type_of_expense_id),
    "cost_center": valueOptions(cost_center, data.cost_center_id),
    "wbs": valueOptions(wbs, data.wbs_id),
    "missing": valueOptions(missingArr, data.missing_receipts)
  }
  // // console.log(defValue);
  // // console.log("Currency",currency);
  const current_company = JSON.parse(sessionStorage.getItem('current_company')as string)
  const columnMRT = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "date_detail",
        header: t("Date (dd/mm/yyyy)"),
        size: 80,
        type: 'date'
      },
      {
        accessorKey: "type_of_expense_id",
        header: t("Description (Type of Expense)"),
        size: 80,
        value: data.type_of_expense_id ? valueOptions(type_of_expense, data.type_of_expense_id) : "",
        type: 'select',
        options: type_of_expense
      },
      {
        accessorKey: "justification",
        header: t("Description (Detail of Expenditure)"),
        size: 80,
      },
      {
        accessorKey: "company",
        header: t("Company"),
        size: 80,
        value: current_company.company_initial,
        isHide: true
      },
      {
        accessorKey: "wbs_id",
        header: t("WBS (STM)"),
        size: 80,
        value: data.wbs_id ? valueOptions(wbs, data.wbs_id) : "",
        type: 'select',
        options: wbs,
        isHide: current_company.company_initial === 'VEI' ? true : false
      },
      {
        accessorKey: "cost_center_id",
        header: t("Cost Center (VEI)"),
        size: 80,
        value: data.cost_center_id ? valueOptions(cost_center, data.cost_center_id) : "",
        type: 'select',
        options: cost_center,
        isHide: current_company.company_initial === 'STM' ? true : false
      },
      {
        accessorKey: "cost_element_id",
        header: t("Cost Element"),
        size: 80,
        value: data.cost_element_id ? valueOptions(cost_element, data.cost_element_id) : "",
        type: 'select',
        options: cost_element
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 80,
        value: data.currency_id ? valueOptions(currency, data.currency_id) : "",
        type: 'select',
        options: currency
      },
      {
        accessorKey: "amount",
        header: t("Amount"),
        size: 80,
      },
      {
        accessorKey: "currency_rate",
        header: t("Currency Rate"),
        size: 80,
      },
      {
        accessorKey: "amount_idr",
        header: t("Amount in IDR"),
        size: 80,
        readOnly: true
      },
      {
        accessorKey: "reject_reason",
        header: t("Reject Reason"),
        size: 80,
        readOnly: true
      },
      {
        accessorKey: "missing_receipts",
        header: t("Missing Receipt"),
        size: 80,
        value: data.missing_receipts ? valueOptions(missingArr, data.missing_receipts) : "0",
        type: 'select',
        options: missingArr
      },
      {
        accessorKey: "attachFile",
        header: t("Receipt"),
        size: 80,
        type: 'file'
      },
    ],
    [t]
  );
  const initialValue = 
    columnMRT.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any)
  const changeAmountIdr = ((amount: any, currency_id: any, currency_rate: any ) => {
    if(amount) {
      let amount_idr: any = parseFloat(replaceToDot(removeDot(amount)));
      if(currency_id !== 'IDR' && currency_rate) {
        // const amoun = parseFloat(replaceToComma(removeDot(amount)))
        const curr_rate = parseFloat(replaceToDot(removeDot(currency_rate)))
        // console.log(amount_idr,curr_rate);
        amount_idr = amount_idr * curr_rate;
      }
      return changeUStoID(replaceToComma(amount_idr.toFixed(2)))
    }
    return 0;
    // return changeUStoID(parseFloat(amount_idr).toFixed(2))
  })
  dayjs.extend(customParseFormat)
  if(mode === 'Save'){
    data = {
      ...data,
      company: current_company.company_initial,
      cost_center_id: cost_center?.[defValue.cost_center]?.value,
      cost_element_id: cost_element?.[defValue.cost_element]?.value,
      currency_id: currency?.[defValue.currency]?.value,
      date_detail: data.date_detail ? dayjs(data.date_detail).toDate() : new Date(),
      type_of_expense_id: type_of_expense?.[defValue.type_of_expense]?.value,
      wbs_id: wbs?.[defValue.wbs]?.value
    }
  }else {
    data = {
      company: current_company.company_initial,
      date_detail: new Date(),
      cost_element_id: "",
      currency_id: "",
      type_of_expense_id: "",
      wbs_id: "",
      cost_center_id: "",
      missing_receipts: 0
    }
  }
  // console.log(data);
  const [values, setValues] = useState<any>({
    ...data,
    date_detail: new Date(data.date_detail)
  })

  const schemaResolver = yupResolver(
    yup.object().shape({
      // employee_id: yup.string().required("Please enter Employee ID"),
      company: yup.string().required("Please enter Company"),
      date_detail: yup.string().nullable().required("Please enter Date"),
      type_of_expense_id: yup.string().nullable().required("Please enter Type of Expense"),
      justification: yup.string().nullable().required("Please enter Description"),
      wbs_id: yup.string().nullable().when("company", {
        is: 'STM',
        then: yup.string().required("Please enter WBS")
      }),
      cost_center_id: yup.string().nullable().when("company", {
        is: 'VEI',
        then: yup.string().required("Please enter Cost Center")
      }),
      cost_element_id: yup.string().nullable().required("Please enter Cost Element"),
      currency_id: yup.string().nullable().required("Please enter Currency"),
      amount: yup.string().required("Please enter Amount"),
      currency_rate: yup.string().nullable().when("currency_id", {
        is:(a: string) => a !== 'IDR',
        then: yup.string().required("Please enter Currency Rate")
      }),
      amount_idr: yup.string(),//.required("Please enter Company Code"),
      reject_reason: yup.string().nullable(), //.required("Please enter Department"),
      attachFile: yup.string().nullable().required("Please Attach File"),
      missing_receipts: yup.string().nullable().required("Please enter Missing Receipts"),
      // confirmpassword: yup
      //   .string()
      //   .oneOf([yup.ref("password"), null], "Passwords don't match")
      //   .required("This value is required."),
      // checkbox: yup.bool().oneOf([true]),
    })
  );
  
  const methods = useForm<any>({
    // defaultValues: data,
    resolver: schemaResolver,
  });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;
  
  setValue('type_of_expense_id', values?.type_of_expense_id ? values?.type_of_expense_id : null)
  setValue('cost_element_id', values?.cost_element_id ? values?.cost_element_id : null)
  setValue('wbs_id', values?.wbs_id ? values?.wbs_id : null)
  setValue('cost_center_id', values?.cost_center_id ? values?.cost_center_id : null)
  setValue('currency_id', values?.currency_id ? values?.currency_id : null)
  setValue('company', current_company.company_initial)
  setValue('attachFile', values?.attachFile)
  setValue('date_detail', values?.date_detail)
  setValue('justification', values?.justification)
  setValue('currency_rate', values?.currency_rate)
  setValue('missing_receipts', values?.missing_receipts)
  // console.log("attachFile", values?.attachFile)
  // console.log("justification", values?.justification)
  // console.log("ERRORS: ",errors);
  // console.log(values?.date_detail)
  
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">{mode === 'Save' ? 'Edit' : 'Create New'}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit((d) =>  console.log(d))}
          // onSubmit={() => {}}
          // resolver={schemaResolver}
        >
          {/* <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '800px' },
              gap: '1.5rem',
            }}
          > */}
          <Form.Group as={Row} className="mb-3">
            {columnMRT?.map((column: any) => (
            <>
            {!column.isHide && (
            <Form.Label column md={2} className="mb-3">
              {column.header}<span className="text-danger">*</span>
              <div style={{ display: "inline" }}>
                    {column.type === 'file' && values?.attachFile && (
                      <FindInPage
                        onClick={() => {
                          openFilePDFImg(values.attachFile)
                        }}
                        className="cursor-pointer ms-2"
                      />
                    )}
                    {column.type === 'file' && values?.attachFile && (
                      <Delete
                        color="error"
                        className="cursor-pointer ms-2"
                        onClick={() =>{
                          setValues({
                            ...values,
                            attachFile: ''
                          })
                        }}
                      />
                    )}
                  </div>
            </Form.Label>
            )}
              {!column.isHide && (
              <Col md={4} className="mb-3">
              {column.type === 'select' ?
                <>
                <Select
                  options={column.options}
                  name={column.accessorKey as string}
                  defaultValue={column?.options?.[column?.value]}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={column.readOnly}
                  onChange={(e) => {
                    if(column.accessorKey === 'currency_id') {
                      const amount_idr = changeAmountIdr(values.amount, e.value, values.currency_rate)
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        currency_rate: e.value === 'IDR' ? null : values.currency_rate,
                        amount_idr
                      })
                    }else if(column.accessorKey === 'gl_id') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        gl:{
                          gabungan: e.label
                        }
                      })
                    }else if(column.accessorKey === 'wbs_id') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        wbs:{
                          gabungan: e.label
                        }
                      })
                    }else if(column.accessorKey === 'type_of_expense_id') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        typeofexpense:{
                          gabungan: e.label,
                          type_code:e.label
                        }
                      })
                    }else if(column.accessorKey === 'cost_element_id') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        costelement:{
                          gabungan: e.label
                        }
                      })
                    }else if(column.accessorKey === 'cost_center_id') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        costcenter:{
                          gabungan: e.label
                        }
                      })
                    }else if(column.accessorKey === 'missing_receipts') {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value
                      })
                    }
                    
                    // setValues({ ...values, [column.accessorKey]: e.value })
                    // console.log(values, errors);
                  }}
                ></Select>
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  hidden={true}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  // onChange={(e) =>
                  //   setValues({ ...values, [e.target.name]: e.target.value })
                  // }
                  value={values[column.accessorKey as string]}
                  // defaultValue={column.options[column.value]?.value}
                />
                </>
                : column.type === 'date' ?
                <>
                <ShreyuDatepicker
                  hideAddon={true}
                  dateFormat="dd MMM yyyy"
                  onChange={(e) => {
                    // console.log(e, allData.start_date, allData.end_date)
                    setValues({ ...values, [column.accessorKey]: e })
                    // console.log(values, errors);
                  }}
                  value={values[column.accessorKey as string]}
                  startDate={allData.start_date}
                  endDate={allData.end_date}
                  minDate={allData.start_date}
                  maxDate={allData.end_date}
                />
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  hidden={true}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  // onChange={(e) =>
                  //   setValues({ ...values, [e.target.name]: e.target.value })
                  // }
                  value={values[column.accessorKey as string]}
                  // defaultValue={values[column.accessorKey as string]}
                />
                </>
                :  column.type === 'file' ?
                <>
                <FormInput
                  key={column.accessorKey + "1"}
                  // label={column.header}
                  name={column.accessorKey as string + "1"}
                  type={(column.type && column.type === 'file') ? 'file' : 'input'}
                  disabled={column.readOnly}
                  onChange={async(e) =>{
                    // console.log("attachFile: ",e.target.files);
                    if(checkFile(e, false, 3*1048576)){
                      setValues({...values, attachFile: await toBase64(e.target?.files?.[0])})
                    }
                  }}
                  // value={}
                />
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  hidden={true}
                  register={register}
                  errors={errors}
                  control={control}
                  type="input"
                  disabled={column.readOnly}
                  value={values.attachFile}
                />
                </>
                :
                <FormInput
                  key={column.accessorKey}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  errors={errors}
                  control={control}
                  type={(column.type && column.type === 'file') ? 'file' : 'input'}
                  disabled={column.readOnly || (column.accessorKey === 'currency_rate' && values.currency_id === 'IDR')}
                  onChange={async(e) =>{
                    if(column.accessorKey ==='amount' || column.accessorKey === 'amount_idr') {
                      const amount_idr = changeAmountIdr(e.target.value, values.currency_id, values.currency_rate)
                      onChangeMoney(values[column.accessorKey as string], e);
                      let a = e.target.value;
                      if (values[column.accessorKey] === '0,00'){
                        if(parseFloat(replaceToDot(a)) >= 10){
                          a = (parseFloat(replaceToDot(a)) / parseFloat('10')).toString()
                        }
                      }
                      // console.log(a)
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: changeUStoID(a),
                        amount_idr
                      })
                    } else if(column.accessorKey ==='currency_rate'){
                      const amount_idr = changeAmountIdr(values.amount, values.currency_id, e.target.value)
                      onChangeMoney(values[column.accessorKey as string], e);
                      let a = e.target.value;
                      if (values[column.accessorKey] === '0,00'){
                        if(parseFloat(replaceToDot(a)) >= 10){
                          a = (parseFloat(replaceToDot(a)) / parseFloat('10')).toString()
                        }
                      }
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: changeUStoID(a),
                        amount_idr
                      })
                    } else if(column.accessorKey ==='attachFile'){
                      // console.log("attachFile: ",e.target.files);
                      setValues({...values, attachFile: await toBase64(e.target?.files?.[0])})
                    } else {
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  }}
                  value={column.type === 'file' ? "" : values[column.accessorKey as string]}
                />
                }
              </Col>
              )}
            </>
            ))}
          </Form.Group>
          {/* </Stack> */}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => {
          setValues(initialValue);
          onClose();
        }}>Cancel</Button>
        {/* <Button color="secondary" onClick={handleSubmit} variant="contained"> */}
        {/* <Button onClick={handleSubmit((values)=>// console.log(values))}> */}
        <Button onClick={
          handleSubmit(() => {
            // console.log(values)
            values.id = data.id
            values.date_detail = dayjs(values.date_detail).format("YYYY-MM-DD")
            delete values.company
            onSubmit(values)
            onClose()
          })
        }
        >
        {/* <Button variant="primary" type="submit"> */}
          {/* Create New */}
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateClaimFormCC;
