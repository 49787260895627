import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";

// types
export interface ModalAddRowInvoiceProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (newRow: any) => any;
  open: boolean;
  // setItem: Dispatch<SetStateAction<BastItem>>;
  item?: any;
  index?: any;
  isEdit?: boolean;
  invoice_id?: any;
}

const ModalAddRowInvoice = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  invoice_id,
  item,
  index,
}: ModalAddRowInvoiceProps) => {
  const {
    register,
    // control,
    // handleSubmit,
    watch,
    setValue,
    getValues,
    // formState: { errors },
  }: any = useForm({
    defaultValues: {
      total_value: 0,
    },
  });
  // const watchTotalValue = watch("total_value");
  
  const { t } = useTranslation();
  
  const [stockCodeOptions, setStockCodeOptions] = useState([]);
  const [currOptions, setCurrOptions] = useState([]);
  const [uomOptions, setUomOptions] = useState([]);

  const [stockCode, setStockCode] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [UOM, setUom] = useState<{ value: string; label: string } | null>(null);
  const [curr, setCurr] = useState<{ value: string; label: string } | null>(
    null
  );

  const handleSubmit = () => {
    //put your validation logic here
    const {
      currency_id,
      description,
      qty,
      stock_code,
      total_value,
      unit_price,
      uom,
      is_reimbursement,
      // id,
    } = getValues();
    // console.log(getValues(), "FORM VALUES SUBMIT");

    onSubmit({
      is_reimbursement,
      stock_code,
      description,
      uom,
      qty,
      currency_id,
      unit_price,
      total_value,
      // id,
      // bast_id,
    });

    onClose();
  };

  useEffect(() => {
    register("stock_code");
    register("uom");
    register("currency_id");
  }, [register]);

  useEffect(() => {
    const fetchOptions = async ({ url, setOptions, label }: any) => {
      try {
        // const response = await axios({
        //   url: url,
        //   method: "POST",
        //   data: {
        //     pagination: {
        //       pageIndex: 0,
        //       pageSize: 100,
        //     },
        //   },
        // });
        // // console.log(response?.data?.data?.data, url);
        // const dataMap = response?.data?.data?.data?.length && response?.data?.data?.data.map((el: any) => ({
        //   value: el.id,
        //   label: el[label],
        // }));
        // if (dataMap) setOptions(() => [...dataMap]);
      } catch (error) {
        console.log(error, "ERROR FETCH OPTIONS");
      }
    };
    fetchOptions({
      url: "/stock-code-list",
      setOptions: setStockCodeOptions,
      label: "stock_code",
    });
    fetchOptions({
      url: "/currency-list",
      setOptions: setCurrOptions,
      label: "currency_name",
    });
  }, []);
  useEffect(() => {
    const subscription = watch((value: any, { name, type }: any) => {
      // console.log(value, name, type, 'value, name, type')
      if (name === "qty" || name === "unit_price") {
        setValue(
          "total_value",
          (Number(value.qty || 0) * Number(value.unit_price || 0)).toFixed(2)
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    if (item) {
      setValue("id", item.id);
      setValue("description", item.description);
      setValue("uom", item.uom);
      setValue("qty", item.qty);
      setValue("currency_id", item.currency_id);
      setValue("unit_price", item.unit_price);
      setValue("total_value", item.total_value);
      setValue("stock_code", item.stock_code);
    }
  }, [item, index]);

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{t("Input Data")}</DialogTitle>
      <DialogContent>
        <Form>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Stock Code")}</Form.Label>
                <Select
                  options={stockCodeOptions}
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  value={stockCode}
                  onChange={(e) => {
                    setStockCode(e);
                    setValue("stock_code", e?.value);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Description"
                  {...register("description")}
                  // value={description}
                  // onChange={(e) => {
                  //   setDescription(e.target.value);
                  // }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>UOM</Form.Label>
                {/* <Form.Control type="text" value="Month" /> */}
                <Select
                  options={uomOptions}
                  // defaultInputValue=""
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  // isDisabled={item.readOnly}
                  value={UOM}
                  onChange={(e) => {
                    setUom(e);
                    setValue("uom", e?.value);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Qty</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input Quantity"
                  {...register("qty")}
                  onChange={(e) => {
                    // console.log(e.target.value.replace(/\./g, ""))
                    // setValue("qty", changeUStoID(e.target.value))
                    setValue("qty", e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Curr.</Form.Label>
                {/* <Form.Control type="text" value="IDR" /> */}
                <Select
                  options={currOptions}
                  // defaultInputValue=""
                  classNamePrefix="react-select"
                  className="react-select react-select-container"
                  // isDisabled={item.readOnly}
                  value={curr}
                  onChange={(e) => {
                    setCurr(e);
                    setValue("currency_id", e?.value);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Unit Price")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("unit_price")}
                  placeholder="Input Price"
                  onChange={(e) => {
                    setValue("unit_price", e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Est Price (IDR)")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("est_price")}
                  onChange={(e) => {
                    setValue("est_price", e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddRowInvoice;
