import { Row, Col } from "react-bootstrap";
import InvoiceTaxSelect, { InvoiceSelectDebitCredit } from "./InvoiceTaxSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  changeUStoID,
  onChangeMoney,
  removeDot,
  replaceToComma,
  replaceToDot,
} from "../../../helpers/utils";
import { useGetUserRole } from "../../../hooks";
import {
  AddCircle,
  AddCircleOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  AUTH_SESSION_KEY,
  CURRENT_COMPANY,
} from "../../../helpers/api/apiCore";
import {
  getDataGL,
  getDataWBS,
  getDataCostCenter,
} from "../../bast/fetch/getOtherData";
import { useTranslation } from "react-i18next";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const inputStyle: any = {
  display: "block",
  width: "100%",
  // padding: 0.45rem 0.9rem,
  fontSize: "0.875rem",
  fontWeight: 400,
  /* line-height: 1.5, */
  color: "#4B4B5A",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ced4da",
  // appearance: "none",
  borderRadius: "0.25rem",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
};

const InvoiceSumForm = ({
  state,
  dataState,
  register,
  getValues,
  setValue,
  mode,
  taxAble,
  nonTax,
}: any) => {
  const {
    vat,
    setVat,
    wht,
    setWht,
    dpp,
    setDpp,
    whtList,
    vatList,
    afterVat,
    setAfterVat,
    afterWht,
    setAfterWht,
    vat_amount,
    set_vat_amount,
    wht_amount,
    set_wht_amount,
    watch,
    set_net_payment,
    net_payment,
    invoiceDetail,
    is_vat,
    is_wht,
    otherData,
    setOtherData,
    currency_code,
    isVendorCreate,
    isVendorEdit,
  } = dataState;
  // console.log('=============================')
  // console.log(taxAble, 'taxAble')
  // console.log(nonTax, 'nonTax')
  const { t } = useTranslation();

  const { userRole } = useGetUserRole();
  const loginData = JSON.parse(
    sessionStorage.getItem(AUTH_SESSION_KEY) as string
  );
  const isVendor = loginData?.data?.vendor !== null;

  // console.log(dataState)
  // console.log("====================================================");
  // console.log(vat, 'vat');
  // console.log(vatList);
  // console.log(vat_amount);
  // console.log(wht, 'wht');
  // console.log(whtList);
  // console.log(wht_amount);
  // console.log(dpp);
  // console.log(afterVat);
  // console.log(net_payment);
  // console.log("====================================================");

  // const calcTotalOther = useCallback(() => {
  //   return otherData
  //   ? otherData.reduce((t: any, c: any) => {
  //       return (
  //         t + parseFloat(replaceToDot(removeDot(c.amount ? c.amount : "0")))
  //       );
  //     }, 0)
  //   : 0;
  // }, [])

  // const totalOther = useMemo(() => calcTotalOther(), [otherData.length, otherData])
  // CALCULATE TOTAL OTHER EXPENSE
  const totalOther = otherData.length
    ? otherData.reduce((t: any, c: any) => {
        let amount = c.amount ? c.amount : "0";
        // console.log(amount);
        if (c?.debit_or_credit?.value === "C" || c?.debit_or_credit === "C") {
          const a = parseFloat(
            replaceToDot(amount.includes(",") ? removeDot(amount) : amount)
          );
          amount = a * -1;
        }
        // console.log(amount);
        return (
          t +
          parseFloat(
            typeof amount === "number"
              ? amount
              : replaceToDot(amount.includes(",") ? removeDot(amount) : amount)
          )
        );
      }, 0)
    : 0;
  // console.log(otherData);
  // console.log(totalOther);

  // CALCULATE NET PAYMENT
  useEffect(() => {
    // console.log(dpp);
    // console.log(afterVat);
    // console.log(afterWht);
    // console.log(totalOther);
    if (dpp) {
      const nAmount = changeUStoID(
        replaceToComma(
          (
            parseFloat(dpp) +
            // parseFloat(taxAble) + parseFloat(nonTax) +
            parseFloat(replaceToDot(removeDot(afterVat))) -
            // (wht.value ? (dpp * wht.value) / 100 : wht.value)
            parseFloat(replaceToDot(removeDot(afterWht))) +
            totalOther
          ).toFixed(2)
        )
      );
      // console.log(nAmount);
      if (register && setValue) {
        register("net_payment");
        if (afterVat || afterWht) {
          setValue("net_payment", nAmount);
          set_net_payment(nAmount);
        }
      }
      if (isVendorCreate || isVendorEdit) {
        setValue("net_payment", nAmount);
        set_net_payment(nAmount);
      }
    }
  }, [afterVat, afterWht, totalOther, dpp, taxAble]);
  // useEffect(() => {
  //   console.log(dpp);
  //   console.log(afterVat);
  //   console.log(afterWht);
  //   console.log(totalOther);
  //   const nAmount = changeUStoID(
  //     replaceToComma(
  //       (
  //         parseFloat(dpp) +
  //         parseFloat(replaceToDot(removeDot(afterVat))) -
  //         // (wht.value ? (dpp * wht.value) / 100 : wht.value)
  //         parseFloat(replaceToDot(removeDot(afterWht))) +
  //         totalOther
  //       ).toFixed(2)
  //     )
  //   );
  //   console.log(nAmount);
  //   if (setValue) {
  //     setValue("net_payment", nAmount);
  //     set_net_payment(
  //       nAmount
  //       // (
  //       //   parseFloat(dpp) +
  //       //   parseFloat(replaceToDot(removeDot(afterVat))) -
  //       //   // (wht.value ? (dpp * wht.value) / 100 : wht.value)
  //       //   parseFloat(replaceToDot(removeDot(afterWht))) +
  //       //   totalOther
  //       // ).toFixed(2)
  //     );
  //   }
  // }, [totalOther, afterVat, afterWht]);

  // CALCULAGE VAT & WHT AMOUNT
  useEffect(() => {
    if (
      (invoiceDetail?.vendor_type === "International" && taxAble) ||
      taxAble === 0
    ) {
      setAfterVat("0");
      setAfterWht(
        changeUStoID(
          replaceToComma(
            ((taxAble * (wht?.percentage ? wht.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
    } else if (taxAble || taxAble === 0) {
      setAfterVat(
        changeUStoID(
          replaceToComma(
            ((taxAble * (vat?.percentage ? vat.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
      setAfterWht(
        changeUStoID(
          replaceToComma(
            ((taxAble * (wht?.percentage ? wht.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
    }
    if (invoiceDetail?.vendor_type === "International" && mode !== "view") {
      setValue("vat_amount", "0");
      setValue(
        "wht_amount",
        changeUStoID(
          replaceToComma(
            ((taxAble * (wht?.percentage ? wht.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
    } else if (mode !== "view") {
      setValue(
        "vat_amount",
        changeUStoID(
          replaceToComma(
            ((taxAble * (vat?.percentage ? vat.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
      setValue(
        "wht_amount",
        changeUStoID(
          replaceToComma(
            ((taxAble * (wht?.percentage ? wht.percentage : 0)) / 100).toFixed(
              3
            )
          )
        )
      );
    }
    if (
      invoiceDetail?.vendor_type === "International" &&
      set_wht_amount &&
      set_vat_amount
    ) {
      set_vat_amount("0");
      set_wht_amount(
        changeUStoID(
          replaceToComma(
            (
              (parseFloat(taxAble) *
                parseFloat(vat?.percentage ? vat.percentage : 0)) /
              100
            ).toFixed(2)
          )
        )
      );
    } else if (set_wht_amount && set_vat_amount) {
      //
      set_wht_amount(
        changeUStoID(
          replaceToComma(
            (
              (parseFloat(taxAble) *
                parseFloat(wht?.percentage ? wht.percentage : 0)) /
              100
            ).toFixed(2)
          )
        )
      );
      set_vat_amount(
        changeUStoID(
          replaceToComma(
            (
              (parseFloat(taxAble) *
                parseFloat(vat?.percentage ? vat.percentage : 0)) /
              100
            ).toFixed(2)
          )
        )
      );
    }
  }, [taxAble]);
  // console.log(dataState, "DATASTATE");
  // useEffect(() => {
  //   if (set_wht_amount && set_vat_amount) {
  //     //
  //     set_wht_amount((parseFloat(taxAble) * parseFloat(wht.value)) / 100);
  //     set_vat_amount((parseFloat(taxAble) * parseFloat(vat.value)) / 100);
  //     // if (afterVat || afterWht) {
  //     //   const nAmount = changeUStoID(
  //     //     replaceToComma(
  //     //       (
  //     //         parseFloat(dpp) +
  //     //         parseFloat(replaceToDot(removeDot(afterVat))) -
  //     //         // (wht.value ? (dpp * wht.value) / 100 : wht.value)
  //     //         parseFloat(replaceToDot(removeDot(afterWht))) +
  //     //         totalOther
  //     //       ).toFixed(2)
  //     //     )
  //     //   );
  //     //   console.log(nAmount);
  //     //   setValue("net_payment", nAmount);
  //     //   set_net_payment(nAmount);
  //     // }
  //   }
  // }, [taxAble]);

  // useEffect(() => {
  //   setValue(
  //     "wht_amount",
  //     // changeUStoID(((parseFloat(dpp) * parseFloat(wht.value)) / 100).toFixed(2))
  //     changeUStoID(replaceToComma(((parseFloat(dpp) * parseFloat(wht.value)) / 100).toFixed(2)))
  //   );
  // }, [wht]);

  return (
    <div className="">
      <Row className="ms-1">
        <Col md={{ span: 12, offset: 0 }}>
          <div className="mt-3">
            {/* SUBTOTAL */}
            <Row>
              <Col md={2}>
                <p className="fw-bold">SUBTOTAL </p>
              </Col>
              <Col></Col>
              <Col md={1}>
                <p className="fw-bold">{currency_code}</p>
              </Col>
              <Col md={3}>
                <p className="fw-bold">
                  {changeUStoID(replaceToComma(String(dpp)))}
                </p>
              </Col>
            </Row>
            {/* With Holding Base */}
            {/* <Row>
              <Col md={2}>
                <p className="fw-bold">WITH HOLDING BASE</p>
              </Col>
              <Col></Col>
              <Col md={1}>
                <p className="fw-bold">{currency_code}</p>
              </Col>
              <Col md={3}>
                <p className="fw-bold">
                  {changeUStoID(replaceToComma(String(taxAble)))}
                </p>
              </Col>
            </Row> */}
            {/* Non Tax */}
            {/* <Row>
              <Col md={2}>
                <p className="fw-bold">NON TAXABLE</p>
              </Col>
              <Col></Col>
              <Col md={1}>
                <p className="fw-bold">{currency_code}</p>
              </Col>
              <Col md={3}>
                <p className="fw-bold">
                  {changeUStoID(replaceToComma(String(nonTax)))}
                </p>
              </Col>
            </Row> */}
            {/* VAT */}
            <Row>
              <Col md={2}>
                <p className="fw-bold ">{t("VAT Amount")}</p>
              </Col>
              {!state?.isAdminApEdit ||
              invoiceDetail?.approval_status_id === "33" ? (
                <Col>
                  <p>
                    {invoiceDetail?.vat?.wht_code
                      ? `(${invoiceDetail?.vat?.wht_code})`
                      : ""}{" "}
                    {invoiceDetail?.vat?.wht_rate}
                  </p>
                </Col>
              ) : state?.isAdminApEdit &&
                is_vat &&
                invoiceDetail?.vendor_type !== "International" ? (
                <Col>
                  <InvoiceTaxSelect
                    setValue={(e: any) => {
                      // console.log(e);
                      if (e?.percentage || e?.percentage === 0) {
                        setVat(e);
                        const amount = changeUStoID(
                          replaceToComma(
                            ((taxAble * e.percentage) / 100).toFixed(2)
                          )
                        );
                        // console.log(amount);
                        setValue("vat_amount", amount);
                        setAfterVat(amount);
                        set_vat_amount(amount);
                      } else {
                        setVat({ label: "", value: "" });
                        setValue("vat_amount", "0");
                        setAfterVat("0");
                        set_vat_amount("0");
                      }
                    }}
                    options={vatList}
                    value={vat}
                  />
                </Col>
              ) : state?.isAdminApEdit &&
                invoiceDetail?.vendor_type === "International" ? (
                <Col>
                  <p>
                    {/* invoiceDetail?.vat?.wht_code
                      ? `(${invoiceDetail?.vat?.wht_code})`
                      : ""}{" "}
                    {invoiceDetail?.vat?.wht_rate */}
                    {vat?.label ? vat?.label : ""}
                  </p>
                </Col>
              ) : (
                <Col></Col>
              )}
              <Col md={1}>
                <p className="fw-bold ">{currency_code}</p>
              </Col>
              {!state?.isAdminApEdit ||
              mode === "view" ||
              !is_vat ||
              invoiceDetail?.approval_status_id === "33" ? (
                <Col md={3}>
                  <p className="fw-bold ">
                    {changeUStoID(
                      replaceToComma(
                        invoiceDetail?.vat_amount
                          ? invoiceDetail?.vat_amount
                          : ""
                      )
                    )}
                  </p>
                </Col>
              ) : state?.isAdminApEdit &&
                invoiceDetail?.approval_status_id !== "33" &&
                invoiceDetail?.vendor_type === "International" ? (
                <Col md={3}>
                  <p className="fw-bold ">{vat_amount ? vat_amount : "0"}</p>
                </Col>
              ) : (
                // (state?.isAdminApEdit && is_vat) ?
                <Col md={3}>
                  <input
                    type="text"
                    className="w-100"
                    style={inputStyle}
                    // defaultValue={new Intl.NumberFormat().format(
                    //   (dpp * vat.value) / 100
                    // )}
                    // value={afterVat}
                    // onChange={(e) => {
                    // const a: number = Number(Number(e.target.value).toFixed(2))
                    // setAfterVat(a)
                    // setAfterVat(e.target.value);
                    // }}
                    {...register("vat_amount")}
                    onChange={(e) => {
                      // console.log(e);
                      if (mode !== "view") {
                        onChangeMoney(getValues("vat_amount"), e);
                        // console.log(e.target.value);
                        setValue("vat_amount", changeUStoID(e.target.value));
                        setAfterVat(changeUStoID(e.target.value));
                        set_vat_amount(changeUStoID(e.target.value));
                      }
                    }}
                  />
                </Col>
              )}
            </Row>
            {/* 
              // state.isAdminApEdit &&
              // (invoiceDetail?.approval_status_id === "6" ||
              //   invoiceDetail?.approval_status_id === "14")
              !isVendor && (
                <Row>
                  <Col md={2}>
                    <p className="fw-bold">BASE VAT</p>
                  </Col>
                  <Col md={{ span: 2 }}>
                    <p>{changeUStoID(replaceToComma(String(taxAble)))}</p>
                  </Col>
                  <Col md={1}>
                    <p className="">x</p>
                  </Col>
                  <Col md={1}>
                    <p>{vat?.value} %</p>
                  </Col>
                </Row>
              )
             */}

            {/* WHT */}

            {(state?.isAdminApEdit ||
              !userRole.includes("Vendor") ||
              (userRole.includes("Vendor") && invoiceDetail?.wht?.wht_rate) ||
              (invoiceDetail?.approval_status_id === "32" &&
                userRole.includes("Vendor"))) && (
              // true && (
              <Row>
                <Col md={2}>
                  <p className="fw-bold">{t("WHT Amount")}</p>
                </Col>
                {!state?.isAdminApEdit ||
                invoiceDetail?.approval_status_id === "33" ? (
                  <Col>
                    <p>
                      {invoiceDetail?.wht?.wht_code
                        ? `(${invoiceDetail?.wht?.wht_code})`
                        : ""}{" "}
                      {invoiceDetail?.wht?.wht_rate}
                    </p>
                  </Col>
                ) : state?.isAdminApEdit && is_wht ? (
                  <Col>
                    <InvoiceTaxSelect
                      setValue={(e: any) => {
                        // console.log(e)
                        if (e?.percentage || e?.percentage === 0) {
                          setWht(e);
                          // console.log(e)
                          const amount = changeUStoID(
                            replaceToComma(
                              ((taxAble * e.percentage) / 100).toFixed(2)
                            )
                          );
                          // console.log(amount)
                          setValue("wht_amount", amount);
                          setAfterWht(amount);
                          set_wht_amount(amount);
                        } else {
                          setWht({ label: "", value: "" });
                          setValue("wht_amount", "0");
                          setAfterWht("0");
                          set_wht_amount("0");
                        }
                      }}
                      options={whtList}
                      value={wht}
                    />
                  </Col>
                ) : (
                  <Col>{/* <p>{invoiceDetail?.wht?.wht_rate}</p> */}</Col>
                )}
                <Col md={1}>
                  <p className="fw-bold ">{currency_code}</p>
                </Col>
                {!state?.isAdminApEdit ||
                mode === "view" ||
                !is_wht ||
                invoiceDetail?.approval_status_id === "33" ? (
                  <Col
                    md={3}
                    className="position-relative"
                    // style={{ backgroundColor: "red" }}
                  >
                    <span className="position-absolute" style={{ left: 0 }}>
                      -
                    </span>
                    <p className="fw-bold ">
                      {changeUStoID(
                        replaceToComma(
                          invoiceDetail?.wht_amount
                            ? invoiceDetail?.wht_amount
                            : ""
                        )
                      )}
                    </p>
                  </Col>
                ) : (
                  <Col
                    md={3}
                    className="position-relative"
                    // style={{ backgroundColor: "red" }}
                  >
                    <span
                      className="position-absolute"
                      style={{ left: 0, top: "0.15rem" }}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="w-100"
                      style={inputStyle}
                      {...register("wht_amount")}
                      onChange={(e) => {
                        if (mode !== "view") {
                          onChangeMoney(getValues("wht_amount"), e);
                          setValue("wht_amount", changeUStoID(e.target.value));
                          setAfterWht(changeUStoID(e.target.value));
                          set_wht_amount(changeUStoID(e.target.value));
                          // set_wht_amount(changeUStoID(e.target.value));
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>
            )}

            {
              // state.isAdminApEdit &&
              // (invoiceDetail?.approval_status_id === "6" ||
              // invoiceDetail?.approval_status_id === "14")
              !isVendor && (
                <Row>
                  <Col md={2}>
                    <p className="fw-bold">BASE WHT</p>
                  </Col>
                  <Col md={{ span: 2 }}>
                    <p>{changeUStoID(replaceToComma(String(taxAble)))}</p>
                  </Col>
                  <Col md={1}>
                    <p className="">x</p>
                  </Col>
                  <Col md={1}>
                    {!state?.isAdminApEdit ? (
                      <p>
                        {invoiceDetail?.wht?.wht_rate}
                        {invoiceDetail?.wht?.wht_rate?.includes("%") ? "" : "%"}
                      </p>
                    ) : (
                      <p>{wht?.percentage} %</p>
                    )}
                  </Col>
                  {/* <Col md={2}>
                    <p className="">
                      ={" "}
                      {!state?.isAdminApEdit ||
                      mode === "view" ||
                      !is_vat ||
                      invoiceDetail?.approval_status_id === "33"
                        ? changeUStoID(
                            replaceToComma(
                              invoiceDetail?.wht_amount
                                ? invoiceDetail?.wht_amount
                                : ""
                            )
                          )
                        : wht_amount}
                    </p>
                  </Col> */}
                </Row>
              )
            }

            {/* 
          OTHERS EXPENSE
         */}
            <OtherExpence
              otherData={otherData}
              setOtherData={setOtherData}
              currency_code={currency_code}
              isAdminApEdit={state?.isAdminApEdit}
              mode={mode}
              invoiceDetail={invoiceDetail}
            />
            {/* <Row>
              <Col md={2}>
                <p className="fw-bold">Other</p>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <input
                      type="text"
                      className="w-100"
                      style={inputStyle}
                      // {...register("wht_amount")}
                      // onChange={(e) => {
                      //   if (mode !== "view") {
                      //     onChangeMoney(getValues("wht_amount"), e);
                      //     setValue("wht_amount", changeUStoID(e.target.value));
                      //     setAfterWht(changeUStoID(e.target.value));
                      //     set_wht_amount(changeUStoID(e.target.value));
                      //     // set_wht_amount(changeUStoID(e.target.value));
                      //   }
                      // }}
                      placeholder="Input Description"
                    />
                  </Col>
                  <Col>
                    <InvoiceTaxSelect
                    // setValue={(e: any) => {
                    // setWht(e);
                    // setValue(
                    //   "wht_amount",
                    //   changeUStoID(
                    //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                    //   )
                    // );
                    // setAfterWht(
                    //   changeUStoID(
                    //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                    //   )
                    // );
                    // }}
                    // options={whtList}
                    // value={wht}
                    />
                  </Col>
                  <Col>
                    <InvoiceTaxSelect
                    // setValue={(e: any) => {
                    //   setWht(e);
                    //   setValue(
                    //     "wht_amount",
                    //     changeUStoID(
                    //       replaceToComma(((dpp * e.value) / 100).toFixed(2))
                    //     )
                    //   );
                    //   setAfterWht(
                    //     changeUStoID(
                    //       replaceToComma(((dpp * e.value) / 100).toFixed(2))
                    //     )
                    //   );
                    // }}
                    // options={whtList}
                    // value={wht}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={1}>
                <p className="fw-bold">Rp</p>
              </Col>
              <Col md={3}>
                <input
                  type="text"
                  className="w-100"
                  style={inputStyle}
                  // {...register("wht_amount")}
                  // onChange={(e) => {
                  //   if (mode !== "view") {
                  //     onChangeMoney(getValues("wht_amount"), e);
                  //     setValue("wht_amount", changeUStoID(e.target.value));
                  //     setAfterWht(changeUStoID(e.target.value));
                  //     set_wht_amount(changeUStoID(e.target.value));
                  //     // set_wht_amount(changeUStoID(e.target.value));
                  //   }
                  // }}
                />
              </Col>
            </Row> */}

            {/* TOTAL */}
            <Row>
              <Col md={2}>
                <p className="fw-bold">NET PAYMENT</p>
              </Col>
              <Col></Col>
              <Col md={1}>
                <p className="fw-bold">{currency_code}</p>
              </Col>
              <Col md={3}>
                <p className="fw-bold">
                  {((!state?.isAdminApEdit && invoiceDetail?.net_payment) ||
                    (state?.isAdminApEdit &&
                      invoiceDetail?.approval_status_id === "33")) &&
                  !isVendorEdit
                    ? changeUStoID(
                        replaceToComma(
                          invoiceDetail?.net_payment
                            ? invoiceDetail.net_payment
                            : "0"
                        )
                      )
                    : // : changeUStoID(
                    //     replaceToComma(net_payment ? net_payment : "0")
                    //   )}
                    // net_payment
                    // ? net_payment
                    // : 0}

                    // getValues && getValues("net_payment")
                    // ? changeUStoID(replaceToComma(getValues("net_payment")))
                    // : "0"}
                    isVendorCreate
                    ? net_payment
                      ? net_payment
                      : "0"
                    : net_payment}
                  {/* net_payment
                    ? changeUStoID(replaceToComma(net_payment))
                    : invoiceDetail?.net_payment?.length
                    ? changeUStoID(replaceToComma(invoiceDetail.net_payment))
                    : "" */}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceSumForm;

const OtherExpence = ({
  otherData,
  setOtherData,
  currency_code,
  isAdminApEdit,
  mode,
  invoiceDetail,
}: any) => {
  // console.log(otherData)
  const { t } = useTranslation();
  const loginData = JSON.parse(
    sessionStorage.getItem(AUTH_SESSION_KEY) as string
  );
  const isVendor = !!loginData?.data?.vendor?.id;
  // console.log(loginData?.data?.vendor?.id);

  const [glList, setGlList] = useState<any>([]);
  const [wbsList, setWbsList] = useState<any>([]);
  const [costCenterList, setCostCenterList] = useState<any>([]);

  useEffect(() => {
    const getGl = async () => {
      const data = await getDataGL();
      // console.log(data);
      setGlList(data);
    };
    const getWbs = async () => {
      const data = await getDataWBS(userCurrentCompany?.company_initial);
      // console.log(data);
      setWbsList(data);
    };
    const getCostCenter = async () => {
      const data = await getDataCostCenter(userCurrentCompany?.company_initial);
      // console.log(data);
      setCostCenterList(data);
    };
    getGl();
    if (userCurrentCompany?.company_initial === "VEI") getCostCenter();
    if (userCurrentCompany?.company_initial === "STM") getWbs();
  }, []);

  return (
    <>
      {(otherData?.length > 1 ||
        (otherData?.length === 1 && otherData[0].amount) ||
        isVendor ||
        isAdminApEdit) &&
        otherData.map((el: any, idx: any) => (
          <Row key={idx}>
            {/* 1 */}
            <Col md={2}>
              <div className="position-relative">
                {(isVendor || isAdminApEdit) &&
                  mode !== "view" &&
                  invoiceDetail?.approval_status_id !== "33" &&
                  (idx === otherData.length - 1 ? (
                    <div
                      className="position-absolute cursor-pointer"
                      style={{ left: "-20px" }}
                      onClick={() =>
                        setOtherData((prev: any) => [
                          ...prev,
                          {
                            expense_desc: "",
                            gl_no: "",
                            gl: "",
                            wbs_no: "",
                            wbs: "",
                            coscenter_no: "",
                            costcenter: "",
                            amount: "",
                          },
                        ])
                      }
                    >
                      <AddCircleOutline style={{ fontSize: "16px" }} />
                    </div>
                  ) : (
                    <div
                      className="position-absolute cursor-pointer"
                      style={{ left: "-20px" }}
                      onClick={() => {
                        setOtherData((prev: any) => {
                          // console.log(idx);
                          // console.log(prev);
                          prev.splice(idx, 1);
                          // console.log(prev);
                          return [...prev];
                        });
                      }}
                    >
                      <RemoveCircleOutline style={{ fontSize: "16px" }} />
                    </div>
                  ))}
                <p className="fw-bold">{t("Other Adjustment")}</p>
              </div>
            </Col>
            {/* 2 */}
            <Col>
              <Row>
                <Col>
                  {(isVendor || isAdminApEdit) &&
                  mode !== "view" &&
                  invoiceDetail?.approval_status_id !== "33" ? (
                    <input
                      type="text"
                      className="w-100"
                      style={inputStyle}
                      // {...register("wht_amount")}
                      // onChange={(e) => {
                      //   if (mode !== "view") {
                      //     onChangeMoney(getValues("wht_amount"), e);
                      //     setValue("wht_amount", changeUStoID(e.target.value));
                      //     setAfterWht(changeUStoID(e.target.value));
                      //     set_wht_amount(changeUStoID(e.target.value));
                      //     // set_wht_amount(changeUStoID(e.target.value));
                      //   }
                      // }}
                      onChange={(e) => {
                        // console.log(e);
                        setOtherData((prev: any) => {
                          // console.log(prev[idx]);
                          prev[idx].expense_desc = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={el.expense_desc}
                      placeholder={t("Input Description")}
                    />
                  ) : (
                    <p className="fw-bold">{el.expense_desc}</p>
                  )}
                </Col>
                <Col>
                  {mode !== "view" &&
                  (invoiceDetail?.approval_status_id === "6" ||
                    invoiceDetail?.approval_status_id === "14" ||
                    isVendor) ? (
                    <InvoiceSelectDebitCredit
                      value={el.debit_or_credit}
                      setValue={(e: any) => {
                        setOtherData((prev: any) => {
                          // console.log(prev[idx]);
                          prev[idx].debit_or_credit = e;
                          return [...prev];
                        });
                      }}
                    />
                  ) : (
                    <p className="fw-bold">
                      {el.debit_or_credit === "D"
                        ? "Debit"
                        : el.debit_or_credit === "C"
                        ? "Credit"
                        : ""}
                    </p>
                  )}
                </Col>
                <Col>
                  {isAdminApEdit &&
                  mode !== "view" &&
                  (invoiceDetail?.approval_status_id === "6" ||
                    invoiceDetail?.approval_status_id === "14") ? (
                    <InvoiceTaxSelect
                      setValue={(e: any) => {
                        // setWht(e);
                        // setValue(
                        //   "wht_amount",
                        //   changeUStoID(
                        //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                        //   )
                        // );
                        // setAfterWht(
                        //   changeUStoID(
                        //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                        //   )
                        // );
                        // console.log(e);
                        setOtherData((prev: any) => {
                          // console.log(prev[idx]);
                          if (e?.value) {
                            prev[idx].gl = e;
                            prev[idx].gl_no = e.value;
                          } else {
                            prev[idx].gl = null;
                            prev[idx].gl_no = null;
                          }
                          return [...prev];
                        });
                      }}
                      options={glList}
                      placeholder="Select GL"
                      value={el.gl}
                    />
                  ) : (
                    <p className="fw-bold">{el.gl_no}</p>
                  )}
                </Col>
                <Col>
                  {isAdminApEdit &&
                  mode !== "view" &&
                  (invoiceDetail?.approval_status_id === "6" ||
                    invoiceDetail?.approval_status_id === "14") ? (
                    <InvoiceTaxSelect
                      setValue={(e: any) => {
                        // setWht(e);
                        // setValue(
                        //   "wht_amount",
                        //   changeUStoID(
                        //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                        //   )
                        // );
                        // setAfterWht(
                        //   changeUStoID(
                        //     replaceToComma(((dpp * e.value) / 100).toFixed(2))
                        //   )
                        // );
                        // console.log(e);
                        setOtherData((prev: any) => {
                          // console.log(prev[idx]);
                          if (userCurrentCompany?.company_initial === "VEI") {
                            if (e?.value) {
                              prev[idx].costcenter = e;
                              prev[idx].coscenter_no = e.value;
                            } else {
                              prev[idx].costcenter = null;
                              prev[idx].coscenter_no = null;
                            }
                          } else {
                            if (e?.value) {
                              prev[idx].wbs = e;
                              prev[idx].wbs_no = e.value;
                            } else {
                              prev[idx].wbs = null;
                              prev[idx].wbs_no = null;
                            }
                          }
                          return [...prev];
                        });
                      }}
                      options={
                        userCurrentCompany?.company_initial === "VEI"
                          ? costCenterList
                          : wbsList
                      }
                      value={
                        userCurrentCompany?.company_initial === "VEI"
                          ? el.costcenter
                          : el.wbs
                      }
                      placeholder={`Select ${
                        userCurrentCompany?.company_initial === "VEI"
                          ? "Cost Center"
                          : "WBS"
                      }`}
                    />
                  ) : (
                    <p className="fw-bold">
                      {userCurrentCompany?.company_initial === "VEI"
                        ? el.coscenter_no
                        : el.wbs_no}
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={1}>
              <p className="fw-bold">{currency_code}</p>
            </Col>
            <Col md={3} className="position-relative">
              {(isVendor || isAdminApEdit) &&
              mode !== "view" &&
              invoiceDetail?.approval_status_id !== "33" ? (
                <>
                  {(el.debit_or_credit === "C" ||
                    el.debit_or_credit?.value === "C") && (
                    <span
                      className="position-absolute"
                      style={{ left: 0, top: "0.15rem" }}
                    >
                      -
                    </span>
                  )}
                  <input
                    type="text"
                    className="w-100"
                    style={inputStyle}
                    onChange={(e) => {
                      setOtherData((prev: any) => {
                        onChangeMoney(prev[idx].amount, e);
                        prev[idx].amount = changeUStoID(e.target.value);
                        return [...prev];
                      });
                    }}
                    value={el.amount}
                    placeholder={t("Input Amount")}
                  />
                </>
              ) : (
                <>
                  <p className="fw-bold">
                    {el.debit_or_credit === "C" && (
                      <span className="position-absolute" style={{ left: 0 }}>
                        -
                      </span>
                    )}
                    {mode === "view"
                      ? changeUStoID(replaceToComma(el.amount))
                      : el.amount}
                  </p>
                </>
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};
