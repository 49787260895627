import { useEffect, useState } from "react";
import BastListComponentClone from "./BastListComponentClone";

import { BASTRecords } from "./data";

// types
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { getData } from "./fetch/getBastListClone";
import { useGetUserRole } from "../../hooks";

type ModalTypeState = "delete" | "approve" | "reject" | "bulk approve" | "";

const BastListClone = () => {
  const records: BASTRecords[] = [
    // {
    //   no: 1,
    //   contractNumber: "4509791607",
    //   migoses: "1005464366",
    //   supplierName: "ESIT CANADA ENTERPRISE SERVICES CO",
    //   amount: "USD 1,731",
    //   status: WAITING_APPROVAL_CONTRACT_OWNER,
    //   createInvoice: "",
    //   canEdit: true,
    //   canDelete: true,
    //   canApprove: true,
    // },
  ];

  const [bastList, setBastList] = useState<any>([]);

  const [isError, setIsError] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: true },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<any>({});

  const { userRole, userName, userId } = useGetUserRole();
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalTypeState>("");

  const [selectedBast, setSelectedBast] = useState<any>([]);
  const [bastStatus, setBastStatus] = useState<any>({
    id: "",
    approval_status_id: "",
  });

  useEffect(() => {
    // console.log("columnFilters", columnFilters);
    // console.log("sorting", sorting);
    // console.log("globalFilter", globalFilter);
    // console.log("pagination", pagination);
    getData({
      columnFilters,
      sorting,
      pagination,
      setBastList,
      setRowCount,
      setIsError,
      // defaultFilter: userType === "contract-owner" || userType === "warehouse-officer" ? [{ id: "suplier_name", value: userName }] : [],
      // defaultFilter: contractOwnerFilters,
      // contractOwnerFilters,
    });
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  return (
    <BastListComponentClone
      data={{
        bastList,
        setBastList,
        columnFilters,
        setColumnFilters,
        sorting,
        setSorting,
        pagination,
        setPagination,
        rowCount,
        setRowCount,
        isError,
        setIsError,
        rowSelection,
        setRowSelection,
        globalFilter,
        setGlobalFilter,
        isModalConfirmationOpen,
        setIsModalConfirmationOpen,
        modalType,
        setModalType,
        selectedBast,
        setSelectedBast,
        bastStatus,
        setBastStatus,
      }}
      user={{ userRole, userName, userId }}
    />
  );
};

export default BastListClone;
