import IASAdminAPSpecial from "./IASAdminAPSpecial";

// images
import logoVale from "../../assets/images/stm-logo.png";
import { API_URI } from "../../constants";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import { changeUStoID, replaceToComma, replaceToDot } from "../../helpers/utils";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
// import logoVale from "../../assets/images/logo-dashboard.png";

const IASAdminAPView = () => {
  const [dataAsli, setDataAsli] = useState();
  const [dataIAS, setDataIAS] = useState<any>({});
  const [iasID, setIasID] = useState<any>(0);
  const [checkList, setCheckList] = useState<any>([]);
  const param  = useParams();
  let check: any = {};
  let id = parseInt(param?.id as string);
  let edit = param.edit
  const getData =(() => {
    getDataCheckList()
    .then((checklist) => {
      console.log(checklist);
      fetch(API_URI + `special-payment/${id}`, {
        method: "GET"
      })
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        if(data && data.success){
          if(data.data) {
            console.log(data.data)
            const b = data.data
            setDataAsli(data.data);
            const a = data.data;
            setDataIAS({
              // ...dataIAS,
              vendorName: {
                title: "Vendor Name",
                value1: a.vendor.vendor_name,
                value2: "",
              },
              invoiceNo: {
                title: "Invoice No.",
                value1: a.special_payment_number,
                value2: "",
              },
              paymentDesc: {
                title: "Payment Description",
                value1: "",
              },
              invoiceDate: {
                title: "Invoice Date",
                value1: dayjs(a.created_at).format('DD MMM YYYY'),
                value2: "",
              },
              checkList: [
                {
                  checked: check.is_invoice == 1 ? true : false,
                  desc: "Invoice dan kuitansi dengan materai (jika ada)",
                },
                {
                  checked: check.is_faktur_pajak == 1 ? true: false,
                  desc: "Faktur Pajak (For PKP Only)",
                },
                {
                  checked: check.is_delivery_order == 1 ? true: false,
                  desc: "Delivery Order (For Goods Only)",
                },
                {
                  checked: check.is_bast_complete == 1 ? true : false,
                  desc: "BAST Complete with SES Number",
                },
                {
                  checked: check.is_contract_expenditure == 1 ? true: false,
                  desc: "Summary of Contract Expenditure (For Contract & Long term PO)",
                },
                {
                  checked: check.is_purchase_order == 1 ? true : false,
                  desc: "Purchase Order (For Spot Purchase Only)",
                },
                {
                  checked: check.is_confirmation_sheet == 1 ? true : false ,
                  desc: "Confirmation Sheet (For Contract & Long term PO)",
                },
                {
                  checked: check.is_cod_dgt == 1 ? true : false,
                  desc: "COD & DGT (For Overseas Services Only))",
                },
                {
                  checked: check.is_invoice_kwitansi == 1 ? true : false,
                  desc: "Invoice/kuitansi Reimbursment (jka ada)",
                },
                {
                  checked: check.is_timesheet == 1 ? true : false,
                  desc: "Timesheet (jika ada)",
                },
                {
                  checked: check.is_sk_pembebasan == 1 ? true : false,
                  desc: "SK Pembebasan PPh 23 (jika ada)",
                },
                {
                  checked: check.is_others == 1 ? true : false,
                  desc: "Dokumen Pendukung Lainnya (jika ada)",
                },
              ],
              receivedDate: dayjs(a.created_at).format('DD MMM YYYY'),
              receivedBy: data.apAdmin,
              deliveredBy: data.presidentDirector,
              wbsNo: a.wbs_no ? a.wbs_no : a.cost_center_id,
              glNo: a.gl_no,
              poNo: "-",
              migoSes: "-",
              miro: a.fv60 ? a.fv60 : "-",
              paymentNo: "-",
              userDeptHead: a.direct_manager ? `APPROVAL by ${a.direct_manager}` : '',
              financeStaff: data.apAdmin,
              financeHead: data.financeManager ? `APPROVAL BY ${data.financeManager}` : '',
              poaDir: data.presidentDirector ? `APPROVAL BY ${data.presidentDirector}` : '',
              curr: a.currency_id,
              dpp: changeUStoID(a.payment_amount),
              vat: {
                name: a.is_vat === '1' ? '11' : ' - ',
                percentage: " - ",
                // amount: a.is_vat ? changeUStoID(11/100*replaceToDot(a.payment_amount)) : '-',
                amount: a.vat_amount ? changeUStoID(a.vat_amount) : "-",
              },
              wht: {
                name: a.wht_name,
                percentage: a.wht_rate ? a.wht_rate : "-",
                amount: a.amount_of_wht ? changeUStoID(a.amount_of_wht) : "-",
              },
              // netPayment: a.is_vat ? changeUStoID(parseFloat(replaceToDot(a.payment_amount)) + (11/100*parseFloat(replaceToDot(a.payment_amount)))) : changeUStoID(replaceToDot(a.payment_amount)),
              netPayment: changeUStoID(replaceToComma(
                (parseFloat(replaceToDot(a.payment_amount)) + (parseFloat(replaceToDot(a.vat_amount)) - (parseFloat(replaceToDot(a.amount_of_wht))))).toFixed(2)
              )),
              approval: a.financeManager ? [
                ["Finance", `APPROVAL BY ${a.financeManager}`],
                ["Taxation", a.user.name],
              ] : [
                ["Finance", `-`],
                ["Taxation", a.user.name],
              ]
              ,
              notes: "",
            })
            // console.log(dataIAS)
          }
        }
      })
    })
  })
  const getIASID = (() => {
    fetch(API_URI + `special-payment-ias`, {
      method: "POST",
      body: JSON.stringify([{
        "special_payment_id": id
      }]),
    })
    .then(response => response.json())
    .then(async(data) => {
      console.log(data);
      setIasID(data.result.id)
    })
  })
  // special-payment-ias/143
  const getDataCheckList = (() => {
    return new Promise ((resolve, reject) => {
      fetch(API_URI + `special-payment-ias/${id}`, {
        method: "GET",
        // body: JSON.stringify([{
        //   "special_payment_id": id
        // }]),
      })
      .then(response => response.json())
      .then((data) => {
        console.log(data.data);
        setCheckList(data.data)
        check = {...data.data};
        resolve(data.data)
      })
      .catch(err => {
        reject(err);
      })
    })
  })

  const setIASID = (() => {
    fetch(API_URI + `special-payment-ias`, {
      method: "POST",
      body: JSON.stringify([{
        // "id": iasID,
        special_payment_id: id,
        is_invoice: dataIAS?.checkList?.[0].checked ? 1 : 0,
        is_faktur_pajak: dataIAS?.checkList?.[1].checked ? 1 : 0,
        is_delivery_order: dataIAS?.checkList?.[2].checked ? 1 : 0,
        is_bast_complete: dataIAS?.checkList?.[3].checked ? 1 : 0,
        is_contract_expenditure: dataIAS?.checkList?.[4].checked ? 1 : 0,
        is_purchase_order: dataIAS?.checkList?.[5].checked ? 1 : 0,
        is_confirmation_sheet: dataIAS?.checkList?.[6].checked ? 1 : 0,
        is_cod_dgt: dataIAS?.checkList?.[7].checked ? 1 : 0,
        is_invoice_kwitansi: dataIAS?.checkList?.[8].checked ? 1 : 0,
        is_timesheet: dataIAS?.checkList?.[9].checked ? 1 : 0,
        is_sk_pembebasan: dataIAS?.checkList?.[10].checked ? 1 : 0,
        is_others: dataIAS?.checkList?.[11].checked ? 1 : 0,
        status: 1
        // is_invoice: dataIAS?.checkList?.[0].checked ? 1 : 0,
      }]),
    })
    .then(response => response.json())
    .then(async(data) => {
      console.log(data);
      if(data.success) {
        toast.success('Success Updating IAS data')
      }
    })
  })
  
  const handleClick=((index: any, item: any) => {
    if(edit === 'edit'){
      const a = dataIAS?.checkList
      // console.log("handleClick",index, item, dataIAS?.checkList?.length)
      const hasil = [];
      for (let i = 0; i < dataIAS?.checkList?.length; i++) {
        if(i === index) {
          hasil.push({checked: !dataIAS?.checkList[i].checked, desc: dataIAS?.checkList[i].desc})
        }else {
          hasil.push(dataIAS?.checkList[i])
        }
      }
      // console.log(hasil)
      setDataIAS({...dataIAS, checkList: [...hasil]})
      // setIsChecked((prev: boolean) => !prev)
    }
  })

  useEffect(() => {
    getData()
    // if(edit === 'edit') {
    //   getIASID()
    // }
  },[id])
  return (
    <>
      <IASAdminAPSpecial
        pageTitle="Invoice IAS"
        logo={logoVale}
        sheetNo="650"
        data={dataIAS}
        edit={edit}
        handleClick={handleClick}
      />
      {edit === 'edit' &&
      <Button onClick={() => setIASID()}>
        Submit
      </Button>
      }
    </>
    // <div></div>
  );
};

export default IASAdminAPView;
