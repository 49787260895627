import { Col, Form, Row, Button } from "react-bootstrap";
import ShreyuDatepicker from "../../Datepicker";
import FeatherIcons from "feather-icons-react";
import PDF from "./Payment Advice - Mandiri.pdf";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { toBase64 } from "../../../utils";
import { API_URI } from "../../../constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { b64toBlob, checkFile } from "../../../helpers/utils";
import FindInPage from "@mui/icons-material/FindInPage";
import { Delete } from "@mui/icons-material";

const PaymentAdvice3 = (props: any) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  let eButton: any
  console.log(props.data);
  const [data, setData] = useState(props.data)
  let submitted = false;
  dayjs.extend(customParseFormat)
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const dateToServer = ((data: string) => {
    return dayjs(data, "DD MMM YYYY").format('YYYY-MM-DD')
  })

  const sendEditSpecialPayment = async (data2: any) => {
    // const response = await axios.get(API_URI + 'department/');
    setLoad(true);
    console.log(data2);
    fetch(API_URI + 'employee-claim', {
      method: "POST",
      body:JSON.stringify(data2),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      toast.success('Sucess Save Payment Advice')
      eButton.disabled = false
      setLoad(false);
      navigate(`/employee-claim/ec-list`)
    })
    .catch(err => {
      console.log(err)
      eButton.disabled = false
      toast.error('Failed To save Payment Advice', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  useEffect(() => {
    dayjs.extend(customParseFormat)
    const a = props?.data
    const b = a?.paid_date ? new Date(a?.paid_date) : new Date()
    setData({...a, paid_date: b});
    submitted = (a?.paymentAdvice) ? true : false
  }, [props])

  return (
    <div className="card mb-12">
      <Loading loading={load} />
      <div className="card-header bg-vale">
        <p className="m-0">Payment Advice</p>
      </div>
      <div className="m-3">
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="paymentAdvice">
              <Form.Label>Upload Payment Advice</Form.Label>
              <div style={{ display: "inline" }}>
                {data?.paymentAdvice && (
                  <FindInPage
                    onClick={() => {
                      const win: any = window.open();
                      const [meta, data2] = data?.paymentAdvice?.split("base64,")
                      const blob = b64toBlob(data2, "application/pdf");
                      const url = URL.createObjectURL(blob);
                      win.document.write(
                        `<iframe src="${
                          url
                        }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                      );
                    }}
                    className="cursor-pointer ms-2"
                  />
                )}
                {data?.paymentAdvice && (
                  <Delete
                    color="error"
                    className="cursor-pointer ms-2"
                    onClick={() =>{
                      setData({
                        ...data,
                        paymentAdvice: null
                      })
                    }}
                  />
                )}
              </div>
              <Form.Control
                type="file"
                placeholder="Input File"
                onChange={async(e: any) => {
                  if(checkFile(e)){
                    const file = await toBase64(e.target?.files[0]);
                    console.log(file)
                    setData({...data, paymentAdvice: file })
                  }
                }}
                // disabled={props.data.paymentAdvice.isDisabled}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="paidDate">
              <Form.Label>Paid Date</Form.Label>
              <Form.Control
                type="date"
                // defaultValue={new Date().toISOString().split("T")[0]}
                value={data?.paid_date ? dayjs(data?.paid_date).add(8, 'hour').toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}
                // isInvalid={errors.posting_date ? true : false}
                // disabled={new Date().getMonth() !== new Date(data?.paid_date).getMonth()}
                onChange={(e) => {
                  console.log(e.target.value);
                  setData({ ...data, paid_date: e.target.value })
                }}
              />
              {/* <ShreyuDatepicker
                hideAddon={true}
                dateFormat="dd MMM yyyy"
                value={data?.paid_date ? new Date(data.paid_date) : undefined}
                onChange={(date) => {
                  setData({...data, paid_date: date})
                }}
              /> */}
            </Form.Group>
          </Col>
          <Col
            md={4}
            className="d-flex align-items-center justify-content-end"
            // className="d-flex align-items-center justify-content-between"
          >
            {/* {
              props.rejectButton()
            } */}
            {/* {submitted ? <div></div>: */}
            <Button className="vale-button ms-3"
              onClick={(e: any) =>{
                eButton = e.target 
                eButton.disabled = true;
                sendEditSpecialPayment([{
                  id: data?.id,
                  paymentAdvice: data?.paymentAdvice,
                  paid_date: dayjs(data?.paid_date).format("YYYY-MM-DD"),
                  approval_status_id: data?.approval_status_id,
                  approved_by: JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id,
                  user_id: JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id,
                  company_code: JSON.parse(sessionStorage.getItem('current_company') as string).company_code
                }])
              }}
            >Submit</Button>
            {/* } */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentAdvice3;
