import { Row, Col } from "react-bootstrap";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Pdf from '../../../constants/invoice.pdf';
import bastPdf from "./evim bast sample.pdf";
import timesheetPdf from "./evim timesheet sample.pdf";
import poPdf from "./evim po sample.pdf";
import confirmationSheetPdf from "./evim confirmatoin sheet sample.pdf";
import invoicePdf from "./evim invoice sample.pdf";
import allPdf from "./VEI_ESI_6022756_USD_1,731.00.pdf";
import dgtPdf from "./evim form dgt sample.pdf";

const DetailItem = ({ title, value = "-" }: any) => {
  const perluButton = value && value[0].includes(".pdf") ? true : false;
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Row style={{ borderBottom: "1px dotted #32313121" }}>
      <Col md={3} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>{title}</p>
      </Col>
      <Col md={1} style={{ marginBottom: 0 }}>
        <p style={{ textAlign: "center", marginBottom: 0 }}>:</p>
      </Col>
      <Col md={8} style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>
          {value}{" "}
          {perluButton && (
            <FindInPageIcon
              onClick={() => {
                openInNewTab(
                  value[0] === "BAST.pdf"
                    ? bastPdf
                    : value[0] === "timesheet.pdf"
                    ? timesheetPdf
                    : value[0] === "All Document.pdf"
                    ? allPdf
                    : value[0] === "invoice.pdf"
                    ? invoicePdf
                    : value[0] === "confirmation sheet.pdf"
                    ? confirmationSheetPdf
                    : value[0] === "cod/cor & dgt.pdf"
                    ? dgtPdf
                    : value[0] === "purchase order.pdf"
                    ? poPdf
                    : Pdf
                );
              }}
              className="cursor-pointer"
            />
          )}
        </p>
      </Col>
    </Row>
  );
};

export default DetailItem;
