export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    // if (file.size > 1048576) {
    //   console.log('over')
    //   reject('File size exceed 1MB')
    // }
    // console.log(file.size)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
