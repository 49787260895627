import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_Row, MRT_SortingState } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit, Check, Close, FileUpload } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CreateClaimNonCC from "./CreateClaimNonCC";
import { useTranslation, Trans } from 'react-i18next';
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { changeUStoID, checkColumnFilters, getCompanySession, getWorkflowRoleCC, replaceToDot, roleEmployeeClaim, roleSpecialPayment, setApprovalAPI } from "../../helpers/utils";
import * as XLSX from 'xlsx/xlsx.mjs';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ConfirmationModal2 from "../../components/MyComponents/Modal/ConfirmationModal2";
import ConfirmationModalEC from "../../components/MyComponents/Modal/ConfirmationModalEC";
import ConfirmationModalCC from "../../components/MyComponents/Modal/ConfirmationModalCC";
import dayjs from "dayjs";
import Loading from "../../components/MyComponents/Loading";
import FeatherIcon from "feather-icons-react";
import { checkNotificationNumber } from "../../helpers/utils2";

type InvoiceColumn = {
  id?: number;
  employee_claim_number?: string;
  subcompany_code?: string;
  doc_no?: string;
  reason_travel_advance?: string;
  employee_id?: string;
  previous_form?: string;
  status: string;
  total_expense_claimed: string;
  difference: string;
  company_to_pay: string;
  return_to_company: string;
  action: string;
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
  canReject: boolean;
  approval_status_id: string;
  approval_status?:{
    id?:number;
    status_name?: string;
    status_color?: string;
  },
  employee:{
    payroll_name: string;
  },
  created_at?: Date;
  currency_id: string;
};

type ModalTypeState = "delete" | "approve" | "reject" | "";

const ClaimCCList = () => {
  const { t, i18n } = useTranslation();
  const [isModalPaymentAdviceOpen, setIsModalPaymentAdviceOpen] = useState(false);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [dataReject, setDataReject] = useState<any>({});
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const [listEmployee, setListEmployee] = useState<any>([]);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  //table state
  const [rowCount, setRowCount] = useState(0);
  const [load, setLoad] = useState(false);
  const colfil = JSON.parse(sessionStorage.getItem('ec-cc') as string);
  const [columnFilters, setColumnFilters] = useState<any>(colfil ? colfil : []);
  const [globalFilter, setGlobalFilter] = useState('');
  // const [sorting, setSorting] = useState<MRT_SortingState>([{
  //   id: 'id',
  //   desc: true
  // }]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const pagi = JSON.parse(sessionStorage.getItem('ec-cc-pagination') as string)
  console.log(pagi)
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    pagi?.pageSize ? pagi : {
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [CSVData, setCSVData] = useState<ExcelColumn[]>([]);
  const [CSVHead, setCSVHead] = useState();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [file, setFile] = useState({});
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));

  const [isExportMonth, setIsExportMonth] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [start_date, set_start_date] = useState("");
  const [end_date, set_end_date] = useState("");
  const [allDepartment, setAllDepartment] = useState<any>(false);

  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(data);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = [];
      Object.keys(jsa[0]).forEach(function(key) {
        head.push(key);
      });
      setCSVData(jsa);
      setCSVHead(head);
      setCreateModalOpen(true);
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const checkValid = (head: any, jsa: any) => {
    let valid = true;
    for(let a in jsa) {
      for (const b of head) {
        let ketemu = false;
        for (let [key, value] of Object.entries(jsa[a])) {
            if(b === key) {
              if(value !== ''){
                ketemu = true;
              }
            }
        }
        if(!ketemu){
          console.log(`${b} is required!`)
          // jsa[a][b] = `${b} is required!`
          valid = false;
        }
      }
    }
    setIsValid(valid);
    return valid;
  }

  const onChangeUploadCSV = (e: any) => {
    console.log(e)
    setFile(e);
  }
  type ExcelColumn = {
    docNo: string,
    paidDate: Date
  }
  const handleSaveCell = (cell: MRT_Cell<ExcelColumn>, value: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    console.log(value);
    CSVData[cell.row.index][cell.column.id as keyof ExcelColumn] = value;
    //send/receive api updates here
    setCSVData([...CSVData]); //re-render with new data
    setIsValid(checkValid(CSVHead, CSVData));
  };

  const [role, setRole] = useState(getWorkflowRoleCC('10'));
  const session_storage = JSON.parse(sessionStorage?.getItem("shreyu_user")as string)
  const permissions = session_storage?.data?.permissions;
  const [permission, setPermission] = useState<any>({});

  console.log(role);

  const checkColumnFiltersCompanyCode = () => {
    if(columnFilters.length <= 1){
       return [
        {id: 'company', value: currentCompany.company_initial},
        // {id: 'employee_id',value: role.employee.payroll_id}
      ];
    } else {
      for (const a of columnFilters) {
        if(a.id === 'company') { 
          a.value = currentCompany.company_initial
        }
      }
      return columnFilters
    }
  }
  const getData = async () => {
    setLoad(true)
    let colFilter: any = [];
    if(permissions) {
      console.log('permissions:', permissions);
      for (const a of permissions){
        if (a.modules_id === '10'){
          console.log('permission:' ,a);
          setPermission(a)
        }
      }
    }
    colFilter = checkColumnFiltersCompanyCode();
    fetch(API_URI + 'employee-claim-cc-list', {
      method: "POST",
      body:JSON.stringify({
        // columnFilters:[
        //   ...columnFilters,{
        //     id: "company_code",
        //     value: currentCompany.company_initial
        //   }
        // ],
        columnFilters: colFilter,
        sorting,
        pagination,
        employee_id: role.employee?.payroll_id ? role.employee?.payroll_id : null,
        all_department: allDepartment
      }),
      headers:{
        "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem('shreyu_user')as string).access_token,
        // "Content-Type":"application/json",
        // "Access-Control-Allow-Origin": "*"
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          const c = [...data.data.data];
          console.log(role);
          for(const b of c) {
            let ketemu = false;
            for(const aa of role?.role){
              if(b.approval_status?.status_name?.includes(aa)){
                ketemu = true;
              }
            }
            console.log(ketemu);
            b.canEdit = (
              b.approval_status?.status_name === 'Rejected' && 
              (b.approval_status?.id === 430 && (role?.employee?.id === b.employee?.id))) && 
              role?.canEdit ? true : false
            b.canDelete = (
              b.approval_status?.status_name === 'Rejected' && 
              ((b.approval_status?.id === 430 && role?.role?.includes('SAP Admin'))) && 
              role?.canDelete) ? true : false
            if(role?.role?.includes('System Admin')){
              // b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && role?.canApprove) ? true : false
              // b.canReject = (b.approval_status?.status_name?.includes('Waiting') && role?.canReject) ? true : false
            }else {
              console.log(role?.employee, b.direct_manager, b.approval_status_id)
              // if (b.approval_status_id == 423){
              //   if((role?.employee?.payroll_name?.toLowerCase() === b.direct_manager?.toLowerCase() ||
              //   role?.employee?.payroll_name?.toLowerCase() === b.assign_direct_manager?.toLowerCase()
              //   ) && b.approval_status_id == 423){
              //     b.canApprove = true
              //     b.canReject = true
              //     b.canEdit = false
              //     b.canDelete = false
              //   } else {
              //     b.canApprove = false
              //     b.canReject = false
              //     b.canEdit = false
              //     b.canDelete = false
              //   }
              // }else if (role?.role === 'President Director' && (b.approval_status_id == '426' || b.approval_status_id == '428')){
              //   b.canApprove = true;
              //   b.canReject = true
              //   b.canEdit = false
              //   b.canDelete = false
              //   b.approval_status.status_color = '#e9ab13'
              //   b.approval_status.status_name = 'Waiting Approval President Director'
              // }else{
              b.canApprove = false;
              b.canReject = false;
              if(b?.approval_status?.id === 423){
                if (b.direct_manager === role?.employee?.payroll_name){
                  b.canApprove = true;
                  b.canReject = true;  
                } else {
                  b.canApprove = false;
                  b.canReject = false;
                }
                // }else if(role?.role?.includes('SAP Admin')){ // || role?.role?.includes('Direct Manager')){
                  //   b.canApprove = false
                //   b.canReject = false
              }else if(b?.approval_status?.status_name.includes('Treasury') && role?.role?.includes('Treasury')){ // || role?.role?.includes('Direct Manager')){
                b.canEdit = true
                b.canApprove = true
                b.canReject = true
              }
                console.log(b);
              // } else {
              //   console.log('masuk ke else ke 2')
              //   b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canApprove) ? true : false
              //   b.canReject = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canReject) ? true : false
              // }
            // }
            }
            console.log(b)
            let hasil = true;
            if(b.claim_details){
              for(const d of b.claim_details) {
                if (d.status === '2') {
                  hasil = false;
                  break;
                }
              }
            }
            if(hasil === false){
              b.canApprove = false;
              // b.canReject = true;
            }
            b.subcompany_code = b.subcompany_code === '001' ? 'Mineral' : 'Geothermal'
            // if(role?.employee?.payroll_id == b.employee?.payroll_id) {
            //   b.canEdit = true;
            // }
            // b.canEdit = true;
            a.push(b)
          }
          console.log(c)
          checkNotificationNumber(document)
          setListEmployee(JSON.parse(JSON.stringify([...c])));
          setRowCount(data.data.total)
          console.log(data.data.data)
          setIsError(false);
          setLoad(false)
        }else{
          setListEmployee([]);
          setRowCount(0)
          // console.log(a)
          setIsError(false);
          setLoad(false)
        }
      }else{
        console.log(data.message);
        setIsError(true);
        setLoad(false)
        // toast.error('Failed getting data', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const sendEditSpecialPayment = async (data2: any) => {
    // const response = await axios.get(API_URI + 'department/');
    console.log(data2);
    fetch(API_URI + 'employee-claim-cc-update', {
      method: "POST",
      body:JSON.stringify(data2),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        console.log(data);
        toast.success('Sucess Save Payment Advice')
        navigate(`employee-claim/cc-list`)
      } else {
        toast.error('Failed To save Payment Advice')
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed To save Payment Advice', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const handleAddUpdateApi = ((dataCreate: any) => {
    for(const a of dataCreate) {
      console.log(a);
      a.doc_no = a.docNo;
      delete a.docNo;
      a.paid_date = dayjs(a.paidDate).format("YYYY-MM-DD");
      delete a.paidDate;
      a.user_id = JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id
    }
    sendEditSpecialPayment(dataCreate)
  })

  const handleDeleteRow = useCallback(
    (row: MRT_Row<InvoiceColumn>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("doc_no")}`
        )
      ) {
        return;
      }
      fetch(API_URI + `employee-claim-cc/${row.original.id}`, {
        method: "POST",
        body: JSON.stringify({
          "_method": "DELETE"
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success('Success Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getData()
      })
      .catch(err => {
        console.log(err)
        setIsError(true);
        toast.error('Failed Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    },
    [listEmployee]
  );
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "created_at",
        header: t("Posting Date"),
        size: 80,
        Cell: ({ row, cell }) => {
          const a:any = cell.getValue();
          return(<div> {dayjs(a).format('DD MMM YYYY')}</div>)
        }
      },
      {
        accessorKey: "employee_claim_number",
        header: t("Doc No"),
        size: 80,
      },
      {
        accessorKey: "employee.payroll_name",
        header: t("Employee Name"),
        size: 80,
      },
      {
        accessorKey: "subcompany_code",
        header: t("Mineral or Geothermal"),
        size: 80,
        filterSelectOptions:[{ text: "Mineral", value: "001" }, {text: "Geothermal", value: '002'}],
        filterVariant: 'select',
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 80,
      },
      {
        accessorKey: "total_expense_claimed",
        header: t("Total Expense Claimed"),
        size: 250,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "company_to_pay",
        header: t("Company To Pay"),
        size: 80,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      // {
      //   accessorKey: "return_to_company",
      //   header: "Return to Company",
      //   size: 80,
      //   Cell: ({ row, cell }) => {
      //     return(<div> {changeUStoID(cell.getValue())}</div>)
      //   }
      // },
      // {
      //   accessorKey: "total_expense_claimed",
      //   header: "Total Expense Claimed",
      //   size: 80,
      //   Cell: ({ row, cell }) => {
      //     return(<div> {changeUStoID(cell.getValue())}</div>)
      //   }
      // },
      {
        accessorKey: "approval_status.status_name",
        header: t("Status"),
        size: 80,
        filterSelectOptions:[
          {text: "Waiting Review Direct Reporting Line", value: 'Direct Reporting'},
          { text: "Waiting Review Treasury", value: "Treasury" },
          { text: "Rejected", value: "Rejected" },
          { text: "Completed", value: "Completed" },
        ],
        filterVariant: 'select',
        Cell: ({ row, cell }) => {
          const value = String(cell.getValue());
          const isIncludeAppr = value.includes("eview");
          return (
            <div
              className={`invoice-${
                isIncludeAppr
                  ? "waiting-approval"
                  : value.toLowerCase().split(" ").join("-")
              }`}
              style={{backgroundColor: row.original.approval_status?.status_color}}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
    ],
    [listEmployee, t]
  );

  const [columnOrder, setColumnOrder] = useState<any>(
    ['mrt-row-actions', 'mrt-row-select', ...columnMRT.map((c) => c.accessorKey)],
  );

  const checkFilter = () => {
    let ketemu = false;
    let colFilter: any =[];
    for(const a of columnFilters) {
      if (a.id === 'company') {
        ketemu = true;
      }
    }
    colFilter = checkColumnFiltersCompanyCode();
    if (ketemu) {
      checkColumnFilters(colFilter, 'ec-cc')
      checkColumnFilters(columnOrder, 'ec-cc-column-order')
      checkColumnFilters(pagination, 'ec-cc-pagination')
    } else {
      const a = JSON.parse(sessionStorage.getItem('ec-cc')as string)
      const a2 = JSON.parse(sessionStorage.getItem('ec-cc-column-order')as string)
      const a3 = JSON.parse(sessionStorage.getItem('ec-cc-pagination')as string)
      if(a3){
        setPagination(a3)
      } else {
        setPagination({ pageIndex: 0, pageSize: 100})
      }
      if(a2){
        setColumnOrder(a2)
      }
      if(a){
        for(const b of a) {
          if (b.id === 'company') {
            b.value = currentCompany.company_initial
          }
        }
        setColumnFilters(a)
        colFilter.push({id: 'company', value: currentCompany.company_initial})
      } else {
        setColumnFilters([{id: 'company', value: currentCompany.company_initial}])
        colFilter.push({id: 'company', value: currentCompany.company_initial})
      }
    }
  }

  // checkFilter();

  useEffect(() => {
    checkFilter();
    console.log('columnFilters', columnFilters)
    console.log('sorting', sorting)
    console.log('globalFilter', globalFilter)
    console.log('pagination', pagination)
    getData();
    console.log(role);
    console.log(getCompanySession)
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    allDepartment,
  ]);

  return (
    <>
      <div className="mb-3"></div>
      <Loading loading={load} />
      <Row className="mt-3">
        {isExportMonth ? (
          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>{t("Choose Month")}</Form.Label>
              <Form.Control
                type="month"
                onChange={(e) => {
                  setMonthYear(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0].substring(0,7)}
                value={monthYear}
              />
            </Form.Group>
          </Col>
        ) : (
          <>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("From Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_start_date(e.target.value);
                  }}
                  value={start_date}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("To Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_end_date(e.target.value);
                  }}
                  value={end_date}
                />
              </Form.Group>
            </Col>
          </>
        )}
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            style={{ fontWeight: "bold" }}
            className="w-100 rounded-pill"
            variant="secondary"
            onClick={() => setIsExportMonth(!isExportMonth)}
          >
            {t("Choose")} {isExportMonth ? t("Date Range") : t("Month")}
          </Button>
        </Col>
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            className="w-100 vale-button d-flex align-items-center justify-content-center"
            onClick={async () => {
              // console.log(start_date)
              // console.log(end_date)
              // console.log(monthYear)
              // console.log(my)
              let params = "";
              let company_code = JSON.parse(sessionStorage.getItem('current_company')as string).company_code
              let my = ''
              if (isExportMonth) {
                const moye = new Date(monthYear);
                my = `${moye.toLocaleString("default", {
                  month: "long",
                })} ${moye.getFullYear()}`;
                params = `?company_code=${company_code}&monthYear=${my}`;
              } else {
                if (!start_date || !end_date) {
                  return toast.warn("Please select date range");
                }
                const oneDay = 24 * 60 * 60 * 1000;
                let firstDate: any = start_date
                let secondDate: any = end_date
                // const diffDays =
                //   Math.round((secondDate - firstDate) / oneDay) + 1;
                // console.log(diffDays);
                // if (diffDays > 31) {
                //   return toast.warn("Date range must be less than 31 days");
                // }
                // if (!diffDays || diffDays < 1) {
                //   return toast.warn(
                //     "Date range must be greater than 0 days"
                //   );
                // }
                if(new Date(end_date) < new Date(start_date)){
                  const tempMonth = end_date;
                  firstDate = tempMonth
                  secondDate = start_date
                  set_end_date(start_date);
                  set_start_date(tempMonth);
                }
                params = `?company_code=${company_code}&start_date=${firstDate}&end_date=${secondDate}`;
              }
              if (allDepartment) params += "&dept=all";
              // return;
              const res = await fetch(
                API_URI + `employee-claim-cc-export-data${params}`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization:
                      "Bearer " +
                      JSON.parse(
                        sessionStorage.getItem("shreyu_user") as string
                      ).access_token,
                  },
                }
              );
              const blob: any = await res.blob();
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              // a.download = "Export_Employee_Claim_CC.xlsx";
              if(isExportMonth){
                my = my.replace(' ', '_');
                a.download = `Employee_Claim_CC_${my}.xlsx`;
              } else {
                a.download = `Employee_Claim_CC_${start_date}_${end_date}.xlsx`;
              }
              a.click();
            }}
          >
            <FeatherIcon icon="download" />{" "}
            <span className="ms-2">{t("Export Employee Claim CC")}</span>
          </Button>
        </Col>{" "}
        {permission?.is_general === "1" && (
          <Col className="d-flex align-items-center justify-content-start pt-2">
            <div className="d-flex align-items-center justify-content-start">
              <Form.Check
                type="switch"
                label={t("All")}
                checked={allDepartment}
                className="custom-switch"
                onClick={() => setAllDepartment(!allDepartment)}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
          <div className="card-header bg-vale">List Employee Claim CC</div>
            <MaterialReactTable
              columns={columnMRT}
              //   enableSelectAll={true}
              //   enableRowSelection={true}
              //   enableMultiRowSelection={true}
              enableSelectAll={role?.canMultiple}
              enableMultiRowSelection={role?.canMultiple}
              enableColumnDragging={true}
              enableRowSelection={(row) => {
                console.log(row.original);
                console.log(rowSelection);
                return row.original.canApprove
              }}
              enableGlobalFilter={false}
              enableColumnOrdering={true}
              enableStickyHeader={true}
              // enableStickyFooter={true}
              manualFiltering
              manualPagination
              manualSorting
              onColumnFiltersChange={setColumnFilters}
              // onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              enableEditing
              enablePinning
              // enableRowNumbers
              rowCount={rowCount}
              onRowSelectionChange={setRowSelection}
              state={{
                rowSelection,
                columnFilters,
                // globalFilter,
                // isLoading,
                pagination,
                showAlertBanner: isError,
                // showProgressBars: isRefetching,
                sorting,
                columnOrder
              }}
              onColumnOrderChange={setColumnOrder}
              initialState={{ 
                density: 'compact',
                showColumnFilters: true,
                columnPinning: { 
                  left: ["mrt-row-select", "mrt-row-actions"],
                  right: ["approval_status.status_name"] 
                },
                columnVisibility: { 
                  subcompany_code: currentCompany.company_initial === 'STM' ? true : false,
                  id: false
                },
              }}
              
              data={listEmployee}
              muiTableBodyCellProps={({ cell, column, row }) => ({
                onClick: (event) => {
                  // console.log(cell.column.id);
                  // console.log('Cell: ', cell);
                  console.log("column: ", column.id);
                  console.log("row: ", Object.keys(row.original));
                  // console.log('table : ', table );
                  if (Object.keys(row.original).includes(column.id)) {
                    navigate(`/employee-claim/cc-view/${row.original.id}`, {
                      state: row.original,
                    });
                    // console.log(cell.getValue())
                    // console.log(row.original);
                  }
                },
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {/* {(role?.role === "SAP Admin" || role?.role === 'System Admin') && ( */}
                  {(permission.is_create === '1') && (
                  <Button
                    onClick={() => navigate("/employee-claim/cc/create/1")}
                    className="vale-button"
                  >
                    {t(`Create New`)}
                  </Button>
                  )}
                {/* )} */}
                {/* {(role?.role?.includes('Treasury') || role?.role?.includes('System Admin')) && (
                  <Button
                    // onClick={() => navigate("/sap/special-payment/create")}
                    onClick={() => setIsModalPaymentAdviceOpen(true)}
                    className="vale-button"
                  >
                    {t(`Import Paid Date`)}
                  </Button>
                )} */}
                </Box>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {permission.is_update === '1' && 
                    (
                      (
                        (row?.original?.approval_status?.status_name === 'Rejected' || row?.original?.approval_status?.id === 423) &&
                        row?.original?.employee_id === role?.employee?.payroll_id
                      ) ||
                      (
                        row?.original?.approval_status?.id === 426 &&
                        role?.role?.includes('Treasury')
                      )
                    ) &&
                    (
                    <Tooltip arrow placement="left" title={t("Edit")}>
                      <IconButton
                        onClick={() => navigate(`/employee-claim/cc/edit/${row.original.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {permission.is_delete === '1' && 
                    (row?.original?.approval_status?.status_name === 'Rejected' || row?.original?.approval_status?.id === 423) &&
                    row?.original?.employee_id === role?.employee.payroll_id && (
                    <Tooltip arrow placement="right" title={t("Delete")}>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(row.original.canApprove) && (
                    <Tooltip arrow placement="right" title={t("Approve")}>
                      <IconButton disabled={load} onClick={() => {
                        setLoad(true)
                        console.log(row.original)
                        console.log("rowSelection",rowSelection)
                        const a = []
                        const d = []
                        if(rowSelection){
                          for (const [key, value] of Object.entries(rowSelection)) {
                            a.push(listEmployee[key].id)
                            d.push(listEmployee[key].employee_id)
                          }
                        }
                        console.log(a);
                        let id, approval_status_id, approved, reason, employee_id
                        if(a.length >= 1) {
                          id = a
                          employee_id = d
                        } else {
                          id = [row.original.id]
                          employee_id = [row.original.employee_id]
                        }
                        setApprovalAPI([{
                          id: id,
                          approval_status_id: row.original.approval_status_id,
                          approved: true,
                          reason: null,
                          approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
                          employee_id: employee_id,
                          previous_form: row.original?.previous_form
                          // employee_id: JSON.parse(sessionStorage.getItem('shreyu_user')as string).data.employee.payroll_id
                        }], "employee-claim-cc-approval").then(() => {
                          toast.success('Success: Approved', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          getData();
                          setLoad(false)
                          // navigate(`/sap/special-payment-list`);
                        }).catch(() => {
                          toast.error('Failed to Approve', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          setLoad(false)
                        })
                      }}>
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(row.original.canReject)&& (
                    <Tooltip arrow placement="right" title={t("Reject")}>
                      <IconButton
                        color="error"
                        onClick={() => {
                          console.log(row.original)
                          setModalType("reject");
                          setIsModalConfirmationOpen(true);
                          setDataReject(row.original);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            />
          </Card>
        </Col>
      </Row>
      <InputPaymentAdviceModal
        open={isModalPaymentAdviceOpen}
        onClose={() => setIsModalPaymentAdviceOpen(false)}
        onSubmit={() => submitImport()}
        onFileUpload={(e: any) => onChangeUploadCSV(e)}
      />
      <ValidateExcelModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={(data: any[]) => handleAddUpdateApi(data)}
        CSV={CSVData!}
        CSVHead={CSVHead!}
        onCellEdit={(cell, value) => handleSaveCell(cell, value)}
      />
      <ConfirmationModalCC
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
        bastStatus={dataReject}
        getData={getData}
      />
    </>
  );
};

const InputPaymentAdviceModal = ({
  open,
  onClose,
  onSubmit,
  setItem,
  item,
  onFileUpload
}: any) => {
  const { t, i18n } = useTranslation();
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{t("Import Payment Date")}</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
                <Button>
                    {t("Download Template")}
                </Button>
            </Col>
        </Row>
        <br></br>
        <Form>
          {/* <Form.Group className="mb-3" controlId="paymentAdvice">
            <Form.Label>Invoice No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input File"
              defaultValue={"SMI-23080071"}
              disabled
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="paymentAdviceDateFile">
            <Form.Label>{t("Upload Paid Date File")}</Form.Label>
            <Form.Control
              type="file"
              placeholder="Input File"
              onChange={(e) => onFileUpload(e)}
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="paidDate">
            <Form.Label>Paid Date</Form.Label>
            <ShreyuDatepicker
              hideAddon={true}
              dateFormat="dd MMM yyyy"
              value={new Date()}
              onChange={(date) => {
                // onDateChange(date);
              }}
            />
          </Form.Group> */}
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ExcelColumn = {
  docNo: string,
  paidDate: Date
}

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (data: any[]) => void;
  onCellEdit: (cell: any, value: any) => void;
  // onFileUpload: (e: any) => void;
  CSV: ExcelColumn[];
  CSVHead: [];
  open: boolean;
}

const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  onCellEdit,
  // onFileUpload,
  CSV,
  CSVHead
}: CreateModalProps) => {
  const { t, i18n } = useTranslation();
  const handleSubmit = (data: any[]) => {
    //put your validation logic here
    onSubmit(data);
    onClose();
  };
  const [stmOrVei, setStmOrVei] = useState("stm");
  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "docNo",
        header: "Document Number",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">{t("Validate Data")}</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          onBlur: (event) => {
            onCellEdit(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
            {t("Double-Click a Cell to Edit")}
          </Typography>
        )}
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={() => {
            handleSubmit(CSV)
          }}>
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimCCList;
