import { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  Check,
  Close,
  Edit,
  EditNote,
  Logout,
  Visibility,
} from "@mui/icons-material";

// components
// import Statistics from "../dashboard/Ecommerce/Statistics";
import ConfirmationModal from "../../components/MyComponents/Modal/ConfirmationModal";

//dummy data
// import { records as data } from "./data";
// types
import { BASTRecords } from "./data";
import { useTranslation } from "react-i18next";

import {
  MaterialReactTable,
  // MRT_ColumnDef,
  // MRT_Row,
  // MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  // MRT_Cell,
} from "material-react-table";
import { getData, getDataRefresh } from "./fetch/getBastList";
// import { useSelector } from "react-redux";
// import { useGetUserRole } from "../../hooks";
import {
  AUTH_SESSION_KEY,
  // APICore,
  CURRENT_COMPANY,
} from "../../helpers/api/apiCore";
import {
  getWfRole,
  // getUserContractCode,
  setContractOwnerFilter,
  getPermission,
  getWfRoleWithDept,
} from "../../helpers/utils2";
import { useGetUserRole } from "../../hooks";
import { changeUStoID, replaceToComma } from "../../helpers/utils";

// helpers
const transformTextCapital = (text: string) => {
  if (text) {
    const output = text
      .split("-")
      .map((word) => {
        const w = word.split("");
        w[0] = w[0].toUpperCase();
        return w.join("");
      })
      .join(" ");
    // console.log(output);
    return output;
  } else {
    return text;
  }
};

const loginData = JSON.parse(
  sessionStorage.getItem(AUTH_SESSION_KEY) as string
);
const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const wf_role = getWfRole("1");
const wf_role_dept = getWfRoleWithDept("1");
// console.log(wf_role_dept)
// const contract_codes = getUserContractCode();
const contractOwnerFilters = setContractOwnerFilter();
const isContractOwner =
  Array.isArray(loginData?.contract) && loginData?.contract?.length > 0;
const permission: any = getPermission();

type BASTColumn = {
  id: any;
  bast_no?: any;
  good_or_services: any;
  status_name: any;
  approval_status_id: any;
  migo_or_ses?: any;
  have_invoice?: any;
  invoice_id?: any;
  contract_no?: any;
  company_sub_id: any;
  suplier_name: any;
  grand_total?: any;
  department_id?: any;
  create_invoice?: any;
  canEdit?: boolean;
  canDelete?: boolean;
  canApprove?: boolean;
  approval_status?: any;
  canReject?: boolean;
  vendor_code?: any;
};

const BastListComponentClone = ({
  canCreate = false,
  userType = "",
  data = [],
  user
}: {
  canCreate?: boolean;
  userType?: string;
  data: any;
  user?: any;
}) => {
  // const { userRole, userData, userName } = useGetUserRole();
  const {
    bastList,
    setBastList,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    rowCount,
    setRowCount,
    isError,
    setIsError,
    rowSelection,
    setRowSelection,
    globalFilter,
    setGlobalFilter,
    isModalConfirmationOpen,
    setIsModalConfirmationOpen,
    modalType,
    setModalType,
    selectedBast,
    setSelectedBast,
    bastStatus,
    setBastStatus,
  } = data
  const { userRole, userName, userId } = user

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columnMRT = useMemo<MRT_ColumnDef<BASTColumn>[]>(
    () => [
      {
        accessorKey: "status_name",
        header: "Status",
        size: 200,
        Cell: ({ cell, row }) => {
          let value: any = cell.getValue();
          value = value || "Waiting Approval";
          return (
            <div
              className={`status-pending`}
              style={{
                backgroundColor: row.original.approval_status?.status_color,
              }}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "create_invoice",
        header: t("Create Invoice"),
        size: 80,
        Cell: ({ row }) => {
          return (
            <>
              {userRole?.includes("Vendor") &&
                permission?.Invoice?.is_create === "1" &&
                (row.original.status_name?.includes("Approved") ||
                  row.original.status_name === "bast-approved") &&
                row.original.have_invoice !== "1" &&
                !row.original.invoice_id && (
                  <Tooltip arrow placement="right" title="Create Invoice">
                    <IconButton
                      onClick={() =>
                        navigate("/invoice/add", {
                          state: { ...row.original },
                        })
                      }
                    >
                      <Logout />
                    </IconButton>
                  </Tooltip>
                )}
              {userRole?.includes("Vendor") &&
                permission?.Invoice?.is_create === "1" &&
                (row.original.status_name?.includes("Approved") ||
                  row.original.status_name === "bast-approved") &&
                row.original.have_invoice === "1" &&
                !!row.original.invoice_id && (
                  <Tooltip arrow placement="right" title="View Invoice">
                    <IconButton
                      onClick={() =>
                        navigate("/invoice/detail/" + row.original.invoice_id, {
                          state: { ...row.original },
                        })
                      }
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          );
        },
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "bast_no",
        header: "Doc No.",
        size: 80,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
      },
      // {
      //   accessorKey: "id",
      //   header: "ID",
      //   size: 80,
      //   enableColumnFilter: false,
      //   enableEditing: false, //disable editing on this column
      // },
      {
        accessorKey: "migo_or_ses",
        header: "MIGO / SES",
        size: 80,
      },
      {
        accessorKey: "company_sub_id",
        header: "Mineral or Geothermal",
        size: 80,
      },
      {
        accessorKey: "suplier_name",
        header: t("Supplier Name"),
        size: 80,
      },
      {
        accessorKey: "grand_total",
        header: t("Amount"),
        size: 80,
        Cell: ({ cell, row }) => {
          return (
            <div
            // className={`status-pending`}
            // style={{
            //   backgroundColor: row.original.approval_status?.status_color,
            // }}
            >
              <p className="m-0">
                {changeUStoID(
                  replaceToComma(
                    row.original.grand_total ? row.original.grand_total : "0"
                  )
                )}
              </p>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <>
      <Row className="mt-3">
        <Col>
          {permission?.Bast?.is_create === "1" && (
            <div className="card-add">
              <div className="align-items-center row">
                <div className="text-sm-end mt-sm-0 mt-2 col p-0">
                  <button
                    type="button"
                    className="btn vale-button"
                    onClick={() => navigate("/new/bast/add")}
                    // onClick={() => navigate("/bast/add")}
                  >
                    <i className="uil-plus me-1"></i>
                    {t("Create BAST")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <Card>
            <div className="card-header bg-vale">{t("List BAST")}</div>
            <MaterialReactTable
              columns={columnMRT}
              data={bastList}
              initialState={{
                columnPinning: { right: ["status_name"] },
                columnVisibility: {
                  company_sub_id: userCurrentCompany?.company_initial === "STM",
                  grand_total: !wf_role.includes("Warehouse Officer"),
                  create_invoice: permission?.Invoice?.is_create === "1",
                },
              }}
              //
              enableRowSelection={(row: any) => {
                return wf_role.includes("SAP Admin")
                  ? false
                  : (wf_role.includes("Warehouse Officer") &&
                      row.original.status_name ===
                        "Waiting Approval Warehouse Officer") ||
                      (isContractOwner &&
                        row.original.status_name ===
                          "Waiting Approval Contract Owner");
              }}
              onRowSelectionChange={setRowSelection}
              // renderTopToolbarCustomActions={({ table }) => (
              //   <Box sx={{ display: "flex", gap: "1rem" }}>
              //     {/* <Button
              //       onClick={() => setCreateModalOpen(true)}
              //       className="vale-button"
              //     >
              //       Import Data
              //     </Button> */}
              //     {/* <Button
              //       onClick={() => {
              //         setDataModalCreate({});
              //         setDataModalCreateMode("Create New");
              //         setOpenModalSingle(true);
              //       }}
              //       className="vale-button"
              //     >
              //       Create Data
              //     </Button> */}
              //     {Object.keys(rowSelection).length > 0 && (
              //       <Button
              //         onClick={() => {
              //           // console.log(rowSelection)
              //           // console.log(bastList)
              //           const a = [];
              //           if (rowSelection) {
              //             for (const [key, value] of Object.entries(
              //               rowSelection
              //             )) {
              //               const b = {
              //                 bast_id: bastList[key].id,
              //                 approval_status_id:
              //                   bastList[key].approval_status_id === "1"
              //                     ? "2"
              //                     : bastList[key].approval_status_id === "2" &&
              //                       bastList[key].good_or_services === "1"
              //                     ? "30"
              //                     : "3",
              //                 module_id: 1,
              //                 user_id: userId,
              //                 vendor_code: bastList[key].vendor_code,
              //               };
              //               // a.push(bastList[key].id);
              //               a.push(b);
              //             }
              //           }
              //           // setOpenMdAct(true);
              //           // console.log(a)
              //           setSelectedBast(a);
              //           setModalType("bulk approve");
              //           setIsModalConfirmationOpen(true);
              //           // setBastStatus({
              //           //   id: row.original.id,
              //           //   approval_status_id:
              //           //     row.original.approval_status_id,
              //           //   vendor_code: row.original.vendor_code,
              //           //   isInputMigo:
              //           //     // wf_role.includes("Contract Owner") &&
              //           //     contractOwnerFilters?.length > 0 &&
              //           //     row.original.good_or_services === "1"
              //           //       ? true
              //           //       : false,
              //           // });
              //         }}
              //         className="vale-button"
              //       >
              //         Approve BAST
              //       </Button>
              //     )}
              //   </Box>
              // )}
              //
              muiTableBodyCellProps={({ column, row }: any) => ({
                onClick: () => {
                  if (
                    column.id !== "create_invoice" &&
                    column.id !== "mrt-row-actions"
                  ) {
                    navigate(`/new/bast/detail/${row.original.id}`, {
                      state: {
                        status: transformTextCapital(row.original.status),
                      },
                    });
                  }
                },
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderRowActions={({ row }) => {
                const canThisSapAdminApprove = wf_role_dept.some((el: any) => {
                  return (
                    el.name === "SAP Admin" &&
                    el.department_ids.includes(row.original.department_id)
                  );
                });
                const canThisWarehouseApprove = wf_role_dept.some((el: any) => {
                  return (
                    el.name === "Warehouse Officer" &&
                    el.department_ids.includes(row.original.department_id)
                  );
                });
                const canThisContractOwnerApprove = contractOwnerFilters.some(
                  (el: any) => {
                    return el.value === row.original.contract_no;
                  }
                );
                // console.log(canThisSapAdminApprove);
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    {row.original.approval_status_id === "5" &&
                      permission?.Bast?.is_update === "1" && (
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            onClick={() => {
                              // console.log(row.original.status);
                              // if (userType === "sap-admin") {
                              //   navigate("/bast/input-migo/edit");
                              // }
                              // if (userType === "vendor") {
                              navigate("/bast/edit/" + row.original.id);
                              // }
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                    {
                      // wf_role.includes("SAP Admin") &&
                      ((canThisSapAdminApprove &&
                        row.original.status_name ===
                          "Waiting Approval SAP Admin") ||
                        // wf_role.includes("Warehouse Officer") &&
                        (canThisWarehouseApprove &&
                          row.original.status_name ===
                            "Waiting Warehouse Input MIGO")) && (
                        <Tooltip
                          arrow
                          placement="left"
                          title="Input MIGO / SES"
                        >
                          <IconButton
                            onClick={() => {
                              // console.log(row.original);
                              if (canThisSapAdminApprove) {
                                navigate(
                                  "/bast/input-ses-migo/edit/" + row.original.id
                                );
                              }
                              // if (userType === "vendor") {
                              //   navigate("/bast/edit");
                              // }
                              if (canThisWarehouseApprove) {
                                navigate(
                                  "/bast/warehouse/edit/" + row.original.id
                                );
                              }
                            }}
                          >
                            <EditNote />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                    {
                      // wf_role.includes("Warehouse Officer") &&
                      ((canThisWarehouseApprove &&
                        row.original.status_name ===
                          "Waiting Approval Warehouse Officer") ||
                        // contractOwnerFilters?.length > 0 &&
                        (canThisContractOwnerApprove &&
                          row.original.status_name ===
                            "Waiting Approval Contract Owner")) && (
                        <>
                          <Tooltip arrow placement="right" title="Approve">
                            <IconButton
                              onClick={() => {
                                if (Object.keys(rowSelection).length > 0) {
                                  const a = [];
                                  if (rowSelection) {
                                    for (const [key, value] of Object.entries(
                                      rowSelection
                                    )) {
                                      const b = {
                                        bast_id: bastList[key].id,
                                        approval_status_id:
                                          bastList[key].approval_status_id ===
                                          "1"
                                            ? "2"
                                            : bastList[key]
                                                .approval_status_id === "2" &&
                                              bastList[key].good_or_services ===
                                                "1"
                                            ? "30"
                                            : "3",
                                        module_id: 1,
                                        user_id: userId,
                                        vendor_code: bastList[key].vendor_code,
                                      };
                                      // a.push(bastList[key].id);
                                      a.push(b);
                                    }
                                  }
                                  // setOpenMdAct(true);
                                  // console.log(a)
                                  // return console.log("A");
                                  setSelectedBast(a);
                                  setModalType("bulk approve");
                                  setIsModalConfirmationOpen(true);
                                } else {
                                  // return console.log("B");
                                  setModalType("approve");
                                  setIsModalConfirmationOpen(true);
                                  setBastStatus({
                                    id: row.original.id,
                                    approval_status_id:
                                      row.original.approval_status_id,
                                    vendor_code: row.original.vendor_code,
                                    isInputMigo:
                                      // wf_role.includes("Contract Owner") &&
                                      contractOwnerFilters?.length > 0 &&
                                      row.original.good_or_services === "1"
                                        ? true
                                        : false,
                                  });
                                }
                              }}
                            >
                              <Check />
                            </IconButton>
                          </Tooltip>
                          {!(Object.keys(rowSelection).length > 0) && (
                            <Tooltip arrow placement="right" title="Reject">
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setModalType("reject");
                                  setIsModalConfirmationOpen(true);
                                  setBastStatus({
                                    id: row.original.id,
                                    approval_status_id:
                                      // row.original.approval_status_id,
                                      "5",
                                    vendor_code: row.original.vendor_code,
                                  });
                                }}
                              >
                                <Close />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )
                    }
                  </Box>
                );
              }}
              enableEditing
              enableRowNumbers
              enableColumnOrdering
              manualFiltering
              manualPagination
              manualSorting
              enableMultiSort={false}
              enableGlobalFilter={false}
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={rowCount}
              state={{
                columnFilters,
                globalFilter,
                rowSelection,
                // isLoading,
                pagination,
                showAlertBanner: isError,
                // showProgressBars: isRefetching,
                sorting,
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  size: 120,
                },
              }}
            />
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
        bastStatus={bastStatus}
        bulkStatus={selectedBast}
        getData={() => {
          if (modalType === "bulk approve") {
            setRowSelection({});
          }
          getDataRefresh({
            columnFilters,
            sorting,
            pagination,
            setBastList,
            setRowCount,
            setIsError,
          });
        }}
      />
    </>
  );
};

export default BastListComponentClone;
