import { VerticalForm, FormInput } from "../../components";
import {
  Link,
  useLocation,
  Navigate,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Button, Alert, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import FeatherIcons from "feather-icons-react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import AuthLayout from "../auth/AuthLayout";
import FormInput2 from "../../components/FormInput2";
import { API_URI } from "../../constants";
import { toast } from "react-toastify";
import { useShowHidePassword } from "../../hooks/useShowHidePassword";

interface UserData {
  email?: string;
  password?: string;
  new_password?: string;
  passwordRetype?: string;
}

const ChangePasswordForgot = () => {
  const { t } = useTranslation();
  const [passCaptcha, setPassCaptcha] = useState(false);
  // const [showPw, setShowPw] = useState(false);
  // const [showPw2, setShowPw2] = useState(false);
  const { showPassword: showPasswordNew, togglePassword: togglePasswordNew } = useShowHidePassword();
  const { showPassword: showPasswordConfirmNew, togglePassword: togglePasswordConfirmNew } = useShowHidePassword();

  // const location = useLocation();
  // console.log(location)
  // const params = new URLSearchParams(location.search);
  // console.log(params.get("email"))
  const par = useSearchParams();
  const [searchParams, setSearchParams] = par;
  const navigate = useNavigate();

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const schemaResolver = yupResolver(
    yup.object().shape({
      // current_password: yup
      //   .string()
      //   .required(t("Please enter your current password")),
      new_password: yup.string().required(t("Please enter new password")),
      passwordRetype: yup.string().required(t("Please re-enter new password")),
    })
  );

  const onSubmit = (formData: UserData) => {
    // dispatch(loginUser(formData["email"], formData["password"]));
    setSubmitDisabled(true);
    fetch(API_URI + "password/reset", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email: searchParams.get("email"),
        password: formData.new_password,
        password_confirmation: formData.passwordRetype,
        token: searchParams.get("token"),
      }),
    })
      .then((response) => response.json())
      .then((data: any) => {
        console.log(data);
        if (data.errors) {
          toast.warn(data.message);
          setSubmitDisabled(false);
          return;
        }
        if (data.success) {
          toast.success(t("Password changed successfully"));
          navigate("/auth/login");
        }
        if (!data.success && data.response_code === 404) {
          toast.warn(data.message);
        }
      })
      .catch((error: any) => {
        console.log(error);
        toast.error(t("Failed to change password"));
        setSubmitDisabled(false);
      });
  };
  function onChange(value: any) {
    console.log("Captcha value:", value);
    setPassCaptcha(true);
  }

  return (
    <>
      <AuthLayout>
        <VerticalForm<UserData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={
            {
              // password: "",
              // passwordRetype: "",
            }
          }
          formClass="authentication-form"
        >
          <FormInput2
            // type="password"
            type={showPasswordNew ? "text" : "password"}
            name="new_password"
            label={t("New Password")}
            startIcon={
              <FeatherIcons
                // onClick={togglePasswordNew}
                icon={"lock"}
                className="icon-dual"
              />
            }
            placeholder={t("*********")}
            containerClass={"mb-3"}
            showHidePassword={togglePasswordNew}
          />
          <FormInput2
            // type="password"
            type={showPasswordConfirmNew ? "text" : "password"}
            name="passwordRetype"
            label={t("Confirm Your New Password")}
            startIcon={
              <FeatherIcons
                // onClick={togglePasswordConfirmNew}
                icon={"lock"}
                className="icon-dual"
              />
            }
            // action={
            //   <Link
            //     to="/auth/forget-password"
            //     className="float-end text-muted text-unline-dashed ms-1"
            //   >
            //     {t("Forgot your password?")}
            //   </Link>
            // }
            placeholder={t("*********")}
            containerClass={"mb-3"}
            showHidePassword={togglePasswordConfirmNew}
          ></FormInput2>

          {/* <FormInput
              type="checkbox"
              name="checkbox"
              label={t("Remember me")}
              containerClass={"mb-3"}
              defaultChecked
            /> */}
          <div className="mb-3 text-center d-flex justify-content-end">
            <Button
              type="submit"
              className="vale-button"
              // disabled={!passCaptcha}
              disabled={submitDisabled}
            >
              {t("Change Password")}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default ChangePasswordForgot;
