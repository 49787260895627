import { useMemo, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CreateClaimNonCC from "./CreateClaimNonCC";
import ShreyuDatepicker from "../../../components/Datepicker";
import * as XLSX from 'xlsx/xlsx.mjs';

type InvoiceColumn = {
  no: number;
  vendorNumber: string;
  cost_desc: string;
  vendorName: string;
  status: string;
  action: string;
  paidDate?: string;
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
  canReject: boolean;
};

const ClaimNonCCListTreasury = () => {
  const navigate = useNavigate();
  const [isModalPaymentAdviceOpen, setIsModalPaymentAdviceOpen] =
    useState(false);
  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "no",
        header: "NO",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "vendorNumber",
        header: "Doc No",
        size: 80,
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        size: 80,
      },
      {
        accessorKey: "cost_desc",
        header: "Description",
        size: 80,
      },
      {
        accessorKey: "vendorName",
        header: "Vendor Name",
        size: 80,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 80,
        Cell: ({ cell }) => {
          const value = String(cell.getValue());
          const isIncludeAppr = value.includes("pproval");
          return (
            <div
              className={`invoice-${
                isIncludeAppr
                  ? "waiting-approval"
                  : value.toLowerCase().split(" ").join("-")
              }`}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
      //   {
      //     header: "Action",
      //     accessor: "action",
      //     sort: true,
      //   }
    ],
    []
  );
  const [CSVData, setCSVData] = useState();
  const [CSVHead, setCSVHead] = useState();
  console.log(CSVData)
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [file, setFile] = useState({});
  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(data);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = [];
      Object.keys(jsa[0]).forEach(function(key) {
        head.push(key);
      });
      setCSVData(jsa);
      setCSVHead(head);
      setCreateModalOpen(true);
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const onChangeUploadCSV = (e: any) => {
    console.log(e)
    setFile(e);
  }

  return (
    <>
      <div className="mb-3"></div>
      <Row className="mt-3">
        <Col>
          <Card>
            <MaterialReactTable
              columns={columnMRT}
              //   enableSelectAll={true}
              //   enableRowSelection={true}
              //   enableMultiRowSelection={true}
              enableEditing
              enablePinning
              initialState={{ columnPinning: { right: ["status"] } }}
              data={[
                {
                  no: 1,
                  vendorNumber: "V-00081",
                  paidDate: "",
                  cost_desc: "HERTZ Australia",
                  vendorName: "Galih Lutfihadi Perdana",
                  status: "Upload Payment Advice",
                  action: "View",
                  canEdit: false,
                  canDelete: false,
                  canApprove: false,
                  canReject: false,
                },
                {
                  no: 2,
                  vendorNumber: "V-00079",
                  paidDate: "2 September 2023",
                  cost_desc: "HERTZ Australia",
                  vendorName: "Galih Lutfihadi Perdana",
                  status: "Completed",
                  action: "View",
                  canEdit: false,
                  canDelete: false,
                  canApprove: false,
                  canReject: false,
                },
              ]}
              muiTableBodyCellProps={({ cell, column, row }) => ({
                onClick: (event) => {
                  // console.log(cell.column.id);
                  // console.log('Cell: ', cell);
                  console.log("column: ", column.id);
                  console.log("row: ", Object.keys(row.original));
                  // console.log('table : ', table );
                  if (Object.keys(row.original).includes(column.id)) {
                    navigate(`/employee-claim/treasury/ec-view`, {
                      state: row.original,
                    });
                    // console.log(cell.getValue())
                    // console.log(row.original);
                  }
                },
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Button
                    onClick={() => navigate("/employee-claim/treasury/ec/create")}
                    className="vale-button"
                  >
                    Create New
                  </Button>
                  <Button
                    // onClick={() => navigate("/employee-claim/treasury/ec/create")}
                    onClick={() => setIsModalPaymentAdviceOpen(true)}
                    className="vale-button"
                  >
                    Import Paid Date
                  </Button>
                </Box>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {row.original.canEdit && (
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        onClick={() =>
                          navigate("/employee-claim/treasury/ec/edit")
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canDelete && (
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => console.log(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canApprove && (
                    <Tooltip arrow placement="right" title="Approve">
                      <IconButton onClick={() => console.log(row)}>
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canApprove && (
                    <Tooltip arrow placement="right" title="Reject">
                      <IconButton
                        color="error"
                        onClick={() => console.log(row)}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            />
          </Card>
        </Col>
      </Row>
      <InputPaymentAdviceModal
        open={isModalPaymentAdviceOpen}
        onClose={() => setIsModalPaymentAdviceOpen(false)}
        onSubmit={() => submitImport()}
        onFileUpload={(e: any) => onChangeUploadCSV(e)}
      />
      <ValidateExcelModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={() => submitImport()}
        CSV={CSVData!}
        CSVHead={CSVHead!}
      />
    </>
  );
};

const InputPaymentAdviceModal = ({
  open,
  onClose,
  onSubmit,
  setItem,
  item,
  onFileUpload
}: any) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Import Payment Date</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
                <Button>
                    Download Template
                </Button>
            </Col>
        </Row>
        <br></br>
        <Form>
          {/* <Form.Group className="mb-3" controlId="paymentAdvice">
            <Form.Label>Invoice No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input File"
              defaultValue={"SMI-23080071"}
              disabled
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="paymentAdviceDateFile">
            <Form.Label>Upload Paid Date File</Form.Label>
            <Form.Control
              type="file"
              placeholder="Input File"
              onChange={(e) => onFileUpload(e)}
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="paidDate">
            <Form.Label>Paid Date</Form.Label>
            <ShreyuDatepicker
              hideAddon={true}
              dateFormat="dd MMM yyyy"
              value={new Date()}
              onChange={(date) => {
                // onDateChange(date);
              }}
            />
          </Form.Group> */}
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: () => void;
  // onFileUpload: (e: any) => void;
  CSV: [];
  CSVHead: [];
  open: boolean;
}

const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  // onFileUpload,
  CSV,
  CSVHead
}: CreateModalProps) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };
  const [stmOrVei, setStmOrVei] = useState("stm");
  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "docNo",
        header: "Document Number",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Validate Data</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimNonCCListTreasury;
