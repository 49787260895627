import { Button, Col, Form, Row } from "react-bootstrap";
import KpiPslChartVei from "../charts/KpiPslChartVei";
import KpiPslChartStm from "../charts/KpiPslChartStm";
import KpiTable from "./Components/ReportTable/KpiTable";
import KpiTableHorizontal from "./Components/ReportTable/KpiTableHorizontal";
import { useEffect, useState } from "react";
import { AUTH_SESSION_KEY, CURRENT_COMPANY } from "../../helpers/api/apiCore";
import { API_URI } from "../../constants";
import Select from "react-select";

const fetchKpi = () => {
  return fetch(API_URI + "dashboard/kpi", {
    method: "POST",
    body: JSON.stringify({
      start_date: "2023-10",
      end_date: "2023-11",
      // company_code: userCurrentCompany?.company_code ? parseInt(userCurrentCompany?.company_code) : null, //vei 1561, stm 1492
      // company_code: userCurrentCompany?.company_code, //vei 1561, stm 1492
      company_code: JSON.parse(
        sessionStorage.getItem(CURRENT_COMPANY) as string
      )?.company_code, //vei 1561, stm 1492
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY) as string)
          .access_token,
    },
  }).then((response) => response.json());
};

const downloadKpi = ({ year }: any) => {
  const company_code = parseInt(
    JSON.parse(sessionStorage.getItem("current_company") as string).company_code
  );
  // fetch(`${API_URI}dashboard/cek-miro?kpi=${kpi}&company_code=${company_code}&year=${year}`, {
  fetch(
    `${API_URI}dashboard/download/kpi/1?company_code=${company_code}&year=${year}`,
    {
      method: "GET",
      // body:JSON.stringify({
      //   "kpi": 7,
      //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
      // }),
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer " +
          JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY) as string)
            .access_token,
      },
    }
  ).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "Report_KPI_Invoice_Payment.xlsx";
      a.click();
    });
  });

  return fetch(API_URI + "dashboard/kpi", {
    method: "POST",
    body: JSON.stringify({
      start_date: "2023-10",
      end_date: "2023-11",
      // company_code: userCurrentCompany?.company_code ? parseInt(userCurrentCompany?.company_code) : null, //vei 1561, stm 1492
      // company_code: userCurrentCompany?.company_code, //vei 1561, stm 1492
      company_code: JSON.parse(
        sessionStorage.getItem(CURRENT_COMPANY) as string
      )?.company_code, //vei 1561, stm 1492
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY) as string)
          .access_token,
    },
  }).then((response) => response.json());
};

const KpiHome = () => {
  const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
  let userCurrentCompany: any;
  if (ucc?.[0] === "{" || ucc?.[0] === "[") {
    userCurrentCompany = JSON.parse(ucc);
  }
  // console.log(ucc);
  // console.log(userCurrentCompany);
  const [range, setRange] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [kpiLevel, setKpiLevel] = useState<any>("90");
  const [kpi, setKpi] = useState<any>({});
  // console.log(kpi);
  const [categories, setCategories] = useState<any>([]);
  const [dataTable, setDataTable] = useState<any>([])
  const [year, setYear] = useState<any>(new Date().getFullYear().toString())
  const yearOptionsFunc=() =>{
    const yearOption = [];
    for (let i = 2023; i <= new Date().getFullYear(); i++) {
      yearOption.push({ label: i.toString(), value: i.toString() });
    }
    return yearOption
  }
  const [yearOption, setYearOption] = useState<any>(yearOptionsFunc())

  // console.log(range)

  const getDataTable = async (ye: any) => {
    fetch(`${API_URI}dashboard/kpi-table?company_code=${userCurrentCompany?.company_code}&year=${ye}`, {
      method: "GET",
      // body:JSON.stringify({
      //   "kpi": 7,
      //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
      // }),
      redirect: 'follow'
    })
    .then(response => response.json())
    .then(data => {
      if(data){
        setDataTable(data.data);
      }
    })
  }

  const fetchKpiData = (ye: any) => {
    fetchKpi()
      .then((data) => {
        console.log(data);
        if (data.success) {
          const kpiData: any = {
            "Jan": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Jan-" + ye,
              date: new Date("Jan-" + ye),
            },
            "Feb": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Feb-" + ye,
              date: new Date("Feb-" + ye),
            },
            "Mar": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Mar-" + ye,
              date: new Date("Mar-" + ye),
            },
            "Apr": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Apr-" + ye,
              date: new Date("Apr-" + ye),
            },
            "May": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "May-" + ye,
              date: new Date("May-" + ye),
            },
            "Jun": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Jun-" + ye,
              date: new Date("Jun-" + ye),
            },
            "Jul": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Jul-" + ye,
              date: new Date("Jul-" + ye),
            },
            "Aug": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Aug-" + ye,
              date: new Date("Aug-" + ye),
            },
            "Sep": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Sep-" + ye,
              date: new Date("Sep-" + ye),
            },
            "Oct": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Oct-" + ye,
              date: new Date("Oct-" + ye),
            },
            "Nov": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Nov-" + ye,
              date: new Date("Nov-" + ye),
            },
            "Dec": {
              ontimePayment: 0,
              delayPayment: 0,
              total: 0,
              label: "Dec-" + ye,
              date: new Date("Dec-" + ye),
            },
          };
          console.log(kpiData)
          // const kpiDataArr = [];
          // const xLabel = [];
          for (let ot of data.ontimePayment) {
            const d = new Date(`${ot.year}-${ot.month}`);
            if(ot.year == ye){
              const m = String(d).split(" ")[1];
              const lbl = m + "-" + ot.year;
              if (kpiData[m]?.total > 0) {
                kpiData[m].ontimePayment += parseInt(ot.total);
                kpiData[m].total += parseInt(ot.total);
              } else {
                kpiData[m] = {
                  ontimePayment: parseInt(ot.total),
                  delayPayment: 0,
                  total: parseInt(ot.total),
                  label: lbl,
                  date: d,
                };
              }
            }

            // xLabel.push(m + '-' + ot.year)
          }
          for (let ot of data.delayPayment) {
            const d = new Date(`${ot.year}-${ot.month}`);
            const m = String(d).split(" ")[1];
            const lbl = m + "-" + ot.year;
            if(ot.year == ye){
              if (kpiData[m]?.total) {
                kpiData[m].delayPayment += parseInt(ot.total);
                kpiData[m].total += parseInt(ot.total);
              } else {
                kpiData[m] = {
                  ontimePayment: 0,
                  delayPayment: parseInt(ot.total),
                  total: parseInt(ot.total),
                  label: lbl,
                  date: d,
                };
              }
            }
          }
          console.log(kpiData);
          const arrKpiData: any = Object.values(kpiData).sort(
            (a: any, b: any) => {
              return a.date - b.date;
            }
          );
          for (let a of arrKpiData) {
            a.percentageOntime =
              a.ontimePayment > 0 ? (a.ontimePayment / a.total) * 100 : 0;
            a.percentageDelay =
              a.delayPayment > 0 ? (a.delayPayment / a.total) * 100 : 0;
          }
          console.log(arrKpiData);
          const chartData: any = {
            data1: [],
            data2: [],
            data3: [],
          };
          const cat = [];
          for (let a of arrKpiData) {
            chartData.data1.push(a.total);
            chartData.data2.push(Math.floor(a.percentageOntime));
            chartData.data3.push(90);
            cat.push(a.label);
          }
          console.log(chartData);
          setKpi(chartData);
          setCategories(cat);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDataTable(year);
    fetchKpiData(year);
  }, []);

  return (
    <>
      <div className="mt-3"></div>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"Years"}</Form.Label>
            <Select
              isMulti={false}
              onChange={(e: any) => {
                setYear(e.value);
                getDataTable(e.value)
                fetchKpiData(e.value);
              }}
              options={[{ label: "2023", value: "2023" }, { label: "2024", value: "2024" }]}
              defaultValue={{ label: "2024", value: "2024" }}
              className={`${
                // formData.contractNo.isDisabled &&
                // "react-select-disabled"
                ""
              } react-select-container`}
              // isDisabled={formData.contractNo.isDisabled}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"KPI value %"}</Form.Label>
            <Form.Control
              type="number"
              value={kpiLevel}
              onChange={(e) => {
                // setRange((prev: any) => ({ ...prev, endDate: e.target.value }));
                // setKpiLevel(e.target.value);
                setKpi((prev: any) => {
                  return {
                    ...prev,
                    data3: prev.data3?.map((el: any) =>
                      parseInt(e.target.value) ? parseInt(e.target.value) : "0"
                    ),
                  };
                });
                // setKpi(e.target.value)
                setKpiLevel(e.target.value);
              }}
              // defaultValue={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </Col>
      </Row>
      {/* CHART */}
      <Row>
        {userCurrentCompany?.company_initial === "STM" && (
          <Col md={12}>
            <KpiPslChartStm
              data={kpi}
              categories={categories}
              kpiLevel={kpiLevel}
              // multiYaxisChartData={multiYaxisChartData}
              // showLoader={!isChatInitilized}
            />
          </Col>
        )}
        {userCurrentCompany?.company_initial === "VEI" && (
          <Col md={12}>
            <KpiPslChartStm
              data={kpi}
              categories={categories}
              kpiLevel={kpiLevel}
              title="KPI INVOICE PAYMENT SERVICE LEVEL PT VALE EKSPLORASI INDONESIA"

              // multiYaxisChartData={multiYaxisChartData}
              // showLoader={!isChatInitilized}
            />
          </Col>
        )}
        <Col md={12} className="mb-3">
          <KpiTableHorizontal
          // multiYaxisChartData={multiYaxisChartData}
          // showLoader={!isChatInitilized}
          data={dataTable}
          year={year}
          delayTitle={"Delay Payment"}
          ontimeTitle={"Payment On Time"}
          />
        </Col>
      </Row>
      <Button
        className="vale-button w-100"
        onClick={async () => {
          downloadKpi({ year });
        }}
      >
        <span className="ms-1">Download Report</span>
      </Button>
    </>
  );
};

export default KpiHome;
