import { toast } from "react-toastify";
import { API_URI } from "../../../constants";
import { CURRENT_COMPANY, USER_COMPANIES } from "../../../helpers/api/apiCore";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
const ucs = sessionStorage.getItem(USER_COMPANIES) as string;
let userCurrentCompany: any;
let userCompanies: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}
if (ucs?.[0] === "{" || ucs?.[0] === "[") {
  userCompanies = JSON.parse(ucs);
}
// console.log(userCurrentCompany)

const fetchBastList = (body: any) =>
  fetch(API_URI + "bast-clone-list", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem('shreyu_user') as string).access_token,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Origin": "no-cors",
    },
    // mode: 'no-cors'
  }).then((response) => response.json());

export const getData = async ({
  columnFilters,
  sorting,
  pagination,
  setBastList,
  setRowCount,
  setIsError,
  defaultFilter,
  contractOwnerFilters,
}: any) => {
  // console.log({
  //   columnFilters: defaultFilter
  //     ? [
  //         ...defaultFilter,
  //         ...columnFilters,
  //         {
  //           id: "company",
  //           value: userCurrentCompany?.company_initial || "",
  //         },
  //       ]
  //     : [
  //         ...columnFilters,
  //         {
  //           id: "company",
  //           value: userCurrentCompany?.company_initial || "",
  //         },
  //       ],
  //   sorting,
  //   pagination,
  // });
  // if (contractOwnerFilters?.length > 0) {
  //   // console.log(contractOwnerFilters);
  //   const promises = [];
  //   for (let a of contractOwnerFilters) {
  //     // console.log(a);
  //     promises.push(
  //       fetchBastList({
  //         columnFilters: defaultFilter
  //           ? [
  //               ...defaultFilter,
  //               ...columnFilters,
  //               { ...a },
  //               {
  //                 id: "company",
  //                 value: userCurrentCompany?.company_initial || "",
  //               },
  //             ]
  //           : [
  //               ...columnFilters,
  //               { ...a },
  //               {
  //                 id: "company",
  //                 value: userCurrentCompany?.company_initial || "",
  //               },
  //             ],
  //         sorting,
  //         pagination,
  //       })
  //     );
  //   }
  //   Promise.all(promises)
  //     .then((values: any) => {
  //       // console.log(values);
  //       const bastList = []
  //       let rowCount = 0
  //       let errFlag = false
        
  //       for (let val of values) {
  //         if (val && val.success) {
  //           if (val.data && val.data.data) {
  //             // console.log(data.data.data, "GET BAST LIST");
  //             const a = [];
  //             let IDR = new Intl.NumberFormat("id-ID", {
  //               style: "currency",
  //               currency: "IDR",
  //             });
  //             for (const b of val.data.data) {
  //               // b.company = b.company?.company_code ? b.company.company_code : null
  //               // b.approval_status_id = b.approval_status_id ? 'Waiting Approval' : "Waiting"
  //               b.company_sub_id =
  //                 b.company_sub_id === "1"
  //                   ? "Mineral"
  //                   : b.company_sub_id === "2"
  //                   ? "Geothermal"
  //                   : "";
  //               b.grand_total = IDR.format(b.grand_total);
  //               a.push(b);
  //             }
  //             // console.log(a, "BASTLIST")
  //             bastList.push(...a);
  //             rowCount += val.data.total
  //           }
  //         } else {
  //           console.log(val.message);
  //           // setIsError(true);
  //           toast.error("Failed getting bast data", {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           });
  //         }
  //       }
  //       console.log(bastList)
  //       setBastList(bastList);
  //       setRowCount(rowCount);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsError(true);
  //       toast.error("Failed getting data", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     });
  // } else {
    fetchBastList({
      columnFilters: defaultFilter
        ? [
            ...defaultFilter,
            ...columnFilters,
            {
              id: "company",
              value: userCurrentCompany?.company_initial || "",
            },
          ]
        : [
            ...columnFilters,
            {
              id: "company",
              value: userCurrentCompany?.company_initial || "",
            },
          ],
      sorting,
      pagination,
    })
      .then((data) => {
        console.log(data);
        if (data && data.success) {
          if (data.data === null) return
          if (data.data || data.data.data) {
            // console.log(data.data.data, "GET BAST LIST");
            const a = [];
            let IDR = new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            });
            for (const b of data.data.data) {
              // b.company = b.company?.company_code ? b.company.company_code : null
              // b.approval_status_id = b.approval_status_id ? 'Waiting Approval' : "Waiting"
              b.company_sub_id =
                b.company_sub_id === "1"
                  ? "Mineral"
                  : b.company_sub_id === "2"
                  ? "Geothermal"
                  : "";
              // b.grand_total = IDR.format(b.grand_total);
              a.push(b);
            }
            // console.log(a, "BASTLIST")
            setBastList(a);
            setRowCount(data.data.total);
            // console.log(a);
            setIsError(false);
          }
        } else {
          console.log(data.message);
          setIsError(true);
          toast.warn("Failed getting bast data", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        toast.warn("Failed getting data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    // console.log(response.json());
    // setDataApi(response);
  // }
};

export const getDataRefresh = ({
  columnFilters,
  sorting,
  pagination,
  setBastList,
  setRowCount,
  setIsError,
  defaultFilter,
  contractOwnerFilters,
}: any) =>
  getData({
    columnFilters,
    sorting,
    pagination,
    setBastList,
    setRowCount,
    setIsError,
    defaultFilter,
    contractOwnerFilters,
  });
