import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
import FeatherIcon from "feather-icons-react";

import GroupInput from "../../components/MyComponents/Input/GroupInput";
import InvoiceSumForm from "./components/InvoiceSumForm";
import TaxSimulation from "./components/TaxSimulation";
import ModalAddRowInvoice from "./components/ModalAddRowInvoice";

import { getDataById } from "../bast/fetch/getBastById";

// static data
import {
  createRecord,
  groupInputAtt1,
  groupInputAtt2,
  groupInputAtt3,
} from "./data";

// dummy
import taxPdfDummy from "./STMM_BIM_019_IDR_75,000,000_LOK.pdf";

// TYPES
import { AddInvoiceCol, SummaryExpCol } from "./types/invoiceTypes";
import { useTranslation } from "react-i18next";

const InvoiceForm = () => {
  const [groupState1, setGroupState1] = useState(groupInputAtt1);
  const [ aa, setAa] = useState<any>()
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [vat, setVat] = useState({ value: 11, label: null });
  const [wht, setWht] = useState({ value: 0, label: null });
  const [dpp, setDpp] = useState(362058285);
  const { state } = useLocation();
  console.log(state, "STATE");
  const [bastDetail, setBastDetail] = useState({});
  console.log(bastDetail, "BAST DETAIL");
  const [isError, setIsError] = useState<any>(null);

  const [openModalAddRow, setOpenModalAddRow] = useState(false);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  // console.log(groupState1);
  const columnMRTInvoiceAdd = useMemo<MRT_ColumnDef<AddInvoiceCol>[]>(
    () => [
      {
        accessorKey: "stockCode",
        header: "Stock Code",
        size: 80,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 120,
      },
      {
        accessorKey: "uom",
        header: "UOM",
        size: 80,
      },
      {
        accessorKey: "qty",
        header: "Qty",
        size: 80,
      },
      {
        accessorKey: "curr",
        header: "Curr",
        size: 80,
      },
      {
        accessorKey: "unitPrice",
        header: "Unit Price",
        size: 80,
      },
      {
        accessorKey: "estPrice",
        header: "Est Price (IDR)",
        size: 80,
      },
    ],
    []
  );
  const columnMRTExpenditures = useMemo<MRT_ColumnDef<SummaryExpCol>[]>(
    () => [
      {
        accessorKey: "migo_or_ses",
        header: "BAST No. (SAS/MIGO)",
        size: 80,
      },
      {
        accessorKey: "invoiceDate",
        header: "Invoice Date",
        size: 80,
      },
      {
        accessorKey: "sapInvoiceNo",
        header: "SAP Invoice No. (MIRO)",
        size: 80,
      },
      {
        accessorKey: "invoiceNumber",
        header: "Invoice Number",
        size: 80,
      },
      {
        accessorKey: "invoiceAmountExcl",
        header: "Invoice Amount (Excl. VAT)",
        size: 80,
      },
      {
        accessorKey: "vat",
        header: "VAT",
        size: 80,
      },
      {
        accessorKey: "invoiceAmountIncl",
        header: "Invoice Amount (Incl. VAT)",
        size: 80,
      },
      {
        accessorKey: "wht",
        header: "Withholding Tax",
        size: 80,
      },
      {
        accessorKey: "paymentAmount",
        header: "Payment Amount",
        size: 80,
      },
      {
        accessorKey: "sumInvoiceToDate",
        header: "Cummulative Invoice to Date",
        size: 80,
      },
      {
        accessorKey: "estValOfWorkNotYetInvoiced",
        header: "Estimated Value of Work Not Yet Invoiced (if any)",
        size: 80,
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        size: 80,
      },
      {
        accessorKey: "payment_date",
        header: "Payment Date",
        size: 80,
      },
      {
        accessorKey: "sapPaymentNo",
        header: "SAP Payment No. (F110)",
        size: 80,
        Cell: ({ cell, row }) => {
          // const value = String(cell.getValue());
          return <Form.Control type="text" size="sm"></Form.Control>;
        },
      },
      {
        accessorKey: "receivedDateOfPayment",
        header: "Received Date of Payment",
        size: 80,
      },
      {
        accessorKey: "bankClearingNo",
        header: "Bank Clearing No.*) (F-53)",
        size: 80,
        Cell: ({ cell, row }) => {
          // const value = String(cell.getValue());
          return <Form.Control type="text" size="sm"></Form.Control>;
        },
      },
      {
        accessorKey: "outStandingContractVal",
        header: "Outstanding Contract Value",
        size: 80,
      },
      {
        accessorKey: "remark",
        header: "Remark (if any)",
        size: 80,
      },
    ],
    []
  );

  useEffect(() => {
    if (state?.id) {
      getDataById({
        id: state.id,
        // id: 2,
        setData: setBastDetail,
        setIsError,
        // fetchContract,
        // setLoading,
      });
    }
  }, []);

  return (
    <>
      <div className="mb-3"></div>

      <Card>
        <div className="card-header bg-vale">
          {state?.isAdminApEdit ? "Edit" : "Create"} Invoice
        </div>
        <Card.Body>
          <Form>
            <Row>
              <Col md={9}>
                <Card>
                  <Card.Body>
                    <GroupInput colSize={4} inputAttArr={groupState1} />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <div className="d-flex justify-content-start mb-3">
                  <Form.Group className="d-flex flex-direction-column align-items-center">
                    <Form.Check
                      className="me-3"
                      type="radio"
                      name="goodsOrServices"
                      label="Goods"
                      checked
                      disabled
                    />
                    <Form.Check
                      type="radio"
                      name="goodsOrServices"
                      label="Services"
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <Form.Group className="d-flex flex-direction-column align-items-center">
                    <Form.Check
                      className="me-3"
                      type="radio"
                      name="geoOrStm"
                      label="Mineral"
                      checked
                      disabled
                    />
                    <Form.Check
                      type="radio"
                      name="geoOrStm"
                      label="Geothermal"
                      disabled
                    />
                  </Form.Group>
                </div>
                <Form.Group className="mb-3" controlId={"attachFile"}>
                  <Form.Label>{"Upload Invoice"}</Form.Label>
                  <Form.Control type={"file"} onChange={(e) => {}} />
                </Form.Group>
              </Col>
            </Row>
            <Card>
              <Card.Body>
                <GroupInput colSize={4} inputAttArr={groupInputAtt2} />
              </Card.Body>
            </Card>
            <MaterialReactTable
              columns={columnMRTInvoiceAdd}
              data={[]}
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => setOpenModalAddRow(true)}
                  className="vale-button"
                >
                  Add Row
                </Button>
              )}
            />
            <InvoiceSumForm
              state={state}
              dataState={{ vat, setVat, wht, setWht, dpp, setDpp }}
            />
            {state?.isAdminApEdit && (
              <TaxSimulation
                isAdminApEdit
                dataState={{ vat, setVat, wht, setWht, dpp, setDpp }}
              />
            )}
            <div className="my-1">
              <h3>Summary of Contract Expenditures</h3>
            </div>
            {/* <div className="mt-4 mb-3 d-flex justify-content-between">
              <h3>Summary of Contract Expenditures</h3>
              <Button className="vale-button" onClick={() => {}}>
                Download Summary Expenditures
              </Button>
            </div> */}
            <MaterialReactTable
              columns={columnMRTExpenditures}
              data={[
                {
                  no: 1,
                  bastNo: "1005470674",
                  invoiceDate: "15-Aug-23",
                  sapInvoiceNo: "",
                  invoiceNumber: "SMI-23080071",
                  invoiceAmountExcl: "362,058,285",
                  vat: "39,826,411",
                  invoiceAmountIncl: "401,884,696",
                  wht: "",
                  paymentAmount: "401,884,696",
                  sumInvoiceToDate: "401,884,696",
                  estValOfWorkNotYetInvoiced: "",
                  dueDate: "14-Sep-23",
                  paymentDate: "",
                  sapPaymentNo: "",
                  receivedDateOfPayment: "",
                  bankClearingNo: "",
                  outStandingContractVal: "IDR 11,565,334,634",
                  remark: "",
                },
                {
                  no: 2,
                  bastNo: "1005470680",
                  invoiceDate: "16-Aug-23",
                  sapInvoiceNo: "",
                  invoiceNumber: "SMI-23080090",
                  invoiceAmountExcl: "80,010,500",
                  vat: "8,801,155",
                  invoiceAmountIncl: "88,811,655",
                  wht: "",
                  paymentAmount: "88,811,655",
                  sumInvoiceToDate: "490,696,351",
                  estValOfWorkNotYetInvoiced: "",
                  dueDate: "16-Sep-23",
                  paymentDate: "",
                  sapPaymentNo: "",
                  receivedDateOfPayment: "",
                  bankClearingNo: "",
                  outStandingContractVal: "IDR 11,476,522,979",
                  remark: "",
                },
              ]}
            />
            <h4>TAX</h4>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={10}>
                    <GroupInput colSize={4} inputAttArr={groupInputAtt3} />
                  </Col>
                  <Col
                    md={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button
                      className="vale-button d-flex align-items-center"
                      onClick={() => {
                        // setCreateModalOpen(true);
                      }}
                    >
                      <FeatherIcon icon="search" />
                      {/* <p>Periksa</p> */}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="d-flex flex-row-reverse">
              <Button className="vale-button">Submit</Button>
            </div>
          </Form>
          <InvoiceGroupInput label="test" type="text" readOnly />
          <Col md={4} className="mb-3">
            <Form.Group>
              <Form.Label>asdf</Form.Label>
              <Form.Control
                type="text"
                readOnly
                // disabled
                defaultValue={"defaultValue"}
                value={aa}
                onChange={(e) => setAa(e.target.value)}
                // {...register(inputId)}
              />
            </Form.Group>
          </Col>
        </Card.Body>
      </Card>
      <ModalAddRowInvoice
        open={openModalAddRow}
        onClose={() => setOpenModalAddRow(false)}
        onSubmit={() => {}}
      />
    </>
  );
};

interface InvoiceGroupInputPropsType {
  label: any;
  readOnly?: boolean;
  defaultValue?: any;
  register?: any;
  inputId?: any;
  type: any;
  disabled?: boolean
}

function InvoiceGroupInput(props: InvoiceGroupInputPropsType) {
  const {
    label,
    type,
    register,
    inputId,
    defaultValue,
    readOnly,
    disabled,
  } = props
  const rest = {
    defaultValue,
    readOnly,
    disabled,
  }
  return (
    <Col md={4} className="mb-3">
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          // {...register(inputId)}
          {...rest}
        />
      </Form.Group>
    </Col>
  );
}

export default InvoiceForm;
