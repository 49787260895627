import * as XLSX from 'xlsx/xlsx.mjs';
import { API_URI } from '../constants';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { toast } from "react-toastify";
const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
const getCompanyMapping = session_storage?.data?.mapping;
// const getCompanySession = [];
const getCompanySession = getCompanyMapping?.map(a => a.get_company?.[0]?.company_initial + ' ' + a.get_company?.[0]?.company_code)
const roleSpecialPayment = (() => {
  const hasil = {}
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
  const role = session_storage?.data?.role?.[0]?.title
  // const getCompanyMapping = session_storage?.data?.mapping;
  // const getCompanySession = [];
  // getCompanyMapping.map(a =>{
  //   getCompanySession.push(a.get_company[0].company_initial + ' ' + a.get_company[0].company_code)
  // })
  const employee = session_storage?.data?.employee
  console.log(session_storage)
  console.log(employee)
  if( role === "System Admin"){
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = true;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = true;
    hasil.iasRead = true;
  } else if (role === 'SAP Admin') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = true;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = false;
    hasil.iasEdit = false;
    hasil.iasRead = false;
  } else if (role === 'Direct Manager') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
  }else if (role === 'Admin AP') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = true;
    hasil.iasRead = false;
  }else if (role === 'Finance Manager') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  }else if (role === 'President Director') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  }else if (role === 'Treasury') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = false;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = false;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  }
  return hasil
})

const getWorkflowRole = ((module) => {
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
  const workflow = session_storage?.workflows;
  const wf_role = [];
  const directManager = {}
  const hasil = {};
  console.log(session_storage)
  console.log(workflow);
  if(session_storage?.directManager){
    for(const a of session_storage?.directManager) {
      if(a?.module_id === '3'){
        directManager.module_id = '3'
        directManager.name = a?.['Direct Manager']
      }
    }
  }
  console.log(directManager);
  if(workflow){
    for(const a of workflow) {
      if(a.module_id === module && !wf_role.includes(a.wf_role)){
        wf_role.push(a.wf_role);
      }
    }
    console.log(wf_role)
    const employee = session_storage?.data?.employee
    hasil.role = wf_role;
    hasil.employee = employee;
    hasil.id = session_storage?.data?.id
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
    // if(directManager?.name){
      // hasil.role.push('Direct Manager')
      // hasil.directManager = directManager?.name
    if(wf_role.includes('Direct Manager')){
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canMultiple = true;
    }
    if(wf_role.includes('Department Manager')){
      // hasil.canEdit = true;
      // hasil.iasEdit = true;
      hasil.canApprove = true;
      hasil.canReject = true;
    }
    // if(wf_role.includes('Finance Manager')){
    //   hasil.iasRead = true;
    // }
    if(wf_role.includes('President Director')){
      hasil.iasRead = true;
    }
    if(wf_role.includes('Releaser FV60')){
      hasil.canEdit = false;
      hasil.canDelete = false;
      hasil.canApprove = true;
      hasil.canReject = true;
    }
    if(wf_role.includes('Treasury')){
      hasil.canApprove = false;
      // hasil.canReject = true;
      hasil.iasRead = true;
      hasil.canEdit = false;
    }
    // console.log(session_storage.data.role[0])
    if(session_storage?.data?.role?.[0]?.title === 'System Admin'){
      const employee = session_storage?.data?.employee
      hasil.role = ['System Admin'];
      hasil.employee = employee;
      hasil.canEdit = true;
      hasil.canDelete = true;
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canMultiple = true;
      hasil.iasEdit = true;
      hasil.iasRead = true;
    }
  }
  console.log(hasil);
  return hasil
})

const roleEmployeeClaim = (() => {
  const hasil = {}
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
  const role = session_storage?.data?.role?.[0]?.title
  // const getCompanyMapping = session_storage?.data?.mapping;
  // const getCompanySession = [];
  // getCompanyMapping.map(a =>{
  //   getCompanySession.push(a.get_company[0].company_initial + ' ' + a.get_company[0].company_code)
  // })
  const employee = session_storage?.data?.employee
  console.log(session_storage)
  console.log(employee)
  if( role === "System Admin"){
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = true;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = true;
    hasil.iasRead = true;
  } else if (role === 'SAP Admin') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = true;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = false;
    hasil.iasEdit = false;
    hasil.iasRead = false;
  } else if (role === 'Direct Manager') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
  }else if (role === 'Admin AP') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = true;
    hasil.iasRead = false;
  }else if (role === 'Finance Manager') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  }else if (role === 'President Director') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  }else if (role === 'Treasury') {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = false;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = false;
    hasil.iasEdit = false;
    hasil.iasRead = true;
  } else {
    hasil.role = role;
    hasil.employee = employee;
    hasil.canEdit = true;
    hasil.canDelete = true;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
  }
  return hasil
})

const getWorkflowRoleEC = ((module) => {
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
  const workflow = session_storage?.workflows;
  const wf_role = [];
  const hasil = {};
  const directManager = {}
  console.log(session_storage)
  console.log(workflow);
  // if(session_storage?.directManager){
  //   for(const a of session_storage?.directManager) {
  //     if(a?.module_id === '3'){
  //       directManager.module_id = '3'
  //       directManager.name = a?.['Direct Manager']
  //     }
  //   }
  // }
  if(workflow){
    for(const a of workflow) {
      if(a.module_id === module && !wf_role.includes(a.wf_role)){
        wf_role.push(a.wf_role);
      }
    }
    console.log(wf_role)
    const employee = session_storage?.data?.employee
    hasil.role = wf_role;
    hasil.employee = employee;
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = true;
    hasil.canReject = true;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
    // if(directManager?.name){
    //   hasil.role.push('Direct Manager')
    //   hasil.directManager = directManager?.name
    // }
    if(wf_role.includes('Direct Reporting Line')){
      // hasil.canApprove = true;
      // hasil.canReject = true;
      hasil.canMultiple = true;
    }
    if(wf_role.includes('Admin AP')){
      hasil.canEdit = false;
      hasil.iasEdit = false;
    }
    if(wf_role.includes('Finance Manager')){
      hasil.iasRead = false;
    }
    if(wf_role.includes('FV60 Releaser')){
      hasil.iasRead = true;
    }
    if(wf_role.includes('Treasury')){
      hasil.canApprove = false;
      hasil.canReject = false;
      hasil.iasRead = false;
      hasil.canEdit = false;
    }
    if(wf_role.includes('SAP Admin')){
      hasil.canEdit = true;
      hasil.canDelete = false;
      hasil.canApprove = true;
      hasil.canReject = true;
    }
    if(wf_role.includes('SAP Admin Direct Report')){
      hasil.canEdit = false;
      hasil.canDelete = false;
      hasil.canApprove = true;
      hasil.canReject = true;
    }
    if(session_storage?.data?.role?.[0]?.title === 'System Admin'){
      const employee = session_storage?.data?.employee
      hasil.role = ['System Admin'];
      hasil.employee = employee;
      hasil.canEdit = true;
      hasil.canDelete = true;
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canMultiple = true;
      hasil.iasEdit = true;
      hasil.iasRead = true;
    }
  }
  return hasil
})

const getWorkflowRoleCC = ((module) => {
  const session_storage = JSON.parse(sessionStorage.getItem("shreyu_user"))
  const workflow = session_storage?.workflows;
  const wf_role = [];
  const hasil = {};
  console.log(session_storage)
  console.log(workflow);
  if(workflow){
    for(const a of workflow) {
      if(a.module_id === module && !wf_role.includes(a.wf_role)){
        wf_role.push(a.wf_role);
      }
    }
    console.log(wf_role)
    const employee = session_storage?.data?.employee
    hasil.role = wf_role;
    hasil.employee = employee;
    hasil.id = session_storage?.data?.id
    hasil.canEdit = false;
    hasil.canDelete = false;
    hasil.canApprove = false;
    hasil.canReject = false;
    hasil.canMultiple = true;
    hasil.iasEdit = false;
    hasil.iasRead = false;
    if(wf_role.includes('Direct Manager')){
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canMultiple = true;
    }
    if(wf_role.includes('Treasury')){
      // hasil.canReject = true;
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canEdit = false;
    }
    if(session_storage?.data?.role?.[0]?.title === 'System Admin'){
      const employee = session_storage?.data?.employee
      hasil.role = ['System Admin'];
      hasil.employee = employee;
      hasil.canEdit = true;
      hasil.canDelete = true;
      hasil.canApprove = true;
      hasil.canReject = true;
      hasil.canMultiple = true;
      hasil.iasEdit = true;
      hasil.iasRead = true;
    }
  }
  // console.log(hasil)
  return hasil
})

const setApprovalAPI = async (data, url=`special-payment-approval`) => {
  return new Promise((resolve, reject) => {
    fetch(API_URI + url, {
      method: "POST",
      body:JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        resolve(data.data)
      }else{
        reject(data.messsage)
      }
    })
    .catch(err => {
      console.log(err)
      reject(err);
    })
  })
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const convertDate = ((dateString) => {
  const a = new Date(dateString);
  return a.getDate() + ' ' + monthNames[a.getMonth()] + ' ' + a.getFullYear();
})

function get_header_row(sheet) {
  var headers = [];
  var range = XLSX.utils.decode_range(sheet['!ref']);
  var C, R = range.s.r; /* start in the first row */
  /* walk every column in the range */
  for(C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
  }
  return headers;
}

const listVendorType = [{
  key: 'LO',
  label: 'Local',
  value: 'Local'
},{
  key: 'NT',
  label: 'National',
  value: 'National'
},{
  key: 'INT',
  label: 'International',
  value: 'International'
},{
  key: 'OT',
  label: 'Others',
  value: 'Others'
},{
  key: 'EC',
  label: 'Employee Claim',
  value: 'Employee Claim'
},{
  key: 'AC',
  label: 'Airlines Charter',
  value: 'Airlines Charter'
}]

const listCompanyOptions= [
  {
    key: 'STM',
    value: 'STM',
    label: 'STM'
  },
  {
    key: 'VEI',
    value: 'VEI',
    label: 'VEI'
  }
]

const valueOptions = ((options, data) => {
  let hasil = 99999999999999
  console.log(options, data);
  if (options && data) {
    for(let i = 0; i<options.length; i++) {
        if(options[i].value == data) {
            hasil = i;
            console.log("ketemu valueOptions di array ke " + i)
            break;
        }
    }
    console.log("valueOptions", hasil, "data", data)
  }
  return hasil
})

// const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const addCommas = num => num.toString().replace(/\B(?<!\,\d{2})(?=(\d{3})+(?!\d))/g, ".");
const changeUStoID = num => {
  if(num) {
    const a = num.toString().replace(/\./g, '').replace(',','.');
    // console.log("a",a)
    const b = Number(parseFloat(a.toString().replace('/\./g', '').replace('/\,/g', '.')).toFixed(2))
    // console.log("b",b)
    let c = b.toLocaleString("in-ID")
    // console.log(c)
    if (c.includes(',')){
      const d = c.split(',');
      if(d[1].length === 1) {
        c+='0'
      // } else if(d[1].length === 2) {
      //   c+= '0'
      // } else if(d[1].length === 3) {
      // } else {
      //   c+= '000'
      }
    }else{
      c += ',00';
    }
    // console.log("c",c)
    return c
  } else {
    return '0,00'
  }
}
const onChangeMoney = (value_lama, e, dontChange = false) => {
  let pointer = e.target.selectionStart;
  const a = changeUStoID(e.target.value)
  if(dontChange) {
    pointer -= 1
  } else {
    if (value_lama?.length) {
      console.log(pointer)
      if( a === '0,00') {
        pointer = 0
      }
      else if( a.length > value_lama.length){
        if( (a.length) === value_lama.length +2) {
          pointer += 1
        }
      } else {
        if( (a.length) === value_lama.length -2) {
          pointer -= 1
        }
      }
      console.log(pointer)
    }
  }
  
  // console.log(a);
  // const pointer = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = pointer;
    element.selectionEnd = pointer;
  });
}
const removeDot = str => str ? str?.toString()?.replace(/\./g, '') : "";
const replaceToDot = str => str.replace(',', '.')
const replaceToComma = str => str.replace('.', ',')
const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
const mineralOrGeo = (num) => {
  if(num === '001'){
    return 'Mineral'
  }else if(num === '002'){
    return 'Geothermal'
  }else{
    return ' - '
  }
}

const mergePDF = (async(a) => {
  try{
    const mergedPdf = await PDFDocument.create();
    const pdf=[]
    for (const b of a) {
      try{
        const pdfA = await PDFDocument.load(b);
        const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
        copiedPagesA.forEach((page) => mergedPdf.addPage(page));
      }catch(err){
        console.log(err)
      }
    }
    const mergedPdfFile = await mergedPdf.save()
    const base64String = "data:application/pdf;base64," + btoa(new Uint8Array(mergedPdfFile).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
  }, ''));
    return base64String;
  }catch(err){
    console.log(err);
    console.log(a);
    return null
  }
})

const getMasterData = (uri, filters ) => {
  console.log(JSON.stringify(filters))
  return new Promise((resolve, reject) => {
    fetch(API_URI + uri, {
      method: "POST",
      body: JSON.stringify(filters)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      resolve(data)
    })
    .catch(err => {
      console.log(err)
      reject(err);
    })
    // console.log(response.json());
    // setDataApi(response);
  })
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const openFilePDF = (dataPDF) => {
  // const win = window.open();
  const [meta, data] = dataPDF.split("base64,")
  const blob = b64toBlob(data, "application/pdf");
  // console.log(blob)
  const url = URL.createObjectURL(blob);
  const win = window.open(url, '_blank');
  win.document.write(`<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
}

const openFilePDFImg = (dataPDF) => {
  const [meta, data] = dataPDF.split(";base64,")
  let type
  let type2;
  console.log(meta);
  if (meta.includes('pdf')) {
    type = meta.split(":")[1]
    type2 = 'pdf'
  } else {
    type = meta.split(":")[1]
    type2 = 'img'
  }
  // if (meta.includes('application/')){
  //   type = "application/" + meta.split("application/")[1]
  //   type2 = type === 'application/pdf' ? 'pdf' : 'img'
  // } else if (meta.includes('image/')){
  //   type = "image/" + meta.split("image/")[1]
  //   type2 = 'img'
  // }
  console.log(type, type2)
  const blob = b64toBlob(data, type);
  const url = URL.createObjectURL(blob);
  const win = window.open(url, '_blank');
  if (type2 === 'pdf') {
    win.document.write(
      `<iframe src="${
        url
      }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  } else {
    win.document.write(
      `
      <img style='display:block; width:400px;height:200px;' id='base64image'
src='${url}' />
      `
    )
  }
}

const checkFileSizeFromBase64 = (dataPDF) => {
  const [meta, data] = dataPDF.split("base64,")
  const decoded = atob(data);
  return decoded.length
}

const checkFile = (e, pdfOnly = true, size = 1048576) => {
  const file_size = e.target.files[0].size;
  const file_type = e.target.files[0].type;
  console.log(file_size, file_type)
  if(file_size > size) {
    if (size === 1048576) {
      toast.warn('File must be below 1MB', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (size === 3 * 1048576) {
      toast.warn('File must be below 3MB', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (size === 5*1048576) {
      toast.warn('File must be below 5MB', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.warn('File must be below 30MB', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return false;
  }
  if(pdfOnly) {
    if(file_type.includes('pdf')){
      return true;
    }else {
      toast.warn('File must be PDF', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  } else {
    if(file_type.includes('jpg') || file_type.includes('jpeg') ||
      file_type.includes('png') || file_type.includes('pdf')
    ){
      return true;
    }else {
      toast.warn('File must be PDF, JPEG, JPG, PNG', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  }
}

const checkColumnFilters = (column, module) => {
  const col = JSON.stringify(column)
  const a = sessionStorage.getItem(module)
  if(a !== col) {
    sessionStorage.setItem(module, col)
  }
}

export {
    roleSpecialPayment,
    roleEmployeeClaim,
    monthNames,
    convertDate,
    get_header_row,
    listVendorType,
    listCompanyOptions,
    valueOptions,
    addCommas,
    removeNonNumeric,
    changeUStoID,
    removeDot,
    onChangeMoney,
    replaceToDot,
    replaceToComma,
    mineralOrGeo,
    setApprovalAPI,
    getCompanySession,
    mergePDF,
    getMasterData,
    b64toBlob,
    openFilePDF,
    openFilePDFImg,
    getWorkflowRole,
    getWorkflowRoleEC,
    getWorkflowRoleCC,
    checkFileSizeFromBase64,
    checkFile,
    checkColumnFilters,
  };
  